import React from "react";

import { Typography, Card, CardContent } from "@material-ui/core";

import Placeholder from "./Placeholder";

const CardLoading = ({ count }) => {
  return (
    <>
      {[...Array(count).keys()].map((a, i) => {
        return (
          <Card style={{ margin: "10px auto" }} key={"loading" + i}>
            <CardContent>
              <Typography variant="h4" style={{ width: "70%" }}>
                <Placeholder variant="text" animation="wave" />
              </Typography>
              <Typography variant="body1" style={{ width: "80%" }}>
                <Placeholder variant="text" animation="wave" />
              </Typography>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  marginBottom: "8px"
                }}
              >
                <Typography variant="body1" style={{ width: "50%" }}>
                  <Placeholder width="50%" variant="text" animation="wave" />
                </Typography>
                <Typography variant="body1" style={{ width: "50%" }}>
                  <Placeholder width="50%" variant="text" animation="wave" />
                </Typography>
              </div>

              {/* <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "flex-end"
                  }}
                >
                  <Placeholder
                    variant="rect"
                    animation="wave"
                    height="36px"
                    width="125px"
                    style={{ margin: "0 6px", borderRadius: "3px" }}
                  />
                  <Placeholder
                    variant="rect"
                    animation="wave"
                    height="36px"
                    width="125px"
                    style={{ margin: "0 6px", borderRadius: "3px" }}
                  />
                </div> */}
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};

export default CardLoading;
