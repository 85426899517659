import React from "react";
import { connect } from "react-redux";
import { on404Error } from "../../store/actions";

import {
  Paper,
  Typography,
  makeStyles,
  Divider,
  Button
} from "@material-ui/core";

import { Link } from "react-router-dom";

const NotAuth = (props) => {
  const classes = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
      width: "100vw",
      padding: "5px"
    },
    paper: { padding: "40px", maxWidth: "600px" },
    line: { display: "flex", alignItems: "center", justifyContent: "center" },
    refresh: {
      textTransform: "none",
      textDecoration: "underline"
    }
  }))();

  const { on404Error } = props;

  const handleRefresh = () => {
    on404Error(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.line}>
            <Typography variant="h5">Not Authorized</Typography>
          </div>
          <Divider variant="fullWidth" style={{ margin: "10px auto" }} />
          <Typography variant="body1" style={{ marginBottom: "20px" }}>
            The resource you are trying to access either does not exist or you
            are not authorized to access it. <br />
            <br />
            You can go back to the previous page or{" "}
            <Link to="/" onClick={handleRefresh}>
              go to the homepage
            </Link>{" "}
            to continue. <br />
            <br />
          </Typography>
          <Button
            className={classes.refresh}
            onClick={(event) => {
              window.location.reload();
            }}
          >
            Try again
          </Button>
        </Paper>
      </div>
    </>
  );
};

export default connect(null, { on404Error })(NotAuth);
