import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";

import {
  onShortAnsAdd,
  onShortAnsRemove,
  onShortAnsTextChange
} from "../../../store/actions";
import { TextField, IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";

const QuestionTypeShortVal = (props) => {
  const { handleAnsDelete, onShortAnsTextChange } = props;

  const textRef = useRef();
  const handleAnsTextChange = (idx) => (event) => {
    onShortAnsTextChange(idx, event.target.value);
  };

  const handleAnsTextLocalChange = () => {};

  const { ans, idx } = props;
  useEffect(() => {
    if (textRef.current) {
      if (!ans) {
        onShortAnsTextChange(idx, "Correct answer");
      } else textRef.current.value = ans;
    }
  });

  return (
    <React.Fragment key={idx}>
      <div style={{ display: "flex", alignItems: "center" }} key={idx}>
        <TextField
          id={`shortAns-${idx}`}
          InputProps={{
            autoComplete: "off"
          }}
          inputRef={textRef}
          defaultValue={ans}
          onBlur={handleAnsTextChange(idx)}
          onChange={handleAnsTextLocalChange}
          autoComplete="off"
          // onKeyDown={handleMoveFocus(idx)}
        />
        <IconButton
          onClick={handleAnsDelete(idx)}
          // className={classes.iconButton}
          aria-label="delete"
          size="small"
        >
          <Clear fontSize="small" />
        </IconButton>
      </div>
    </React.Fragment>
  );
};

const QuestionTypeShort = (props) => {
  const { onShortAnsAdd, onShortAnsRemove, onShortAnsTextChange } = props;

  const { localQuestion } = props.questionEditor;
  const { shortAns } = localQuestion;

  const handleAnsDelete = (idx) => (event) => {
    onShortAnsRemove(idx);
  };

  const handleAnsAdd = (event) => {
    const ansSize = shortAns.length;
    onShortAnsAdd().then(() => {
      handleFocus(ansSize);
    });
  };

  const handleFocus = (idx) => {
    const lastElId = "shortAns-" + String(idx);
    const el = document.getElementById(lastElId);
    el.focus();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      {shortAns.map((ans, idx) => {
        return (
          <QuestionTypeShortVal
            key={"shortAns-" + idx}
            ans={ans}
            idx={idx}
            handleAnsDelete={handleAnsDelete}
            onShortAnsTextChange={onShortAnsTextChange}
          />
        );
      })}
      <div>
        <TextField
          id="action"
          onClick={handleAnsAdd}
          type="text"
          placeholder="Add a correct answer"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    questionEditor: state.questionEditor
  };
};

export default connect(mapStateToProps, {
  onShortAnsAdd,
  onShortAnsRemove,
  onShortAnsTextChange
})(React.memo(QuestionTypeShort));
