import React, { useState } from "react";

import Popover from "@material-ui/core/Popover";
import { Button, IconButton } from "@material-ui/core";

export const withPopover = (Component) => ({
  popover,
  anchorOrigin,
  transformOrigin,
  hover = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    setOpen(false);
  };

  if (hover) {
    props.onMouseEnter = handleOpen;
    props.onMouseLeave = handleClose;
  } else {
    props.onClick = handleOpen;
  }

  return (
    <React.Fragment>
      <Component {...props} />
      <Popover
        id={`simple-popover`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin
            ? anchorOrigin
            : {
                vertical: "top",
                horizontal: "right"
              }
        }
        transformOrigin={
          transformOrigin
            ? transformOrigin
            : {
                vertical: "top",
                horizontal: "right"
              }
        }
        disableRestoreFocus={hover ? true : false}
        style={{ pointerEvents: hover ? "none" : "initial" }}
      >
        {popover}
      </Popover>
    </React.Fragment>
  );
};

export const IconButtonWithPopover = withPopover(IconButton);
export const ButtonWithPopover = withPopover(Button);
