import React from "react";

// mui
import { Button, makeStyles, Typography } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { Lock } from "@material-ui/icons";

const SubmitReviewLimit = (props) => {
  const classes = makeStyles((theme) => ({
    unlock: {
      textTransform: "none",
      padding: "15px 20px"
    },
    sectionName: {
      fontWeight: 600
    },
    summary: {
      border: "black 0.5px solid",
      maxWidth: "300px",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      margin: "15px 0"
    }
  }))();

  const { test, answers, showUnlock } = props;
  const location = useLocation();

  const renderSectionSummary = (
    { counts, score, maxScore, numQuestions },
    sidx
  ) => {
    const { correct, incorrect, answered, unanswered, partial } = counts;
    return (
      <div className={classes.summary}>
        <div style={{ flexGrow: 1, marginRight: "5px" }}>
          {maxScore ? (
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Total Scored: {"" + score}
            </Typography>
          ) : null}
          {maxScore ? (
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Maximum: {"" + maxScore}
            </Typography>
          ) : null}
          <Typography variant="body2">
            Questions: {numQuestions || "-"}
          </Typography>
        </div>
        <div style={{ flexGrow: 1 }}>
          <Typography variant="body2">Correct: {correct || "-"}</Typography>
          {answered ? (
            <Typography variant="body2">Answered: {answered || "-"}</Typography>
          ) : null}
          {partial ? (
            <Typography variant="body2">Partial: {partial || "-"}</Typography>
          ) : null}
          <Typography variant="body2">Incorrect: {incorrect || "-"}</Typography>
          <Typography variant="body2">
            Unanswered: {unanswered || "-"}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div style={{ marginTop: "30px" }}>
        {showUnlock ? (
          <>
            <Link
              to={{ pathname: "/login", search: "?goto=" + location.pathname }}
              className={classes.loginLink}
            >
              <Button
                className={classes.unlock}
                variant="contained"
                color="secondary"
                startIcon={<Lock />}
              >
                Login to View Question Level Review
              </Button>
            </Link>
          </>
        ) : null}
        {test.sections.map((section, sidx) => {
          const sectionAnswers = answers.sections[section._id];
          const { counts = {}, score, maxScore } = sectionAnswers;
          const numQuestions = section.questions ? section.questions.length : 0;
          return (
            <div key={"section" + section._id} style={{ margin: "10px 0" }}>
              <Typography className={classes.sectionName}>
                {section.name}
              </Typography>
              {renderSectionSummary(
                { counts, score, maxScore, numQuestions },
                sidx
              )}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default SubmitReviewLimit;
