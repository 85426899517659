import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import appconfig from "../../config";

let fb = null;
let storage = null;
// let analytics = null;
if (!firebase.apps.length) {
  fb = firebase.initializeApp(appconfig.FB_CONFIG);
  storage = fb.storage();
  // analytics = fb.analytics();
}
export default fb;
export { storage };

const google_provider = new firebase.auth.GoogleAuthProvider();
const fb_provider = new firebase.auth.FacebookAuthProvider();
const twitter_provider = new firebase.auth.TwitterAuthProvider();
const github_provider = new firebase.auth.GithubAuthProvider();

export { google_provider, fb_provider, twitter_provider, github_provider };
