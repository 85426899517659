import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  // Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
// import { Info } from "@material-ui/icons";

import { onLogout, onAccountFetch } from "../../../store/actions";
import UserAvatarWithInfo from "./UserAvatarWithInfo";
import {
  ExpandMore as ExpandMoreIcon,
  GroupAddOutlined,
  AccountCircleOutlined,
} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  divider: { margin: "8px 0" },
  accordion: {
    boxShadow: "none",
    cursor: "pointer",
  },
  listItem: {
    paddingLeft: "2px",
  },
}));

const UserAccordion = (props) => {
  const classes = useStyles();
  // const user = firebase.auth().currentUser;
  const { name, email, role, isVerified } = props.auth;
  const { iconWithInfo } = props;
  const {
    // totalCredits,
    _id,
  } = props.account;
  const { onLogout, onAccountFetch } = props;

  const history = useHistory();
  const handleAccountFetch = useCallback(() => {
    onAccountFetch();
  }, [onAccountFetch]);

  useEffect(() => {
    if (!_id && isVerified) {
      handleAccountFetch();
    }
  }, [_id, isVerified, handleAccountFetch]);

  const handleGoToAccount = () => {
    history.push("/account");
  };

  const handleTutorTests = () => {
    history.push("/t/tests");
  };

  const handleStudentTests = () => {
    history.push("/s/tests");
  };

  const [userInfoExpanded, setUserInfoExpaned] = useState(false);

  return (
    <Accordion
      className={classes.accordion}
      onChange={() => {
        setUserInfoExpaned((userInfoExpanded) => !userInfoExpanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={(e) => {
          e.stopPropagation();
        }}
        id="user-avatar"
      >
        <UserAvatarWithInfo
          name={name}
          email={userInfoExpanded ? email : ""}
          //   onClick={handleClick}
          iconWithInfo={iconWithInfo}
        />
      </AccordionSummary>
      <AccordionDetails>
        <List style={{ width: "100%" }}>
          <ListItem
            button
            onClick={handleGoToAccount}
            className={classes.listItem}
          >
            <ListItemIcon>
              <AccountCircleOutlined />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ style: { fontWeight: 600 } }}
              primary="Account Settings"
            />
          </ListItem>
          {role === "admin" ? (
            <>
              <ListItem
                button
                onClick={handleTutorTests}
                className={classes.listItem}
              >
                <ListItemIcon>
                  <GroupAddOutlined />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ style: { fontWeight: 600 } }}
                  primary="Tutor Tests"
                />
              </ListItem>
              <ListItem
                button
                onClick={handleStudentTests}
                className={classes.listItem}
              >
                <ListItemIcon>
                  <GroupAddOutlined />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ style: { fontWeight: 600 } }}
                  primary="Student Tests"
                />
              </ListItem>
            </>
          ) : null}
          <Divider className={classes.divider} />
          <ListItem
            button
            onClick={(e) => {
              e.preventDefault();
              onLogout();
            }}
            className={classes.listItem}
          >
            <ListItemText
              secondaryTypographyProps={{ style: { fontWeight: 600 } }}
              secondary="Logout"
            />
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  account: state.account,
});

export default connect(mapStateToProps, { onLogout, onAccountFetch })(
  UserAccordion
);
