import * as actionTypes from "../actionTypes";

const initialState = {
  shouldBlockNav: false,
  displaySnack: false,
  snackMessage: "",
  networkError: false,
  notFoundError: false,
  spinner: false,
  loading: false,
  serverDiff: 0,
  userlogout: false
};

const navReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TESTS_MORE_FETCHING:
    case actionTypes.SHARED_TESTS_FETCHING:
    case actionTypes.TESTS_FETCHING:
    case actionTypes.ASSIGNS_FETCHING:
    case actionTypes.TEST_FETCHING:
    case actionTypes.REVIEW_TEST_FETCHING:
    case actionTypes.REVIEW_SUBMIT_FETCHING:
    case actionTypes.ATTEMPT_FETCHING:
    case actionTypes.ATTEMPT_STARTING:
    case actionTypes.SUBMIT_FETCHING:
    case actionTypes.SUBMIT_SUBMITTING:
    case actionTypes.SIGNIN_ANON_START:
    case actionTypes.CHECKOUT_CREATING:
      return { ...state, loading: true };
    case actionTypes.TESTS_FETCH_MORE_SUCCESS:
    case actionTypes.SHARED_TESTS_FETCH_SUCCESS:
    case actionTypes.TESTS_FETCH_SUCCESS:
    case actionTypes.TESTS_FETCH_ERROR:
    case actionTypes.TEST_FETCH_SUCCESS:
    case actionTypes.TEST_FETCH_ERROR:
    case actionTypes.ASSIGNS_FETCH_SUCCESS:
    case actionTypes.ASSIGNS_FETCH_ERROR:
    case actionTypes.REVIEW_TEST_FETCH_SUCCESS:
    case actionTypes.REVIEW_TEST_FETCH_ERROR:
    case actionTypes.REVIEW_SUBMIT_FETCH_SUCCESS:
    case actionTypes.REVIEW_SUBMIT_FETCH_ERROR:
    case actionTypes.ATTEMPT_FETCH_SUCCESS:
    case actionTypes.ATTEMPT_FETCH_ERROR:
    case actionTypes.ATTEMPT_START_SUCCESS:
    case actionTypes.ATTEMPT_START_ERROR:
    case actionTypes.SUBMIT_FETCH_SUCCESS:
    case actionTypes.SUBMIT_FETCH_ERROR:
    case actionTypes.SUBMIT_SUBMIT_SUCCESS:
    case actionTypes.SUBMIT_SUBMIT_ERROR:
    case actionTypes.SIGNIN_ANON_SUCCESS:
    case actionTypes.SIGNIN_ANON_ERROR:
    case actionTypes.CHECKOUT_CREATE_SUCCESS:
    case actionTypes.CHECKOUT_CREATE_ERROR:
      return { ...state, loading: false };
    case actionTypes.TEST_EDITING:
    case actionTypes.TEST_CREATING:
    case actionTypes.TEST_REMOVING:
    case actionTypes.SIGNUP_EP_START:
    case actionTypes.LOGIN_EP_START:
    case actionTypes.SUBMIT_SAVING:
    case actionTypes.REVIEW_TEST_UNASSIGNING:
    case actionTypes.REVIEW_TEST_SUBMIT_DISCARDING:
      return { ...state, spinner: true };
    case actionTypes.TEST_CREATE_SUCCESS:
    case actionTypes.TEST_DUPLICATE_SUCCESS:
    case actionTypes.TEST_REMOVE_SUCCESS:
    case actionTypes.TEST_REMOVE_ERROR:
    case actionTypes.SIGNUP_EP_SUCCESS:
    case actionTypes.SIGNUP_EP_ERROR:
    case actionTypes.LOGIN_EP_SUCCESS:
    case actionTypes.LOGIN_EP_ERROR:
    case actionTypes.SUBMIT_SAVE_SUCCESS:
    case actionTypes.SUBMIT_SAVE_ERROR:
    case actionTypes.REVIEW_TEST_UNASSIGN_SUCCESS:
    case actionTypes.REVIEW_TEST_UNASSIGN_ERROR:
    case actionTypes.REVIEW_TEST_SUBMIT_DISCARD_SUCCESS:
    case actionTypes.REVIEW_TEST_SUBMIT_DISCARD_ERROR:
      return { ...state, spinner: false };
    case actionTypes.USER_NAME_CHANGE_ERROR:
    case actionTypes.TEST_CREATE_ERROR:
      return {
        ...state,
        displaySnack: !!action.payload.message,
        snackMessage: "Error saving changes...",
        spinner: false
      };
    case actionTypes.USER_NAME_CHANGE_SUCCESS:
    case actionTypes.TEST_EDIT_SUCCESS: {
      return {
        ...state,
        shouldBlockNav: false,
        displaySnack: !!action.payload.message,
        snackMessage: "Changes saved",
        spinner: false
      };
    }

    case actionTypes.TEST_EDIT_ERROR:
      return {
        ...state,
        shouldBlockNav: true,
        displaySnack: !!action.payload.message,
        snackMessage: "Error saving changes...",
        spinner: false
      };
    case actionTypes.TEST_SNACK_CLOSE:
      return {
        ...state,
        displaySnack: false
      };
    case actionTypes.TEST_BLOCK_NAV:
      return {
        ...state,
        shouldBlockNav: action.payload.shouldBlockNav
      };

    case actionTypes.NETWORK_ERROR: {
      return {
        ...state,
        networkError: action.payload.networkError,
        notFoundError: action.payload.networkError
          ? false
          : state.notFoundError,
        loading: action.payload.networkError ? false : state.loading,
        spinner: action.payload.networkError ? false : state.spinner
      };
    }
    case actionTypes.NOT_FOUND_ERROR: {
      return {
        ...state,
        notFoundError: action.payload.notFoundError,
        networkError: action.payload.notFoundError ? false : state.networkError,
        loading: action.payload.notFoundError ? false : state.loading,
        spinner: action.payload.notFoundError ? false : state.spinner
      };
    }
    case actionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        userlogout: true
      };
    }
    // From axios
    case "APP_SERVER_DIFF": {
      return {
        ...state,
        serverDiff: action.payload.diff
      };
    }
    default: {
      return state;
    }
  }
};

export default navReducer;
