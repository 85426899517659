import React, { useState } from "react";

// mui
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, Typography, Tabs, Tab, Divider } from "@material-ui/core";
import { CheckCircle, Cancel } from "@material-ui/icons";
import { red, green, blue, lightBlue } from "@material-ui/core/colors";

import Image from "../../../shared/components/UIElements/Image1";
import ImageFull from "../../../shared/components/UIElements/Image";
import clsx from "clsx";

const SubmitReviewLayout = (props) => {
  const classes = makeStyles((theme) => ({
    divider: { margin: "10px 0", width: "100%" },
    checkRoot: {
      // padding: "4px",
      color: `${theme.palette.text.primary} !important`
    },
    formControlLabelDisabled: {
      alignItems: "flex-start",
      color: `${theme.palette.text.primary} !important`,
      marginLeft: 0,
      marginRight: 0
    },
    questionRoot: {
      textAlign: "left",
      margin: "10px 0"
    },
    textAnsInputRoot: {
      "& input": {
        color: `${theme.palette.text.primary} !important`,
        padding: "10px 14px"
      }
    },
    textAnsInput1: {
      border: "2px solid grey !important"
    },
    textAnsInput2: {
      border: "2px solid grey !important",
      backgroundColor: "rgba(128,128,128,0.1)"
    },
    options: { display: "flex", alignItems: "center" },
    correct: {
      border: "2px solid grey",
      backgroundColor: "rgba(128,128,128,0.1)",
      borderRadius: "3px",
      width: "100%",
      paddingRight: "10px"
    },
    incorrect: {
      width: "100%",
      paddingRight: "10px"
    },
    iconcorrect: {
      "& path": {
        fill: theme.palette.icons.safe
      },
      marginRight: "3px"
    },
    iconincorrect: {
      "& path": {
        fill: theme.palette.icons.danger
      },
      marginRight: "5px"
    },
    noIcon1: {
      minWidth: "27px"
    },
    noIcon2: {
      minWidth: "29px"
    },
    scores: {
      backgroundColor: "lightgrey",
      borderRadius: "3px",
      padding: "0 3px",
      marginRight: "5px"
    },
    scoresred: {
      backgroundColor: red[100]
    },
    scoresgreen: {
      backgroundColor: green[100]
    },
    scoresblue: {
      backgroundColor: blue[100]
    },
    scoreslightblue: {
      backgroundColor: lightBlue[100]
    },
    summary: {
      border: "black 0.5px solid",
      maxWidth: "300px",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      margin: "15px 0"
    },
    question: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      width: "100%",
      marginBottom: "20px",
      marginTop: "10px"
    }
  }))();

  const { test, answers } = props;
  const [tabValue, setTabValue] = useState(0);

  const handleTabValue = (event, value) => {
    setTabValue(value);
  };

  const MCQMSComponent = (question, sidx, qidx, answer) => {
    const { options, correct } = question;
    const { msAns = [] } = answer;

    return (
      <FormControl component="fieldset" style={{ width: "100%" }}>
        <FormGroup>
          {options.map((option, oidx) => {
            return (
              <div className={classes.options} key={"" + sidx + qidx + oidx}>
                {msAns.includes(option.text) && correct[oidx] ? (
                  <CheckCircle classes={{ root: classes.iconcorrect }} />
                ) : msAns.includes(option.text) && !correct[oidx] ? (
                  <Cancel classes={{ root: classes.iconincorrect }} />
                ) : correct[oidx] ? (
                  <div className={classes.noIcon1} />
                ) : (
                  <div className={classes.noIcon2} />
                )}
                <FormControlLabel
                  classes={{ disabled: classes.formControlLabelDisabled }}
                  className={
                    correct[oidx] ? classes.correct : classes.incorrect
                  }
                  control={
                    <Checkbox
                      classes={{ root: classes.checkRoot }}
                      color="primary"
                      checked={msAns.includes(option.text) || false}
                      disabled
                      size="small"
                    />
                  }
                  label={
                    <>
                      <Typography style={{ paddingTop: "6px" }}>
                        {option.text}
                      </Typography>
                      {option.image ? (
                        <div>
                          <Image image={option.image} />
                        </div>
                      ) : null}
                    </>
                  }
                />
              </div>
            );
          })}
        </FormGroup>
      </FormControl>
    );
  };

  const MCQSSComponent = (question, sidx, qidx, answer) => {
    const { options, correct } = question;
    const { ssAns = [] } = answer;

    return (
      <FormControl component="fieldset" style={{ width: "100%" }}>
        <RadioGroup aria-label="options" name="options1" value={ssAns}>
          {options.map((option, oidx) => {
            return (
              <div key={"" + sidx + qidx + oidx}>
                <div className={classes.options}>
                  {ssAns === option.text && correct[oidx] ? (
                    <CheckCircle classes={{ root: classes.iconcorrect }} />
                  ) : ssAns === option.text && !correct[oidx] ? (
                    <Cancel classes={{ root: classes.iconincorrect }} />
                  ) : correct[oidx] ? (
                    <div className={classes.noIcon1} />
                  ) : (
                    <div className={classes.noIcon2} />
                  )}
                  <FormControlLabel
                    classes={{ disabled: classes.formControlLabelDisabled }}
                    className={
                      correct[oidx] ? classes.correct : classes.incorrect
                    }
                    value={option.text}
                    disabled
                    control={
                      <Radio
                        classes={{ root: classes.checkRoot }}
                        color="primary"
                        // checked={option.text === ssAns}
                        size="small"
                      />
                    }
                    label={
                      <>
                        <Typography style={{ paddingTop: "6px" }}>
                          {option.text}
                        </Typography>
                        {option.image ? (
                          <div>
                            <Image image={option.image} />
                          </div>
                        ) : null}
                      </>
                    }
                  />
                </div>
              </div>
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  const NumComponent = (question, sidx, qidx, answer) => {
    return (
      <>
        <div className={classes.options}>
          {answer.result === "correct" ? (
            <CheckCircle classes={{ root: classes.iconcorrect }} />
          ) : answer.result === "incorrect" ? (
            <Cancel classes={{ root: classes.iconincorrect }} />
          ) : null}
          <TextField
            placeholder="Answer"
            variant="outlined"
            value={answer.numAns || ""}
            disabled
            // inputProps={{className:classes.textAnsLabelDisabled}}
            InputProps={{
              classes: {
                notchedOutline: !answer.numAns
                  ? classes.textAnsInput1
                  : classes.textAnsInput2,
                root: classes.textAnsInputRoot
              }
            }}
          />
        </div>
        <Typography variant="body2">
          {question.correctAns ? `Answer: ${question.correctAns}` : null}
        </Typography>
      </>
    );
  };

  const ShortComponent = (question, sidx, qidx, answer) => {
    return (
      <>
        <div className={classes.options}>
          {answer.result === "correct" ? (
            <CheckCircle classes={{ root: classes.iconcorrect }} />
          ) : answer.result === "incorrect" ? (
            <Cancel classes={{ root: classes.iconincorrect }} />
          ) : null}
          <TextField
            placeholder="Answer"
            variant="outlined"
            value={answer.shortAns || ""}
            disabled
            // inputProps={{className:classes.textAnsLabelDisabled}}
            InputProps={{
              classes: {
                notchedOutline: !answer.shortAns
                  ? classes.textAnsInput1
                  : classes.textAnsInput2,
                root: classes.textAnsInputRoot
              }
            }}
          />
        </div>
        <Typography variant="body2">
          Answer: {question.shortAns.join(", ")}
        </Typography>
      </>
    );
  };

  const LongComponent = (question, sidx, qidx, answer) => {
    return (
      <>
        <div className={classes.options}>
          <TextField
            multiline
            rowsMax="10"
            placeholder="Answer"
            value={answer.longAns || ""}
            disabled
            InputProps={{
              classes: {
                root: classes.textAnsInputRoot
              }
            }}
            style={{ width: "100%" }}
          />
        </div>
      </>
    );
  };

  const ImageComponent = (question, sidx, qidx, answer) => {
    return (
      <>
        <div className={classes.options}>
          <div style={{ display: "flex", flexWrap: "wrap" }}></div>
          {answer.imagesAns
            ? answer.imagesAns.map((image, i) => {
                return <Image image={image} key={"image" + sidx + qidx + i} />;
              })
            : null}
        </div>
      </>
    );
  };

  const renderQuestionHeader = (question, qidx) => {
    return (
      <Typography variant="h6">
        <span>{`${qidx + 1}. `}</span>
        {question.text}
      </Typography>
    );
  };

  const renderQuestionImage = (question) => {
    return question.image && question.image.url ? (
      <div
        style={{
          display: "flex",
          justifyContent: `${question.image.justify}`
        }}
      >
        <img
          src={question.image.url}
          style={{ width: `${question.image.scale}%` }}
          alt="q"
        />
      </div>
    ) : null;
  };

  const renderQuestionComponent = (question, sidx, qidx, answer) => {
    switch (question.type) {
      case "MCQMS":
        return MCQMSComponent(question, sidx, qidx, answer);
      case "MCQSS":
        return MCQSSComponent(question, sidx, qidx, answer);
      case "NUMBER":
        return NumComponent(question, sidx, qidx, answer);
      case "SHORT":
        return ShortComponent(question, sidx, qidx, answer);
      case "LONG":
        return LongComponent(question, sidx, qidx, answer);
      case "IMAGE":
        return ImageComponent(question, sidx, qidx, answer);
      default:
        return null;
    }
  };

  const renderQuestionScore = ({ markingName, score }) => {
    const scoreText = score === undefined || score === null ? "-" : score;
    return (
      <Typography variant="body2" className={classes.scores}>
        {markingName === "manual"
          ? "To be scored"
          : markingName === "noscore"
          ? "Not scored"
          : `Score: ${scoreText}`}
      </Typography>
    );
  };

  const renderQuestion = (question, sidx, qidx, answer) => {
    const resultKey = {
      unanswered: "Unanswered",
      correct: "Correct",
      incorrect: "Incorrect",
      partial: "Partially Correct",
      answered: "Answered"
    };
    const { result, score } = answer || {};
    const resultText = resultKey[result] || "Unanswered";
    return (
      <div key={question._id} className={classes.question}>
        <div className={classes.questionRoot}>
          {renderQuestionHeader(question, qidx)}
          {renderQuestionImage(question)}
          {renderQuestionComponent(question, sidx, qidx, answer || {})}
        </div>
        <div style={{ display: "flex" }}>
          <Typography
            variant="body2"
            className={clsx(
              classes.scores,
              result === "correct"
                ? classes.scoresgreen
                : result === "incorrect"
                ? classes.scoresred
                : result === "partial"
                ? classes.scoresblue
                : result === "answered"
                ? classes.scoreslightblue
                : null
            )}
          >
            {resultText}
          </Typography>
          <Divider flexItem variant="fullWidth" />
          {renderQuestionScore({
            markingName: question.marking.name,
            score: score
          })}
        </div>
        <Divider className={classes.divider} />
      </div>
    );
  };

  const renderSectionSummary = (
    { counts, score, maxScore, numQuestions },
    sidx
  ) => {
    const { correct, incorrect, answered, unanswered, partial } = counts;
    return (
      <div className={classes.summary}>
        <div style={{ flexGrow: 1, marginRight: "5px" }}>
          {maxScore ? (
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Total Scored: {"" + score}
            </Typography>
          ) : null}
          {maxScore ? (
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Maximum: {"" + maxScore}
            </Typography>
          ) : null}
          <Typography variant="body2">
            Questions: {numQuestions || "-"}
          </Typography>
        </div>
        <div style={{ flexGrow: 1 }}>
          <Typography variant="body2">Correct: {correct || "-"}</Typography>
          {answered ? (
            <Typography variant="body2">Answered: {answered || "-"}</Typography>
          ) : null}
          {partial ? (
            <Typography variant="body2">Partial: {partial || "-"}</Typography>
          ) : null}
          <Typography variant="body2">Incorrect: {incorrect || "-"}</Typography>
          <Typography variant="body2">
            Unanswered: {unanswered || "-"}
          </Typography>
        </div>
      </div>
    );
  };

  const renderSection = (section, sidx, sectionAnswers) => {
    const { counts = {}, score, maxScore } = sectionAnswers;
    const numQuestions = section.questions ? section.questions.length : 0;

    return (
      <div key={section._id} style={{ margin: "10px 0" }}>
        {renderSectionSummary({ counts, score, maxScore, numQuestions }, sidx)}
        <div variant="body2" style={{ textAlign: "left" }}>
          {section.text &&
            section.text.split("\n").map((item, i) => (
              <Typography variant="body2" key={"sectext" + i}>
                {item}
              </Typography>
            ))}
          <div style={{ marginTop: "10px" }}></div>
          <ImageFull image={section.image} />
        </div>
        {section.text && <Divider className={classes.divider} />}
        {section.questions.map((question, qidx) => {
          const answer = sectionAnswers.questions[question._id];
          return renderQuestion(question, sidx, qidx, answer);
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div style={{ marginTop: "30px" }}>
        <div />
        <Tabs
          value={tabValue}
          onChange={handleTabValue}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          style={{ borderBottom: "0.5px solid grey" }}
        >
          {test.sections.map((section, index) => {
            return <Tab key={"tab" + index} label={section.name} />;
          })}
        </Tabs>
        {test.sections.map((section, index) => {
          const sectionAnswers = answers.sections[section._id];
          return (
            <div key={"section" + index} value={tabValue}>
              {tabValue === index &&
                renderSection(section, index, sectionAnswers)}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default SubmitReviewLayout;
