import * as actionTypes from "../actionTypes";

export const onUserSelected = (userSelected) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADMIN_USER_SELECTED,
      payload: { userSelected }
    });
  };
};
