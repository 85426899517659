import React from "react";

import withAppHeader from "../../shared/hoc/withAppHeader";
import TestRouter from "./TestRouter";
import TestTitle from "../components/Test/TestTitle";
import TestHeaderActions from "../components/Test/TestHeaderActions";

const TestRouterWrapper = withAppHeader(TestRouter);

const TestPage = (props) => {
  return (
    <React.Fragment>
      {/* {spinner || (loading && tests) ? <Linear /> : null} */}
      <TestRouterWrapper
        {...props}
        title={<TestTitle />}
        actions={<TestHeaderActions />}        
      />
    </React.Fragment>
  );
};

export default TestPage;
