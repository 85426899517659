import React, { useState, useCallback, useEffect, useRef } from "react";
import { Prompt } from "react-router-dom";
import clsx from "clsx";
// mui
import {
  makeStyles,
  Typography,
  Button,
  Dialog,
  Avatar,
  ListItem,
  IconButton,
  AppBar,
  Toolbar,
  Container,
  Popover,
  Paper,
  Divider,
  Drawer,
  Tabs,
  Tab,
  useMediaQuery,
} from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import {
  Replay,
  Bookmark,
  ChevronRight,
  ChevronLeft,
  ExpandMore,
  Close,
  Person,
  Info,
  InfoOutlined,
} from "@material-ui/icons";

import firebase from "../../shared/utils/firebase";

import ReviewPanel from "./ReviewPanel";
import QuestionLayout from "./QuestionLayout";
import ImageComp from "../../shared/components/UIElements/ImageWithPlaceholder";
import { Spinner } from "../../shared/components/UIElements/Progress";
import Timer from "./Timer";
import TimeDisplay from "../components/TimeDisplay";

// import useTraceUpdate from "../../shared/hooks/trace-hook";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  // "@global": {
  //   body: {
  //     overflow: "hidden"
  //   }
  // },
  content: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "1080px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      border: "none",
    },
    margin: "auto",
    borderWidth: "0px 2px",
    borderStyle: "solid",
    borderColor: theme.palette.border.light,
    boxSizing: "border-box",
  },
  content1: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  topAppBar: {
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    padding: "10px",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  testNameBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  testNameLg: theme.typography.h6,
  testNameSm: theme.typography.body1,
  testName: {
    fontWeight: 600,
    margin: "8px 12px",
    textAlign: "left",
  },
  topBarActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px",
    },
  },
  bottomBar: {
    top: "auto",
    bottom: 0,
    display: "flex",
    flexDirection: "row",
  },
  bottomBarActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: { justifyContent: "flex-end" },
    alignItems: "center",
    flexGrow: 3,
    flexWrap: "wrap",
  },
  actionsDivider: {
    width: "3px",
    height: "84px",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "transparent",
    },
  },
  submitBtnDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  testInsBtn: {
    fontWeight: 600,
    textTransform: "none",
  },
  submitBtn: {
    textTransform: "none",
    fontWeight: "600",
    border: "none",
    margin: "0 8px",
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    color: "white",
  },
  submitConfirm: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  confirmSubmitBtn: {
    textTransform: "none",
    fontWeight: "600",
    border: "none",
    margin: "20px 8px",
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    color: "white",
  },
  cancelSubmitBtn: {
    textTransform: "none",
    fontWeight: "600",
    border: "none",
    margin: "20px 8px",
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    color: "white",
  },
  reviewBtn: {
    border: "none",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    textTransform: "none",
    fontWeight: "600",
    color: "white",
  },
  timerBtn: {
    margin: "0 8px",
    cursor: "default",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  timerBtnText: {
    textTransform: "none",
    fontWeight: "600",
    padding: "1px 0",
  },
  timerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 8px",
    padding: "2px 9px",
    border: "1px solid grey",
    borderRadius: "5px",
    boxSizing: "border-box",
  },
  drawer: {
    width: `${drawerWidth}px`,
    height: "100%",
    padding: "64px 0px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  drawerPaper: {
    height: "100%",
    width: `${drawerWidth}px`,
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  reviewPanel: {
    position: "relative",
    overflow: "auto",
  },
  questionContainer: {
    padding: "0 12px",
    margin: 0,
    overflow: "auto",
    position: "relative",
  },
  actionBtn: {
    textTransform: "none",
    fontWeight: "600",
    margin: "0 4px",
    border: "1px solid transparent",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  actionBtnSm: {
    margin: "0 4px",
    border: "1px solid transparent",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  clearBtn: {
    color: theme.palette.button.textBtn,
    textTransform: "none",
    fontWeight: "600",
    margin: "0 4px",
    border: "1px solid transparent",
    backgroundColor: "none",
  },
  bookmarkBtn: {
    color: theme.palette.button.textBtn,
    textTransform: "none",
    fontWeight: "600",
    margin: "0 4px",
    border: "1px solid transparent",
    backgroundColor: "none",
  },
  bookmarkBtnOn: {
    color: orange[400],
    textTransform: "none",
    fontWeight: "600",
    margin: "0 4px",
    border: "1px solid transparent",
  },
  sectionName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "left",
    padding: "10px 10px",
    cursor: "pointer",
  },
  tabsContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "8px 0",
    },
    padding: "16px 0",
    display: "flex",
    alignItems: "center",
  },
  sectionTabsRoot: {
    flexGrow: 1,
    width: "100%",
  },
  sectionTabsScroller: {
    marginTop: "4px",
  },
  sectionIndicator: {
    minHeight: "0px",
    background: "transparent",
  },
  sectionNameRoot: {
    textTransform: "none",
    fontWeight: "600",
    marginRight: "8px",
    minHeight: 0,
    borderColor: theme.palette.button.borderDefault,
    backgroundColor: theme.palette.button.default,
    borderRadius: "3px",
    border: "1px solid",
  },
  sectionSelected: {
    color: "white",
    fontWeight: "bold",
    backgroundColor: theme.palette.button.textBtn,
  },
  questionRoot: {
    textAlign: "left",
    marginTop: "12px",
    padding: "0 8px",
  },
  questionNumber: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.primary.dark,
  },
  questionHeader: {
    marginTop: "12px",
  },
  sectionSelector: { "&:hover": { background: "none" } },
  sectionToolBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  selectSection: {
    paddingLeft: "12px",
    fontWeight: "600",
  },

  morePopup: {
    padding: "10px",
  },
  // gap: {
  //   height: 0,
  //   padding: 0,
  //   [theme.breakpoints.down("xs")]: {
  //     padding: "64px",
  //   },
  // },
}));

const SubmitLayout = (props) => {
  const classes = useStyles();

  // const trigger = useScrollTrigger({
  //   disableHysteresis: true,
  //   threshold: 100
  // });
  const breakpoint = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const [reviewOpen, setReviewOpen] = useState(false);
  const [currentSectionId, setCurrentSectionId] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [submitConfirm, setSubmitConfirm] = useState(null);
  const [morePopup, setMorePopup] = useState(null);
  const [testInsOpen, setTestInsOpen] = useState(false);
  const [sectionInsOpen, setSectionInsOpen] = useState(false);
  const firstLoad = useRef(true);
  const user = firebase.auth().currentUser;

  const {
    test,
    answers = [],
    endTime,
    serverDiff,
    shouldBlockNav,
    // spinner,
    loading,
    // handleTestSave,
    handleTestSubmit,
    handleMSChange,
    handleSSChange,
    handleNumAnsChange,
    handleShortAnsChange,
    handleLongAnsChange,
    handleImagesAnsChange,
    handleMarkForReview,
  } = props;

  const {
    sectionsMap: sectionsAMap = new Map(),
    questionsMap: questionsAMap = new Map(),
    questionIds,
  } = answers || [];
  const {
    sectionsMap: sectionsTMap = new Map(),
    questionsMap: questionsTMap = new Map(),
    name,
    intro,
    imageUrls,
  } = test || {};

  const { instructions, introImage } = intro || {};
  const isLast =
    !currentQuestion ||
    !currentQuestion.questionId ||
    questionIds.indexOf(currentQuestion.questionId) === questionIds.length - 1;

  const isFirst =
    !currentQuestion ||
    !currentQuestion.questionId ||
    questionIds.indexOf(currentQuestion.questionId) === 0;

  const handleReviewOpen = () => {
    setReviewOpen(true);
  };

  const handleReviewClose = () => {
    setReviewOpen(false);
  };

  const handleSectionSelected = (sectionId) => {
    const qIds = sectionsAMap.get(sectionId).questionIds;
    const qId = qIds.length > 0 ? qIds[0] : null;
    handleQuestionSelected(qId, sectionId);
  };

  const handleQuestionSelected = useCallback(
    (questionId, sectionId) => {
      let qId = questionId;
      if (qId === "start") qId = questionIds[0];
      setCurrentQuestion(questionsAMap.get(qId));
      if (sectionId) setCurrentSectionId(sectionId);
      else
        setCurrentSectionId(
          questionsAMap.has(qId) && questionsAMap.get(qId).sectionId
        );
    },
    [questionsAMap, questionIds]
  );

  const handleClearAns = (question) => {
    switch (questionsTMap.get(question.questionId).type) {
      case "MCQMS":
        return handleMSChange(question.questionId);
      case "MCQSS":
        return handleSSChange(question.questionId);
      case "NUMBER":
        return handleNumAnsChange(question.questionId);
      case "SHORT":
        return handleShortAnsChange(question.questionId);
      case "LONG":
        return handleLongAnsChange(question.questionId);
      case "IMAGE":
        return handleImagesAnsChange(question.questionId);
      default:
        return;
    }
  };

  const handleReview = (question, value) => {
    handleMarkForReview(question.questionId, value);
  };

  const handleNextQuestion = () => {
    if (currentQuestion && currentQuestion.questionId) {
      const i = questionIds.indexOf(currentQuestion.questionId);
      if (!isLast) handleQuestionSelected(questionIds[i + 1]);
    } else handleQuestionSelected("start");
    // const sidx = Array.from(sectionsAMap.keys()).indexOf(currentSectionId);
    // const qidx = currentQuestion.index;
    // answers.sections[sidx].questions[qidx] = { ...currentQuestion };
    // handleTestSave();
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion && currentQuestion.questionId)
      handleQuestionSelected(
        questionIds[questionIds.indexOf(currentQuestion.questionId) - 1]
      );
    else handleQuestionSelected("start");
  };

  const handleSubmitConfirmOpen = (event) => {
    setSubmitConfirm(event.target);
  };

  const handleSubmitConfirmClose = (event) => {
    setSubmitConfirm(null);
  };

  const handleMorePopupOpen = (event) => {
    setMorePopup(event.target);
  };

  const handleMorePopupClose = (event) => {
    setMorePopup(null);
  };

  const handleTestInsOpen = (event) => {
    setTestInsOpen(true);
    setMorePopup(null);
  };

  const handleTestInsClose = (event) => {
    setTestInsOpen(false);
  };

  const handleSectionInsOpen = (event) => {
    setSectionInsOpen(true);
  };

  const handleSectionInsClose = (event) => {
    setSectionInsOpen(false);
  };

  const handleSubmit = useCallback(
    (mode) => {
      const { sections } = answers;
      const questionsArr = Array.from(questionsAMap.values());
      for (const section of sections) {
        section.questions = questionsArr.splice(0, section.questions.length);
      }
      handleTestSubmit(mode);
    },
    [handleTestSubmit, answers, questionsAMap]
  );

  const handleTimeUp = useCallback(
    (clock) => {
      const { diff } = clock;
      if (diff <= 0) {
        handleSubmit("EXPIRED");
      }
    },
    [handleSubmit]
  );

  useEffect(() => {
    if (firstLoad.current && sectionsAMap && sectionsAMap.size > 0) {
      firstLoad.current = false;
      handleQuestionSelected("start");
      /**
       * This is a way of making a call to every image url in the test
       * and getting it cached in the browser. This avoids a lag in loading
       * the image when the user navigates to a  question that has an image.
       */
      for (const url of imageUrls) {
        new Image().src = url;
      }
    }
  }, [sectionsAMap, handleQuestionSelected, imageUrls]);

  const renderTimer = () => (
    <Timer
      onChange={handleTimeUp}
      endTime={endTime}
      showZero
      offset={serverDiff}
      TimeDisplay={TimeDisplay}
    />
  );

  const renderQuestionNumber = (question, qidx) => {
    return (
      <div className={classes.questionNumber}>
        <div style={{ minWidth: "40%" }}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {`Question ${qidx + 1}. `}
          </Typography>
        </div>
        <div style={{ textAlign: "right" }}>
          {question.answered ? (
            <Button
              size={breakpoint ? "small" : "medium"}
              className={classes.clearBtn}
              onClick={(e) => {
                e.preventDefault();
                handleClearAns(question);
              }}
              startIcon={breakpoint ? null : <Replay fontSize="small" />}
              variant="outlined"
            >
              {breakpoint ? "Clear" : "Clear response"}
            </Button>
          ) : null}
          {!breakpoint ? (
            <Button
              size="medium"
              className={
                question.review ? classes.bookmarkBtnOn : classes.bookmarkBtn
              }
              onClick={(e) => {
                e.preventDefault();
                handleReview(currentQuestion, !question.review);
              }}
              variant="outlined"
              startIcon={<Bookmark fontSize="default" />}
              disableRipple
              disableFocusRipple
            >
              {question.review ? "Marked for review" : "Mark for review"}
            </Button>
          ) : (
            <IconButton
              // size="small"
              className={
                question.review ? classes.bookmarkBtnOn : classes.bookmarkBtn
              }
              onClick={(e) => {
                e.preventDefault();
                handleReview(currentQuestion, !question.review);
              }}
            >
              <Bookmark
                className={
                  question.review ? classes.bookmarkBtnOn : classes.bookmarkBtn
                }
                fontSize="default"
              />
            </IconButton>
          )}
        </div>
      </div>
    );
  };

  const renderQuestionHeader = (question) => {
    return (
      <>
        <Typography className={classes.questionHeader}>
          {question.text}
        </Typography>
      </>
    );
  };

  const renderQuestionImage = (question) => {
    return <ImageComp image={question.image} />;
  };

  const renderQuestionComponent = (question, questionAns) => {
    const sectionId = question.sectionId;
    const config = {
      question,
      questionAns,
      sid: sectionId,
      classes,
    };

    return (
      <QuestionLayout
        {...config}
        handleMSChange={handleMSChange}
        handleSSChange={handleSSChange}
        handleNumAnsChange={handleNumAnsChange}
        handleShortAnsChange={handleShortAnsChange}
        handleLongAnsChange={handleLongAnsChange}
        handleImagesAnsChange={handleImagesAnsChange}
      />
    );
  };

  const renderQuestion = (questionId) => {
    let qId = questionId;
    if (!qId) return;
    const qa = questionsAMap.get(qId);
    const qt = questionsTMap.get(qId);
    return (
      <>
        <Paper className={classes.questionRoot} elevation={0}>
          {renderQuestionNumber(currentQuestion, qa.index)}
          {renderQuestionHeader(qt)}
          {renderQuestionImage(qt)}
          {renderQuestionComponent(qt, qa)}
        </Paper>
      </>
    );
  };

  return (
    <>
      <Prompt
        when={shouldBlockNav}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {sectionsAMap.size > 0 ? (
        <>
          <div
            className={
              reviewOpen && !breakpoint
                ? clsx(classes.content, classes.content1)
                : classes.content
            }
          >
            {/* Top bar */}
            <div>
              <AppBar
                color="default"
                position="sticky"
                elevation={0}
                className={classes.topAppBar}
              >
                <div className={classes.testNameBox}>
                  <Typography
                    className={clsx(
                      classes.testName,
                      breakpoint ? classes.testNameSm : classes.testNameLg
                    )}
                  >
                    {name}
                  </Typography>
                  {instructions || introImage ? (
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        handleMorePopupOpen(e);
                      }}
                    >
                      <InfoOutlined fontSize="default" />
                    </IconButton>
                  ) : null}
                </div>
                <div className={classes.topBarActions}>
                  {endTime ? renderTimer() : <div style={{ height: "36px" }} />}
                  {reviewOpen && !breakpoint ? null : (
                    <Button
                      className={classes.reviewBtn}
                      variant="outlined"
                      size="large"
                      disableFocusRipple
                      onClick={(e) => {
                        e.preventDefault();
                        handleReviewOpen(e);
                      }}
                      endIcon={breakpoint ? <ExpandMore /> : <ChevronRight />}
                    >
                      Review
                    </Button>
                  )}
                </div>
              </AppBar>
              {/* Scroll content */}
              <Container className={classes.questionContainer}>
                {/* Section title on mobile */}
                <div className={classes.tabsContainer}>
                  {currentSectionId ? (
                    <>
                      <IconButton
                        disableRipple
                        onClick={(e) => {
                          e.preventDefault();
                          handleSectionInsOpen(e);
                        }}
                      >
                        <Info />
                      </IconButton>
                      <Tabs
                        value={currentSectionId}
                        onChange={null}
                        // indicatorColor="inherit"
                        textColor="inherit"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        classes={{
                          indicator: classes.sectionIndicator,
                          root: classes.sectionTabsRoot,
                          scroller: classes.sectionTabsScroller,
                        }}
                      >
                        {Array.from(sectionsAMap.keys()).map((id, i) => (
                          <Tab
                            key={"tab" + id}
                            classes={{
                              root: classes.sectionNameRoot,
                              selected: classes.sectionSelected,
                            }}
                            disableRipple
                            label={sectionsTMap.get(id).name}
                            onClick={(e) => {
                              e.preventDefault();
                              handleSectionSelected(id);
                              // handleSectionSelectClose();
                            }}
                            disableFocusRipple
                            value={id}
                          />
                        ))}
                      </Tabs>
                    </>
                  ) : null}
                </div>
                <Divider />
                {/* Question layout */}
                {currentQuestion
                  ? renderQuestion(currentQuestion.questionId)
                  : null}

                {/* Review Panel */}
              </Container>
            </div>
            {/* Bottom bar on mobile */}
            <AppBar
              color="default"
              position="sticky"
              elevation={0}
              className={classes.bottomBar}
            >
              <div className={classes.bottomBarActions}>
                {breakpoint ? (
                  <>
                    <IconButton
                      className={clsx(classes.actionBtnSm)}
                      variant="outlined"
                      size="medium"
                      disableFocusRipple
                      disabled={isFirst}
                      onClick={(e) => {
                        e.preventDefault();
                        handlePreviousQuestion(e);
                      }}
                    >
                      <ChevronLeft />
                    </IconButton>
                    <IconButton
                      className={clsx(classes.actionBtnSm)}
                      variant="outlined"
                      size="medium"
                      disableFocusRipple
                      onClick={(e) => {
                        e.preventDefault();
                        handleNextQuestion(e);
                      }}
                      disabled={isLast}
                    >
                      <ChevronRight />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Button
                      startIcon={<ChevronLeft />}
                      className={clsx(classes.actionBtn)}
                      variant="outlined"
                      size="large"
                      disableFocusRipple
                      disabled={isFirst}
                      onClick={(e) => {
                        e.preventDefault();
                        handlePreviousQuestion(e);
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      className={clsx(classes.actionBtn)}
                      variant="outlined"
                      size="large"
                      disableFocusRipple
                      onClick={(e) => {
                        e.preventDefault();
                        handleNextQuestion(e);
                      }}
                      disabled={isLast}
                      endIcon={<ChevronRight />}
                    >
                      Next
                    </Button>
                  </>
                )}
              </div>
              <Divider
                orientation="vertical"
                flexItem
                className={classes.actionsDivider}
              />

              <div className={classes.submitBtnDiv}>
                <Button
                  className={classes.submitBtn}
                  variant="outlined"
                  size={breakpoint ? "large" : "large"}
                  disableFocusRipple
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmitConfirmOpen(e);
                  }}
                >
                  Submit
                </Button>
              </div>
              <Toolbar></Toolbar>
            </AppBar>

            {/* Modals */}
          </div>
          <div className={classes.gap}></div>
          <Drawer
            open={reviewOpen}
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
            anchor={breakpoint ? "bottom" : "right"}
            onClose={handleReviewClose}
            variant={!breakpoint && reviewOpen ? "permanent" : "temporary"}
          >
            <div className={classes.reviewPanel}>
              <ReviewPanel
                answers={answers}
                open={reviewOpen}
                handleClose={handleReviewClose}
                selected={currentQuestion ? currentQuestion.questionId : null}
                handleQuestionSelected={handleQuestionSelected}
              />
            </div>
          </Drawer>

          {/* Test Instructions */}
          <Dialog
            fullScreen={breakpoint ? true : false}
            open={testInsOpen}
            onClose={handleTestInsClose}
            fullWidth
          >
            <AppBar color="default" position="sticky" elevation={0}>
              <Toolbar variant="dense" className={classes.sectionToolBar}>
                <Typography variant="body1" className={classes.selectSection}>
                  Test Instructions
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    e.preventDefault();
                    handleTestInsClose(e);
                  }}
                >
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>

            <div style={{ padding: "12px" }}>
              <div>
                {instructions &&
                  instructions
                    .split("\n")
                    .map((item, i) => (
                      <Typography key={"ins" + i}>{item}</Typography>
                    ))}
              </div>
              <ImageComp image={introImage} />
            </div>
          </Dialog>

          {/* Section Instructions */}
          <Dialog
            fullScreen={breakpoint ? true : false}
            open={sectionInsOpen}
            onClose={handleSectionInsClose}
            fullWidth
          >
            <AppBar color="default" position="sticky" elevation={0}>
              <Toolbar variant="dense" className={classes.sectionToolBar}>
                <Typography variant="body1" className={classes.selectSection}>
                  {currentSectionId && sectionsTMap.get(currentSectionId).name}
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSectionInsClose(e);
                  }}
                >
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>

            <div style={{ padding: "12px" }}>
              <div variant="body2" style={{ textAlign: "left" }}>
                {currentSectionId &&
                  sectionsTMap.get(currentSectionId).text &&
                  sectionsTMap
                    .get(currentSectionId)
                    .text.split("\n")
                    .map((item, i) => (
                      <Typography variant="body2" key={"sectext" + i}>
                        {item}
                      </Typography>
                    ))}
              </div>
              {currentSectionId && (
                <ImageComp image={sectionsTMap.get(currentSectionId).image} />
              )}
            </div>
          </Dialog>

          {/* Submit Confirm Popover */}
          <Dialog
            open={Boolean(submitConfirm)}
            // anchorEl={submitConfirm}
            // anchorOrigin={{
            //   vertical: "center",
            //   horizontal: "right",
            // }}
            // transformOrigin={{
            //   vertical: "center",
            //   horizontal: "right",
            // }}
            fullWidth
            maxWidth="xs"
            classes={{
              paper: classes.submitConfirm,
            }}
            onClose={handleSubmitConfirmClose}
            disableRestoreFocus
          >
            <Button
              variant="contained"
              className={classes.confirmSubmitBtn}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit("USER");
                handleSubmitConfirmClose(e);
              }}
              size="large"
            >
              Confirm Submit
            </Button>
            <Button
              variant="contained"
              className={classes.cancelSubmitBtn}
              onClick={(e) => {
                e.preventDefault();
                handleSubmitConfirmClose(e);
              }}
              size="large"
            >
              Cancel
            </Button>
          </Dialog>

          {/* More Options Popup */}
          <Popover
            open={Boolean(morePopup)}
            anchorEl={morePopup}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            classes={{
              paper: classes.morePopup,
            }}
            onClose={handleMorePopupClose}
            disableRestoreFocus
          >
            <ListItem className={classes.profile} style={{ display: "flex" }}>
              <Avatar style={{ marginRight: "10px" }}>
                <Person />
              </Avatar>
              <div>
                <Typography>{user.displayName}</Typography>
                <Typography variant="body2">{user.email}</Typography>
              </div>
            </ListItem>
            <Divider style={{ marginTop: "12px" }} />
            <ListItem>
              <Button
                className={classes.testInsBtn}
                onClick={(e) => {
                  e.preventDefault();
                  handleTestInsOpen(e);
                }}
              >
                Test Instructions
              </Button>
            </ListItem>
          </Popover>
        </>
      ) : null}
      {loading ? <Spinner /> : null}
    </>
  );
};

export default SubmitLayout;
