export const NETWORK_ERROR = "NETWORK_ERROR";
export const NOT_FOUND_ERROR = "NOT_FOUND_ERROR";
export const ADMIN_USER_SELECTED = "ADMIN_USER_SELECTED";
export const AUTH_CHANGE = "AUTH_CHANGE";
export const NONE = "NONE";
export const ABORT_AUTH_AXIOS = "ABORT_AUTH_AXIOS";
export const SIGNIN_ANON_START = "SIGNIN_ANON_START";
export const SIGNIN_ANON_SUCCESS = "SIGNIN_ANON_SUCCESS";
export const SIGNIN_ANON_ERROR = "SIGNIN_ANON_ERROR";
export const SIGNUP_EP_START = "SIGNUP_EP_START";
export const SIGNUP_EP_SUCCESS = "SIGNUP_EP_SUCCESS";
export const SIGNUP_EP_ERROR = "SIGNUP_EP_ERROR";
export const SIGNUP_GOOGLE_START = "SIGNUP_GOOGLE_START";
export const SIGNUP_GOOGLE_SUCCESS = "SIGNUP_GOOGLE_SUCCESS";
export const SIGNUP_GOOGLE_ERROR = "SIGNUP_GOOGLE_ERROR";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const USER_NAME_CHANGE = "USER_NAME_CHANGE";
export const USER_NAME_CHANGE_SUCCESS = "USER_NAME_CHANGE_SUCCESS";
export const USER_NAME_CHANGE_ERROR = "USER_NAME_CHANGE_ERROR";

export const LOGIN_EP_START = "LOGIN_EP_START";
export const LOGIN_EP_SUCCESS = "LOGIN_EP_SUCCESS";
export const LOGIN_EP_ERROR = "LOGIN_EP_ERROR";
export const LOGIN_GOOGLE_START = "LOGIN_GOOGLE_START";
export const LOGIN_GOOGLE_SUCCESS = "LOGIN_GOOGLE_SUCCESS";
export const LOGIN_GOOGLE_ERROR = "LOGIN_GOOGLE_ERROR";

export const TESTS_FETCHING = "TESTS_FETCHING";
export const TESTS_FETCH_SUCCESS = "TESTS_FETCH_SUCCESS";
export const TESTS_MORE_FETCHING = "TESTS_MORE_FETCHING";
export const TESTS_FETCH_MORE_SUCCESS = "TESTS_FETCH_MORE_SUCCESS";
export const TESTS_FETCH_ERROR = "TESTS_FETCH_ERROR";
export const SHARED_TESTS_FETCHING = "SHARED_TESTS_FETCHING";
export const SHARED_TESTS_FETCH_SUCCESS = "SHARED_TESTS_FETCH_SUCCESS";
export const TESTS_SEARCHING = "TESTS_SEARCHING";
//
export const TEST_CREATING = "TEST_CREATING";
export const TEST_CREATE_SUCCESS = "TEST_CREATE_SUCCESS";
export const TEST_DUPLICATE_SUCCESS = "TEST_DUPLICATE_SUCCESS";
export const TEST_CREATE_ERROR = "TEST_CREATE_ERROR";
//
export const TEST_REMOVING = "TEST_REMOVING";
export const TEST_REMOVE_SUCCESS = "TEST_REMOVE_SUCCESS";
export const TEST_REMOVE_ERROR = "TEST_REMOVE_ERROR";
//
export const TEST_FETCHING = "TEST_FETCHING";
export const TEST_FETCH_SUCCESS = "TEST_FETCH_SUCCESS";
export const TEST_FETCH_ERROR = "TEST_FETCH_ERROR";
//
export const TEST_EDITING = "TEST_EDITING";
export const TEST_EDIT_SUCCESS = "TEST_EDIT_SUCCESS";
export const TEST_EDIT_ERROR = "TEST_EDIT_ERROR";
export const TEST_NAME_UPDATING = "TEST_NAME_UPDATING";
export const TEST_SECTION_REORDERING = "TEST_SECTION_REORDERING";
//
export const TEST_SHARED = "TEST_SHARED";
export const TEST_SECTION_ADDED = "TEST_SECTION_ADDED";
export const TEST_SECTION_EDITED = "TEST_SECTION_EDITED";
export const TEST_SECTION_REORDERED = "TEST_SECTION_REORDERED";
export const TEST_SECTION_REMOVED = "TEST_SETTINGS_REMOVED";
export const TEST_QUESTION_REORDERED = "TEST_QUESTION_REORDERED";
export const TEST_QUESTION_ADDED = "TEST_QUESTION_ADDED";
export const TEST_QUESTION_EDITED = "TEST_QUESTION_EDITED";
export const TEST_QUESTION_REMOVED = "TEST_QUESTION_REMOVED";
export const TEST_SETTINGS_UPDATED = "TEST_SETTINGS_UPDATED";
export const TEST_TAGS_UPDATED = "TEST_TAGS_UPDATED";
export const TEST_ASSIGNED = "TEST_ASSIGNED";
//
export const TEST_NAME_UPDATED = "TEST_NAME_CHANGED";
export const TEST_OPEN_UPDATED = "TEST_OPEN_UPDATED";
export const TEST_SECTION_SELECTED = "TEST_SECTION_SELECTED";
export const TEST_ERROR_RESET = "TEST_ERROR_RESET";
export const TEST_BLOCK_NAV = "TEST_BLOCK_NAV";
export const TEST_SNACK_CLOSE = "TEST_SNACK_CLOSE";
export const SECTION_EDITOR_OPEN = "SECTION_EDITOR_OPEN";
export const SECTION_EDITOR_CLOSE = "SECTION_EDITOR_CLOSE";
export const QUESTION_EDITOR_OPEN = "QUESTION_EDITOR_OPEN";
export const QUESTION_EDITOR_CLOSE = "QUESTION_EDITOR_CLOSE";

//
export const SECTION_CREATING_NEW = "SECTION_CREATING_NEW";
export const SECTION_EDITING = "SECTION_EDITING";
//
export const SECTION_NAME_CHANGE = "SECTION_NAME_CHANGE";
export const SECTION_MARKING_CHANGE = "SECTION_MARKING_CHANGE";
export const SECTION_MARKING_FROM_CHANGE = "SECTION_MARKING_FROM_CHANGE";
export const SECTION_TEXT_CHANGE = "SECTION_TEXT_CHANGE";
export const SECTION_IMAGE_CHANGE = "SECTION_IMAGE_CHANGE";
export const SECTION_QTYPE_CHANGE = "SECTION_QTYPE_CHANGE";
// export const SECTION_SEQ_CHANGE = "SECTION_SEQ_CHANGE"

export const QUESTION_CREATING_NEW = "QUESTION_CREATING_NEW";
export const QUESTION_EDITING = "QUESTION_EDITING";

export const QUESTION_TEXT_CHANGE = "QUESTION_TEXT_CHANGE";
export const QUESTION_TYPE_CHANGE = "QUESTION_TYPE_CHANGE";
export const QUESTION_CORRECT_ANS_CHANGE = "QUESTION_CORRECT_ANS_CHANGE";
export const QUESTION_OPTION_ADD = "QUESTION_OPTION_ADD";
export const QUESTION_OPTION_REMOVE = "QUESTION_OPTION_REMOVE";
export const QUESTION_OPTION_TEXT_CHANGE = "QUESTION_OPTION_TEXT_CHANGE";
export const QUESTION_OPTION_IMAGE_CHANGE = "QUESTION_OPTION_IMAGE_CHANGE";
export const QUESTION_OPTION_CORRECT_CHANGE = "QUESTION_OPTION_CORRECT_CHANGE";
export const QUESTION_OPTIONS_REORDER = "QUESTION_OPTIONS_REORDER";
export const QUESTION_SHORTANS_ADD = "QUESTION_SHORTANS_ADD";
export const QUESTION_SHORTANS_REMOVE = "QUESTION_SHORTANS_REMOVE";
export const QUESTION_SHORTANS_TEXT_CHANGE = "QUESTION_SHORTANS_TEXT_CHANGE";
export const QUESTION_NUMIMAGES_CHANGE = "QUESTION_NUMIMAGES_CHANGE";
export const QUESTION_IMAGE_LOADING = "QUESTION_IMAGE_LOADING";
export const QUESTION_IMAGE_CHANGE = "QUESTION_IMAGE_CHANGE";
export const QUESTION_MARKING_CHANGE = "QUESTION_MARKING_CHANGE";
export const QUESTION_MARKING_FROM_CHANGE = "QUESTION_MARKING_FROM_CHANGE";
export const QUESTION_SEQUENCE_CHANGE = "QUESTION_SEQUENCE_CHANGE";

/* Tutor Screens */
export const REVIEW_TEST_FETCHING = "REVIEW_TEST_FETCHING";
export const REVIEW_TEST_FETCH_SUCCESS = "REVIEW_TEST_FETCH_SUCCESS";
export const REVIEW_TEST_FETCH_ERROR = "REVIEW_TEST_FETCH_ERROR";

export const REVIEW_TEST_UNASSIGNING = "REVIEW_TEST_UNASSIGNING";
export const REVIEW_TEST_UNASSIGN_SUCCESS = "REVIEW_TEST_UNASSIGN_SUCCESS";
export const REVIEW_TEST_UNASSIGN_ERROR = "REVIEW_TEST_UNASSIGN_ERROR";

export const REVIEW_SUBMIT_FETCHING = "REVIEW_SUBMIT_FETCHING";
export const REVIEW_SUBMIT_FETCH_SUCCESS = "REVIEW_SUBMIT_FETCH_SUCCESS";
export const REVIEW_SUBMIT_FETCH_ERROR = "REVIEW_SUBMIT_FETCH_ERROR";

export const REVIEW_TEST_SUBMIT_DISCARDING = "REVIEW_TEST_SUBMIT_DISCARDING";
export const REVIEW_TEST_SUBMIT_DISCARD_SUCCESS =
  "REVIEW_TEST_SUBMIT_DISCARD_SUCCESS";
export const REVIEW_TEST_SUBMIT_DISCARD_ERROR =
  "REVIEW_TEST_SUBMIT_DISCARD_ERROR";

/* Student  screens */
// See all tests that are assigned
export const ASSIGNS_FETCHING = "ASSIGNS_FETCHING";
export const ASSIGNS_FETCH_SUCCESS = "ASSIGNS_FETCH_SUCCESS";
export const ASSIGNS_FETCH_ERROR = "ASSIGNS_FETCH_ERROR";

// Before starting a test
// Attempt
export const ATTEMPT_FETCHING = "ATTEMPT_FETCHING";
export const ATTEMPT_FETCH_SUCCESS = "ATTEMPT_FETCH_SUCCESS";
export const ATTEMPT_FETCH_ERROR = "ATTEMPT_FETCH_ERROR";
export const ATTEMPT_STARTING = "ATTEMPT_STARTING";
export const ATTEMPT_START_SUCCESS = "ATTEMPT_START_SUCCESS";
export const ATTEMPT_START_ERROR = "ATTEMPT_START_ERROR";
export const ATTEMPT_RESET = "ATTEMPT_RESET";

// Starting a test
export const SUBMIT_FETCHING = "SUBMIT_FETCHING";
export const SUBMIT_FETCH_SUCCESS = "SUBMIT_FETCH_SUCCESS";
export const SUBMIT_FETCH_ERROR = "SUBMIT_FETCH_ERROR";
export const SUBMIT_TIME_SETTING = "SUBMIT_TIME_SETTING";
export const SUBMIT_TIME_SUCCESS = "SUBMIT_TIME_SUCCESS";
export const SUBMIT_TIME_ERROR = "SUBMIT_TIME_ERROR";

// During the test
export const SUBMIT_UPDATE_ANSWERS = "SUBMIT_UPDATE_ANSWERS";
export const SUBMIT_REVIEW_ANSWER = "SUBMIT_REVIEW_ANSWER";
export const SUBMIT_SAVING = "SUBMIT_SAVING";
export const SUBMIT_SAVE_SUCCESS = "SUBMIT_SAVE_SUCCESS";
export const SUBMIT_SAVE_ERROR = "SUBMIT_SAVE_ERROR";

// When test is submitted
export const SUBMIT_SUBMITTING = "SUBMIT_SUBMITTING";
export const SUBMIT_SUBMIT_SUCCESS = "SUBMIT_SUBMIT_SUCCESS";
export const SUBMIT_SUBMIT_ERROR = "SUBMIT_SUBMIT_ERROR";
export const SUBMIT_RESET = "SUBMIT_RESET";

// Account and credits management
export const ACCOUNT_CREDITS_ADDING = "ACCOUNT_CREDITS_ADDING";
export const ACCOUNT_CREDITS_ADD_SUCCESS = "ACCOUNT_CREDITS_ADD_SUCCESS";
export const ACCOUNT_CREDITS_ADD_ERROR = "ACCOUNT_CREDITS_ADD_ERROR";

export const ACCOUNT_CREDITS_FETCHING = "ACCOUNT_CREDITS_FETCHING";
export const ACCOUNT_CREDITS_FETCH_SUCCESS = "ACCOUNT_CREDITS_FETCH_SUCCESS";
export const ACCOUNT_CREDITS_FETCH_ERROR = "ACCOUNT_CREDITS_FETCH_ERROR";

export const ACCOUNT_FETCHING = "ACCOUNT_FETCHING";
export const ACCOUNT_FETCH_SUCCESS = "ACCOUNT_FETCH_SUCCESS";
export const ACCOUNT_FETCH_ERROR = "ACCOUNT_FETCH_ERROR";

export const ACCOUNT_TRSN_FETCHING = "ACCOUNT_TRSN_FETCHING";
export const ACCOUNT_TRSN_FETCH_SUCCESS = "ACCOUNT_TRSN_FETCH_SUCCESS";
export const ACCOUNT_TRSN_FETCH_ERROR = "ACCOUNT_TRSN_FETCH_ERROR";

export const ALL_PRODUCTS_FETCHING = "ALL_PRODUCTS_FETCHING";
export const ALL_PRODUCTS_FETCH_SUCCESS = "ALL_PRODUCTS_FETCH_SUCCESS";
export const ALL_PRODUCTS_FETCH_ERROR = "ALL_PRODUCTS_FETCH_ERROR";

export const CHECKOUT_CREATING = "CHECKOUT_CREATING";
export const CHECKOUT_CREATE_SUCCESS = "CHECKOUT_CREATE_SUCCESS";
export const CHECKOUT_CREATE_ERROR = "CHECKOUT_CREATE_ERROR";
export const PLAN_SELECTED = "PLAN_SELECTED";
