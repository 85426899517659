import React, { useState, useCallback, useRef, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import MUIRichTextEditor from "mui-rte";

import ContentDialog1 from "../../../shared/components/Modals/ContentDialog1";

// "draft-js";

import {
  onSectionNameChange,
  onSectionTextChange,
  onSectionMarkingChange,
  onSectionImageChange,
  onSectionMarkingFromChange,
  onSectionEditorClose,
  onSectionQTypeChange
} from "../../../store/actions";
import Marking from "../Marking/Marking2";
import ImageProvider from "../../../shared/components/UIElements/ImageProvider";
import ImageReceiver from "../../../shared/components/UIElements/ImageReceiver";
import QuestionTypeSelect from "../Question/QuestionTypeSelect";
import { questionTypes } from "../Question/questionTypes";
import { patterns } from "../Marking/scoringPatterns";
import { useHistory, useLocation } from "react-router-dom";

const SectionEditor = (props) => {
  const useStyles = makeStyles((theme) => ({
    sectionEditor: { display: "flex", flexDirection: "column" },
    sectionNameRoot: {
      fontWeight: 700,
      fontFamily: ["Open Sans", "sans-serif"],
      fontSize: "18px",
      width: "100%"
    },
    formControlRoot: {
      marginBottom: "30px"
    },
    markingHeading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500
    },
    richEditorContainer: {
      maxHeight: "300px",
      height: "200px",
      overflowY: "auto",
      borderTop: "0.5px rgba(0,0,0,0.42) solid",
      paddingTop: "10px"
    },
    sectionEditorRich: {
      textAlign: "left",
      padding: "0 10px",
      borderRadius: "5px",
      border: "1px solid rgba(0, 0, 0, 0.42)"
    },
    richEditorRoot: {},
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      marginTop: "30px"
    },
    markingContainer: {
      width: "100%",
      flexGrow: 1,
      [theme.breakpoints.up("sm")]: { width: "48%" }
    },
    typeContainer: {
      width: "100%",
      flexGrow: 1,
      marginBottom: "15px",
      [theme.breakpoints.up("sm")]: { width: "48%", marginRight: "10px" }
    }
  }));

  const classes = useStyles();

  const {
    onSectionNameChange,
    onSectionTextChange,
    onSectionMarkingChange,
    onSectionMarkingFromChange,
    onSectionImageChange,
    onSectionEditorClose,
    onSectionQTypeChange
  } = props;

  const nameRef = useRef();
  const textRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const editorRef = useRef();

  const { handleSectionEdited, handleSectionEditorLoad } = props;

  const { localSection, open, mode } = props.sectionEditor;
  const { name, text, image, qtype, marking, markingFrom } = localSection;
  const { selectedSectionId: id } = props.test;
  const { marking: testMarking } = props.test.settings;

  const [error, setError] = useState({
    nameError: false,
    nameErrorMsg: ""
  });

  const handleMarkingChange = useCallback(
    (marking) => {
      if (marking.name === "test") {
        onSectionMarkingChange(testMarking);
      } else {
        onSectionMarkingChange(marking);
      }
      onSectionMarkingChange(marking);
    },
    [onSectionMarkingChange, testMarking]
  );

  const handleMarkingFrom = useCallback(
    (value) => {
      const markingFrom = value === "test" ? "TEST" : "SELF";
      onSectionMarkingFromChange(markingFrom);
      markingFrom !== "SELF" && onSectionMarkingChange(testMarking);
    },
    [onSectionMarkingFromChange, onSectionMarkingChange, testMarking]
  );

  // const handleMarkingFromChange = useCallback(
  //   event => {
  //     const markingFrom = event.target.checked ? "TEST" : "SELF";
  //     onSectionMarkingFromChange(markingFrom);
  //     markingFrom === "TEST" && onSectionMarkingChange(testMarking);
  //   },
  //   [onSectionMarkingFromChange, onSectionMarkingChange, testMarking]
  // );

  const handleNameChange = (event) => {
    setError({
      nameError: false,
      nameErrorMsg: ""
    });
    onSectionNameChange(event.target.value);
  };

  const handleLocalNameChange = () => {};

  const handleTextChange = (event) => {
    onSectionTextChange(event.target.value);
  };

  const handleLocalTextChange = () => {};

  const [imageFile, setImageFile] = useState(null);
  const handleImageFile = (file, name) => {
    setImageFile(file);
  };

  const handleSectionImageChange = useCallback(
    (image, name) => {
      onSectionImageChange(image);
    },
    [onSectionImageChange]
  );

  const handleTypeChange = (event) => {
    const qtype = event.target.value;
    onSectionQTypeChange(qtype).then(() => {
      const allowedMarking = questionTypes[qtype].marking;
      if (!allowedMarking.includes(marking.name)) {
        const markingName = allowedMarking[0];
        const newMarking = patterns[markingName];
        handleMarkingChange(newMarking);
      }
    });
  };

  const handleClose = useCallback(
    (event) => {
      setError({
        nameError: false,
        nameErrorMsg: ""
      });
      onSectionEditorClose();
    },
    [onSectionEditorClose]
  );

  const handleAction = async (event) => {
    await handleSectionEdited().catch((error) => {
      const nameErrorMsg = error.nameErrorMsg;
      setError({ nameError: !!nameErrorMsg, nameErrorMsg });
    });
  };

  useEffect(() => {
    if (open) {
      if (
        !editorRef.current &&
        !(location.state && location.state.sectionSettings)
      ) {
        history.push(location.pathname, { sectionSettings: true });
        editorRef.current = true;
      } else if (!(location.state && location.state.sectionSettings)) {
        handleClose();
        editorRef.current = false;
      }
    } else {
      if (
        editorRef.current &&
        location.state &&
        location.state.sectionSettings
      ) {
        history.goBack();
        editorRef.current = false;
      } else if (location.state && location.state.sectionSettings) {
        handleSectionEditorLoad(id);
        editorRef.current = true;
      }
    }
  });

  return (
    <ContentDialog1
      open={open}
      dialogTitle={mode === "NEW_SECTION" ? "New Section" : "Edit Section"}
      actionText="Save"
      handleClose={handleClose}
      handleAction={handleAction}
      closeDisabled={props.nav.spinner}
      actionDisabled={props.nav.spinner}
    >
      <div className={classes.sectionEditor}>
        <TextField
          required
          margin="dense"
          id="name"
          label="Section Name"
          type="text"
          helperText={error.nameError ? error.nameErrorMsg : null}
          error={error.nameError}
          defaultValue={name || ""}
          inputRef={nameRef}
          onBlur={handleNameChange}
          onChange={handleLocalNameChange}
          classes={{
            root: classes.formControlRoot
          }}
        />
        <div style={{ display: "flex" }}>
          <TextField
            classes={{ root: classes.sectionNameRoot }}
            id="outlined-multiline-static"
            label="Section description"
            multiline
            rowsMax={6}
            placeholder="Enter section instructions, marking scheme, etc..."
            defaultValue={text}
            inputRef={textRef}
            onBlur={handleTextChange}
            onChange={handleLocalTextChange}
          />
          <ImageProvider name={"section"} handleImageFile={handleImageFile} />
        </div>
        <div>
          <ImageReceiver
            name={"section"}
            imageObj={imageFile}
            image={image}
            handleImageChange={handleSectionImageChange}
          />
        </div>

        <div className={classes.container}>
          <QuestionTypeSelect
            handleTypeChange={handleTypeChange}
            type={qtype}
            classes={{ root: classes.typeContainer }}
          />
          <Marking
            marking={marking}
            markingFrom={markingFrom}
            qtype={qtype}
            parent={"test"}
            parentMarking={testMarking}
            handleMarking={handleMarkingChange}
            handleMarkingFrom={handleMarkingFrom}
            classes={{ root: classes.markingContainer }}
          />
        </div>
        {/* <div className={classes.sectionEditorRich}>
          <MUIRichTextEditor
            classes={{
              editorContainer: classes.richEditorContainer,
              root: classes.richEditorRoot
            }}
            label="Enter section instructions, details and information... "
            // onSave={}
            toolbarButtonSize="small"
            controls={[
              "title",
              "bold",
              "italic",
              "underline",
              "media",
              "bulletList"
            ]}
            inlineToolbar={false}
            // value={JSON.stringify(
            //   convertToRaw(EditorState.createEmpty().getCurrentContent())
            // )}
            value={JSON.stringify(convertToRaw(contentState))}
            // onChange={handleEditorChange}
          />
        </div> */}
      </div>
    </ContentDialog1>
  );
};

const mapStateToProps = (state) => {
  return {
    sectionEditor: state.sectionEditor,
    test: state.test,
    nav: state.blockNav
  };
};

export default connect(mapStateToProps, {
  onSectionNameChange,
  onSectionTextChange,
  onSectionMarkingChange,
  onSectionMarkingFromChange,
  onSectionImageChange,
  onSectionEditorClose,
  onSectionQTypeChange
})(SectionEditor);
