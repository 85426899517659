// react related
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import "@stripe/stripe-js";

// mui
import { MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles, Snackbar } from "@material-ui/core";

// local
import Main from "./shared/pages/Main";
// import { Loader } from "./shared/components/UIElements/Progress";
import { Spinner as Loader } from "./shared/components/UIElements/Progress";
import theme from "./shared/styles/theme";
import { onIdTokenChanged, onDisplaySnackClose } from "./store/actions";

import GoogleAnalytics from "./shared/analytics/GoogleAnalytics";

const App = (props) => {
  const classes = makeStyles((theme) => ({
    app: {
      fontFamily: "sans-serif",
      textAlign: "center"
    }
  }))();

  const { onDisplaySnackClose } = props;
  const { isLoggedIn, role, isAuthenticating, isVerified } = props.auth;
  const { networkError, notFoundError, displaySnack, snackMessage } = props.nav;

  const handleSnackClose = (event) => {
    onDisplaySnackClose();
  };

  return isAuthenticating ? (
    <Loader />
  ) : (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <div className={classes.app}>
          <Main
            isLoggedIn={isLoggedIn}
            role={role}
            isVerified={isVerified}
            networkError={networkError}
            notFoundError={notFoundError}
          />
          <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            key={"top,center"}
            open={displaySnack}
            onClose={handleSnackClose}
            message={snackMessage}
          />
        </div>
        <GoogleAnalytics />
      </BrowserRouter>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    nav: state.blockNav
  };
};

export default connect(mapStateToProps, {
  onIdTokenChanged,
  onDisplaySnackClose
})(App);
