import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import {
  Box,
  Card,
  CardHeader,
  CardActions,
  Button,
  CardContent,
  Typography
} from "@material-ui/core";

const TestItem = (props) => {
  const classes = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(4, 3),
      margin: theme.spacing(4, 0)
    },
    top: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1,
      flexWrap: "wrap",
      flexDirection: "column",
      [theme.breakpoints.up("sm")]: { flexDirection: "row" }
    },
    header: {
      textAlign: "left",
      flexGrow: 1,
      alignItems: "center"
    },
    headerRoot: {
      padding: theme.spacing(1, 3, 1, 3)
    },
    actions: {
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "flex-start",
      flexShrink: 3,
      padding: theme.spacing(0),
      [theme.breakpoints.up("sm")]: { justifyContent: "flex-end" }
    },
    action: {
      margin: theme.spacing(2)
    },
    bottom: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      padding: theme.spacing(0, 3),
      "&:last-child": { paddingBottom: theme.spacing(5) }
    },
    bottom1: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      flexGrow: 1,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "50%"
      }
    },
    bottomItem: {
      flexGrow: 1,
      textAlign: "left",
      paddingRight: theme.spacing(3)
    }
  }))();

  const { test, submitsAllowed, lastSubmit, numberOfSubmits } = props.assign;

  // const { submitsAllowed, submits } = props;
  // const submitsAllowed = submitsAllowed || 2;
  // const submits = [""];
  const submitId = lastSubmit && lastSubmit._id;
  const { name, numQuestions, times, _id: testId } = test || {};
  const { startTime, endTime, timeLimit = 0 } = times || {};

  const startDate = new Date(startTime).setHours(0, 0, 0, 0);
  const today = new Date().setHours(0, 0, 0, 0);
  const notAvailable = today < startDate;

  const nextAttempt = numberOfSubmits ? numberOfSubmits + 1 : 1;
  const nextAttemptLabel =
    submitsAllowed >= nextAttempt || submitsAllowed === 0
      ? nextAttempt === 1
        ? "Attempt"
        : "Attempt (" + nextAttempt + ")"
      : "";
  const nextAttemptDisabled = endTime && moment(endTime) <= moment();

  const { status = "", endTime: lastEndTime = null } = lastSubmit || {};
  const continueLast =
    status === "IN PROGRESS" &&
    (!lastEndTime || moment(lastEndTime) > moment());

  const attemptButton = (
    <React.Fragment>
      {notAvailable ? (
        <Typography className={classes.action}>
          {"Available on " + moment(startTime).format("MMM Do")}
        </Typography>
      ) : nextAttemptLabel ? (
        <Link
          style={{
            textDecoration: "none",
            cursor: nextAttemptDisabled ? "default" : "pointer"
          }}
          to={"/test/" + testId}
          onClick={(e) => {
            nextAttemptDisabled && e.preventDefault();
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            className={classes.action}
            variant="contained"
            color="primary"
            aria-label="attempt"
            disabled={nextAttemptDisabled}
          >
            {nextAttemptLabel}
          </Button>
        </Link>
      ) : null}
    </React.Fragment>
  );

  const continueButton = (
    <React.Fragment>
      <Link
        style={{ textDecoration: "none" }}
        to={"/submit/" + submitId}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          className={classes.action}
          variant="contained"
          color="secondary"
          aria-label="attempt"
        >
          Continue
        </Button>
      </Link>
    </React.Fragment>
  );

  const numQLabel = numQuestions ? numQuestions + " Questions" : null;
  const timeLabel = timeLimit ? timeLimit + " minutes" : null;
  const momentStart = moment(startTime);
  const momentEnd = moment(endTime);
  const startLabel =
    startTime &&
    "Start: " +
      momentStart.format(
        momentStart.minutes() === 0 ? "MMM Do ha" : "MMM Do h:mma"
      );
  const endLabel =
    endTime &&
    "End: " +
      momentEnd.format(
        momentEnd.minutes() === 0 ? "MMM Do ha" : "MMM Do h:mma"
      );

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <Box className={classes.top}>
          <CardHeader
            classes={{ content: classes.header, root: classes.headerRoot }}
            title={name}
          />
          <CardActions className={classes.actions} disableSpacing>
            {numberOfSubmits > 0 && status !== "IN PROGRESS" ? (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: "/review/" + submitId,
                  state: { testName: name }
                }}
              >
                <Button
                  className={classes.action}
                  variant="contained"
                  color="primary"
                >
                  Review
                </Button>
              </Link>
            ) : null}
            {continueLast ? continueButton : attemptButton}
          </CardActions>
        </Box>
        <CardContent className={classes.bottom}>
          <div className={classes.bottom1}>
            <Typography
              className={classes.bottomItem}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {numQLabel}
            </Typography>
            <Typography
              className={classes.bottomItem}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {timeLabel}
            </Typography>
          </div>

          {continueLast ? (
            <div className={classes.bottom1}>
              <Typography
                className={classes.bottomItem}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                In Progress
              </Typography>
            </div>
          ) : nextAttemptDisabled ? (
            <div className={classes.bottom1}>
              <Typography
                className={classes.bottomItem}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                Not Available
              </Typography>
            </div>
          ) : (
            <div className={classes.bottom1}>
              <Typography
                className={classes.bottomItem}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {startLabel}
              </Typography>
              <Typography
                className={classes.bottomItem}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {endLabel}
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default TestItem;
