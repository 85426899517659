import React, { useEffect } from "react";
import { connect } from "react-redux";

import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@material-ui/core";

import CardLoading from "../../shared/components/UIElements/CardLoading1";

import SharedTestItem from "../components/Share/SharedTestItem";

import NoTests from "../components/Tests/NoTests";
import withAppHeader from "../../shared/hoc/withAppHeader";

import { onSharedTestsFetch } from "../../store/actions";

const SharedTests = (props) => {
  const { sharedTests, loadMoreShared } = props.tests;
  const { loading } = props.nav;
  const { onSharedTestsFetch } = props;  

  useEffect(() => {
    if ((!sharedTests || sharedTests.length === 0) && loadMoreShared)
      onSharedTestsFetch();
  }, [onSharedTestsFetch, sharedTests, loadMoreShared]);

  return (
    <React.Fragment>
      <div
        style={{
          maxWidth: "820px",
          width: "90%",
          margin: "auto",
          marginBottom: "100px",
          overflow: "auto",
        }}
      >
        {loading && (!sharedTests || sharedTests.length === 0) ? (
          <CardLoading count={2} />
        ) : (
          <>
            {sharedTests && sharedTests.length > 0 ? (
              <InfiniteScroll
                dataLength={sharedTests.length}
                next={() => {
                  onSharedTestsFetch({ skip: sharedTests.length });
                }}
                hasMore={loadMoreShared}
                loader={<CircularProgress size={20} />}
              >
                {sharedTests.map((test, idx) => (
                  <SharedTestItem test={test} key={test._id + idx} idx={idx} />
                ))}
              </InfiniteScroll>
            ) : (
              <NoTests message={`There are no tests shared with you yet.`} />
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tests: state.tests,
    nav: state.blockNav,
  };
};

const SharedTestsWithHeader = withAppHeader(
  connect(mapStateToProps, {
    onSharedTestsFetch,
  })(SharedTests)
);

const SharedTestsPage = (props) => {
  const headerText = "Shared With Me";
  return <SharedTestsWithHeader {...props} title={headerText} />;
};

export default SharedTestsPage;
