import React from "react";

// mui
import { makeStyles, Typography } from "@material-ui/core";

import moment from "moment";
import { duration as formatDuration } from "../../../shared/utils/formats";

const SubmitReviewSummary = (props) => {
  const classes = makeStyles((theme) => ({
    root: {
      display: "flex",
      margin: "15px 0",
      flexWrap: "wrap"
    },
    summary: {
      border: "black 2px solid",
      maxWidth: "300px",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
      marginRight: "10px",
      marginBottom: "5px"
    }
  }))();

  const { test, submit } = props;
  const { answers, timeTaken, submitTime, endTime, startedTime } = submit || {};

  const { numQuestions } = test;
  const { counts, score, maxScore } = answers;
  const { correct, incorrect, answered, unanswered, partial } = counts || {};

  const maxDuration =
    endTime && startedTime ? moment(endTime).diff(startedTime) : null;
  const duration =
    maxDuration && timeTaken
      ? formatDuration(Math.min(maxDuration, timeTaken))
      : timeTaken
      ? formatDuration(timeTaken)
      : "";
  return (
    <>
      <div className={classes.root}>
        <div className={classes.summary}>
          <div style={{ flexGrow: 1, marginRight: "5px" }}>
            {maxScore ? (
              <Typography variant="body2" style={{ fontWeight: 700 }}>
                Total Scored: {"" + score}
              </Typography>
            ) : null}
            {maxScore ? (
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Maximum: {"" + maxScore}
              </Typography>
            ) : null}
            <Typography variant="body2">
              Questions: {numQuestions || "-"}
            </Typography>
          </div>
          <div style={{ flexGrow: 1 }}>
            <Typography variant="body2">Correct: {correct || "-"}</Typography>
            {answered ? (
              <Typography variant="body2">
                Answered: {answered || "-"}
              </Typography>
            ) : null}
            {partial ? (
              <Typography variant="body2">Partial: {partial || "-"}</Typography>
            ) : null}
            <Typography variant="body2">
              Incorrect: {incorrect || "-"}
            </Typography>
            <Typography variant="body2">
              Unanswered: {unanswered || "-"}
            </Typography>
          </div>
        </div>
        <div style={{ flexGrow: 1 }}>
          <div>
            <Typography variant="body2" style={{ fontStyle: "italic" }}>
              Submitted: {submit && moment(submitTime).format("Do MMMM h:mma")}
            </Typography>
          </div>
          <div>
            <Typography variant="body2" style={{ fontStyle: "italic" }}>
              Duration: {duration}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmitReviewSummary;
