import * as actionTypes from "../actionTypes";
import update from "immutability-helper";

const initialState = {
  localQuestion: {
    _id: "",
    text: "",
    image: {}, // url, justify, scale
    type: "MCQSS",
    correctAns: "",
    shortAns: [], // [String]
    numImages: "",
    correct: [], // [Boolean]
    options: [], // [text: String, isCorrect: Boolean ]
    marking: null,
    markingFrom: "",
    sequence: null // numeric, uppercase, lowercase
  },
  open: false,
  mode: null
};

const questionEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.QUESTION_EDITOR_OPEN:
      return { ...state, open: true };
    case actionTypes.QUESTION_EDITOR_CLOSE:
      return { ...initialState, open: false };
    case actionTypes.QUESTION_CREATING_NEW: {
      return {
        ...initialState,
        localQuestion: {
          ...initialState.localQuestion,
          marking: action.payload.marking,
          markingFrom: action.payload.markingFrom,
          type: action.payload.type || initialState.localQuestion.type
        },
        mode: action.payload.mode,
        open: true
      };
    }
    case actionTypes.QUESTION_EDITING:
      return {
        ...state,
        localQuestion: action.payload.localQuestion,
        mode: action.payload.mode,
        open: true
      };

    case actionTypes.QUESTION_TEXT_CHANGE: {
      const text = action.payload.text;
      const question = update(state.localQuestion, { text: { $set: text } });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_IMAGE_CHANGE: {
      const image = action.payload.image;
      const question = update(state.localQuestion, {
        image: { $set: image }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_MARKING_CHANGE: {
      const marking = action.payload.marking;
      const question = update(state.localQuestion, {
        marking: { $set: marking }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_MARKING_FROM_CHANGE: {
      const markingFrom = action.payload.markingFrom;
      const question = update(state.localQuestion, {
        markingFrom: { $set: markingFrom }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_SEQUENCE_CHANGE: {
      const sequence = action.payload.sequence;
      const question = update(state.localQuestion, {
        sequence: { $set: sequence }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_TYPE_CHANGE: {
      const type = action.payload.type;
      const question = update(state.localQuestion, {
        type: { $set: type },
        options: {
          $apply: (options) => {
            const correctOnes = options.filter(
              (option, index) => option.isCorrect
            );
            const firstCorrect =
              correctOnes.length > 0 ? options.indexOf(correctOnes[0]) : null;
            return options.map((option, index) => {
              option.isCorrect =
                type === "MCQSS" && index !== firstCorrect
                  ? false
                  : option.isCorrect;
              return option;
            });
          }
        },
        correct: {
          $apply: (correct) => {
            const firstCorrect = correct.indexOf(true);
            return correct.map((value, index) => {
              return type === "MCQSS" && index !== firstCorrect ? false : value;
            });
          }
        }
      });

      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_CORRECT_ANS_CHANGE: {
      const correctAns = action.payload.correctAns;
      const question = update(state.localQuestion, {
        correctAns: { $set: correctAns }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_OPTION_ADD: {
      const id = Math.random().toString(36).substring(2, 15);
      const question = update(state.localQuestion, {
        options: { $push: [{ id, text: "", isCorrect: false }] },
        correct: { $push: [false] }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_OPTION_REMOVE: {
      const idx = action.payload.idx;
      const question = update(state.localQuestion, {
        options: { $splice: [[idx, 1]] },
        correct: { $splice: [[idx, 1]] }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_OPTION_TEXT_CHANGE: {
      const { optionText, idx } = action.payload;
      const options = [...state.localQuestion.options];
      if (options[idx]) options[idx].text = optionText;
      // const question = update(state.localQuestion, {
      //   options: { [idx]: { text: { $set: optionText } } }
      // });
      return {
        ...state,
        // localQuestion: question
        localQuestion: { ...state.localQuestion, options }
      };
    }
    case actionTypes.QUESTION_SHORTANS_ADD: {
      const question = update(state.localQuestion, {
        shortAns: { $push: [""] }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_SHORTANS_REMOVE: {
      const idx = action.payload.idx;
      const question = update(state.localQuestion, {
        shortAns: { $splice: [[idx, 1]] }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_SHORTANS_TEXT_CHANGE: {
      const { shortAnsText, idx } = action.payload;
      const question = update(state.localQuestion, {
        shortAns: { [idx]: { $set: shortAnsText } }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_NUMIMAGES_CHANGE: {
      const numImages = action.payload.numImages;
      const question = update(state.localQuestion, {
        numImages: { $set: numImages }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_OPTION_IMAGE_CHANGE: {
      const { optionImage, idx } = action.payload;
      const question = update(state.localQuestion, {
        options: { [idx]: { image: { $set: optionImage } } }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_OPTION_CORRECT_CHANGE: {
      const idx = action.payload.idx;
      const question = update(state.localQuestion, {
        options: {
          $apply: (options) => {
            return options.map((option, idxx) => {
              option.isCorrect =
                state.localQuestion.type === "MCQMS"
                  ? idxx === idx
                    ? !option.isCorrect
                    : option.isCorrect
                  : state.localQuestion.type === "MCQSS"
                  ? idxx === idx
                    ? !option.isCorrect
                    : false
                  : null;
              return option;
            });
          }
        },
        correct: {
          $apply: (correct) => {
            return correct.map((value, idxx) => {
              return state.localQuestion.type === "MCQMS"
                ? idxx === idx
                  ? !value
                  : value
                : state.localQuestion.type === "MCQSS"
                ? idxx === idx
                  ? !value
                  : false
                : null;
            });
          }
        }
      });
      return { ...state, localQuestion: question };
    }
    case actionTypes.QUESTION_OPTIONS_REORDER: {
      const { from, to } = action.payload;
      // const idx = action.payload.idx;
      // const direction = action.payload.direction;
      const question = { ...state.localQuestion };
      const newOptions = [...question.options];
      const newCorrect = [...question.correct];

      // const newIndex =
      //   (idx === 0 && direction === "up") ||
      //   (idx === question.options.length - 1 && direction === "down")
      //     ? idx
      //     : direction === "up"
      //     ? idx - 1
      //     : idx + 1;
      newOptions.splice(to, 0, newOptions.splice(from, 1)[0]);
      newCorrect.splice(to, 0, newCorrect.splice(from, 1)[0]);
      question.options = newOptions;
      question.correct = newCorrect;
      return { ...state, localQuestion: question };
    }
    default:
      return state;
  }
};

export default questionEditorReducer;
