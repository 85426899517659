import axios from "axios";
import store from "../../store/store";
import { onNetworkError, on404Error } from "../../store/actions";
import appconfig from "../../config";
import firebase from "./firebase";

import axiosRetry from "axios-retry";

import * as Sentry from "@sentry/react";

const instance = axios.create({
  baseURL: appconfig.BASE_API_URL,
});

axiosRetry(instance, {
  retries: 3,
  retryCondition: (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      ["auth/id-token-expired"].includes(error.response.data.code)
    ) {
      const user = firebase.auth().currentUser;
      user.getIdToken(true).then((token) => {
        error.response.config.headers["X-Firebase-ID-Token"] = token;
      });
      return true;
    }
    if (error.response && [403, 404].includes(error.response.status)) {
      return false;
    } else {
      return (
        axiosRetry.isNetworkOrIdempotentRequestError(error) &&
        window.navigator.onLine
      );
    }
  },
  retryDelay: (retryNumber, error) => {
    const delay = Math.pow(1.5, retryNumber) * 1000;
    const randomSum = Math.min(delay + 1000 * Math.random(), 5000);
    return randomSum;
  },
});

// instance.defaults.headers.common["Authorization"] = "AUTH_TOKEN";
// axios.defaults.headers.post["Content-Type"] = "application/json";
// instance.defaults.baseURL = "https://testserver-tajindersingh30132969.codeanyapp.com/tests/";

const setServerDiff = (reqSend, reqRcvd, resSend, resRcvd) => {
  const diff = (reqRcvd * 1 - reqSend * 1 + resSend * 1 - resRcvd * 1) / 2;
  store.dispatch({
    type: "APP_SERVER_DIFF",
    payload: { diff },
  });
};

instance.interceptors.request.use(
  async (request) => {
    const state = store.getState();
    const { token, role } = state.auth;
    let reqId;
    while (!reqId) {
      reqId = Math.random().toString(36).substring(2, 15);
    }
    request.headers["X-Request-ID"] = reqId;
    request.headers["X-Request-Time"] = new Date().valueOf();
    if (role === "admin") {
      const { userSelected } = state.admin;
      if (userSelected && userSelected._id) {
        request.headers["X-Admin-As-User"] = JSON.stringify({
          id: userSelected._id,
          uid: userSelected.fid,
          name: userSelected.name,
        });
      }
    }
    if (!request.headers["X-Firebase-ID-Token"]) {
      request.headers["X-Firebase-ID-Token"] = token;
    }
    return request;
  },
  (error) => {
    Sentry.captureException(error, {
      contexts: { logs: { key: null, stack: "Error in request interceptor" } },
    });
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    if (!response.handled) {
      // Sentry.withScope(function (scope) {
      //   Sentry.captureMessage(response.status + " " + response.config.method, {
      //     contexts: { logs: { url: response.config.url } }
      //   });
      // });
      setServerDiff(
        response.config.headers["X-Request-Time"],
        response.headers["x-request-received"],
        response.headers["x-response-sent"],
        new Date().valueOf()
      );
      store.dispatch(onNetworkError(false));
      store.dispatch(on404Error(false));

      response.handled = true;
    }
    return response;
  },
  async (error) => {
    if (!error.handled) {
      // error.name, error.message, error.response);
      if (error.message.includes("404") || error.message.includes("403")) {
        store.dispatch(on404Error(true));
      }
      // if (error.message === "Network Error")
      else {
        store.dispatch(onNetworkError(true));
      }
      const logs = {
        key: error.config && error.config.headers["X-Request-ID"],
      };
      if (error.response && error.response.data) {
        const { data } = error.response;
        logs.key = logs.key || data.key;
        logs.message = data.message;

        logs.stack = data.stack ? data.stack.slice(0, 8192) : undefined;
      }
      Sentry.captureException(error, { contexts: { logs } });
      // request is set in scope for submit answers
      // removing it from scope once the exception is captured
      Sentry.configureScope((scope) => {
        scope.setContext("request", null);
      });
      error.handled = true;
    }
    return Promise.reject(error);
  }
);

export default instance;
