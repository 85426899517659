import {
  Button,
  Checkbox,
  makeStyles,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import { AddPhotoAlternate } from "@material-ui/icons";
import React from "react";

import Image from "../../../shared/components/UIElements/Image";

const useStyles = makeStyles((theme) => ({}));

const QuestionLayout = React.memo((props) => {
  const classes = useStyles();
  const { question } = props;
  const { type } = question;

  const questionTypeLayout = (qtype) => {
    if (qtype === "MCQSS" || qtype === "MCQMS") {
      return (
        <>
          {question.options.map((option, idx) => {
            return (
              <div key={option._id}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {qtype === "MCQSS" ? (
                    <>
                      <Radio
                        classes={{ root: classes.checkRoot }}
                        color="primary"
                        checked={question.correct[idx]}
                        disabled={true}
                      />
                      {option.text}
                    </>
                  ) : (
                    <>
                      <Checkbox
                        classes={{ root: classes.checkRoot }}
                        color="primary"
                        checked={question.correct[idx]}
                        disabled={true}
                      />
                      {option.text}
                    </>
                  )}
                </div>
                <div style={{ marginLeft: "12px" }}>
                  {option.image ? (
                    <Image image={{ ...option.image, scale: 30 }} />
                  ) : null}
                </div>
              </div>
            );
          })}
        </>
      );
    }
    if (qtype === "NUMBER") {
      return (
        <div style={{ width: "100%", textAlign: "left" }}>
          <TextField
            placeholder="Numeric answer"
            variant="standard"
            disabled={true}
            style={{ width: "100%" }}
          />
          <Typography variant="caption">
            Correct answer(s): {question.correctAns}
          </Typography>
        </div>
      );
    }
    if (qtype === "SHORT") {
      return (
        <div style={{ width: "100%", textAlign: "left" }}>
          <TextField
            placeholder="Short answer text"
            variant="standard"
            disabled={true}
            style={{ width: "100%" }}
          />
          <Typography variant="caption">
            Correct answer(s): {question.shortAns.join(", ")}
          </Typography>
        </div>
      );
    }
    if (qtype === "LONG") {
      return (
        <div style={{ width: "100%", textAlign: "left" }}>
          <TextField
            placeholder="Long answer text"
            variant="standard"
            disabled={true}
            style={{ width: "100%" }}
          />
        </div>
      );
    }
    if (qtype === "IMAGE") {
      return (
        <div style={{ width: "100%", textAlign: "left" }}>
          <Button
            aria-label="upload picture"
            component="span"
            variant="outlined"
            style={{ textTransform: "none" }}
            disabled
          >
            <AddPhotoAlternate
              fontSize="default"
              style={{ marginRight: "10px" }}
            />
            Add Image
          </Button>
        </div>
      );
    }
    return null;
  };

  return <>{questionTypeLayout(type)}</>;
});

export default QuestionLayout;
