import * as actionTypes from "../actionTypes";
import axios from "../../shared/utils/axios";

export const onAssignsFetch = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.ASSIGNS_FETCHING
    });
    return axios
      .get("/api/assigns", {
        // params: {
        //   _limit: 10
        // }
      })
      .then((response) => {
        const assigns = response.data;
        dispatch(onAssignsFetchSuccess(assigns));
      })
      .catch((error) => {
        dispatch(onAssignsFetchError(error));
      });
  };
};

export const onAssignsFetchSuccess = (assigns) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ASSIGNS_FETCH_SUCCESS,
      payload: { assigns: assigns }
    });
  };
};

export const onAssignsFetchError = (error) => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.ASSIGNS_FETCH_ERROR,
      payload: error || "An unknown error ocurred."
    });
};
