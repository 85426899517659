import React, { useEffect, useState, useRef } from "react";
import { storage } from "../../utils/firebase";

import { makeStyles } from "@material-ui/core/styles";
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  Delete,
  Clear,
} from "@material-ui/icons";
import { Slider, CircularProgress, Fab } from "@material-ui/core";
import { connect } from "react-redux";

// import { withPopover } from "../../hoc/withPopover";

const useStyles = makeStyles((theme) => ({
  progress: {
    textAlign: "center",
    marginTop: "20px",
  },
  imageContainer: {
    "&:hover [name='toshow']": {
      visibility: "visible",
    },
    position: "relative",
  },
  imageBox1: {
    position: "relative",
    padding: "10px 10px",
    boxSizing: "border-box",
    display: "flex",
  },
  imageBox2: {
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    marginBottom: "10px",
    boxShadow: theme.shadows[1],
  },
  imageControls: {
    // [theme.breakpoints.up("sm")]: { visibility: "hidden" },
    visibility: "hidden",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "10px",
    justifyContent: "flex-end",
    // backgroundColor: theme.palette.grey[100],
    backgroundColor: "rgba(224, 224, 224, 0.7)",
    borderRadius: "5px",
    position: "absolute",
    right: 0,
    top: 0,
  },
  imageControlsDelete: {
    // [theme.breakpoints.up("sm")]: { visibility: "hidden" },
    visibility: "hidden",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    // boxShadow: theme.shadows[1],
    // width: "30px",
    // height: "30px",
    // backgroundColor: theme.palette.grey[100],
    position: "absolute",
    right: -14,
    top: -14,
  },
  imageDeleteFab: {
    boxShadow: theme.shadows[1],
    height: "28px",
    minHeight: "28px",
    width: "28px",
  },
  imageDeleteFabLabel: {
    color: theme.palette.action.active,
  },
  slider: { backgroundColor: theme.palette.action.active },
  sliderThumb: { backgroundColor: theme.palette.action.active, opacity: 1 },
}));

const ImageReceiver = (props) => {
  const classes = useStyles();

  const { name, imageObj, image, handleImageChange, controls = true } = props;
  const { fid: uid } = props.auth;

  const [loading, setLoading] = useState(false);
  const firstMount = useRef(true);

  useEffect(() => {
    setLoading(false);
  }, [image]);

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      return;
    }
    if (imageObj) {
      setLoading(true);
      const currentImageName = "firebase-image-" + Date.now();
      const imageRef = storage.ref(`user/${uid}/images/${currentImageName}`);
      const uploadTask = imageRef.put(imageObj, {
        cacheControl: "public, max-age=8640000",
      });
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("progressing...");
          //          const progress =
          //          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.code);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // console.log("File available at", downloadURL);
          });
        }
      );

      const process = async () => {
        const snapshot = await uploadTask.catch((error) => {
          console.log(error.code);
        });

        if (snapshot) {
          const url = await snapshot.ref
            .getDownloadURL()
            .catch((error) => console.log(error));
          return Promise.resolve({ url, justify: "flex-start", scale: 65 });
        } else return Promise.resolve({});
      };

      process().then((result) => {
        handleImageChange(result, name);
      });

      return function () {
        setLoading(false);
      };
    }
  }, [imageObj, uid, handleImageChange, name]);

  const { url = "", justify = "center", scale = 65 } = image || {};

  // const FabWithPopover = withPopover(Fab);

  return (
    <React.Fragment>
      {loading ? (
        <div className={classes.progress}>
          <CircularProgress size={30} />
        </div>
      ) : url ? (
        <div className={classes.imageContainer} style={{}}>
          <div
            className={classes.imageBox1}
            style={{
              justifyContent: `${justify}`,
            }}
          >
            <div
              className={controls ? "" : classes.imageBox2}
              style={{
                width: `${scale * 0.9}%`,
                maxWidth: controls ? `${scale * 0.9}%` : "180px",
                minWidth: "120px",
              }}
            >
              <img
                style={{ width: "100%", height: "auto" }}
                src={url}
                alt="q"
              />
              {controls ? null : (
                <div className={classes.imageControlsDelete} name="toshow">
                  <Fab
                    color="default"
                    size="small"
                    aria-label="more"
                    className={classes.imageDeleteFab}
                    classes={{ label: classes.imageDeleteFabLabel }}
                    onClick={() => handleImageChange({ url: "" }, name)}
                  >
                    <Clear fontSize="small" />
                  </Fab>
                </div>
              )}
            </div>
          </div>
          {controls ? (
            <div className={classes.imageControls} name="toshow">
              {justify === "flex-start" ? (
                <FormatAlignLeft
                  style={{ padding: "10px", cursor: "pointer" }}
                  onClick={() =>
                    handleImageChange({ url, justify: "center", scale }, name)
                  }
                  color={justify === "flex-start" ? "inherit" : "action"}
                  fontSize="small"
                />
              ) : null}
              {justify === "center" ? (
                <FormatAlignCenter
                  style={{ padding: "10px", cursor: "pointer" }}
                  onClick={() =>
                    handleImageChange({ url, justify: "flex-end", scale }, name)
                  }
                  color={justify === "center" ? "inherit" : "action"}
                  fontSize="small"
                />
              ) : null}
              {justify === "flex-end" ? (
                <FormatAlignRight
                  style={{ padding: "10px", cursor: "pointer" }}
                  onClick={() =>
                    handleImageChange(
                      { url, justify: "flex-start", scale },
                      name
                    )
                  }
                  color={justify === "flex-end" ? "inherit" : "action"}
                  fontSize="small"
                />
              ) : null}
              <div
                style={{
                  width: "120px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0 10px",
                }}
              >
                {/* <ImageOutlined color="action" fontSize="small" /> */}
                <Slider
                  min={30}
                  value={scale}
                  onChange={(event, value) => {
                    event.stopPropagation();
                    handleImageChange({ url, justify, scale: value }, name);
                  }}
                  aria-labelledby="continuous-slider"
                  style={{ margin: "0 10px" }}
                  classes={{
                    rail: classes.slider,
                    track: classes.slider,
                    thumb: classes.sliderThumb,
                  }}
                />
                {/* <ImageOutlined color="action" /> */}
              </div>
              {/* <Divider flexItem orientation="vertical" variant="middle" /> */}
              <Delete
                style={{ padding: "10px", cursor: "pointer" }}
                onClick={() => handleImageChange({ url: "" }, name)}
                color="action"
              />
              {/* <FabWithPopover
                  popover={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px 0px"
                      }}
                    >
                      <div style={{ display: "flex", padding: "0px 0px" }}>
                        <FormatAlignLeft
                          style={{ padding: "10px", cursor: "pointer" }}
                          onClick={() =>
                            handleImageChange({
                              url,
                              justify: "flex-start",
                              scale
                            }, name)
                          }
                          color={
                            justify === "flex-start" ? "inherit" : "action"
                          }
                        />
                        <FormatAlignCenter
                          style={{ padding: "10px", cursor: "pointer" }}
                          onClick={() =>
                            handleImageChange({ url, justify: "center", scale }, name)
                          }
                          color={justify === "center" ? "inherit" : "action"}
                        />
                        <FormatAlignRight
                          style={{ padding: "10px", cursor: "pointer" }}
                          onClick={() =>
                            handleImageChange({
                              url,
                              justify: "flex-end",
                              scale
                            }, name)
                          }
                          color={justify === "flex-end" ? "inherit" : "action"}
                        />
                      </div>
                      <div
                        style={{
                          width: "120px",
                          display: "flex",
                          alignItems: "center",
                          margin: "0 10px"
                        }}
                      >
                        <Slider
                          min={30}
                          value={scale}
                          onChange={(event, value) => {
                            event.stopPropagation();
                            handleImageChange({ url, justify, scale: value }, name);
                          }}
                          aria-labelledby="continuous-slider"
                          style={{ margin: "0 10px" }}
                          classes={{
                            rail: classes.slider,
                            track: classes.slider,
                            thumb: classes.sliderThumb
                          }}
                        />
                      </div>
                      <Delete
                        style={{ padding: "10px", cursor: "pointer" }}
                        onClick={() => handleImageChange({ url: "" }, name)}
                        color="action"
                      />
                    </div>
                  }
                  color="action"
                  size="small"
                  aria-label="add"
                >
                  <MoreVert fontSize="small" />
                </FabWithPopover> */}
              {/* </div> */}
            </div>
          ) : null}
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ImageReceiver);
