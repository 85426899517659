import * as actionTypes from "../actionTypes";

const initialState = {
  localSection: {
    _id: "",
    name: "",
    text: "",
    marking: null,
    markingFrom: "",
    qtype: "MCQSS", // default value is also handled in testsActions and questionEditor reducer
    image: {},
    questions: [],
    numQuestions: 0
  },
  rules: {
    name: {
      type: "string",
      required: true
    }
  },
  open: false,
  mode: null
};

const sectionEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SECTION_EDITOR_OPEN:
      return { ...state, open: true };
    case actionTypes.SECTION_EDITOR_CLOSE:
      return { ...initialState, open: false };
    case actionTypes.SECTION_CREATING_NEW:
      return {
        ...initialState,
        open: true,
        mode: action.payload.mode,
        localSection: {
          ...initialState.localSection,
          marking: action.payload.marking,
          markingFrom: action.payload.markingFrom
        }
      };
    case actionTypes.SECTION_EDITING:
      return {
        ...state,
        open: true,
        mode: action.payload.mode,
        localSection: {
          ...action.payload.localSection,
          qtype:
            action.payload.localSection.qtype || initialState.localSection.qtype
        }
      };
    case actionTypes.SECTION_NAME_CHANGE: {
      const localSection = { ...state.localSection };
      localSection.name = action.payload.name;
      return {
        ...state,
        localSection
      };
    }
    case actionTypes.SECTION_TEXT_CHANGE: {
      const localSection = { ...state.localSection };
      localSection.text = action.payload.text;
      return {
        ...state,
        localSection
      };
    }
    case actionTypes.SECTION_MARKING_CHANGE: {
      const localSection = { ...state.localSection };
      localSection.marking = action.payload.marking;
      return {
        ...state,
        localSection
      };
    }
    case actionTypes.SECTION_MARKING_FROM_CHANGE: {
      const localSection = { ...state.localSection };
      localSection.markingFrom = action.payload.markingFrom;
      return {
        ...state,
        localSection
      };
    }
    case actionTypes.SECTION_IMAGE_CHANGE: {
      const localSection = { ...state.localSection };
      localSection.image = action.payload.image;
      return {
        ...state,
        localSection
      };
    }
    case actionTypes.SECTION_QTYPE_CHANGE: {
      const localSection = { ...state.localSection };
      localSection.qtype = action.payload.qtype;
      return {
        ...state,
        localSection
      };
    }
    default:
      return state;
  }
};

export default sectionEditorReducer;
