import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import TestReviewLayout from "../components/TestReview/TestReviewLayout";
import { onReviewTestFetch2 } from "../../store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10px 0",
    width: "100%",
    minHeight: "calc(100vh - 116px)"
  }
}));

const TestReview = (props) => {
  const { match, history } = props;
  const testId = match.params.testId;
  const classes = useStyles();

  const {
    review: { assigns, testId: previousId },
    test,
    onReviewTestFetch2
  } = props;

  const { details } = test;
  const { name } = details || {};

  const handleSubmitSelected = (submitId) => {
    history.push("/review/" + submitId, { testName: name });
  };

  const change = testId !== previousId;

  useEffect(() => {
    if (!assigns || change) onReviewTestFetch2(testId);
  }, [testId, assigns, change, onReviewTestFetch2]);

  return (
    <>
      {!change ? (
        <>
          <div className={classes.root}>
            <TestReviewLayout
              showAssign
              assigns={assigns}
              test={test}
              handleSubmitSelected={handleSubmitSelected}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    review: state.reviewtest
  };
};

export default connect(mapStateToProps, {
  onReviewTestFetch2
})(TestReview);
