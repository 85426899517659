import React from "react";

import withAppHeader from "../../shared/hoc/withAppHeader";
import TestsList from "./TestsList";
import TestsHeader from "../components/Tests/TestsHeader";
import TestsSearchBar from "../components/Tests/TestsSearchBar";
import { TestSearchProvider } from "../../shared/context/test-search-context";

const TestsListWrapper = withAppHeader(TestsList);

// const title = <Typography noWrap >
//                   {"All Tests Here"}
//               </Typography>

const title = "My Tests";

const Tests = (props) => {
  const actions = (
    <>
      <TestsSearchBar />
      <TestsHeader />
    </>
  );

  return (
    <React.Fragment>
      {/* {spinner || (loading && tests) ? <Linear /> : null} */}
      <TestSearchProvider>
        <TestsListWrapper {...props} title={title} actions={actions} />
      </TestSearchProvider>
    </React.Fragment>
  );
};

export default Tests;
