import React, { useState } from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import ChipInput from "./ChipInput";

const ChipInputWrapper = (props) => {
  const {
    classes,
    values,
    showCount,
    handleChange,
    helperDuplicate,
    helperError,
    helperInput,
    ...rest
  } = props;

  const [inputError, setInputError] = useState(false);
  const [helperText, setHelperText] = useState(helperInput);
  const [inputValue, setInputValue] = useState("");

  const handleValueAdd = (input) => {
    if (!input) return;
    let inputArr;
    if (Array.isArray(input)) inputArr = input;
    else {
      inputArr = input.split(/[; ,\n\t\r]+/);
      inputArr = inputArr.filter((el) => el.trim().length !== 0);
    }
    const check = inputArr.filter((str) => {
      return !str.trim().match(/^[\w-.]+@([\w-]+.)+[\w-]{2,}$/);
    });
    if (inputArr.length === 0) return;
    if (check.length > 0) {
      setInputError(true);
      setHelperText(helperError);
    } else {
      const newValues = [...values, ...inputArr];
      const newValues1 = newValues.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
      if (newValues1.length < newValues.length) setHelperText(helperDuplicate);
      handleChange(newValues1);
      setInputValue("");
    }
  };

  const handleValueDelete = (input, index) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    handleChange(newValues);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setInputError(false);
    setHelperText(helperInput);
  };

  return (
    <div>
      {showCount ? (
        <div style={{ flexGrow: 1, height: "1.2em" }}>
          {values.length > 0 ? (
            <Typography
              style={{ float: "right", whiteSpace: "pre-line" }}
              className={classes.countLabel}
              color="primary"
              variant="caption"
            >
              {values.length + " selected"}
            </Typography>
          ) : null}
        </div>
      ) : null}
      <ChipInput
        allowDuplicates={true}
        variant="outlined"
        fullWidth
        fullWidthInput={true}
        blurBehavior="add"
        InputProps={{ onChange: handleInputChange }}
        error={inputError}
        value={values}
        inputValue={inputValue}
        onAdd={handleValueAdd}
        onDelete={handleValueDelete}
        helperText={helperText}
        clearInputValueOnChange={false}
        className={clsx(classes.chipInput)}
        style={{ marginBottom: "1em" }}
        {...rest}
      />
    </div>
  );
};

export default ChipInputWrapper;
