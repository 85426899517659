import React from "react";

import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "6px"
  },
  text: {
    marginRight: "8px"
  },
  text2: {
    fontSize: "0.8em"
  }
}));

const SummaryBar = (props) => {
  const classes = useStyles();
  const { test } = props;
  const {
    details: { numQuestions, maxScore }
  } = test;

  return (
    <div className={classes.root}>
      <Typography className={classes.text2} variant="h6">
        {`#Questions: ${numQuestions} / `}
        {maxScore ? `Max. score: ${maxScore}` : null}
      </Typography>
    </div>
  );
};

export default React.memo(SummaryBar);
