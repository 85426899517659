import React, { createContext, useEffect } from "react";
import { connect } from "react-redux";

import {
  onIdTokenChanged,
  onSigninAnon,
  onSignupEP,
  onLoginEP,
  onLogout
} from "../../store/actions";
import firebase from "../../shared/utils/firebase";

export const AuthContext = createContext({
  abortController: null,
  isAuthenticating: true,
  isLoggingIn: false,
  isLoggedIn: false,
  role: "",
  user: null,
  error: "",
  isVerified: false,
  token: null,
  expirationTime: null,
  // onIdTokenChanged: () => {},
  onSigninAnon: () => {},
  onSignupEP: () => {},
  onLoginEP: () => {},
  onLogout: () => {}
});

const AuthProvider = (props) => {
  const {
    abortController,
    isAuthenticating,
    isLoggingIn,
    isLoggedIn,
    role,
    user,
    error,
    isVerified,
    token,
    expirationTime
  } = props.auth;

  const {
    onIdTokenChanged,
    onSigninAnon,
    onSignupEP,
    onLoginEP,
    onLogout
  } = props;

  useEffect(() => {
    firebase.auth().onIdTokenChanged((user) => {
      onIdTokenChanged(user);
    });
  }, [onIdTokenChanged]);

  return (
    <AuthContext.Provider
      value={{
        abortController,
        isAuthenticating,
        isLoggingIn,
        isLoggedIn,
        role,
        user,
        error,
        isVerified,
        token,
        expirationTime,
        onSigninAnon,
        onSignupEP,
        onLoginEP,
        onLogout
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps, {
  onIdTokenChanged,
  onSigninAnon,
  onSignupEP,
  onLoginEP,
  onLogout
})(AuthProvider);
