import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  IconButton,
  Typography,
  Button,
  Divider,
  Tooltip,
  DialogTitle,
  DialogContent,
  useMediaQuery
} from "@material-ui/core";
import { ViewColumn } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  selector: {
    padding: "9px"
  },
  button: {
    textTransform: "none",
    margin: "5px 5px 9px 5px",
    backgroundColor: "lightgrey"
  },
  title: {
    padding: "0",
    paddingTop: "8px"
  },
  titleText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px"
  },
  close: {
    textTransform: "none"
  }
}));

const Selector = React.memo((props) => {
  const classes = useStyles();
  const breakpoint = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { columns, handleColumns, handlePresetColumns } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handlePreset = (value) => {
    const len = columns.length;
    const arr = [...Array(len).keys()];
    switch (value) {
      case "all":
        handlePresetColumns(arr);
        break;
      case "basic":
        handlePresetColumns(arr.slice(0, 6));
        break;
      case "leader":
        handlePresetColumns([0, 2].concat(arr.slice(6)));
        break;
      default:
        return null;
    }
  };

  // return null;
  return (
    <>
      <Tooltip title="Select Columns">
        <IconButton onClick={handleClick} className={classes.selector}>
          <ViewColumn />
        </IconButton>
      </Tooltip>

      <Dialog
        open={Boolean(anchorEl)}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        fullScreen={!breakpoint}
        scroll="paper"
      >
        <DialogTitle disableTypography className={classes.title}>
          <div className={classes.titleText}>
            <Typography variant="body1">Select Columns</Typography>
            {handleClose ? (
              <Button
                aria-label="close"
                variant="outlined"
                color="primary"
                className={classes.close}
                onClick={handleClose}
                size="small"
              >
                Done
              </Button>
            ) : null}
          </div>

          <div style={{ padding: "0 8px" }}>
            <Button
              size="small"
              className={classes.button}
              onClick={(event) => handlePreset("all")}
            >
              All
            </Button>
            <Button
              size="small"
              className={classes.button}
              onClick={(event) => handlePreset("basic")}
            >
              Summary
            </Button>
            <Button
              size="small"
              className={classes.button}
              onClick={(event) => handlePreset("leader")}
            >
              Leaderboard
            </Button>
          </div>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              {columns.map((col, i) => {
                return (
                  <div key={col.id}>
                    <FormControlLabel
                      // classes={{ root: classes.formControlLabelRoot }}
                      control={
                        <Checkbox
                          // classes={{ root: classes.checkRoot }}
                          size="medium"
                          color="primary"
                          disabled={col.id === "name"}
                          checked={col.show}
                          onChange={(event) => handleColumns(i)}
                        />
                      }
                      label={
                        <Typography variant="body2">{col.label}</Typography>
                      }
                    />
                  </div>
                );
              })}
            </FormGroup>
          </FormControl>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default Selector;
