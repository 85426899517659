import { io } from "socket.io-client";

import appconfig from "../../config";
import store from "../../store/store";

/**
 * Namespaces
 */
const TEST_TIMER_NS = "test-timer-namespace";
const TEST_SUBMIT_NS = "test-submit-namespace";
// const TEST_EDIT_NS = "test-edit-namespace";
// const USER_MESSAGES_NS = "user-messages-namespace";
// const USER_NOTIF_NS = "user-notifications-namespace";

export const timerSocket = io(appconfig.BASE_API_URL + "/" + TEST_TIMER_NS, {
  autoConnect: false,
});
export const submitSocket = io(appconfig.BASE_API_URL + "/" + TEST_SUBMIT_NS, {
  autoConnect: false,
  auth: (cb) => {
    cb(store.getState().auth);
  },
});
export const socket = io(appconfig.BASE_API_URL, {
  autoConnect: false,
});

socket.io.on("error", (error) => {
  console.log("connection error");
});

socket.io.on("reconnect", (attempt) => {
  console.log("reconnection at attempt: ", attempt);
});

socket.io.on("reconnect_attempt", (attempt) => {
  console.log("attempting reconn: ", attempt);
});

socket.io.on("reconnect_error", (error) => {
  console.log("reconnection error:");
});

socket.io.on("reconnect_failed", () => {
  console.log("reconnection failed");
});

// socket.onAny((event, ...args) => {
//   console.log("socket", event, args);
// });

// submit_socket.on("connect", () => {
//   submit_socket.emit("auth", store.getState().auth);
// });
