import React from "react";
import clsx from "clsx";

import { DragIndicator } from "@material-ui/icons";
import { ListItem, makeStyles, Typography } from "@material-ui/core";

import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

// import EditableType from "../../../shared/components/UIElements/EditableType";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Handle = styled.div`
  opacity: 0.4;
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    verticalAlign: "middle",
    alignItems: "center",
    padding: theme.spacing(1, 0)
  },
  selected: {
    backgroundColor: "lightgrey"
  },
  label: {
    whiteSpace: "normal",
    textAlign: "left",
    padding: theme.spacing(1)
  }
}));

const DraggableSection = (props) => {
  const { id, idx, title, selectedSectionIdx } = props;
  const classes = useStyles();
  return (
    <Draggable draggableId={id} index={idx}>
      {(provided) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={clsx(
            // classes.root,
            idx === selectedSectionIdx ? classes.selected : null
          )}
        >
          <ListItem>
            <Handle {...provided.dragHandleProps}>
              <DragIndicator fontSize="small" />
            </Handle>
            <div
              className={clsx(
                classes.root
                // idx === selectedSectionIdx ? classes.selected : null
              )}
            >
              <Typography variant="body1" className={classes.label}>
                {title}
              </Typography>
            </div>
          </ListItem>
        </Container>
      )}
    </Draggable>
  );
};

export default DraggableSection;
