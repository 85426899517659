/**
 * This component carries the logic for calculating the remaining time
 * given an end-time and starts a timer to update the time every second.
 * The offset value can be used to sync with a server if provided.
 */
import React, { useEffect, useCallback, useRef, useState, useContext } from "react";
import moment from "moment";

import { ServerTimeContext } from "../../shared/context/server-time-context";

const Timer = React.memo((props) => {
  const {
    endTime,
    onChange = null,
    showZero = false,
    // offset = 0,
    TimeDisplay,
  } = props;

  /**
   * offset value is obtained via context to skip being passed as a prop
   * to the parent component (and then being passed down to the Timer component).
   * Otherwise, the entire parent component will re-render whenever the value
   * of offset changes.
   */
  const offset = useContext(ServerTimeContext);

  /**
   * The updateClock function uses endTime in its calculations.
   * Using a ref will avoid updateClock to be redefined whenever
   * the value of endTime changes, which will in turn avoid the
   * useEffect that starts the timer to re-render (unmount and mount)
   * the component each time.
   * The offset value is used to sync with the server when required.
   * An additional shift of 1000ms is applied for UX purposes.
   **/  
  const [end, setEnd] = useState(null);
    
  useEffect(() => {
    
    setEnd(moment(endTime)
      .add(1000)
      .add(-1 * offset));
  }, [endTime, offset]);

  // timer should remain constant between renders, hence using a ref
  const timer = useRef(null);

  // Time object to be passed to the TimeDisplay component
  const [timeObj, setTimeObj] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    showZero: showZero,
  });

  /**
   * Function to calculate the time values remaining every second
   */
  const updateClock = useCallback(
    (end) => {      
      let diff;
      diff = moment(end).diff(moment());
      if (diff <= 0) {
        clearInterval(timer.current);
        timer.current = null;
        const time = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          diff: 0,
          showZero,
        };
        setTimeObj(time);
        onChange && onChange(time);
      } else {
        const duration = moment.duration(diff);
        const seconds = duration.seconds();
        const minutes = duration.minutes();
        const hours = duration.hours();
        const days = duration.days();
        const time = { days, hours, minutes, seconds, diff, showZero };
        setTimeObj(time);
        onChange && onChange(time);
      }
    },
    [onChange, showZero]
  );

  /**
   * When the component loads, this useEffect starts the timer
   * to update the clock every second.
   */
  useEffect(() => {
    clearInterval(timer.current);
    timer.current = null;    
      updateClock(end);
      timer.current = setInterval(() => {
        updateClock(end);
      }, 1000);
    return () => {
      clearInterval(timer.current);
      timer.current = null;
    };
  }, [updateClock, end]);

  return (
    <React.Fragment>
      <TimeDisplay {...timeObj} />
    </React.Fragment>
  );
});

export default Timer;
