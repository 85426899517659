export const patterns = {
  noscore: {
    name: "noscore",
    label: "None",
    description: "No marking will be applied."
  },
  custom: {
    name: "custom",
    label: "Custom",
    description: "Custom marking will be applied.",
    correctMarks: 1,
    incorrectMarks: 0,
    blankMarks: 0,
    compute: true
  },
  manual: {
    name: "manual",
    label: "Manual",
    description:
      "Marks can be applied manually for each question after the test.",
    correctMarks: 1,
    compute: false
  },
  jeeType1: {
    name: "jeeType1",
    label: "JEE Multiple Option",
    description:
      "JEE Advanced scheme with partial marking:\n" +
      "+4 if all options marked are correct.\n" +
      "+3 if all 4 options are correct but only 3 options are chosen.\n" +
      "+2 if 3 or more options are correct but only 2 are marked both of which are correct.\n" +
      "+1 if 2 or more options are correct but only 1 is marked which is correct.\n" +
      "-1 if any option marked is incorrect.\n" +
      "0 if the question is left unanswered.",
    correctMarks: 4,
    incorrectMarks: -1,
    blankMarks: 0,
    compute: true
  },
  jeeType3: {
    name: "jeeType3",
    label: "JEE Single Option",
    description:
      "JEE scheme:\n" +
      "+4 if option marked correct.\n" +
      "-1 if option marked is incorrect.\n" +
      "0 if the question is left unanswered.",
    correctMarks: 4,
    incorrectMarks: -1,
    blankMarks: 0,
    compute: true
  },
  jeeType2: {
    name: "jeeType2",
    label: "JEE Numerical Type",
    description:
      "JEE scheme:\n" +
      "+3 if answer is correct to two decimal places.\n" +
      "0 if answer is incorrect.\n" +
      "0 if the question is left unanswered.",
    correctMarks: 3,
    incorrectMarks: 0,
    blankMarks: 0,
    compute: true
  }
};

export const patternsArr = Object.values(patterns);

const scoringPatterns = {
  section: {
    name: "section",
    label: "Section Marking",
    description: "The marking scheme selected for the Section will be used."
  },
  test: {
    name: "test",
    label: "Test Marking",
    description: "The marking scheme selected for the Test will be used."
  },
  ...patterns
};

export default scoringPatterns;
