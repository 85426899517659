import React from "react";
import clsx from "clsx";
import {
  makeStyles,
  Typography,
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputLabel,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { InfoOutlined, Replay } from "@material-ui/icons";
import { patterns, patternsArr } from "./scoringPatterns";
import { questionTypes } from "../Question/questionTypes";

const Marking = (props) => {
  const classes = makeStyles((theme) => ({
    panelDetails: {
      flexDirection: "column",
      padding: "0",
      marginBottom: "15px"
    },
    groupedMenuItem: {
      marginLeft: "20px"
    },
    note: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.secondary
    },
    selectFormControl: {
      flexGrow: 1,
      marginRight: "5px"
    },
    selectRoot: {
      display: "flex",
      alignItems: "center",
      paddingTop: "12px",
      paddingBottom: "12px",
      minWidth: "0",
      overflow: "hidden"
    },
    selectText: {
      minWidth: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    selectLabel: {
      paddingLeft: "14px",
      margin: "-8px 5px 0 5px",
      marginTop: "-8px"
    },
    marksSelector: {
      display: "flex",
      alignItems: "center"
    },
    marksList: {
      display: "flex"
    },
    marks: {
      marginRight: "15px",
      maxWidth: "140px"
    },
    tooltip: {
      fontSize: "0.8rem"
    }
  }))();

  const {
    marking,
    parent,
    qtype,
    allowAllMarking,
    markingFrom,
    parentMarking,
    handleMarking,
    handleMarkingFrom,
    classes: classesProp
  } = props;

  const allowedMarking = questionTypes[qtype]
    ? questionTypes[qtype].marking
    : [];

  const self = !markingFrom || markingFrom === "SELF";
  const { name, correctMarks, incorrectMarks, blankMarks } =
    marking || parentMarking || patternsArr[0];

  const selectedName = name;
  const handleSelectMarking = (event) => {
    const selected = event.target.value;
    handleMarkingFrom && handleMarkingFrom(selected);
    handleMarking(patterns[selected]);
  };

  const handleMarksChange = (event, type) => {
    const marks = event.target.value;
    handleMarking({ ...marking, [type]: marks });
    handleMarkingFrom && handleMarkingFrom("self");
  };

  const handleRevert = () => {
    handleMarkingFrom && handleMarkingFrom(parent);
    handleMarking(parentMarking);
  };

  const marksList = () => {
    return (
      <div className={classes.marksList}>
        {correctMarks !== undefined ? (
          <div className={classes.marks}>
            <TextField
              margin="dense"
              // variant="outlined"
              label={name !== "manual" ? "Correct" : "Maximum marks"}
              value={correctMarks}
              onChange={(event) => handleMarksChange(event, "correctMarks")}
              disabled={!!name.match(/jeeType/)}
              type="number"
              InputLabelProps={{ shrink: true }}
            />
          </div>
        ) : null}
        {incorrectMarks !== undefined ? (
          <div className={classes.marks}>
            <TextField
              margin="dense"
              label={"Incorrect"}
              value={incorrectMarks}
              onChange={(event) => handleMarksChange(event, "incorrectMarks")}
              disabled={!!name.match(/jeeType/)}
              type="number"
              InputLabelProps={{ shrink: true }}
            />
          </div>
        ) : null}
        {blankMarks !== undefined ? (
          <div className={classes.marks}>
            <TextField
              margin="dense"
              value={blankMarks}
              label={"Unanswered"}
              onChange={(event) => handleMarksChange(event, "blankMarks")}
              disabled={!!name.match(/jeeType/)}
              type="number"
              InputLabelProps={{ shrink: true }}
            />
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div
      className={clsx(classesProp && classesProp.root, classes.panelDetails)}
    >
      <div className={classes.marksSelector}>
        <FormControl className={classes.selectFormControl}>
          <InputLabel
            className={classes.selectLabel}
            style={{ backgroundColor: "white" }}
            id="select-filled-label"
            shrink
          >
            Marking Scheme
          </InputLabel>
          <Select
            classes={{ root: classes.selectRoot }}
            variant="outlined"
            label="Marking Scheme"
            value={selectedName}
            onChange={handleSelectMarking}
            displayEmpty
            labelId="select-filled-label"
          >
            {patternsArr
              .slice(parent ? 0 : 0, parent ? 3 : 3)
              .map((pattern, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={pattern.name}
                    disabled={
                      allowAllMarking
                        ? false
                        : !allowedMarking.includes(pattern.name)
                    }
                  >
                    <Typography className={classes.selectText}>
                      {pattern.label}
                    </Typography>
                  </MenuItem>
                );
              })}
            <ListSubheader disableSticky>JEE Marking Schemes</ListSubheader>
            {patternsArr.slice(parent ? 3 : 3).map((pattern, i) => {
              return (
                <MenuItem
                  key={i}
                  value={pattern.name}
                  className={classes.groupedMenuItem}
                  disabled={
                    allowAllMarking
                      ? false
                      : !allowedMarking.includes(pattern.name)
                  }
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography className={classes.selectText}>
                      {pattern.label}
                    </Typography>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {parent ? (
          <Tooltip
            arrow
            classes={{ tooltip: classes.tooltip }}
            title={`Revert to ${parent} setting`}
          >
            <span>
              <IconButton
                disabled={!self || !allowedMarking.includes(parentMarking.name)}
                onClick={handleRevert}
              >
                <Replay fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        ) : null}
        <Tooltip
          arrow
          classes={{ tooltip: classes.tooltip }}
          title={patterns[selectedName].description
            .split("\n")
            .map((item, i) => (
              <span key={i}>
                {item}
                <br />
              </span>
            ))}
        >
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </div>
      {marksList()}
    </div>
  );
};

export default Marking;
