/**
 * Static component that returns a time string
 * given values for days, hours, minutes and seconds
 * and a boolean to indicate if a string should be shown
 * when all values are zero.
 */

import React, { useCallback } from "react";
import clsx from "clsx";
import {
  makeStyles,
  useMediaQuery,
  Button,
  Typography,
} from "@material-ui/core";
import { AlarmRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  timerBtn: () => ({
    margin: "0 8px",
    cursor: "default",
    padding: "6px 20px",
    border: "2px solid",
    [theme.breakpoints.down("xs")]: {
      border: "0.5px solid",
      padding: "8px 16px",
    },
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    boxSizing: "border-box"
  }),
  timerBtnText: {
    textTransform: "none",
    fontWeight: "600",    
  },
}));

const TimeDisplay = React.memo((props) => {
  const classes = useStyles();
  const breakpoint = useMediaQuery((theme) => theme.breakpoints.up("smd"));

  const timeStr = useCallback(({ days, hours, minutes, seconds, showZero }) => {
    const pad = (num) => ("00" + num).slice(-2);
    let str = "";
    if (days > 0) {
      str = days + "d " + hours + "h " + minutes + "m " + seconds + "s";
    } else if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      str = showZero ? "00:00:00" : "";
    } else {
      str = pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
    }
    return str;
  }, []);

  return (
    <React.Fragment>
      <Button
        startIcon={breakpoint ? <AlarmRounded /> : null}
        className={clsx(classes.timerBtn)}
        disableFocusRipple
        disableRipple
        size="large"
      >
        <Typography className={classes.timerBtnText}>
          {timeStr(props)}
        </Typography>
      </Button>
    </React.Fragment>
  );
});

export default TimeDisplay;
