import * as actionTypes from "../actionTypes";
import cloneDeep from "lodash.clonedeep";

// QUESTION_EDITING, QUESTION_CREATING_NEW
export const onQuestionEditorLoad = (idx) => {
  return (dispatch, getState) => {
    const test = getState().test;
    const { questions, marking = {}, markingFrom, qtype } = test.sections[
      test.selectedSectionIdx
    ];
    if (idx >= 0) {
      const question = questions[idx];
      const localQuestion = cloneDeep(question);

      dispatch({
        type: actionTypes.QUESTION_EDITING,
        payload: {
          localQuestion,
          mode: "EDIT_QUESTION"
        }
      });
    } else {
      dispatch({
        type: actionTypes.QUESTION_CREATING_NEW,
        payload: {
          mode: "NEW_QUESTION",
          markingFrom: markingFrom === "SELF" ? "SECTION" : "TEST",
          marking,
          type: qtype
        }
      });
    }
    return Promise.resolve();
  };
};

// QUESTION_TEXT_CHANGE
export const onTextChange = (text) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.QUESTION_TEXT_CHANGE, payload: { text } });
    return Promise.resolve();
  };
};

// QUESTION_TYPE_CHANGE
export const onTypeChange = (type) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.QUESTION_TYPE_CHANGE, payload: { type } });
    return Promise.resolve();
  };
};

// QUESTION_CORRECT_ANS_CHANGE
export const onCorrectAnsChange = (text) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_CORRECT_ANS_CHANGE,
      payload: { correctAns: text }
    });
    return Promise.resolve();
  };
};

// QUESTION_NUMIMAGES_CHANGE
export const onNumImagesChange = (value) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_NUMIMAGES_CHANGE,
      payload: { numImages: value }
    });
    return Promise.resolve();
  };
};

// QUESTION_OPTION_ADD
export const onOptionAdd = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_OPTION_ADD
    });
    return Promise.resolve();
  };
};

// QUESTION_OPTION_REMOVE
export const onOptionRemove = (idx) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_OPTION_REMOVE,
      payload: { idx }
    });
    return Promise.resolve();
  };
};

// QUESTION_OPTION_TEXT_CHANGE
export const onOptionTextChange = (idx, text) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_OPTION_TEXT_CHANGE,
      payload: { optionText: text, idx }
    });
    return Promise.resolve();
  };
};

// QUESTION_OPTION_IMAGE_CHANGE
export const onOptionImageChange = (idx, image) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_OPTION_IMAGE_CHANGE,
      payload: { optionImage: image, idx }
    });
    return Promise.resolve();
  };
};

// QUESTION_OPTION_CORRECT_CHANGE
export const onOptionCorrectChange = (idx) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_OPTION_CORRECT_CHANGE,
      payload: { idx }
    });
    return Promise.resolve();
  };
};

// QUESTION_OPTIONS_REORDER
export const onOptionsReorder = (from, to) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_OPTIONS_REORDER,
      payload: { from, to }
    });
    return Promise.resolve();
  };
};

// Deprecated
// export const onImageUpload = file => {
//   return async (dispatch, getState) => {
//     dispatch({
//       type: actionTypes.QUESTION_IMAGE_LOADING
//     });
//     const uid = getState().auth.user.uid;
//     const currentImageName = "firebase-image-" + Date.now();
//     const imageRef = storage.ref(`user/${uid}/images/${currentImageName}`);

//     const uploadTask = imageRef.put(file);
//     uploadTask.on(
//       "state_changed",
//       snapshot => {
//         console.log("progressing...");
//         const progress =
//           (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//       },
//       error => {
//         console.log(error.code);
//       },
//       () => {
//         uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
//           console.log("File available at", downloadURL);
//         });
//       }
//     );

//     const snapshot = await uploadTask.catch(error => {
//       console.log(error.code);
//     });

//     if (snapshot) {
//       const url = await snapshot.ref
//         .getDownloadURL()
//         .catch(error => console.log(error));
//       const image = { url };
//       dispatch(onImageChange(image));
//     }
//   };
// };

// QUESTION_IMAGE_CHANGE

export const onImageChange = (image) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_IMAGE_CHANGE,
      payload: { image }
    });
    return Promise.resolve();
  };
};

// QUESTION_MARKING_CHANGE
export const onQuestionMarkingChange = (marking) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_MARKING_CHANGE,
      payload: { marking }
    });
    return Promise.resolve();
  };
};

// QUESTION_MARKING_FROM_CHANGE
export const onQuestionMarkingFromChange = (markingFrom) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_MARKING_FROM_CHANGE,
      payload: { markingFrom }
    });
    return Promise.resolve();
  };
};

// QUESTION_SEQUENCE_CHANGE
export const onQuestionSequenceChange = (sequence) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_SEQUENCE_CHANGE,
      payload: { sequence }
    });
    return Promise.resolve();
  };
};

// QUESTION_SHORTANS_ADD
export const onShortAnsAdd = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_SHORTANS_ADD
    });
    return Promise.resolve();
  };
};

// QUESTION_SHORTANS_REMOVE
export const onShortAnsRemove = (idx) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_SHORTANS_REMOVE,
      payload: { idx }
    });
    return Promise.resolve();
  };
};

// QUESTION_SHORTANS_TEXT_CHANGE
export const onShortAnsTextChange = (idx, text) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_SHORTANS_TEXT_CHANGE,
      payload: { shortAnsText: text, idx }
    });
    return Promise.resolve();
  };
};
// onShortAnsAdd, onShortAnsRemove, onShortAnsTextChange
