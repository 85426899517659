import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

// import MUIRichTextEditor from "mui-rte";

// import Button from "@material-ui/core/Button";

import {
  onOptionAdd,
  onOptionRemove,
  onOptionsReorder
} from "../../../store/actions";
// import QuestionOption from "./QuestionOption";
import DraggableQuestionOption from "./DraggableOption";
import { Checkbox, Radio, TextField } from "@material-ui/core";

const QuestionTypeMCQ = (props) => {
  const { onOptionsReorder, onOptionRemove, onOptionAdd } = props;

  const { localQuestion } = props.questionEditor;
  let { options, correct, type } = localQuestion;

  const onDragEnd = (result) => {
    const {
      destination,
      source
      // draggableId
    } = result;
    if (!destination || destination.index === source.index) return;
    onOptionsReorder(source.index, destination.index);
  };

  const handleOptionUp = (from) => (event) => {
    const to = from === 0 ? from : from - 1;
    onOptionsReorder(from, to);
  };

  const handleOptionDown = (from) => (event) => {
    const to = from === options.length - 1 ? from : from + 1;
    onOptionsReorder(from, to);
  };

  const handleOptionDelete = (idx) => (event) => {
    onOptionRemove(idx);
  };

  const handleOptionAdd = useCallback(
    (event) => {
      const optionsSize = options.length;
      if (
        optionsSize === 0 ||
        event.type === "click" ||
        (event.type === "keydown" &&
          (event.key === "Enter" || event.key === "ArrowDown"))
      ) {
        onOptionAdd().then(() => {
          handleFocus(optionsSize);
        });
      }
    },
    [onOptionAdd, options.length]
  );

  useEffect(() => {
    if (options.length === 0) handleOptionAdd();
  }, [handleOptionAdd, options.length]);

  const handleMoveFocus = (fromIdx, event) => {
    const optionsSize = options.length;
    if (event.type === "keydown") {
      if (event.key === "Enter" || event.key === "ArrowDown") {
        event.stopPropagation();
        if (fromIdx === optionsSize - 1) {
          doBlur(fromIdx);
          handleOptionAdd(event);
        } else {
          handleFocus(fromIdx + 1);
        }
      } else if (event.key === "ArrowUp") {
        if (fromIdx > 0) {
          handleFocus(fromIdx - 1);
        }
      }
    }
  };

  const doBlur = (idx) => {
    const elId = "option-" + String(idx);
    const el = document.getElementById(elId);
    el.blur();
  };

  const handleFocus = (idx) => {
    const lastElId = "option-" + String(idx);
    const el = document.getElementById(lastElId);
    // el.focus();
    el.select();
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1
          }}
        >
          <Droppable droppableId={"options"}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {options.map((option, idx) => (
                  <DraggableQuestionOption
                    option={option}
                    idx={idx}
                    key={option._id || option.id}
                    type={type}
                    numOptions={options.length}
                    isCorrect={correct[idx]}
                    handleMoveFocus={handleMoveFocus}
                    handleOptionUp={handleOptionUp}
                    handleOptionDown={handleOptionDown}
                    handleOptionDelete={handleOptionDelete}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginLeft: "24px"
            }}
          >
            {type === "MCQSS" ? (
              <Radio color="primary" disabled />
            ) : (
              <Checkbox color="primary" disabled />
            )}
            <TextField
              id="add-option"
              onClick={handleOptionAdd}
              type="text"
              placeholder="Add an option"
              style={{ marginBottom: "10px" }}
            />
          </div>
        </div>
      </DragDropContext>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    questionEditor: state.questionEditor
  };
};

export default connect(mapStateToProps, {
  onOptionAdd,
  onOptionRemove,
  onOptionsReorder
})(React.memo(QuestionTypeMCQ));
