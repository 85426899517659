import React, { createContext, useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { onTestBlockNav } from "../../store/actions";

import { patternsArr } from "../components/Marking/scoringPatterns";

export const TestSettingsContext = createContext({
  handleReset: () => {},
  fields: {},
  funcs: {}
});

const TestSettingsProvider = (props) => {
  const { settings, details } = props;
  const { onTestBlockNav } = props;
  const [reset, setReset] = useState(true);

  // General - Name
  const [name, setName] = useState(details.name || "");

  // General - Intro
  const [instructions, setInstructions] = useState(settings.instructions || "");
  const [introImage, setIntroImage] = useState(settings.introImage || {});
  const [acknowledge, setAcknowldege] = useState(settings.acknowledge || false);
  const [acknowledgeText, setAcknowledgeText] = useState(
    settings.acknowledgeText ||
      "I have read and understood the instructions and agree to adhere by the test guidelines."
  );

  // General - Time
  const [startTime, setStartTime] = useState(settings.startTime || null);
  const [endTime, setEndTime] = useState(settings.endTime || null);
  const [timeLimit, setTimeLimit] = useState(settings.timeLimit || "");
  const [errors, setErrors] = useState({
    nameErrorMsg: "",
    timeLimitErrorMsg: "",
    startTimeErrorMsg: "",
    endTimeErrorMsg: ""
  });

  // Scoring - Pattern
  const [marking, setMarking] = useState(settings.marking || patternsArr[0]);

  // Layout - Questions
  const [layout, setLayout] = useState(settings.layout || "");
  // BY_QUESTION, SINGLE_PAGE, BY_SECTION (tbd)

  // Review
  const [reviewMode, setReviewMode] = useState(settings.reviewMode || "");
  // all, scores, none
  const [reviewWhen, setReviewWhen] = useState(settings.reviewWhen || "");
  // immediate, endtime, custom
  const [reviewCustomWhen, setReviewCustomWhen] = useState(
    settings.reviewCustomWhen || ""
  );

  useEffect(() => {
    if (reset) {
      setName(details.name || "");
      setInstructions(settings.instructions || "");
      setIntroImage(settings.introImage || {});
      setAcknowldege(settings.acknowledge || false);
      setAcknowledgeText(
        settings.acknowledgeText ||
          "I have read and understood the instructions and agree to adhere by the test guidelines."
      );
      setStartTime(settings.startTime || null);
      setEndTime(settings.endTime || null);
      setTimeLimit(settings.timeLimit || "");
      setErrors({
        nameErrorMsg: "",
        timeLimitErrorMsg: "",
        startTimeErrorMsg: "",
        endTimeErrorMsg: ""
      });
      setMarking(settings.marking || patternsArr[0]);
      setLayout(settings.layout || "");
      setReviewMode(settings.reviewMode || "all");
      setReviewWhen(settings.reviewWhen || "immediate");
      setReviewCustomWhen(settings.reviewCustomWhen || null);
      setReset(false);
    }
  }, [settings, reset, details]);

  const handleReset = useCallback((value) => {
    setReset(value);
  }, []);

  const handleErrors = useCallback((errors) => {
    setErrors(errors);
  }, []);

  const handleName = useCallback(
    (event) => {
      setName(event.target.value);
      onTestBlockNav(true);
    },
    [onTestBlockNav]
  );

  const handleInstructions = useCallback(
    (event) => {
      setInstructions(event.target.value);
      onTestBlockNav(true);
    },
    [onTestBlockNav]
  );

  const handleIntroImage = useCallback(
    (image, name) => {
      setIntroImage(image);
      onTestBlockNav(true);
    },
    [onTestBlockNav]
  );

  const handleAcknowledge = useCallback(
    (event) => {
      setAcknowldege(!acknowledge);
      onTestBlockNav(true);
    },
    [acknowledge, onTestBlockNav]
  );

  const handleAcknowledgeText = useCallback(
    (event) => {
      setAcknowledgeText(event.target.value);
      onTestBlockNav(true);
    },
    [onTestBlockNav]
  );

  const handleStartTime = useCallback(
    (date) => {
      setStartTime(date);
      setErrors({
        ...errors,
        endTimeErrorMsg: "",
        startTimeErrorMsg: "",
        timeLimitErrorMsg: ""
      });
      onTestBlockNav(true);
    },
    [onTestBlockNav, errors]
  );

  const clearStartTime = useCallback(() => {
    setStartTime(null);
    setErrors({
      ...errors,
      endTimeErrorMsg: "",
      startTimeErrorMsg: "",
      timeLimitErrorMsg: ""
    });
    onTestBlockNav(true);
  }, [onTestBlockNav, errors]);

  const handleEndTime = useCallback(
    (date) => {
      setEndTime(date);
      setErrors({
        ...errors,
        endTimeErrorMsg: "",
        startTimeErrorMsg: "",
        timeLimitErrorMsg: ""
      });
      onTestBlockNav(true);
    },
    [onTestBlockNav, errors]
  );

  const clearEndTime = useCallback(() => {
    setEndTime(null);
    setErrors({
      ...errors,
      endTimeErrorMsg: "",
      startTimeErrorMsg: "",
      timeLimitErrorMsg: ""
    });
    onTestBlockNav(true);
  }, [onTestBlockNav, errors]);

  const handleTimeLimit = useCallback(
    (event) => {
      setTimeLimit(event.target.value);
      setErrors({
        ...errors,
        endTimeErrorMsg: "",
        startTimeErrorMsg: "",
        timeLimitErrorMsg: ""
      });
      onTestBlockNav(true);
    },
    [onTestBlockNav, errors]
  );

  const handleMarking = useCallback(
    (marking) => {
      setMarking(marking);
      onTestBlockNav(true);
    },
    [onTestBlockNav]
  );

  const handleLayout = useCallback(
    (event) => {
      setLayout(event.target.value);
      onTestBlockNav(true);
    },
    [onTestBlockNav]
  );

  const handleReviewMode = useCallback(
    (value) => {
      setReviewMode(value);
      onTestBlockNav(true);
    },
    [onTestBlockNav]
  );

  const handleReviewWhen = useCallback(
    (value) => {
      setReviewWhen(value);
      onTestBlockNav(true);
    },
    [onTestBlockNav]
  );

  const handleReviewCustomWhen = useCallback(
    (value) => {
      setReviewCustomWhen(value);
      onTestBlockNav(true);
    },
    [onTestBlockNav]
  );

  return (
    <TestSettingsContext.Provider
      value={{
        handleReset,
        fields: {
          name,
          instructions,
          introImage,
          acknowledge,
          acknowledgeText,
          startTime,
          endTime,
          timeLimit,
          marking,
          layout,
          reviewMode,
          reviewWhen,
          reviewCustomWhen
        },
        funcs: {
          handleName,
          handleInstructions,
          handleIntroImage,
          handleAcknowledge,
          handleAcknowledgeText,
          handleStartTime,
          clearStartTime,
          handleEndTime,
          clearEndTime,
          handleTimeLimit,
          handleMarking,
          handleLayout,
          handleReviewMode,
          handleReviewWhen,
          handleReviewCustomWhen
        },
        errors,
        handleErrors
      }}
    >
      {props.children}
    </TestSettingsContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    settings: state.test.settings,
    details: state.test.details
  };
};

export default connect(mapStateToProps, { onTestBlockNav })(
  TestSettingsProvider
);
