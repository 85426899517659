import React, { createContext } from "react";
import { useState, useEffect } from "react";

import moment from "moment";

/** Utility imports */
import { timerSocket } from "../utils/socket";


export const ServerTimeContext = createContext(0);

export const ServerTimeProvider = props => {
  const [serverDiff, setServerDiff] = useState(0);

  /**
   * Initialize and manage test timer socket
   */
  useEffect(() => {
    let isMounted = true;
    timerSocket.connect();
    // Register timerSocket event listeners
    timerSocket.on("SERVER_TIME", (serverTime) => {
      if (isMounted) {
        const diff = moment(serverTime).diff(moment());
        setServerDiff(diff);
      }
    });

    return () => {
      isMounted = false;
      timerSocket.disconnect();
    };
  }, []);

  return (
    <ServerTimeContext.Provider value={serverDiff}>
      {props.children}
    </ServerTimeContext.Provider>
  );
};