/**
 * Static component that returns a time string
 * given values for days, hours, minutes and seconds
 * and a boolean to indicate if a string should be shown
 * when all values are zero.
 */

import React, { useCallback } from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  timerText: {},
}));

const SimpleTimeDisplay = React.memo((props) => {
  const classes = useStyles();

  const timeStr = useCallback(({ days, hours, minutes, seconds, showZero }) => {
    const pad = (num) => ("00" + num).slice(-2);
    let str = "";
    if (days > 0) {
      str = days + "d " + hours + "h " + minutes + "m " + seconds + "s";
    } else if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      str = showZero ? "00:00:00" : "";
    } else {
      str = pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
    }
    return str;
  }, []);

  return (
    <React.Fragment>
      <Typography className={classes.timerText}>{timeStr(props)}</Typography>
    </React.Fragment>
  );
});

export default SimpleTimeDisplay;
