import * as actionTypes from "../actionTypes";
import axios from "../../shared/utils/axios";
import _axios from "axios";
import * as Sentry from "@sentry/react";

import firebase from "../../shared/utils/firebase";
import { validate } from "../../shared/utils/validator";

const createUser = async ({
  name,
  email,
  token,
  isNewUser,
  role,
  cancelToken,
  prevUser,
}) => {
  const headers = {
    "X-Firebase-ID-Token": token,
  };
  const response = await axios
    .post(
      "/api/users/",
      { name, email, isNewUser, role, prevUser },
      { headers },
    )
    .catch((error) => {
      console.log("Error", error.message);
      if (error & error.data) throw Error(error.data.message);
      else throw error;
    });
  return Promise.resolve(response);
};

// const getCurrentAnon = async () => {
//   const currentLoggedIn = firebase.auth().currentUser;
//   const currentTokenResult =
//     firebase.auth().currentUser && (await currentLoggedIn.getIdTokenResult());
//   const currentClaims = currentTokenResult && currentTokenResult.claims;
//   const { mid, role } = currentClaims || {};
//   const anonUser = role === "anon" ? mid : null;
//   return anonUser;
// };

const onSetUser = async (dispatch, user, refresh = false, source) => {
  let fid, mid, role, name, email, phone, isAnonymous, isVerified;
  let token, expirationTime;
  let isLoggedIn;
  if (!user) user = firebase.auth().currentUser;
  if (user) {
    const tokenResult = await user.getIdTokenResult(refresh);
    if (tokenResult) {
      expirationTime = tokenResult.expirationTime;
      token = tokenResult.token;
      role = tokenResult.claims.role;
      mid = tokenResult.claims.mid;
      isLoggedIn = Boolean(token);
    }
    fid = user.uid;
    isVerified = user.emailVerified;
    isAnonymous = user.isAnonymous;
    name = user.displayName;
    email = user.email;
    phone = user.phoneNumber;
  }
  Sentry.setUser({
    fid,
    mid,
    role,
    name,
    email,
    phone,
    isAnonymous,
    isVerified,
  });
  dispatch({
    type: actionTypes.AUTH_CHANGE,
    payload: {
      fid,
      mid,
      role,
      name,
      email,
      phone,
      token,
      expirationTime,
      isVerified,
      isAnonymous,
      isLoggedIn,
    },
  });
};

export const onIdTokenChanged = (user) => {
  return async (dispatch, getState) => {
    if (!getState().auth.isLoggingIn) {
      await onSetUser(dispatch, user, false, "onIdTokenChanged");
    }
    return Promise.resolve();
  };
};

export const onSigninAnon = () => {
  return async (dispatch, getState) => {
    try {
      if (!firebase.auth().currentUser) {
        dispatch({
          type: actionTypes.SIGNIN_ANON_START,
          payload: {},
        });
        await firebase.auth().signInAnonymously();
        // Any errors above will be caught by the catch block
        // And will have the right auth error code
        const user = firebase.auth().currentUser;
        const token1 = user ? await user.getIdToken() : null;
        await createUser({
          name: null,
          email: null,
          token: token1,
          isNewUser: null,
          role: "anon",
        }).catch(async (error) => {
          await user.delete();
          console.log("Error", error.message);
        });
        const tokenResult = await user.getIdTokenResult(true);
        const token = tokenResult ? tokenResult.token : null;
        const expirationTime = tokenResult ? tokenResult.expirationTime : null;
        const isLoggedIn = token ? !!token : false;
        const role = tokenResult ? tokenResult.claims.role : null;
        const isVerified = user.emailVerified;
        dispatch({
          type: actionTypes.SIGNIN_ANON_SUCCESS,
          payload: {
            user,
            token,
            isLoggedIn,
            role,
            isVerified,
            expirationTime,
          },
        });
        return Promise.resolve();
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      dispatch({
        type: actionTypes.SIGNIN_ANON_ERROR,
        payload: { error: error.message },
      });
      return Promise.reject();
    }
  };
};

export const onSignupEP = ({ email, password, role, name }) => {
  const roleVar = role;
  return async (dispatch, getState) => {
    try {
      const nameError = await validate(name, {
        required: [true, "Name is required"],
      });
      if (nameError) throw new Error("app/name-invalid");

      const role = roleVar;
      const axiosAbortCtrl = _axios.CancelToken.source();
      dispatch({
        type: actionTypes.SIGNUP_EP_START,
        payload: { abortController: axiosAbortCtrl },
      });

      const creds = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      // Any errors above will be caught by the catch block
      // And will have the right auth error code
      await creds.user.updateProfile({
        displayName: name,
      });
      await creds.user.sendEmailVerification();

      // Create user in db and set custom claims
      // If either the user is not created successfully
      // or custom claims are not set successfully
      // the user will be deleted from firebase on next login
      // and will need to signup again with email
      let token = await creds.user.getIdToken();
      const headers = {
        "X-Firebase-ID-Token": token,
      };
      await axios.post("/api/users/", { name, email, role }, { headers });

      // Refresh the token to get custom claims
      await onSetUser(dispatch, null, true, "onSignupEP");
      dispatch({ type: actionTypes.LOGIN_EP_SUCCESS });
    } catch (error) {
      console.log(email);
      Sentry.captureException(error, {
        contexts: { logs: { email: email } },
      });
      let emailErrorMsg, pwdErrorMsg, nameErrorMsg, errorMsg;
      if (
        ["auth/email-already-in-use", "auth/invalid-email"].includes(error.code)
      ) {
        emailErrorMsg = error.message;
      } else if (["auth/weak-password"].includes(error.code)) {
        pwdErrorMsg = error.message;
      } else {
        errorMsg = error.code || "";
      }
      dispatch({
        type: actionTypes.SIGNUP_EP_ERROR,
        payload: { error: errorMsg },
      });
      return Promise.reject({
        nameErrorMsg,
        emailErrorMsg,
        pwdErrorMsg,
      });
    }
  };
};

export const onLoginEP = ({ email, password }) => {
  return async (dispatch, getState) => {
    try {
      const axiosAbortCtrl = _axios.CancelToken.source();
      dispatch({
        type: actionTypes.LOGIN_EP_START,
        payload: { abortController: axiosAbortCtrl },
      });

      const creds = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      // Any errors above will be caught by the catch block
      // And will have the right auth error code
      const tokenResult = await creds.user.getIdTokenResult();
      // Any errors above will be caught by the catch block
      const {
        claims: { role, mid },
        token,
      } = tokenResult;
      if (!role || !mid) {
        // User was not setup successfully in DB
        // and claims weren't set
        // Delete user in fb and throw an error
        // NOTE: If claims were set, that means the
        // db user would have been created as well.
        // NOTE: this does not handle the case where a
        // user has been deleted from the db, but has a
        // well-formed (with claims) account in fb
        Sentry.captureMessage("Deleting user", {
          contexts: { logs: { user: creds.user } },
        });
        await creds.user.delete();
        throw new Error("app/deleted-user");
      }
      const headers = {
        "X-Firebase-ID-Token": token,
      };

      await axios.get("/api/users/signin", { headers });
      await onSetUser(dispatch, null, true, "onLoginEP");
      dispatch({ type: actionTypes.LOGIN_EP_SUCCESS });
    } catch (error) {
      console.log(email);
      Sentry.captureException(error, {
        contexts: { logs: { email: email } },
      });
      // don't do "onLogout", since this will also logout
      // the anonymous user who might be logged in
      // dispatch(onLogout());
      let emailErrorMsg, pwdErrorMsg, errorMsg;
      if (["auth/invalid-email"].includes(error.code)) {
        emailErrorMsg = error.message;
      } else if (
        [
          "auth/user-not-found",
          "auth/user-disabled",
          "auth/wrong-password",
        ].includes(error.code)
      ) {
        pwdErrorMsg =
          "The username and password combination provided is invalid or the account does not exist.";
      } else if (error.message === "app/deleted-user")
        emailErrorMsg =
          "There was an error setting up the account. Signup again to create a new account.";
      else errorMsg = error.code || error.message;
      dispatch({
        type: actionTypes.LOGIN_EP_ERROR,
        payload: { error: errorMsg },
      });
      return Promise.reject({
        emailErrorMsg,
        pwdErrorMsg,
      });
    }
  };
};

export const onLogout = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.LOGOUT_SUCCESS });
    if (firebase.auth().currentUser) {
      await firebase.auth().signOut();
    }
    // Any errors will be caught by Sentry
  };
};

export const onUserNameChange = (name) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.USER_NAME_CHANGE,
    });
    const user = firebase.auth().currentUser;
    if (user) user.updateProfile({ displayName: name });
    return axios
      .patch("/api/users/name_change", { name })
      .then((response) => {
        dispatch({
          type: actionTypes.USER_NAME_CHANGE_SUCCESS,
          payload: { message: "saved" },
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.USER_NAME_CHANGE_ERROR,
          payload: { message: "error" },
        });
      });
  };
};
