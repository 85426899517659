import React from "react";
import { Input, Tooltip, IconButton, makeStyles } from "@material-ui/core";
import { PhotoOutlined } from "@material-ui/icons";

const ImageProvider = ({ name, handleImageFile, ...other }) => {
  const classes = makeStyles((theme) => ({
    imageInput: {
      "& input": { display: "none" },
      // visibility: "hidden",
      alignSelf: "flex-start"
    }
  }))();

  return (
    <div className={classes.imageInput}>
      <Input
        type="file"
        id={name}
        value=""
        onChange={(e) => handleImageFile(e.target.files[0], name)}
        inputProps={{ accept: "image/*" }}
      />
      <label htmlFor={name}>
        {other.children || (
          <Tooltip title="Insert Image">
            <IconButton aria-label="upload picture" component="span">
              <PhotoOutlined fontSize="default" />
            </IconButton>
          </Tooltip>
        )}
      </label>
    </div>
  );
};

export default React.memo(ImageProvider);
