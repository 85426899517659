import React, { useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
// import MUIRichTextEditor from "mui-rte";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import ImageProvider from "../../../shared/components/UIElements/ImageProvider";
import ImageReceiver from "../../../shared/components/UIElements/ImageReceiver";

import {
  onTextChange,
  onTypeChange,
  onOptionAdd,
  onImageChange,
  onQuestionMarkingChange,
  onQuestionMarkingFromChange
} from "../../../store/actions";
import Marking from "../Marking/Marking2";
import QuestionTypeSelect from "./QuestionTypeSelect";
import { patterns } from "../Marking/scoringPatterns";
import { questionTypes } from "./questionTypes";

const useStyles = makeStyles((theme) => ({
  questionTextContainer: {
    "&:hover [class*='-imageInput-']": {
      visibility: "visible"
    },
    display: "flex"
  },
  imageInput: {
    "& input": { display: "none" },
    visibility: "hidden",
    alignSelf: "flex-start"
  },
  questionTextRoot: {
    fontWeight: 700,
    fontFamily: ["Open Sans", "sans-serif"],
    fontSize: "18px",
    width: "100%"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginTop: "30px"
  },
  markingContainer: {
    width: "100%",
    flexGrow: 1,
    marginTop: "40px",
    [theme.breakpoints.up("sm")]: { width: "50%" }
  },
  typeContainer: {
    width: "100%",
    flexGrow: 1,
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: { width: "50%" }
  }
}));

const QuestionBase = React.memo((props) => {
  const {
    onTextChange,
    onTypeChange,
    onOptionAdd,
    onImageChange,
    onQuestionMarkingChange,
    onQuestionMarkingFromChange
  } = props;
  const classes = useStyles();

  const { localQuestion } = props.questionEditor;
  const { text, image, type, marking, markingFrom, options } = localQuestion;

  const { sectionMarking } = props;

  const textRef = useRef();

  const [imageFile, setImageFile] = useState(null);

  const handleQuestionImageFile = (imageFile, name) => {
    setImageFile(imageFile);
  };

  const handleTextChange = (event) => {
    onTextChange(event.target.value);
  };

  const handleTextLocalChange = () => {};

  const handleTypeChange = (event, value) => {
    const qtype = event ? event.target.value : value ? value : "SHORT";
    onTypeChange(qtype)
      .then(() => {
        if (qtype === "MCQMS" || qtype === "MCQSS") {
          if (options.length === 0) handleOptionAdd(new MouseEvent("click"));
          else {
            handleFocus(0);
          }
        }
      })
      .then(() => {
        const allowedMarking = questionTypes[qtype].marking;
        if (!allowedMarking.includes(marking.name)) {
          const markingName = allowedMarking[0];
          const newMarking = patterns[markingName];
          handleMarkingChange(newMarking);
        }
      });
  };

  const handleOptionAdd = (event) => {
    const optionsSize = options.length;
    if (
      event.type === "click" ||
      (event.type === "keydown" &&
        (event.key === "Enter" || event.key === "ArrowDown"))
    ) {
      onOptionAdd().then(() => handleFocus(optionsSize));
    }
  };

  const handleFocus = (idx) => {
    const lastElId = "option-" + String(idx);
    const el = document.getElementById(lastElId);
    el.focus();
  };

  const handleQuestionImageChange = useCallback(
    (image, name) => {
      onImageChange(image);
    },
    [onImageChange]
  );

  const handleMarkingChange = useCallback(
    (marking) => {
      return onQuestionMarkingChange(
        marking.name === "section" ? sectionMarking : marking
      );
    },
    [onQuestionMarkingChange, sectionMarking]
  );

  const handleMarkingFromChange = useCallback(
    (value) => {
      const markingFrom = value === "section" ? "SECTION" : "SELF";
      onQuestionMarkingFromChange(markingFrom);
      markingFrom !== "SELF" && onQuestionMarkingChange(sectionMarking);
      return Promise.resolve();
    },
    [onQuestionMarkingFromChange, onQuestionMarkingChange, sectionMarking]
  );

  return (
    <>
      <QuestionTypeSelect
        handleTypeChange={handleTypeChange}
        type={type}
        classes={{ root: classes.typeContainer }}
      />
      <div style={{ height: "10px" }} />
      <div className={classes.questionTextContainer}>
        <TextField
          variant="filled"
          classes={{ root: classes.questionTextRoot }}
          id="outlined-multiline-static"
          // label="Question"
          InputLabelProps={{ shrink: true }}
          multiline
          rows={1}
          rowsMax={12}
          placeholder="Enter question text..."
          defaultValue={text}
          inputRef={textRef}
          onBlur={handleTextChange}
          onChange={handleTextLocalChange}
        />
        <ImageProvider
          name={"question"}
          handleImageFile={handleQuestionImageFile}
        />
      </div>
      <ImageReceiver
        name={"question"}
        imageObj={imageFile}
        image={image}
        handleImageChange={handleQuestionImageChange}
      />
      <div style={{ height: "20px" }} />
      {props.children}
      <Marking
        marking={marking}
        markingFrom={markingFrom}
        parent={"section"}
        qtype={type}
        parentMarking={sectionMarking}
        handleMarking={handleMarkingChange}
        handleMarkingFrom={handleMarkingFromChange}
        classes={{ root: classes.markingContainer }}
      />
      <div style={{ height: "40px" }} />
    </>
  );
});

const mapStateToProps = (state) => {
  return {
    questionEditor: state.questionEditor
  };
};

export default connect(mapStateToProps, {
  onTextChange,
  onTypeChange,
  onOptionAdd,
  onImageChange,
  onQuestionMarkingChange,
  onQuestionMarkingFromChange
})(QuestionBase);
