import React, { useEffect } from "react";
import { connect } from "react-redux";
import qs from "qs";

import { makeStyles } from "@material-ui/core";

import SectionEditor from "../components/Section/SectionEditor";
import QuestionList from "../components/Question/QuestionList";
import SectionHeader from "../components/Section1/SectionHeader";

import {
  onTestFetch,
  onSectionEditorLoad,
  onTestSectionAddEdit,
  onTestErrorReset,
} from "../../store/actions";

const useStyles = makeStyles(() => ({
  content: {
    // flexGrow: 1,
    maxWidth: "800px",
    width: "95%",
    margin: "auto",
  },
  questionList: {
    margin: "20px 0px",
  },
}));

const TestPage = (props) => {
  const {
    onTestFetch,
    onSectionEditorLoad,
    onTestSectionAddEdit,
    onTestErrorReset,
  } = props;

  const { location, match } = props;

  const testId = match.params.testId;
  const share = qs.parse(location.search, { ignoreQueryPrefix: true }).share;

  const {
    test: { details, loading, readOnly },
  } = props;

  const classes = useStyles();

  const change = loading && (!details._id || testId !== details._id);

  const handleSectionEdited = async () => {
    await onTestSectionAddEdit().catch((error) => {
      throw error;
    });
  };

  const handleSectionEditorLoad = (id) => {
    onSectionEditorLoad(id);
  };

  useEffect(() => {
    return () => {
      onTestErrorReset();
    };
  }, [onTestErrorReset]);

  useEffect(() => {
    onTestFetch(testId, share);
  }, [testId, share, onTestFetch]);

  return (
    <div className={classes.content}>
      <SectionHeader
        change={change}
        handleSectionEditorLoad={handleSectionEditorLoad}
      />
      <div className={classes.questionList}>
        <QuestionList change={change} />
      </div>
      {readOnly ? null : (
        <SectionEditor
          handleSectionEditorLoad={handleSectionEditorLoad}
          handleSectionEdited={handleSectionEdited}
        />
      )}
    </div>
  );
};

export default connect(null, {
  onTestFetch,
  onSectionEditorLoad,
  onTestSectionAddEdit,
  onTestErrorReset,
})(TestPage);
