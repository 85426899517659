import React from "react";
import { connect } from "react-redux";

import { useLocation, useParams } from "react-router-dom";

import { makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import EditableType from "../../../shared/components/UIElements/EditableType";
// import Title from "../UIElements/Title";
// import LinearProgressBar from "../UIElements/LinearProgressBar";

import { onTestNameUpdate } from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  // titleContainer: {
  //   width: "100%",
  //   display: "flex",
  //   marginLeft: "6px",
  //   justifyContent: "flex-start",
  //   [theme.breakpoints.up("sm")]: {
  //     marginLeft: "12px",
  //   },
  // },
  editable: {
    display: "flex",
    justifyContent: "flex-start",
  },
  label: theme.typography.h5,
}));

const TestTitle = (props) => {
  const location = useLocation();
  const params = useParams();
  const { testId } = params;
  const {
    nav: { loading },
    disableEdit,
  } = props;
  const { onTestNameUpdate } = props;
  const {
    test: { details },
  } = props;

  const classes = useStyles();

  const testName =
    details && details._id === testId
      ? details.name
      : location.state
      ? location.state.testName
      : "";
  //
  const handleTitleUpdated = (name) => {
    onTestNameUpdate(testId, name);
  };

  return (
    <>
      <div className={classes.titleContainer}>
        {loading && !testName ? (
          <Typography variant="h4">
            <Skeleton
              animation="wave"
              style={{ backgroundColor: "lightgrey", width: "180px" }}
            />
          </Typography>
        ) : (
          <EditableType
            editable={!disableEdit}
            onEdited={disableEdit ? null : handleTitleUpdated}
            title={testName}
            classNames={{
              label: classes.label,
              container: classes.editable,
            }}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    test: state.test,
    nav: state.blockNav,
  };
};

export default connect(mapStateToProps, {
  onTestNameUpdate,
})(TestTitle);
