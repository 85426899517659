import { Avatar } from "@material-ui/core";
import { PersonOutline } from "@material-ui/icons";
import React from "react";

const UserAvatar = (props) => {
  const { name, className } = props;
  const avatar = React.useMemo(() => {
    const userInitial = name ? name.charAt(0).toUpperCase() : null;
    return (
      <Avatar alt={name || "username"} className={className}>
        {userInitial || <PersonOutline />}
      </Avatar>
    );
  }, [name, className]);
  return <>{avatar}</>;
};

export default UserAvatar;
