import * as actionTypes from "../actionTypes";

export const onMSChange3 = (qid, value, checked) => {    
  return (dispatch, getState) => {    
    const answers = getState().submit.submit.answers;
    const { questionsMap } = answers;
    const question = questionsMap.get(qid);
    if (!value) {
      question.msAns = [];
      if (question.answered && answers.totalAnswered) {
        answers.totalAnswered = answers.totalAnswered - 1;
        answers.ansReview = answers.ansReview - 1;
      }
      question.answered = false;
    } else {
      if (!question.msAns) question.msAns = [];
      const index = question.msAns.indexOf(value);
      if (!checked) {
        if (index > -1) question.msAns.splice(index, 1);
      } else {
        if (index === -1) question.msAns.splice(index, 0, value);
      }
      const ans = !!question.msAns.length;
      if (question.answered !== ans) {
        answers.totalAnswered = (answers.totalAnswered || 0) + (ans ? 1 : -1);
        answers.ansReview =
          (answers.ansReview || 0) + question.review ? (ans ? 1 : -1) : 0;
      }
      question.answered = ans;
    }
    answers.questionsMap.set(qid, question);
    //answers.sections[sidx].questions[qidx] = question;    
    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

export const onSSChange3 = (qid, value) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { questionsMap } = answers;
    const question = questionsMap.get(qid);
    const qidx = question.index;
    const sidx = question.sidx;

    question.ssAns = value;
    const ans = !!value;
    if (question.answered !== ans) {
      answers.totalAnswered = (answers.totalAnswered || 0) + (ans ? 1 : -1);
      answers.ansReview =
        (answers.ansReview || 0) + (question.review ? (ans ? 1 : -1) : 0);
    }
    question.answered = ans;
    answers.sections[sidx].questions[qidx] = question;    
    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

export const onNumAnsChange3 = (qid, value) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { questionsMap } = answers;
    const question = questionsMap.get(qid);
    const qidx = question.index;
    const sidx = question.sidx;

    question.numAns = value;
    const ans = !!value || value === 0;
    if (question.answered !== ans) {
      answers.totalAnswered = (answers.totalAnswered || 0) + (ans ? 1 : -1);
      answers.ansReview =
        (answers.ansReview || 0) + (question.review ? (ans ? 1 : -1) : 0);
    }
    question.answered = ans;
    answers.sections[sidx].questions[qidx] = question;
    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

export const onShortAnsChange3 = (qid, value) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { questionsMap } = answers;
    const question = questionsMap.get(qid);
    const qidx = question.index;
    const sidx = question.sidx;

    question.shortAns = value;
    const ans = !!value || value === 0;
    if (question.answered !== ans) {
      answers.totalAnswered = (answers.totalAnswered || 0) + (ans ? 1 : -1);
      answers.ansReview =
        (answers.ansReview || 0) + (question.review ? (ans ? 1 : -1) : 0);
    }
    question.answered = ans;
    answers.sections[sidx].questions[qidx] = question;

    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

export const onLongAnsChange3 = (qid, value) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { questionsMap } = answers;
    const question = questionsMap.get(qid);
    const qidx = question.index;
    const sidx = question.sidx;

    question.longAns = value;
    const ans = !!value || value === 0;
    if (question.answered !== ans) {
      answers.totalAnswered = (answers.totalAnswered || 0) + (ans ? 1 : -1);
      answers.ansReview =
        (answers.ansReview || 0) + (question.review ? (ans ? 1 : -1) : 0);
    }
    question.answered = ans;
    answers.sections[sidx].questions[qidx] = question;

    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

export const onImagesAnsChange3 = (qid, value, index) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { questionsMap } = answers;
    const question = questionsMap.get(qid);
    const qidx = question.index;
    const sidx = question.sidx;

    if (!question.imagesAns) question.imagesAns = [];
    if (!value) {
      question.imagesAns = [];
    } else {
      if (index || index === 0) {
        // presence of an index value indicates that the image needs to be removed
        // the index value also tells where to remove the image from
        question.imagesAns.splice(index, 1);
      } else {
        question.imagesAns.push(value);
      }
    }    
    const ans = !!question.imagesAns.length;
    if (question.answered !== ans) {
      answers.totalAnswered = (answers.totalAnswered || 0) + (ans ? 1 : -1);
      answers.ansReview =
        (answers.ansReview || 0) + (question.review ? (ans ? 1 : -1) : 0);
    }
    question.answered = ans;
    answers.sections[sidx].questions[qidx] = question;

    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

// onMarkForReview
export const onMarkForReview3 = (qid) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { questionsMap } = answers;
    const question = questionsMap.get(qid);
    const qidx = question.index;
    const sidx = question.sidx;

    question.review = !question.review;
    answers.totalReview =
      (answers.totalReview || 0) + (question.review ? 1 : -1);
    answers.ansReview =
      (answers.ansReview || 0) +
      (question.answered ? (question.review ? 1 : -1) : 0);
    answers.sections[sidx].questions[qidx] = question;

    dispatch({
      type: actionTypes.SUBMIT_REVIEW_ANSWER,
      payload: { answers },
    });
    return Promise.resolve();
  };
};
