import React, { useContext } from "react";

import { Box, makeStyles, useMediaQuery } from "@material-ui/core";

import { MenuContext } from "../context/menu-context.js";

const useStyles = makeStyles((theme) => ({
  box1: {
    width: `calc(100% - ${theme.global.drawerWidth}px)`,
    // maxWidth: "1080px",
    marginLeft: `${theme.global.drawerWidth}px`,
  },
  box2: {
    width: "100%",
    // maxWidth: "1080px",
    margin: "auto",
    display: "flex",
  },
  boxMobile: {
    width: "100%",
    marginLeft: "0px",
  },
}));

const Container = (props) => {
  const classes = useStyles();
  const { sideNav } = useContext(MenuContext);
  const breakpoint = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const { box1, box2, boxMobile } = classes;

  return (
    <Box className={breakpoint ? boxMobile : sideNav ? box1 : box2}>
      {props.children}
    </Box>
  );
};

export default Container;
