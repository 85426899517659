import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  Box,
  Card,
  CardHeader,
  CardActions,
  Button,
  CardContent,
  Typography,
  makeStyles
} from "@material-ui/core";

import { CheckCircleOutlineRounded, Assessment } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4, 3),
    margin: theme.spacing(4, 0)
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    flexWrap: "wrap"
    // flexDirection: "column",
    // [theme.breakpoints.up("smd")]: { flexDirection: "row" }
  },
  header: {
    width: "100%",
    [theme.breakpoints.up("smd")]: { width: "60%" }
  },
  headerText: {
    textAlign: "left"
    // width: "100%",
    // // flexGrow: 1,
    // alignItems: "center"
  },
  headerRoot: {
    padding: theme.spacing(1, 3, 1, 3)
  },
  byName: {
    padding: theme.spacing(2, 3),
    textAlign: "left",
    display: "block",
    flexGrow: 1
  },
  actions: {
    width: "100%",
    [theme.breakpoints.up("smd")]: { width: "30%" },
    alignItems: "flex-start",
    justifyContent: "flex-end",
    padding: theme.spacing(0)
  },
  action: {
    margin: theme.spacing(2),
    textTransform: "none"
  },
  subheader1: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: theme.spacing(0, 3),
    "&:last-child": { paddingBottom: theme.spacing(5) }
  },
  subheader2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  subheaderItem: {
    flexGrow: 1,
    textAlign: "left",
    paddingRight: theme.spacing(3)
  }
}));

const TestItem = (props) => {
  const classes = useStyles();

  const { test } = props;

  const { _id: testId, name, numQuestions, times, creator } = test;
  const { startTime, endTime, timeLimit = 0 } = times || {};

  const numQLabel = numQuestions ? numQuestions + " Questions" : null;
  const timeLabel = timeLimit ? timeLimit + " minutes" : null;
  const momentStart = moment(startTime);
  const momentEnd = moment(endTime);
  const startLabel =
    startTime &&
    "Start: " +
      momentStart.format(
        momentStart.minutes() === 0 ? "MMM Do ha" : "MMM Do h:mma"
      );
  const endLabel =
    endTime &&
    "End: " +
      momentEnd.format(
        momentEnd.minutes() === 0 ? "MMM Do ha" : "MMM Do h:mma"
      );

  const subheader = () => {
    return (
      <CardContent className={classes.subheader1}>
        <div className={classes.subheader2}>
          <Typography
            className={classes.subheaderItem}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {numQLabel}
          </Typography>
          <Typography
            className={classes.subheaderItem}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {timeLabel}
          </Typography>
        </div>
        <div className={classes.subheader2}>
          {startLabel ? (
            <Typography
              className={classes.subheaderItem}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {startLabel}
            </Typography>
          ) : null}

          {endLabel ? (
            <Typography
              className={classes.subheaderItem}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {endLabel}
            </Typography>
          ) : null}
        </div>
      </CardContent>
    );
  };

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <Box className={classes.top}>
          <div className={classes.header}>
            <CardHeader
              classes={{
                content: classes.headerText,
                root: classes.headerRoot
              }}
              title={name}
            />
            {subheader()}
            <Typography variant="caption" className={classes.byName}>
              by {creator.name}
            </Typography>
          </div>
          <CardActions className={classes.actions} disableSpacing>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/q/test/" + testId,
                state: { testName: name }
              }}
            >
              <Button
                className={classes.action}
                variant="contained"
                color="primary"
                startIcon={<CheckCircleOutlineRounded />}
              >
                Questions
              </Button>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/q/test/" + testId,
                state: { testName: name },
                search: "?view=1"
              }}
            >
              <Button
                className={classes.action}
                variant="outlined"
                color="primary"
                startIcon={<Assessment />}
              >
                Submissions
              </Button>
            </Link>
          </CardActions>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default TestItem;
