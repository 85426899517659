import {
  RadioButtonChecked,
  CheckBox,
  LooksOne,
  ShortText,
  Subject,
  Backup
} from "@material-ui/icons";

export const questionTypes = {
  MCQSS: {
    id: 1,
    type: "MCQSS",
    label: "Single Choice",
    icon: RadioButtonChecked,
    marking: ["manual", "noscore", "custom", "jeeType2", "jeeType3"]
  },
  MCQMS: {
    id: 2,
    type: "MCQMS",
    label: "Checkboxes",
    icon: CheckBox,
    marking: ["manual", "noscore", "custom", "jeeType1", "jeeType2", "jeeType3"]
  },
  NUMBER: {
    id: 3,
    type: "NUMBER",
    label: "Number",
    icon: LooksOne,
    marking: ["manual", "noscore", "custom", "jeeType2"]
  },
  SHORT: {
    id: 4,
    type: "SHORT",
    label: "Short Text",
    icon: ShortText,
    marking: ["custom", "manual", "noscore"]
  },
  LONG: {
    id: 5,
    type: "LONG",
    label: "Paragraph",
    icon: Subject,
    marking: ["manual", "noscore"]
  },
  IMAGE: {
    id: 6,
    type: "IMAGE",
    label: "Image Upload",
    icon: Backup,
    marking: ["manual", "noscore"]
  }
};
