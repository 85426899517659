import React from "react";

import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  skel: {
    backgroundColor: "rgba(218,218,218,0.75)",
    "&:after": {
      backgroundColor: "#dfdfdf",
      animationDuration: "2s"
    }
  }
}));

const Placeholder = (props) => {
  const classes = useStyles();
  return <Skeleton className={classes.skel} {...props} />;
};

export default Placeholder;
