import React from "react";
import { useState } from "react";
import {
  TextField,
  Button,
  InputAdornment
  // Typography
  // Divider
} from "@material-ui/core";

import EmailTwoTone from "@material-ui/icons/EmailTwoTone";
import LockTwoTone from "@material-ui/icons/LockTwoTone";

import Forgot from "./Forgot";

// import GoogleButton from "../Auth/GoogleButton";

const Login = React.memo((props) => {
  const { handleOnSubmit } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    emailError: false,
    emailErrorMsg: "",
    pwdError: false,
    pwdErrorMsg: ""
  });

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setError({ ...error, emailError: false, emailErrorMsg: "" });
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError({ ...error, pwdError: false, pwdErrorMsg: "" });
  };

  const handleLogin = async (email, password) => {
    await handleOnSubmit(email, password).catch((error) => {
      const emailErrorMsg = error.emailErrorMsg;
      const pwdErrorMsg = error.pwdErrorMsg;
      setError({
        emailError: !!emailErrorMsg,
        emailErrorMsg,
        pwdError: !!pwdErrorMsg,
        pwdErrorMsg
      });
    });
  };

  return (
    <>
      {/* <div
        style={{
          margin: "16px auto",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <GoogleButton label="Login with Google" />
      </div>
      <div style={{ margin: "24px 0 12px 0" }}>
        <Divider style={{ height: "2px" }} />
        <div>
          <Typography
            style={{
              margin: "auto",
              marginTop: "-14px",
              width: "20px",
              backgroundColor: "white",
              padding: "0 5px"
            }}
          >
            or
          </Typography>
        </div>
      </div> */}
      <div>
        {/* <Typography>Use TestCrackr Login</Typography> */}
        <form>
          <TextField
            style={{ width: "100%", margin: "10px 0" }}
            id="email"
            // label="Email"
            value={email}
            error={error.emailError}
            helperText={error.emailError ? error.emailErrorMsg : null}
            type="email"
            onChange={handleEmailChange}
            placeholder="Email address"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailTwoTone color="primary" />
                </InputAdornment>
              )
            }}
          />
          <TextField
            style={{ width: "100%", margin: "10px 0" }}
            id="password"
            type="password"
            value={password}
            error={error.pwdError}
            helperText={error.pwdError ? error.pwdErrorMsg : null}
            onChange={handlePasswordChange}
            placeholder="Password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoTone color="primary" />
                </InputAdornment>
              )
              // endAdornment: (
              //   <InputAdornment position="end">
              //     <VisibilityIcon color="active" />
              //   </InputAdornment>
              // )
            }}
          />
          <Button
            type="submit"
            style={{ width: "100%", marginTop: "30px", textTransform: "none" }}
            variant="contained"
            color="primary"
            size="large"
            onClick={(event) => {
              event.preventDefault();
              handleLogin(email, password);
            }}
          >
            Login
          </Button>
        </form>
        <Forgot />
      </div>
    </>
  );
});

export default Login;
