import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";

import {
  useMediaQuery,
  ListItemText,
  ListItemIcon,
  List,
  ListItem,
  Divider,
  IconButton,
  Tooltip,
  makeStyles,
  Button,
} from "@material-ui/core";
import AssignEditor from "../Assign/AssignEditor1";
import ShareEditor from "../Share/ShareEditor";
import TestSettingsDialog from "./TestSettings";
import TestSettingsProvider from "../../context/test-settings-context";
import TestTagsEditor from "./TestTagsEditor";
// import ThemeEditor from "./ThemeEditor";

import {
  MoreVert,
  Delete,
  FileCopyOutlined,
  Visibility,
  Settings,
  Send,
  PersonAdd,
} from "@material-ui/icons";
import { IconButtonWithPopover } from "../../../shared/hoc/withPopover";
import { ListItemWithConfirm } from "../../../shared/hoc/withConfirm";

import { onTestRemove, onTestDuplicate } from "../../../store/actions";

const useStyles = makeStyles(() => ({
  divider: {
    margin: "6px 0",
  },
  sendBtn: {
    color: "inherit",
    textTransform: "none",
    borderColor: "inherit",
    fontWeight: 600,
    padding: "0 24px",
    margin: "0 12px",
    minHeight: "36px",
  },
  preview: { textDecoration: "none", color: "inherit" },
}));

const TestHeaderActions = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  const { testId } = params;
  const breakpoint = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const { onTestRemove, onTestDuplicate } = props;
  const {
    test: {
      details: { open, name },
      tags = [],
    },
    nav: { loading },
  } = props;

  const [testAssignOpen, setTestAssignOpen] = useState(false);
  const [testShareOpen, setTestShareOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [testTagsOpen, setTestTagsOpen] = useState(false);

  const [pop, setPop] = useState(false);

  const handleTestDelete = (id) => {
    onTestRemove(id).then(() => history.replace("/t/tests"));
  };

  const handleTestDuplicate = (id) => {
    onTestDuplicate(id).then((newId) => {
      history.push("/q/test/" + newId);
    });
  };

  const handleAssignOpen = (value) => {
    setTestAssignOpen(value);
  };

  const handleShareOpen = (value) => {
    setTestShareOpen(value);
  };

  const handleSettingsOpen = (value) => {
    setSettingsOpen(value);
  };

  const handleTestTagsOpen = (value) => {
    setTestTagsOpen(value);
  };

  const getPreviewLink = (testId) => "/test/" + testId;

  return (
    <>
      {loading && !name ? null : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <ThemeEditor theme={theme} handleTheme={handleTheme} /> */}
          {breakpoint ? (
            <Tooltip title="Preview">
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to={getPreviewLink(testId)}
                // target="_blank"
                // rel="noopener noreferrer"
              >
                <IconButton
                  color="inherit"
                  disableRipple
                  size={breakpoint ? "medium" : "medium"}
                >
                  <Visibility fontSize={breakpoint ? "default" : "small"} />
                </IconButton>
              </Link>
            </Tooltip>
          ) : null}
          {breakpoint ? (
            <Tooltip title="Settings">
              <IconButton
                color="inherit"
                disableRipple
                onClick={() => {
                  handleSettingsOpen(true);
                }}
                size={breakpoint ? "medium" : "medium"}
              >
                <Settings fontSize={breakpoint ? "default" : "small"} />
              </IconButton>
            </Tooltip>
          ) : null}
          {/* <Tooltip title="Tags">
          <IconButton
            color="inherit"
            disableRipple
            onClick={(event) => {
              handleTestTagsOpen(true);
            }}
            size={breakpoint ? "medium" : "medium"}
          >
            <Settings fontSize={breakpoint ? "default" : "small"} />
          </IconButton>
        </Tooltip> */}
          {breakpoint ? (
            <Button
              variant="outlined"
              onClick={() => {
                handleAssignOpen(true);
              }}
              className={classes.sendBtn}
            >
              Send
            </Button>
          ) : (
            <Tooltip title="Send">
              <IconButton
                color="inherit"
                disableRipple
                onClick={() => {
                  handleAssignOpen(true);
                }}
                size={breakpoint ? "medium" : "medium"}
              >
                <Send fontSize={breakpoint ? "default" : "small"} />
              </IconButton>
            </Tooltip>
          )}

          <IconButtonWithPopover
            color="inherit"
            size={breakpoint ? "medium" : "medium"}
            disableRipple
            disableFocusRipple
            key={pop}
            popover={
              <List aria-label="More Test Actions">
                {breakpoint ? null : (
                  <>
                    <Link
                      className={classes.preview}
                      to={getPreviewLink(testId)}
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <Visibility />
                        </ListItemIcon>
                        <ListItemText primary="Preview" />
                      </ListItem>
                    </Link>
                    <ListItem
                      button
                      onClick={(event) => {
                        handleSettingsOpen(true);
                        setPop(!pop);
                      }}
                    >
                      <ListItemIcon>
                        <Settings />
                      </ListItemIcon>
                      <ListItemText primary="Settings" />
                    </ListItem>
                    <Divider className={classes.divider} />
                  </>
                )}
                <ListItem
                  button
                  onClick={(event) => {
                    handleShareOpen(true);
                    setPop(!pop);
                  }}
                >
                  <ListItemIcon>
                    <PersonAdd />
                  </ListItemIcon>
                  <ListItemText primary="Share" />
                </ListItem>
                <Divider className={classes.divider} />
                <ListItem
                  button
                  onClick={(event) => {
                    handleTestDuplicate(testId);
                    setPop(!pop);
                  }}
                >
                  <ListItemIcon>
                    <FileCopyOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Duplicate" />
                </ListItem>
                <ListItemWithConfirm
                  dialog={{
                    title: "Delete Test",
                    text: "Are you sure you want to delete this test?",
                    onClose: () => setPop(!pop),
                  }}
                  button
                  onClick={() => handleTestDelete(testId)}
                >
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  <ListItemText primary="Delete" />
                </ListItemWithConfirm>
              </List>
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Tooltip title="More">
              <MoreVert fontSize="small" />
            </Tooltip>
          </IconButtonWithPopover>
        </div>
      )}

      <ShareEditor
        testId={testId}
        testShareOpen={testShareOpen}
        handleShareOpen={handleShareOpen}
      />
      <AssignEditor
        isTestPublic={open}
        testId={testId}
        testAssignOpen={testAssignOpen}
        handleAssignOpen={handleAssignOpen}
      />
      <TestSettingsProvider>
        <TestSettingsDialog
          open={settingsOpen}
          handleOpen={handleSettingsOpen}
        />
      </TestSettingsProvider>
      <TestTagsEditor
        open={testTagsOpen}
        handleOpen={handleTestTagsOpen}
        testTags={tags}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    test: state.test,
    nav: state.blockNav,
  };
};

export default connect(mapStateToProps, {
  onTestRemove,
  onTestDuplicate,
})(TestHeaderActions);
