import moment from "moment";

export const duration = (time, type) => {
  const pad = (num) => ("00" + num).slice(-2);
  const d = moment.duration(time, type || "milliseconds");
  if (d) {
    const { days, hours, minutes, seconds } = {
      days: d.get("days"),
      hours: d.get("hours"),
      minutes: d.get("minutes"),
      seconds: d.get("seconds")
    };
    let str = "";
    if (days > 0) {
      str = days + "d " + hours + "h " + minutes + "m " + seconds + "s";
    } else {
      str = pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
    }
    return str;
  } else return "-";

  // const duration = d
  //   ? d.get("minutes") + ": minutes " + d.get("seconds") + " sec"
  //   : "-";
};

// change this
// take a date as argument
// return a string that says how far back it was
// like: a minute ago, 10 minutes ago, an hour ago
// Today, Yesterday, 2 days ago, date, etc
export const when = (time) => {
  const pad = (num) => ("00" + num).slice(-2);
  const d = moment.duration(time, "milliseconds");
  if (d) {
    const { days, hours, minutes, seconds } = {
      days: d.get("days"),
      hours: d.get("hours"),
      minutes: d.get("minutes"),
      seconds: d.get("seconds")
    };
    let str = "";
    if (days > 0) {
      str = days + "d " + hours + "h " + minutes + "m " + seconds + "s";
    } else {
      str = pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
    }
    return str;
  } else return "-";

  // const duration = d
  //   ? d.get("minutes") + ": minutes " + d.get("seconds") + " sec"
  //   : "-";
};
