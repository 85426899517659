import React, { useEffect, useState } from "react";
import moment from "moment";

import { IconButton, Tooltip } from "@material-ui/core";
import { AssignmentReturnedOutlined } from "@material-ui/icons";

import { CSVLink } from "react-csv";

const TestReviewCSV = (props) => {
  const { assigns, loading, test } = props;

  const [csvData, setCsvData] = useState([[]]);
  const [fileName, setFileName] = useState("data");

  useEffect(() => {
    const data = assigns.map((assign) => {
      const { name = "Unknown", email } = assign.user || {};
      const {
        answers,
        status = "NOT STARTED",
        endTime,
        startedTime,
        submitTime,
        timeTaken,
        submitMethod
      } = assign.lastSubmit || {};
      const score =
        answers && (answers.score || answers.score === 0) ? answers.score : "-";
      const time =
        status === "COMPLETED"
          ? endTime
            ? moment
                .min(moment(submitTime), moment(endTime))
                .format("MMM Do YYYY hh:mma")
            : moment(submitTime).format("MMM Do YYYY hh:mma")
          : "-";
      const maxDuration =
        endTime && startedTime ? moment(endTime).diff(startedTime) : null;
      const duration =
        maxDuration && timeTaken
          ? Math.min(maxDuration, timeTaken)
          : timeTaken
          ? timeTaken
          : "";
      const durationVal = Number.parseFloat(duration / 60000).toFixed(1);
      const sectionScores = {};
      if (test) {
        if (answers) {
          for (const i in test.sections) {
            const section = test.sections[i];
            const sectionNum = Number(i) + 1;
            sectionScores[section.name + " (" + sectionNum + ")"] = answers
              .sections[section._id]
              ? answers.sections[section._id].score || 0
              : 0;
          }
        }
      }
      return {
        Name: name,
        Email: email,
        "Total Score": score,
        Status: status,
        "Start Time": moment(startedTime).format("MMM Do YYYY hh:mma"),
        "End Time": time,
        "Duration (minutes)": durationVal,
        Mode: submitMethod,
        ...sectionScores
      };
    });
    setCsvData(data);
    setFileName((name) =>
      test && test.details
        ? test.details.name + "_" + moment().format("YYYYMMDDHHmm") + ".csv"
        : name + ".csv"
    );
  }, [assigns, test]);

  return (
    <CSVLink data={csvData} filename={fileName}>
      <Tooltip title="Download CSV">
        <span>
          <IconButton disabled={loading}>
            <AssignmentReturnedOutlined />
          </IconButton>
        </span>
      </Tooltip>
    </CSVLink>
  );
};

export default TestReviewCSV;
