import React, { useState } from "react";
import { connect } from "react-redux";

import {
  IconButton,
  Paper,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItem,
  makeStyles,
  // useMediaQuery,
  // useScrollTrigger,
  Tabs,
  Tab,
  Tooltip,
  Container,
} from "@material-ui/core";
import {
  FileCopyOutlined,
  Settings,
  Delete,
  MoreVert,
  AddCircleOutline,
  SwapVert,
} from "@material-ui/icons";

import { IconButtonWithPopover } from "../../../shared/hoc/withPopover";
import { ListItemWithConfirm } from "../../../shared/hoc/withConfirm";
import SectionReorder from "./SectionReorder";

import {
  onSectionEditorLoad,
  onTestSectionRemove,
  onTestSectionDuplicate,
  onSectionSelected,
} from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    backgroundColor: "white",
    zIndex: 500,
    transition: "top 200ms ease-out",
    // borderRadius: 0,
    top: `${theme.global.appHeaderHeight}px`,
    [theme.breakpoints.down("xs")]: {
      position: "sticky", // was "static" earlier
      top: `${theme.global.appHeaderHeightSm}px`,
    },
  },
  container: {
    overflow: "auto",
    position: "relative",
    padding: "0 12px",
    margin: 0,
    // width: "800px"
  },
  tabs: {
    display: "flex",
    alignItems: "center",
  },
  tabsRoot: {
    flexGrow: 1,
    width: "100%",
  },
  indicator: {
    boxSizing: "border-box",
    minHeight: "0px",
    height: "34px",
    border: "2px solid grey",
    borderRadius: "3px",
    background: "transparent",
    margin: "6px 0",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  nameRoot: {
    textTransform: "none",
    fontWeight: "600",
    margin: "6px",
    minHeight: 0,
    height: "34px",
  },
  selectedName: {
    color: "black",
  },
  actions: {
    display: "flex",
  },
  divider: {
    margin: "8px 0",
  },
}));

const SectionHeader = (props) => {
  const classes = useStyles();

  const { onTestSectionRemove, onTestSectionDuplicate, onSectionSelected } =
    props;

  const { sections, selectedSectionId, selectedSectionIdx, readOnly } =
    props.test;

  const { handleSectionEditorLoad, change } = props;

  // const breakpoint = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  // const trigger = useScrollTrigger({ threshold: 0 });

  const [reorderOpen, setReorderOpen] = useState(false);

  const handleSectionDelete = (idx) => {
    onTestSectionRemove(idx);
  };

  const handleTestSectionDuplicate = (id) => {
    onTestSectionDuplicate(id);
  };

  const handleReorderOpen = () => {
    setReorderOpen(true);
  };

  const handleReorderClose = () => {
    setReorderOpen(false);
  };

  const handleSectionSelected = (event, index) => {
    onSectionSelected(index);
  };

  const MorePopover = () => {
    const [pop, setPop] = useState(false);
    return sections.length > 0 ? (
      <IconButtonWithPopover
        key={pop}
        popover={
          <List aria-label="More Section Actions" dense>
            <ListItem
              button
              onClick={() => handleSectionEditorLoad(selectedSectionId)}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Section Settings" />
            </ListItem>
            <Divider className={classes.divider} />
            <ListItem
              button
              onClick={() => handleTestSectionDuplicate(selectedSectionId)}
            >
              <ListItemIcon>
                <FileCopyOutlined />
              </ListItemIcon>
              <ListItemText primary="Duplicate Section" />
            </ListItem>
            <ListItemWithConfirm
              dialog={{
                title: "Delete Section",
                text: "Are you sure you want to delete this section?",
                onClose: () => setPop(!pop),
              }}
              button
              disabled={sections.length <= 1}
              onClick={() => handleSectionDelete(selectedSectionIdx)}
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText primary="Delete Section" />
            </ListItemWithConfirm>
            <Divider className={classes.divider} />
            <ListItem button onClick={(event) => handleSectionEditorLoad()}>
              <ListItemIcon>
                <AddCircleOutline />
              </ListItemIcon>
              <ListItemText primary="Add section" />
            </ListItem>
            <ListItem button onClick={handleReorderOpen}>
              <ListItemIcon>
                <SwapVert />
              </ListItemIcon>
              <ListItemText primary="Reorder Sections" />
            </ListItem>
          </List>
        }
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MoreVert fontSize="small" />
      </IconButtonWithPopover>
    ) : null;
  };

  return (
    <React.Fragment>
      {!change && selectedSectionId ? (
        <>
          <Paper
            // style={{
            //   top: breakpoint ? 106 : trigger ? 0 : 106,
            // }}
            // elevation={0}
            className={classes.paper}
          >
            <Container className={classes.container}>
              <div className={classes.tabs}>
                <Tabs
                  value={selectedSectionIdx}
                  onChange={handleSectionSelected}
                  // indicatorColor="inherit"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons="on"
                  aria-label="scrollable auto tabs example"
                  classes={{
                    // indicator: classes.indicator,
                    root: classes.tabsRoot,
                    flexContainer: classes.tabsFlex,
                  }}
                >
                  {sections.map((section, idx) => (
                    <Tab
                      key={"tab" + section._id}
                      classes={{
                        root: classes.nameRoot,
                        selected: classes.selectedName,
                      }}
                      label={section.name}
                      disableFocusRipple
                      value={idx}
                    />
                  ))}
                </Tabs>
                {readOnly ? null : (
                  <div className={classes.actions}>
                    <Tooltip title="Add section">
                        <IconButton
                          onClick={(event) => handleSectionEditorLoad()}
                        >
                          <AddCircleOutline fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    <MorePopover />
                  </div>
                )}
              </div>
            </Container>
          </Paper>
          <SectionReorder open={reorderOpen} handleClose={handleReorderClose} />
        </>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  test: state.test,
});

export default connect(mapStateToProps, {
  onSectionEditorLoad,
  onTestSectionRemove,
  onTestSectionDuplicate,
  onSectionSelected,
})(SectionHeader);
