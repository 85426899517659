import React, { useState } from "react";
import { connect } from "react-redux";

import { PersonAdd, AccountCircleOutlined } from "@material-ui/icons";
import {
  makeStyles,
  Container,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FilledInput,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Divider
} from "@material-ui/core";

import ContentDialog from "../../../shared/components/Modals/ContentDialog1";
import ChipInputWrapper from "../../../shared/components/UIElements/ChipInputWrapper";
import { onTestShare } from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  linkOuter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "100%",
    margin: "12px 0"
  },
  link: {
    flex: 1,
    marginLeft: "10px"
  },
  linkInput: {
    borderRadius: "3px",
    "&:before": {
      borderBottom: "0px"
    },
    "&:after": {
      borderBottom: "0px"
    },
    "&:hover:before": {
      borderBottom: "0px"
    }
  },
  linkText: {
    backgroundColor: "lightgrey",
    padding: "9px",
    whiteSpace: "nowrap",
    borderRadius: "3px",
    color: "black",
    fontSize: "0.9em"
  },
  copyBtn: {
    backgroundColor: theme.palette.background.light,
    color: theme.palette.primary.main,
    textTransform: "none",
    "&:hover": { backgroundColor: theme.palette.background.light },
    width: "110px"
  },
  selectRoot: { paddingTop: "17.5px", paddingBottom: "17.5px" },
  selectOutline: { border: 0 },
  selectInput: { borderRadius: "4px" },
  selectUnderline: {
    "&:before": { border: 0 },
    "&:after": { border: 0 },
    "&:hover:before": { border: 0 }
  }
}));

const selectRoles = new Map([
  ["read", "Viewer"]
  // ["edit", "Editor"]
]);

const ShareTestDialog = (props) => {
  const classes = useStyles();
  const [emails, setEmails] = useState([]);
  const [perm, setPerm] = useState("read");

  const {
    testShareOpen,
    handleShareOpen,
    testId,
    test: { acl = [] },
    onTestShare
  } = props;

  const aclMap = acl.reduce((map, item, i) => {
    item.users.forEach((user) => map.set(user, item.name));
    return map;
  }, new Map());

  const testLink =
    window.location.origin +
    "/q/test/" +
    testId +
    // "/?share=" +
    // (test.details.share ? test.details.share.read : "")
    "/?usp=sharing";
  const linkRef = React.createRef();

  const handleEmailsChange = (emails) => {
    setEmails(emails);
  };

  const handlePermChange = (event) => {
    setPerm(event.target.value);
  };

  const handleCopy = (ref) => {
    navigator.clipboard.writeText(ref.current.value).catch((err) => {
      // This can happen if the user denies clipboard permissions:
      alert("Link could not be copied.");
    });
  };

  const handleShareClose = (event) => {
    handleShareOpen(false);
    setEmails([]);
  };

  const handleSave = () => {
    if (emails.length > 0) {
      onTestShare(testId, emails, perm);
    }
    handleShareClose();
  };

  return (
    <React.Fragment>
      <ContentDialog
        open={testShareOpen}
        disableTypography
        dialogTitle={
          <div style={{ display: "flex", alignItems: "center" }}>
            <PersonAdd />
            <Typography
              variant="h6"
              style={{ marginLeft: "10px", fontWeight: 500 }}
            >
              Share test
            </Typography>
          </div>
        }
        hasCancel
        actionText="Save"
        handleClose={handleShareClose}
        handleAction={handleSave}
        // closeDisabled={props.nav.spinner}
        // actionDisabled={props.nav.spinner}
      >
        <Container style={{ padding: 0 }}>
          <Typography variant="body2">
            Enter email addresses of people you want to share the test with.
            <br />
            Only people added can access with the link.
          </Typography>
          <div
            style={{ display: "flex", marginTop: "24px", marginBottom: "24px" }}
          >
            <div style={{ flexGrow: 1 }}>
              <ChipInputWrapper
                classes={{ chipInput: classes.chipInput }}
                handleChange={handleEmailsChange}
                values={emails}
                helperDuplicate="Duplicates were removed"
                helperError="Entries are missing or not correctly formatted"
                helperInput="Enter emails separated by a comma or space"
              />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <FormControl className={classes.formControl}>
                <Select
                  classes={{ root: classes.selectRoot }}
                  input={
                    <FilledInput
                      classes={{
                        // notchedOutline: classes.selectOutline,
                        underline: classes.selectUnderline,
                        root: classes.selectInput
                      }}
                    />
                  }
                  value={perm}
                  onChange={handlePermChange}
                  renderValue={(key) => (
                    <Typography variant="body2">
                      {selectRoles.get(key)}
                    </Typography>
                  )}
                >
                  {[...selectRoles.keys()].map((key) => (
                    <MenuItem key={key} value={key}>
                      {selectRoles.get(key)}
                    </MenuItem>
                  ))}

                  {/* <MenuItem value={"edit"}>Editor</MenuItem> */}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className={classes.linkOuter}>
            <Button
              onClick={(e) => handleCopy(linkRef)}
              className={classes.copyBtn}
              disableFocusRipple
            >
              Copy link
            </Button>
            <div className={classes.link}>
              <TextField
                variant="filled"
                onChange={null}
                value={testLink}
                style={{ width: "100%" }}
                InputProps={{
                  classes: { input: classes.linkText, root: classes.linkInput }
                }}
                // disabled
                inputRef={linkRef}
              />
            </div>
          </div>
        </Container>
        <Divider style={{ margin: "16px 0" }} />
        <Container style={{ padding: 0 }}>
          {acl && acl.length > 0 ? (
            <>
              <Typography variant="body2">Shared with:</Typography>
              <List>
                {[...aclMap.keys()].map((item) => {
                  return (
                    <ListItem
                      button
                      key={item._id + aclMap.get(item)}
                      style={{ cursor: "default" }}
                      disableRipple
                      disableFocusRipple
                    >
                      <ListItemAvatar>
                        <AccountCircleOutlined />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.name || item.email}
                        secondary={item.email}
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="body2">
                          {selectRoles.get(aclMap.get(item))}
                        </Typography>
                      </ListItemSecondaryAction>
                      {/* {item.name + " " + item.email + " " + aclMap.get(item)} */}
                    </ListItem>
                  );
                })}
              </List>
            </>
          ) : (
            <div />
          )}
        </Container>
      </ContentDialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  test: state.test
});

// export default ShareTestDialog;
export default connect(mapStateToProps, { onTestShare })(ShareTestDialog);
