import React from "react";
import { Link } from "react-router-dom";

import ImgWithFallback from "../UIElements/ImgWithFallback";

const Logo = () => {
  return (
    <Link to="/">
      <ImgWithFallback
        src={process.env.PUBLIC_URL + "/TC_Logo_Cropped.webp"}
        fallback={process.env.PUBLIC_URL + "/TC_Logo_Cropped.png"}
        alt="TestCrackr Logo"
        height={"41px"}
      />
    </Link>
  );
};

export default Logo;
