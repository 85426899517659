import React, { useContext } from "react";

import { useHistory } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";

import {
  makeStyles,
  Toolbar,
  Box,
  Typography,
  // useScrollTrigger,
  // Slide,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  //   ArrowBack, HomeOutlined, Search,
  Menu as MenuIcon,
  ArrowBack,
  HomeOutlined,
} from "@material-ui/icons";

import { Linear } from "../components/UIElements/Progress";
import { MenuContext } from "../context/menu-context";
import Logo from "../components/Navigation/Logo";

const useStyles = makeStyles((theme) => ({
  appHeader: {
    height: `${theme.global.appHeaderHeight}px`,
    zIndex: theme.zIndex.drawer + 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      height: `${theme.global.appHeaderHeightSm}px`,
    },
  },
  logoBox: {
    minWidth: `${theme.global.drawerWidth}px`,
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    boxSizing: "border-box",
    paddingLeft: "20px",
  },
  toolBar: {
    height: `${theme.global.appHeaderHeight}px`,
    [theme.breakpoints.down("xs")]: {
      height: `${theme.global.appHeaderHeightSm}px`,
    },
  },
  menuIcon: {
    color: "inherit",
    margin: "0px 0px",
  },
  skeleton: {
    backgroundColor: "lightgrey",
    maxWidth: "280px",
    minWidth: "150px",
  },
  pageHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginRight: "20px",
  },
  titleContainer: {
    textAlign: "left",
    paddingLeft: "20px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    flexGrow: 1,
    flexShrink: 2,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    flexGrow: 2,
    flexShrink: 1,
    position: "relative",
    maxWidth: "100%",
  },
}));

const withAppHeader = (Component) => (props) => {
  const { title, navAction, actions, progress } = props;
  const classes = useStyles();
  // const trigger = useScrollTrigger();
  const history = useHistory();
  const { onMobileNavOpen, sideNav } = useContext(MenuContext);

  const titleComponent =
    typeof title === "string" || title instanceof String ? (
      <Typography variant="h5" noWrap>
        {title}
      </Typography>
    ) : (
      title
    );

  const breakpoint = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const AppHeader = (
    <AppBar elevation={0} className={classes.appHeader} color="default">
      {breakpoint ? null : (
        <Box component="div" className={classes.logoBox}>
          <Logo />
        </Box>
      )}
      <div className={classes.pageHeader}>
        {breakpoint ? (
          sideNav ? (
            <IconButton
              color="primary"
              disableRipple={false}
              onClick={onMobileNavOpen}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div style={{ width: "20px" }} />
          )
        ) : null}
        <div className={classes.titleContainer}>
          {progress ? (
            <Typography variant="h4">
              <Skeleton animation="wave" className={classes.skeleton} />
            </Typography>
          ) : (
            <>
              {navAction === "back" ? (
                <IconButton
                  disableRipple={false}
                  onClick={() => {
                    if (history.length > 1) history.goBack();
                    else history.replace("/");
                  }}
                >
                  <ArrowBack />
                </IconButton>
              ) : null}
              {navAction === "home" ? (
                <IconButton
                  disableRipple={false}
                  onClick={() => history.push("/")}
                >
                  <HomeOutlined />
                </IconButton>
              ) : null}
              {titleComponent}
            </>
          )}
        </div>

        <div className={classes.actionsContainer}>{actions}</div>
      </div>
    </AppBar>
  );

  return (
    <>
      <div>{progress ? <Linear /> : null}</div>
      {/* {breakpoint && slide ? (
        <>
          <Slide appear={false} direction="down" in={!trigger}>
            {AppHeader}
          </Slide>
        </>
      ) : (
        AppHeader
      )} */}
      {AppHeader}
      <Toolbar className={classes.toolBar} />
      <Component {...props} />
    </>
  );
};

export default withAppHeader;
