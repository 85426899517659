import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Typography, Button, makeStyles } from "@material-ui/core";
import moment from "moment";
// import Timer from "../components/Timer1";

const TestError = (props) => {
  const classes = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      height: "400px",
      // width: "100%",
      // maxWidth: "400px",
      margin: "0 5%"
    },
    message: {
      marginBottom: "20px"
    },
    button: {
      margin: "0 10px"
    },
    timeText: {
      fontWeight: 600,
      marginTop: "5px"
    }
  }))();

  const history = useHistory();
  const { errorType, submitId, startTime } = props;

  // errorType
  // NOACCESS
  // INPROGRESSSUBMIT
  // NOMORESUBMITS
  // ENDTIME
  // COMPLETED
  // NOTSTARTED

  const startDisabled = startTime && moment(startTime) > moment();

  const messages = {
    NOACCESS:
      "You don't have permission to access this test. Check with the test owner to get access.",
    INPROGRESSSUBMIT:
      "This test is currently in progress. Select Resume to continue.",
    NOMORESUBMITS:
      "No more submissions for this test can be made. Check with the test owner to get access.",
    ENDTIME:
      "An attempt to resume this test is not allowed due to date or time limits set by the test owner.",
    COMPLETED:
      "This test was successfully completed. Select Review to see results.",
    NOTSTARTED:
      "The test cannot be started right now due to date or time restrictions. Please check back later closer to the start time."
  };

  console.log(errorType, submitId);
  return (
    <div className={classes.root}>
      <div className={classes.message}>
        <Typography>{messages[errorType]}</Typography>
      </div>
      <div>
        <Button
          variant="contained"
          onClick={(event) => {
            history.replace("/");
          }}
          className={classes.button}
        >
          Home
        </Button>
        {errorType === "INPROGRESSSUBMIT" ? (
          <Link
            style={{ textDecoration: "none" }}
            to={"/submit/" + submitId}
            replace
            // target="_blank"
            // rel="noopener noreferrer"
          >
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              aria-label="attempt"
            >
              Resume
            </Button>
          </Link>
        ) : null}
        {errorType === "COMPLETED" ? (
          <Link
            style={{ textDecoration: "none" }}
            to={"/review/" + submitId}
            replace
          >
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              aria-label="attempt"
            >
              Review
            </Button>
          </Link>
        ) : null}
        {errorType === "NOTSTARTED" ? (
          <>
            <Link
              style={{
                textDecoration: "none",
                cursor: startDisabled ? "default" : "pointer"
              }}
              to={history.location.pathname}
              replace
              onClick={(e) => {
                startDisabled && e.preventDefault();
              }}
              // target="_blank"
              // rel="noopener noreferrer"
            >
              <Button
                className={classes.action}
                variant="contained"
                color="primary"
                aria-label="attempt"
                disabled={startDisabled}
              >
                Start
              </Button>
            </Link>
            {startTime ? (
              <div className={classes.end}>
                <Typography className={classes.timeText} variant="body2">
                  {`Start Time: ${moment(startTime).format(
                    "DD/MM/YY hh:mm A"
                  )}`}
                </Typography>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TestError;
