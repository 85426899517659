import React, { createContext } from "react";
import { useState, useCallback } from "react";

export const MenuContext = createContext({
  mobileNavOpen: false,
  onMobileNavOpen: () => {},
  onMobileNavClose: () => {},

  sideNav: true,
  showSideNav: () => {},
});

export const MenuProvider = (props) => {
  /**
   * mobileNav
   */
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const onMobileNavOpen = useCallback((event) => {
    setMobileNavOpen(true);
  }, []);

  const onMobileNavClose = useCallback((event) => {
    setMobileNavOpen(false);
  }, []);

  /**
   * sideNav
   */
  const [sideNav, setSideNav] = useState(true);

  const showSideNav = useCallback((value) => {
    setSideNav(value);
  }, []);

  return (
    <MenuContext.Provider
      value={{
        mobileNavOpen,
        onMobileNavOpen,
        onMobileNavClose,
        sideNav,
        showSideNav,
      }}
    >
      {props.children}
    </MenuContext.Provider>
  );
};
