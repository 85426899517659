import React from "react";
import { connect } from "react-redux";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { onTestSectionReorder } from "../../../store/actions";
import DraggableSection from "./DraggableSection";
import ContentDialog from "../../../shared/components/Modals/ContentDialog1";

const SectionReorder = (props) => {
  const { open, handleClose } = props;
  const { test } = props;
  const { onTestSectionReorder } = props;
  const { sections, selectedSectionIdx } = test || {};

  const onDragEnd = (result) => {
    const {
      destination,
      source
      // draggableId
    } = result;
    if (!destination || destination.index === source.index) return;
    onTestSectionReorder(source.index, destination.index);
  };

  return (
    <>
      <ContentDialog
        open={open}
        dialogTitle={"Reorder Sections"}
        actionText="Done"
        handleAction={handleClose}
        handleClose={handleClose}
        hasCancel={false}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"sections"}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sections.map((section, index) => {
                  return (
                    <DraggableSection
                      id={section._id}
                      key={section._id}
                      selectedSectionIdx={selectedSectionIdx}
                      idx={index}
                      title={section.name}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ContentDialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  test: state.test,
  nav: state.blockNav
});

export default connect(mapStateToProps, { onTestSectionReorder })(
  SectionReorder
);
