import React, { useState } from "react";

import {
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    paddingLeft: "20px",
    color: "inherit",
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "inherit",
    },
    "&:active .MuiOutlinedInput-notchedOutline": {
      borderColor: "inherit",
    },
  },
  notched: {
    borderColor: "inherit",
  },
  closeBtn: {
    color: "inherit",
  },
}));

const filter = createFilterOptions();

/**
 * This is a general SearchBar component made
 * based on mui Autocomplete
 * It requires three functions passed in props:
 * onSearch
 * handleShowResults
 * handleResults
 */

const SearchBar = (props) => {
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState();
  const classes = useStyles();

  const { onSearch, onClose, handleShowResults, handleResults } = props;

  const getOptionLabel = (option) => option.name || option;

  const handleChange = (event, value, reason) => {
    if (reason === "clear") {
      handleShowResults(false);
      handleResults([]);
    } else if (reason === "create-option") {
      handleShowResults(true);
      const filtered = filter(options, { inputValue: query, getOptionLabel });
      handleResults([...filtered]);
    } else {
      handleShowResults(true);
      handleResults([value]);
    }
  };

  const handleClose = (event, reason) => {
    // if (onClose) onClose();
  };

  const handleInputChange = (event, query, reason) => {
    if (reason === "clear") {
      if (onClose) onClose();
      handleShowResults(false);
      handleResults([]);
    }
    setQuery(query);
    if (query.match(/\s\w{1}$|^\w{1}$/)) {
      setLoading(true);
      onSearch(query)
        .then((result) => {
          setOptions(result || []);
        })
        // errors are handled in axios/Sentry
        .then(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Autocomplete
      id="combo-box-demo"
      options={query ? options || [] : []}
      freeSolo
      // clearOnBlur={true}
      clearOnEscape={false}
      blurOnSelect={false}
      onInputChange={handleInputChange}
      forcePopupIcon={false}
      noOptionsText="No results"
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      onClose={handleClose}
      classes={{ clearIndicator: classes.closeBtn }}
      // filterOptions={(options, state) => {
      //   const filtered = filter(options, state);
      //   console.log(state);
      //   return filtered;
      // }}
      renderOption={(option) => {
        return <Typography variant="body1">{option.name}</Typography>;
      }}
      closeIcon={<Close fontSize={"small"} />}
      clearText="Clear search"
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search"
          margin="dense"
          // style={{ margin: "4px 0" }}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: classes.inputRoot,
              notchedOutline: classes.notched,
              focused: classes.notched,
            },
            startAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={24}
                    style={{ marginRight: "8px" }}
                  />
                ) : (
                  <Search style={{ marginRight: "8px" }} />
                )}
              </React.Fragment>
            ),
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchBar;
