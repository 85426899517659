import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "../../shared/utils/axios";

import { onUserSelected, onLogout } from "../../store/actions";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

export const onUserFetch = async (query) => {
  if (query.length > 0)
    return axios
      .post("/api/admin/getUsersFromQuery", { query })
      .then((response) => {
        const users = response.data;
        // console.log(users);
        return users;
      })
      .catch((error) => {
        console.log("Error", error.message);
        return error;
      });
  else return [];
};

const UserSelect = (props) => {
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState();

  const { onUserSelected, onLogout } = props;
  const { userSelected } = props.admin;

  const handleNameChange = async (event) => {
    const q = event.target.value;
    if (q.match(/\s\w{1}$|^\w{1}$/)) {
      setLoading(true);
      onUserFetch(q)
        .then((result) => {
          setOptions(result || []);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Button onClick={onLogout}>Logout</Button>

      <Autocomplete
        id="combo-box-demo"
        options={options || []}
        freeSolo
        // multiple
        getOptionLabel={(option) => option.name}
        // style={{ width: 300 }}
        onChange={(e, v, r) => {
          onUserSelected(v);
        }}
        renderOption={(option) => {
          return (
            <div>
              <Typography>{option.name}</Typography>
              <Typography variant="body2" style={{ fontSize: 10 }}>
                {option.role} {option._id}
              </Typography>
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select user"
            variant="outlined"
            onChange={handleNameChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />

      {userSelected ? (
        <Card>
          <CardContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
              }}
            >
              <Typography variant="h6">Selected</Typography>
              <Typography>{userSelected.name}</Typography>
              <Typography variant="body2" style={{ fontSize: "10px" }}>
                {userSelected._id}
              </Typography>
              <Typography variant="body2" style={{ fontSize: "10px" }}>
                {userSelected.role}
              </Typography>
            </div>
          </CardContent>
          <CardActions>
            <Link
              style={{ textDecoration: "none", textTransform: "none" }}
              to="/t/tests"
            >
              <Button variant="contained">Go</Button>
            </Link>
            <Button
              style={{ textTransform: "none" }}
              onClick={() => {
                onUserSelected(null);
              }}
            >
              Clear
            </Button>
          </CardActions>
        </Card>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    admin: state.admin
  };
};

export default connect(mapStateToProps, { onUserSelected, onLogout })(
  UserSelect
);
