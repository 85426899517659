import React, { createContext } from "react";
import { useState } from "react";

export const TestSearchContext = createContext({
  showResults: false,
  results: [],
  handleShowResults: () => {},
  handleResults: () => {},
});

export const TestSearchProvider = (props) => {
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState([]);

  const handleShowResults = (value) => {
    setShowResults(value);
  };

  const handleResults = (value) => {
    setResults(value);
  };

  return (
    <TestSearchContext.Provider
      value={{
        showResults: showResults,
        results: results,
        handleShowResults: handleShowResults,
        handleResults: handleResults,
      }}
    >
      {props.children}
    </TestSearchContext.Provider>
  );
};
