import * as actionTypes from "../actionTypes";

const initialState = {
  _id: null,
  userId: null,
  stripe_customer_id: null,
  subscription: null,
  // buckets: [{empty: Boolean, original: Number, remaining: Number, expire: Date, category: String}],
  totalCredits: null,
  transactions: null, // {category, credits, submissionId, invoiceId}

  // overall
  allProducts: null,
  //
  accountLoading: false,
  productsLoading: false
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_CREDITS_ADDING:
    case actionTypes.ACCOUNT_CREDITS_ADD_SUCCESS:
    case actionTypes.ACCOUNT_CREDITS_ADD_ERROR:
    case actionTypes.ACCOUNT_CREDITS_FETCHING:
    case actionTypes.ACCOUNT_TRSN_FETCHING:
    case actionTypes.ACCOUNT_CREDITS_FETCH_ERROR:
    case actionTypes.ACCOUNT_TRSN_FETCH_ERROR:
      return { ...state };
    case actionTypes.ALL_PRODUCTS_FETCHING:
      return { ...state, productLoading: true };
    case actionTypes.ACCOUNT_FETCHING:
      return { ...state, accountLoading: true };
    case actionTypes.ACCOUNT_CREDITS_FETCH_SUCCESS: {
      return { ...state, ...action.payload.account };
    }
    case actionTypes.ALL_PRODUCTS_FETCH_SUCCESS: {
      const { allProducts } = action.payload;
      return { ...state, allProducts, productLoading: false };
    }
    case actionTypes.ACCOUNT_TRSN_FETCH_SUCCESS: {
      return { ...state, ...action.payload.account };
    }
    case actionTypes.ACCOUNT_FETCH_SUCCESS: {
      return { ...state, ...action.payload.account, accountLoading: false };
    }
    case actionTypes.ACCOUNT_FETCH_ERROR: {
      return { ...state, accountLoading: false };
    }
    case actionTypes.ALL_PRODUCTS_FETCH_ERROR: {
      return { ...state, productLoading: false };
    }
    default:
      return state;
  }
};

export default account;
