import React, { useEffect, useRef, useState } from "react";

import { Skeleton } from "@material-ui/lab";

const Image = (props) => {
  const { image, classes } = props;
  const { url = "", justify = "flex-start", scale = 100 } = image || {};

  const [show, setShow] = useState(false);
  const imageUrlRef = useRef();
  const prevImageUrl = imageUrlRef.current;

  useEffect(() => {
    if (prevImageUrl !== url) setShow(false);
  }, [prevImageUrl, url]);

  useEffect(() => {
    imageUrlRef.current = image ? image.url : undefined;
  });

  return (
    <>
      {url ? (
        <div
          style={{
            display: "flex",
            justifyContent: `${justify}`
          }}
          className={classes && classes.container}
        >
          <img
            onLoad={() => setShow(true)}
            style={{
              maxWidth: `${scale}%`,
              display: show ? "flex" : "none"
            }}
            className={classes && classes.img}
            src={url}
            alt="q"
          />
          {!show ? (
            <Skeleton
              variant="rect"
              width={"80%"}
              height={50}
              animation="wave"
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default Image;
