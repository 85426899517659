import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  makeStyles
} from "@material-ui/core";
import { connect } from "react-redux";
import { onNetworkError } from "../../../store/actions";
import { CloudOff, BlurOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const NetworkError = (props) => {
  const classes = useStyles();

  const { networkError } = props.nav;
  const { onNetworkError } = props;

  const handleClose = () => {
    onNetworkError(false);
  };
  const isOffline = !window.navigator.onLine;
  return (
    <Dialog
      open={networkError}
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isOffline ? (
        <>
          <DialogTitle id="alert-dialog-title">
            <div className={classes.title}>
              <CloudOff fontSize="large" />
              <Typography variant="h6">No Internet!</Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ textAlign: "center" }}
            >
              Poor network connection detected.
              <br />
              Please check your connectivity.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              style={{ textTransform: "none", backgroundColor: "lightgrey" }}
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle id="alert-dialog-title">
            <div className={classes.title}>
              <BlurOff fontSize="large" />
              <Typography variant="h6">Whooops...</Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ textAlign: "center" }}
            >
              Something didn't work as expected.
              <br />
              Try again in a little bit.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              style={{ textTransform: "none", backgroundColor: "lightgrey" }}
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default connect(
  (state) => ({
    nav: state.blockNav
  }),
  { onNetworkError }
)(NetworkError);
