/**
 * This is a router component that decides what to show when
 * a test is started. Depending on the logic, it takes the
 * user to the Test Intro layout or shows an error screen.
 */

import React, { useCallback, useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import moment from "moment";

/** Utility imports */
import { ServerTimeContext } from "../../shared/context/server-time-context";
import { withServerTime } from "../../shared/hoc/withServerTime";

// actions
import {
  onAttemptFetch,
  onAttemptStart,
  onAttemptReset,
  onSigninAnon,
} from "../../store/actions";
import { Spinner } from "../../shared/components/UIElements/Progress";
import TestError from "./TestError";
import { useHistory } from "react-router-dom";
import TestIntro from "./TestIntro1";

const TestStart = React.memo((props) => {
  const { onAttemptFetch, onAttemptStart, onAttemptReset, onSigninAnon } =
    props;
  const { loading } = props.nav;
  const history = useHistory();

  const testId = props.match.params.testId;
  const { test, submit, assign } = props.attempt;
  const { role, mid } = props.auth;
  const { submitsAllowed, lockSubmits, lastSubmit, numberOfSubmits } =
    assign || {};
  const { _id: lastSubmitId, status: lastSubmitStatus } = lastSubmit || {};
  const { _id: submitId } = submit || {};
  const { times, intro, open, creator: owner } = test || {};
  const { _id: creator } = owner || {};
  const { disableIntro } = intro || {};
  const { endTime, startTime } = times || {};

  const serverDiff = useContext(ServerTimeContext);

  const [showError, setShowError] = useState("");
  const [showIntro, setShowIntro] = useState(false);
  const [bypassIntro, setBypassIntro] = useState(false);
  const [validated, setValidated] = useState(false);
  const [readyToStart, setReadyToStart] = useState(false);

  const handleValidations = useCallback(async () => {
    if (!assign && !open && mid !== creator) {
      setShowError("NOACCESS");
    } else if (lastSubmitStatus === "IN PROGRESS") {
      setShowError("INPROGRESSSUBMIT");
    } else if (
      // !open &&
      lockSubmits ||
      (submitsAllowed !== 0 && submitsAllowed <= numberOfSubmits)
    ) {
      setShowError("NOMORESUBMITS");
    } else if (endTime && moment(endTime) < moment().add(serverDiff)) {
      setShowError("ENDTIME");
    }
    // if (startTime && moment(startTime) > moment().add(serverDiff).add(15000)) {
    //   setShowError("NOTSTARTED");
    // }
    setShowIntro(
      bypassIntro
        ? false
        : !disableIntro ||
            (startTime && moment(startTime) > moment().add(serverDiff))
    );
  }, [
    assign,
    disableIntro,
    endTime,
    lastSubmitStatus,
    lockSubmits,
    mid,
    numberOfSubmits,
    open,
    serverDiff,
    startTime,
    submitsAllowed,
    creator,
    bypassIntro,
  ]);

  const handleReadyToStart = useCallback(() => {
    if (!showError) {
      onSigninAnon()
        .then(() => onAttemptStart(testId))
        .then(() => setReadyToStart(true));
    }
  }, [showError, testId, onSigninAnon, onAttemptStart]);

  const handleTestStart = () => {
    onAttemptReset();
    setShowError("");
    setShowIntro(false);
    setValidated(false);
    setReadyToStart(false);
    setBypassIntro(true);
  };

  useEffect(() => {
    onAttemptFetch(testId);
    return () => {
      onAttemptReset();
    };
  }, [onAttemptFetch, onAttemptReset, testId, bypassIntro]);

  useEffect(() => {
    if (test && !validated) {
      setValidated(true);
      handleValidations();
    }
  }, [validated, test, showIntro, handleValidations]);

  useEffect(() => {
    if (!showIntro && !submitId && validated) {
      handleReadyToStart();
    }
  }, [showIntro, submitId, handleReadyToStart, validated]);

  useEffect(() => {
    if (readyToStart && submitId) {
      history.replace("/submit/" + submitId);
    }
  }, [readyToStart, submitId, history]);

  return (
    <React.Fragment>
      {showError ? (
        <TestError errorType={showError} submitId={lastSubmitId} />
      ) : test && showIntro ? (
        <TestIntro
          ask={role === "anon" || !role}
          test={test}
          serverDiff={serverDiff}
          handleTestStart={handleTestStart}
        />
      ) : null}
      {loading ? <Spinner /> : null}
    </React.Fragment>
  );
});

const mapStateToProps = (state) => {
  return {
    attempt: state.attempt,
    nav: state.blockNav,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  onAttemptFetch,
  onAttemptStart,
  onAttemptReset,
  onSigninAnon,
})(withServerTime(TestStart));
