import React, { useState, useContext } from "react";
import clsx from "clsx";
import {
  TextField,
  makeStyles,
  Switch,
  InputAdornment,
  Button
} from "@material-ui/core";
import { PhotoOutlined } from "@material-ui/icons";

import { TestSettingsContext } from "../../context/test-settings-context";
import ImageProvider from "../../../shared/components/UIElements/ImageProvider";
import ImageReceiver from "../../../shared/components/UIElements/ImageReceiver";

const TestGeneralSettings = (props) => {
  const classes = makeStyles((theme) => ({
    container: {
      marginTop: "20px"
    },
    underline: {
      "&&&:before": {
        borderBottom: "none"
      },
      "&&:after": {
        borderBottom: "none"
      }
    },
    label1: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.secondary
    },
    inputWithImage: {
      display: "flex"
    },
    panelDetailsSub: {
      margin: "15px 0"
    },
    textRoot: {
      width: "100%"
    }
  }))();

  const [imageFile, setImageFile] = useState(null);
  const handleImageFile = (file, name) => {
    setImageFile(file);
  };

  const context = useContext(TestSettingsContext);

  const { fields, funcs, errors } = context;

  const {
    name,
    instructions,
    introImage,
    acknowledge,
    acknowledgeText
  } = fields;

  const {
    handleName,
    handleInstructions,
    handleIntroImage,
    handleAcknowledge,
    handleAcknowledgeText
  } = funcs;

  const { nameErrorMsg } = errors;

  return (
    <div className={classes.container}>
      <div className={classes.container}>
        <div className={classes.panelDetailsSub}>
          <TextField
            classes={{ root: classes.textRoot }}
            id="outlined-multiline-static"
            value={name}
            required
            label={"Name"}
            placeholder="Enter test name"
            InputLabelProps={{ shrink: true }}
            helperText={nameErrorMsg}
            error={!!nameErrorMsg}
            onChange={handleName}
          />
        </div>
      </div>
      <div className={classes.container}>
        <div className={clsx(classes.inputWithImage)}>
          {/* <Typography className={classes.label1}>Instructions</Typography> */}
          <TextField
            classes={{ root: classes.textRoot }}
            id="multiline-static"
            multiline
            // rows={4}
            rowsMax={20}
            placeholder="Enter details"
            label="Instructions"
            InputLabelProps={{ shrink: true }}
            value={instructions}
            onChange={handleInstructions}
          />
        </div>
        <div className={classes.panelDetailsSub}>
          <ImageReceiver
            name={"instructions"}
            imageObj={imageFile}
            image={introImage}
            handleImageChange={handleIntroImage}
          />
          {introImage && introImage.url ? null : (
            <ImageProvider
              name={"instructions"}
              handleImageFile={handleImageFile}
            >
              <Button
                aria-label="upload picture"
                component="span"
                variant="outlined"
                color="primary"
                style={{ textTransform: "none" }}
              >
                <PhotoOutlined
                  fontSize="default"
                  style={{ marginRight: "10px" }}
                />
                Add Image
              </Button>
            </ImageProvider>
          )}
        </div>
        <div className={classes.panelDetailsSub}>
          <TextField
            classes={{ root: classes.textRoot }}
            placeholder={acknowledge ? "Acknowlegdement Text" : ""}
            onClick={!acknowledge ? handleAcknowledge : null}
            value={acknowledge ? acknowledgeText : "Require Acknowledgement"}
            onChange={handleAcknowledgeText}
            InputProps={{
              classes: { underline: acknowledge ? null : classes.underline },
              endAdornment: (
                <InputAdornment position="end" className={classes.endAdorn}>
                  <Switch
                    checked={acknowledge}
                    onChange={handleAcknowledge}
                    color="primary"
                    name="checkedA"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </InputAdornment>
              )
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TestGeneralSettings;
