import * as actionTypes from "../actionTypes";
import axios from "../../shared/utils/axios";

import * as Sentry from "@sentry/react";

export const onMSClear2 = (sid, qid) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { sections } = answers;
    const section = sections.filter((s) => s.sectionId === sid)[0];
    const question = section.questions.filter((q) => q.questionId === qid)[0];
    question.msAns = [];
    if (question.answered === true && answers.totalAnswered) {
      answers.totalAnswered = answers.totalAnswered - 1;
      answers.ansReview = answers.ansReview - 1;
    }
    question.answered = false;
    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

export const onMSChange2 = (sid, qid, value, checked) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { sections } = answers;
    const section = sections.filter((s) => s.sectionId === sid)[0];
    const question = section.questions.filter((q) => q.questionId === qid)[0];
    if (!question.msAns) question.msAns = [];
    const index = question.msAns.indexOf(value);
    if (!checked) {
      if (index > -1) question.msAns.splice(index, 1);
    } else {
      if (index === -1) question.msAns.splice(index, 0, value);
    }
    const ans = !!question.msAns.length;
    if (question.answered !== ans) {
      answers.totalAnswered = (answers.totalAnswered || 0) + (ans ? 1 : -1);
      answers.ansReview =
        (answers.ansReview || 0) + question.review ? (ans ? 1 : -1) : 0;
    }
    question.answered = ans;
    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

export const onSSChange2 = (sid, qid, value) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { sections } = answers;
    const section = sections.filter((s) => s.sectionId === sid)[0];
    const question = section.questions.filter((q) => q.questionId === qid)[0];
    question.ssAns = value;
    const ans = !!value;
    if (question.answered !== ans) {
      answers.totalAnswered = (answers.totalAnswered || 0) + (ans ? 1 : -1);
      answers.ansReview =
        (answers.ansReview || 0) + (question.review ? (ans ? 1 : -1) : 0);
    }
    question.answered = ans;
    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

export const onNumAnsChange2 = (sid, qid, value) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { sections } = answers;
    const section = sections.filter((s) => s.sectionId === sid)[0];
    const question = section.questions.filter((q) => q.questionId === qid)[0];
    question.numAns = value;
    const ans = !!value || value === 0;
    if (question.answered !== ans) {
      answers.totalAnswered = (answers.totalAnswered || 0) + (ans ? 1 : -1);
      answers.ansReview =
        (answers.ansReview || 0) + (question.review ? (ans ? 1 : -1) : 0);
    }
    question.answered = ans;

    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

export const onShortAnsChange2 = (sid, qid, value) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { sections } = answers;
    const section = sections.filter((s) => s.sectionId === sid)[0];
    const question = section.questions.filter((q) => q.questionId === qid)[0];
    question.shortAns = value;
    const ans = !!value || value === 0;
    if (question.answered !== ans) {
      answers.totalAnswered = (answers.totalAnswered || 0) + (ans ? 1 : -1);
      answers.ansReview =
        (answers.ansReview || 0) + (question.review ? (ans ? 1 : -1) : 0);
    }
    question.answered = ans;

    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

export const onLongAnsChange2 = (sid, qid, value) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { sections } = answers;
    const section = sections.filter((s) => s.sectionId === sid)[0];
    const question = section.questions.filter((q) => q.questionId === qid)[0];
    question.longAns = value;
    const ans = !!value || value === 0;
    if (question.answered !== ans) {
      answers.totalAnswered = (answers.totalAnswered || 0) + (ans ? 1 : -1);
      answers.ansReview =
        (answers.ansReview || 0) + (question.review ? (ans ? 1 : -1) : 0);
    }
    question.answered = ans;
    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

export const onImagesAnsChange2 = (sid, qid, index, image) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { sections } = answers;
    const section = sections.filter((s) => s.sectionId === sid)[0];
    const question = section.questions.filter((q) => q.questionId === qid)[0];
    if (!question.imagesAns) question.imagesAns = [];
    if (index || index === 0) {
      question.imagesAns.splice(index, 1);
    } else {
      question.imagesAns.push(image);
    }
    const ans = !!question.imagesAns.length;
    if (question.answered !== ans) {
      answers.totalAnswered = (answers.totalAnswered || 0) + (ans ? 1 : -1);
      answers.ansReview =
        (answers.ansReview || 0) + (question.review ? (ans ? 1 : -1) : 0);
    }
    question.answered = ans;
    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

// onMarkForReview
export const onMarkForReview2 = (sid, qid, value) => {
  return (dispatch, getState) => {
    const answers = getState().submit.submit.answers;
    const { sections } = answers;
    const section = sections.filter((s) => s.sectionId === sid)[0];
    const question = section.questions.filter((q) => q.questionId === qid)[0];
    if (question.review !== value) {
      answers.totalReview = (answers.totalReview || 0) + (value ? 1 : -1);
      answers.ansReview =
        (answers.ansReview || 0) + (question.answered ? (value ? 1 : -1) : 0);
    }
    question.review = value;

    dispatch({
      type: actionTypes.SUBMIT_REVIEW_ANSWER,
      payload: { answers },
    });
    return Promise.resolve();
  };
};

export const onTestSave2 = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SUBMIT_SAVING,
    });
    const { submit, test } = getState().submit;
    const { version: saveVersion } = test;
    Sentry.configureScope(function (scope) {
      scope.setContext("request", {
        data: JSON.stringify(submit.answers ? submit.answers.sections : []),
      });
    });
    return axios
      .put("/api/submits/v2/save/" + submit._id, { submit, saveVersion })
      .then((response) => {
        dispatch({
          type: actionTypes.SUBMIT_SAVE_SUCCESS,
        });
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SUBMIT_SAVE_ERROR,
        });
        return Promise.reject();
      });
  };
};

export const onTestSubmit2 = (mode) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SUBMIT_SUBMITTING,
    });
    const { submit, test } = getState().submit;
    const { version: saveVersion } = test;
    // Sentry.configureScope(function (scope) {
    //   scope.setContext("request", {
    //     data: JSON.stringify(submit.answers ? submit.answers.sections : [])
    //   });
    // });
    return axios
      .put("/api/submits/v2/submit/" + submit._id, {
        submit,
        mode,
        saveVersion,
      })
      .then((response) => {
        dispatch({
          type: actionTypes.SUBMIT_SUBMIT_SUCCESS,
        });
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SUBMIT_SUBMIT_ERROR,
        });
        return Promise.reject();
      });
  };
};
