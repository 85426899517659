import React, { useContext } from "react";
import {
  makeStyles,
  Typography,
  InputAdornment,
  FormHelperText,
  Input,
  FormControl,
  Tooltip,
  InputLabel
} from "@material-ui/core";

// import Marking from "../Marking/Marking2";
import { TestSettingsContext } from "../../context/test-settings-context";
import { Clear, InfoOutlined } from "@material-ui/icons";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const TestScoringSettings = (props) => {
  const classes = makeStyles((theme) => ({
    container: {
      marginTop: "20px"
    },
    label2: {
      paddingTop: "10px",
      marginRight: "10px",
      width: "30%",
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.primary
    },
    label3: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.secondary
    },
    removeDate: {
      // fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      cursor: "pointer"
    },
    note: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular
      // color: theme.palette.text.secondary
    },
    selectFormControl: {
      flexGrow: 1,
      margin: "8px 0 8px 0"
    },
    dateTimeSelect: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-start"
    },
    dateTimeInput: {
      flexGrow: 1
    },
    tooltip: {
      marginLeft: "10px",
      marginBottom: "12px"
    }
  }))();

  const context = useContext(TestSettingsContext);

  const { fields, funcs, errors } = context;

  // const { marking } = fields;
  // const { handleMarking } = funcs;

  const { startTime, endTime, timeLimit } = fields;

  const {
    handleStartTime,
    clearStartTime,
    handleEndTime,
    clearEndTime,
    handleTimeLimit
  } = funcs;

  const { timeLimitErrorMsg, startTimeErrorMsg, endTimeErrorMsg } = errors;

  return (
    <>
      <div className={classes.container}>
        <React.Fragment>
          <div className={classes.dateTimeSelect}>
            <FormControl
              error={!!timeLimitErrorMsg}
              className={classes.selectFormControl}
            >
              <InputLabel htmlFor="timelimit">Time Limit (minutes)</InputLabel>
              <Input
                id="timelimit"
                value={timeLimit}
                onChange={handleTimeLimit}
                type="number"
                classes={{ root: classes.dateTimeInput }}
              />
              {timeLimitErrorMsg ? (
                <FormHelperText>{timeLimitErrorMsg}</FormHelperText>
              ) : null}
            </FormControl>
            <Tooltip
              placement="left-start"
              className={classes.tooltip}
              title={
                <Typography className={classes.note}>
                  If value is provided a timer will be shown and the test has to
                  be submitted within the time limit. The test will be
                  automatically submitted with the marked answers if the time
                  limit is over.
                  <br />
                  If value is not provided, the test will not be timed.
                </Typography>
              }
            >
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </div>
          <div className={classes.dateTimeSelect}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                classes={{ root: classes.dateTimeInput }}
                disablePast
                showTodayButton
                // variant="inline"
                format="EEEE, MMM do yyyy hh:mm a"
                margin="normal"
                id="start-picker"
                value={startTime}
                onChange={handleStartTime}
                error={!!startTimeErrorMsg}
                helperText={startTimeErrorMsg ? startTimeErrorMsg : ""}
                label="Start Time"
                InputProps={{
                  endAdornment: startTime ? (
                    <InputAdornment position="end">
                      <Clear
                        onClick={(event) => {
                          event.stopPropagation();
                          clearStartTime();
                        }}
                        className={classes.removeDate}
                      />
                    </InputAdornment>
                  ) : null
                }}
              />
            </MuiPickersUtilsProvider>
            <Tooltip
              placement="left-start"
              className={classes.tooltip}
              title={
                <Typography className={classes.note}>
                  If value is provided, it sets the time before which the test
                  cannot be started.
                </Typography>
              }
            >
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </div>
          <div className={classes.dateTimeSelect}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                classes={{ root: classes.dateTimeInput }}
                disablePast
                showTodayButton
                format="EEEE, MMM do yyyy hh:mm a"
                margin="normal"
                id="datetime-picker"
                value={endTime}
                minDate={startTime}
                onChange={handleEndTime}
                error={!!endTimeErrorMsg}
                helperText={endTimeErrorMsg ? endTimeErrorMsg : ""}
                label="End Time"
                InputProps={{
                  endAdornment: endTime ? (
                    <InputAdornment position="end">
                      <Clear
                        onClick={(event) => {
                          event.stopPropagation();
                          clearEndTime();
                        }}
                        className={classes.removeDate}
                      />
                    </InputAdornment>
                  ) : null
                }}
              />
            </MuiPickersUtilsProvider>
            <Tooltip
              placement="left-start"
              className={classes.tooltip}
              title={
                <Typography className={classes.note}>
                  If value is provided, it sets the time when the test will end.
                  <br />
                  If a test is in progress, it will be automatocally submitted
                  with the marked answers when the end time is reached.
                </Typography>
              }
            >
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </div>
        </React.Fragment>
      </div>
      {/* <div className={classes.container}>
        <Marking
          marking={marking}
          handleMarking={handleMarking}
          allowAllMarking
        />
      </div> */}
    </>
  );
};

export default TestScoringSettings;
