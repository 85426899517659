import * as actionTypes from "../actionTypes";

const initialState = {
  error: false,
  assigns: []
};

const assignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ASSIGNS_FETCH_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case actionTypes.ASSIGNS_FETCH_ERROR:
      return { ...state, error: action.payload.error };
    case actionTypes.ASSIGNS_FETCHING:
      return { ...state, error: false };
    default:
      return state;
  }
};

export default assignsReducer;
