import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { TextField } from "@material-ui/core";
// import {
//   // Edit as EditIcon,
//   DoneRounded as DoneRoundedIcon,
// } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  // normalWidth: {
  //   width: "95%",
  //   [theme.breakpoints.down("xs")]: {
  //     width: "90%",
  //   },
  // },
  // editWidth: {
  //   width: "95%",
  //   [theme.breakpoints.down("xs")]: {
  //     width: "90%",
  //   },
  // },
  textField: {
    width: "100%",
   },
  textFieldHtmlInput: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    padding: "3px 0 2px 0",
  },
  textFieldInput: {
    color: "inherit",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
  },
  underline:{
    borderRadius: "5px",
    padding: "10px",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&&:after": {
      borderBottom: "none",
    }
  },
  boxed: {
    border: "1px solid",
  },
  noBoxed: {
    border: "1px solid transparent",    
  },
  error: {
    border: "1px solid",
    borderColor: "red"    
  }
}));

const Title = ({
  editable,
  onEdited,
  onEditing,
  editTrigger,
  title,
  classNames = {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setHoverState] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [error, setError] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setTitleText(title);
  }, [title]);

  useEffect(() => {
    setIsEditing(editTrigger);
  }, [editTrigger]);

  const handleIsEditing = (event) => {
    // setIsEditing(true);
    onEditing ? onEditing() : setIsEditing(true);
  };

  const handleTitleChange = (event) => {
    if (error && event.target.value) setError(false);
    setTitleText(event.target.value);
  };

  const handleOnBlur = (event) => {
    if (titleText) {
      setError(false);
      if (
        (event.type === "keydown" && event.key === "Enter") ||
        event.type === "blur"
      ) {
        setIsEditing(false);
        setHoverState(false);
        handleDoneEditing(event);
      } else if (event.type === "keydown" && event.key === "Escape") {
        setTitleText(title);
        setIsEditing(false);
        setHoverState(false);
        handleDoneEditing(event);
      }
    } else {
      setError(true);
    }
    
  };

  const handleMouseEnter = (event) => {
    setHoverState(true);
  };

  const handleMouseLeave = (event) => {
    setHoverState(false);
  };

  const handleDoneEditing = (event) => {
    if (event.target.value && title !== event.target.value)
      onEdited(event.target.value);
    // else onEdited(null);
  };

  return (
    <React.Fragment>
      <div style={{ width: "100%" }} className={classNames.container}>
        <TextField
          autoFocus
          value={titleText || ""}
          fullWidth={false}
          size="small"
          onMouseEnter={editable && !isEditing ? handleMouseEnter : null}
          onMouseLeave={editable ? handleMouseLeave : null}
          onClick={editable ? handleIsEditing : null}
          onChange={handleTitleChange}
          onKeyDown={editable && isEditing ? handleOnBlur : null}
          onBlur={editable && isEditing ? handleOnBlur : null}
          error={!titleText}
          className={clsx(classes.textField)}
          // className={isEditing ? classes.editWidth : classes.normalWidth}
          // helperText={!titleText ? "A value is required" : null}
          InputProps={{
            inputProps: {
              readOnly: !isEditing,
              className: classes.textFieldHtmlInput,
            },
            disableUnderline: false,
            classes: {
              underline: clsx(classes.underline, 
                error ? classes.error : isEditing || isHovering
                  ? classes.boxed
                  : classes.noBoxed),
            },
            readOnly: !isEditing,
            className: clsx(classes.textFieldInput, classNames.label),
            endAdornment: (
              <React.Fragment>
                {
                  //   editable && isHovering && !isEditing ? (
                  //   <IconButton
                  //     disableFocusRipple
                  //     disableRipple
                  //     disableTouchRipple
                  //     color="inherit"
                  //     style={{ backgroundColor: "transparent" }}
                  //     size="small"
                  //   >
                  //     <EditIcon fontSize="small" style={{ marginLeft: "5px" }} />
                  //   </IconButton>
                  // ) :
                  // editable && isEditing ? (
                  //   <IconButton
                  //     disableFocusRipple
                  //     disableRipple
                  //     disableTouchRipple
                  //     size="small"
                  //     style={{
                  //       backgroundColor: "transparent",
                  //       color: "inherit",
                  //     }}
                  //     onClick={handleDoneEditing}
                  //   >
                  //     <DoneRoundedIcon color="inherit" fontSize="small" />
                  //   </IconButton>
                  // ) : null
                }
              </React.Fragment>
            ),
          }}
        />
      </div>
    </React.Fragment>
  );
};

Title.propTypes = {
  editable: PropTypes.bool,
  onEdited: PropTypes.func,
  title: PropTypes.string,
};

Title.defaultProps = {
  editable: false,
  onEdited: () => {},
  title: "",
};

export default Title;
