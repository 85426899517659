import * as actionTypes from "../actionTypes";

const initialState = {
  user: null, // to be deprecated since props below will be used
  fid: null, // firebase id
  mid: null, // mongodb idemail: null,
  role: "",
  name: null,
  email: null,
  phone: null,
  token: null,
  expirationTime: null, // token expiration time
  isVerified: false,
  isAnonymous: false,
  abortController: null,
  isAuthenticating: true, // on initial load when firebase fetches cookies
  isLoggingIn: false, // when user is logging in or signing up
  isLoggedIn: false, // when firebase token is retrieved and is active
  error: "" // error for auth issues
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNIN_ANON_START:
    case actionTypes.SIGNUP_EP_START:
      return { ...state, isLoggingIn: true, ...action.payload };
    case actionTypes.SIGNIN_ANON_SUCCESS:
    case actionTypes.SIGNUP_EP_SUCCESS: {
      return { ...state, ...action.payload, isLoggingIn: false };
    }
    case actionTypes.SIGNIN_ANON_ERROR:
    case actionTypes.SIGNUP_EP_ERROR: {
      return { ...state, error: action.payload.error, isLoggingIn: false };
    }
    case actionTypes.SIGNUP_GOOGLE_START:
      return { ...state };
    case actionTypes.SIGNUP_GOOGLE_SUCCESS:
      return { ...state };
    case actionTypes.SIGNUP_GOOGLE_ERROR:
      return { ...state };
    case actionTypes.LOGIN_EP_START:
      return { ...state, isLoggingIn: true };
    case actionTypes.LOGIN_EP_SUCCESS: {
      return { ...state, ...action.payload, isLoggingIn: false };
    }
    case actionTypes.LOGIN_EP_ERROR: {
      return { ...state, error: action.payload.error, isLoggingIn: false };
    }
    case actionTypes.LOGIN_GOOGLE_START:
      return { ...state };
    case actionTypes.LOGIN_GOOGLE_SUCCESS:
      return { ...state };
    case actionTypes.LOGIN_GOOGLE_ERROR:
      return { ...state };
    case actionTypes.AUTH_CHANGE: {
      return {
        ...state,
        ...action.payload,
        isAuthenticating: false
      };
    }
    case actionTypes.LOGOUT_START:
      return { ...state };
    case actionTypes.LOGOUT_SUCCESS:
      // All of the app's state is reinitialized in reducers index.js file
      return { ...state, isAuthenticating: false };
    default: {
      return state;
    }
  }
};

export default authReducer;
