import React, { useState } from "react";

import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

const none = index => {};
const numeric = index => String(index + 1);
// Fix these to convert them into AA, aa type
const uppercase = index => String.fromCharCode(index + 1 + 64);
const lowercase = index => String.fromCharCode(index + 1 + 96);

export const sequences = {
  none: {
    id: none(0),
    func: none
  },
  numeric: {
    id: numeric(0),
    func: numeric
  },
  uppercase: {
    id: uppercase(0),
    func: uppercase
  },
  lowercase: {
    id: lowercase(0),
    func: lowercase
  }
};

const OptionsSequence = React.memo(props => {
  let sequenceArr = [];
  for (const [key, value] of Object.entries(sequences)) {
    sequenceArr.push({ name: key, ...value });
  }

  const { sequence = "none", handleSequenceSelect, index } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const func = sequences[sequence || "none"].func;

  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSequenceChange = (event, index) => {
    handleSequenceSelect(sequenceArr[index].name);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div
        // onClick={handleClick}
        style={{ paddingRight: "12px", cursor: "pointer" }}
      >
        <Typography variant="body2">{func(index)}</Typography>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          {sequenceArr.map((seq, index) => {
            return (
              <MenuItem
                key={seq.name}
                selected={sequence === seq.name}
                onClick={event => handleSequenceChange(event, index)}
              >
                {seq.id}
              </MenuItem>
            );
          })}
        </div>
      </Popover>
    </>
  );
});

export default OptionsSequence;
