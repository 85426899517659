import React from "react";
import clsx from "clsx";

import {
  makeStyles,
  Typography,
  Badge,
  Divider,
  IconButton,
  AppBar,
  Toolbar,
  Container,
  Avatar,
  Box,
  useMediaQuery
} from "@material-ui/core";

import { Close } from "@material-ui/icons";
import { grey, green, red, orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  toolBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1
  },
  title: {
    paddingLeft: "12px",
    fontWeight: "600"
  },
  content: {
    paddingLeft: "12px",
    paddingRight: "12px"
  },
  container: {
    padding: "24px 12px"
    // flex: 1
  },
  legends: {
    display: "flex",
    // alignItems: "center",
    // flexWrap: "wrap",
    lineHeight: "",
    flexDirection: "column"
  },
  grid: {
    // overflowY: "scroll"
  },
  sectionTitle: {
    fontWeight: 600,
    textAlign: "left",
    padding: "12px 0"
  },
  sectionGrid: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "12px 0"
  }
}));

const Legend = (props) => {
  const {
    shape = "round",
    color = grey,
    badge = "",
    value = "",
    label = "",
    onClick = null,
    highlight
  } = props;

  const classes = makeStyles((theme) => ({
    legend: {
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap",
      marginRight: "10px",
      marginBottom: "10px"
    },
    clickable: {
      cursor: "pointer"
    },
    avatar: {      
      [theme.breakpoints.down("xs")]: {
        width: "36px",
        height: "36px",
        padding: "6px",        
      }
    },
    box: {
      borderRadius: "3px"
    },
    value: {
      fontSize: "0.7rem"
    },
    label: {
      paddingLeft: "16px"
    },
    badge: {
      right: 4,
      // top: 4,
      bottom: 4,
      backgroundColor: badge[400]
    },
    highlight: {
      boxShadow: "0px 5px 8px grey",
      border: "3px solid purple",
      [!theme.breakpoints.down("xs")]: {
        boxSizing: "border-box"  
      }      
    }
  }))();

  const component = (
    <Box>
      <Avatar
        className={clsx(
          classes.avatar,
          shape === "box" ? classes.box : null,
          onClick ? classes.clickable : null,
          highlight ? classes.highlight : null
        )}        
        style={{ backgroundColor: color[400] }}
        onClick={onClick}
      >
        <Typography className={classes.value} variant="body2">
          {value}
        </Typography>
      </Avatar>
    </Box>
    
  );

  return (
    <div className={classes.legend}>
      {badge ? (
        <Badge
          // variant="dot"
          badgeContent=" "
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          classes={{ badge: classes.badge }}
        >
          {component}
        </Badge>
      ) : (
        component
      )}
      {label ? (
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
      ) : null}
    </div>
  );
};

const ReviewPanel = (props) => {
  const classes = useStyles();
  const { open, qRefs, handleClose, answers, selected, handleQuestionSelected } = props;
  const { sectionsMap, questionsMap } = answers;
  const { totalAnswered, total, totalReview, 
    // ansReview 
  } = answers;

  const breakpoint = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const handleScroll = (questionId) => {
    const sid =
      questionsMap.has(questionId) && questionsMap.get(questionId).sectionId;
    const refId = sid + questionId;

    const elScrollOffset = qRefs[refId].current.getBoundingClientRect().top;
    const scrollOffset =
      window.pageYOffset || document.documentElement.scrollTop;
    const target = elScrollOffset + scrollOffset - 68;
    window.scrollTo({
      top: target,
      behavior: breakpoint ? "auto" : "smooth"
    });
    let scrollTimeout;
    window.onscroll = (e) => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(function () {
        if (open && breakpoint) handleClose();
      }, 100);
    };
    /* Alternate method to close the dialog after scroll 
      Doesn't work for the last item in the list
    */
    // window.onscroll = (e) => {
    //   const currentScrollOffset =
    //     window.pageYOffset || document.documentElement.scrollTop;
    //   // Scroll reach the target
    //   if (
    //     currentScrollOffset > target - 1 &&
    //     currentScrollOffset < target + 1
    //   ) {
    //     handleClose();
    //     window.onscroll = null; // remove listener
    //   }
    // };
  };

  const handleClick = (questionId) => {
    if (handleQuestionSelected) {
      handleQuestionSelected(questionId);
      if (breakpoint) handleClose();
    } else {
      handleScroll(questionId);
      if (breakpoint) handleClose();
    }
  };

  return (
    <>
      <AppBar color="default" position="sticky" elevation={0}>
          <Toolbar variant="dense" className={classes.toolBar}>
            <Typography variant="body1" className={classes.title}>
              Review
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
      <Container className={classes.container}>
        <div className={classes.legends}>
          {/* <Legend value={0} label="Not Visited" /> */}
          <Legend 
            // value={totalAnswered || 0} 
            // label="Answered" 
            label={`${totalAnswered || 0}${" "}Answered`}
            color={green} />
          <Legend
            // value={total - (totalAnswered || 0)}
            label={`${total - (totalAnswered || 0)}${" "}Not Answered`}
            color={red}
          />          
          <Legend
            // value={totalReview || 0}
            // label="For Review"
            label={`${totalReview || 0}${" "}For Review`}
            // color={grey}
            // shape="box"  
            badge={orange}          
          />
          {/* <Legend
            value={ansReview || 0}
            label="For Review and Answered"
            color={orange}
            shape="box"
            badge={green}
          /> */}
        </div>
        <Divider variant="fullWidth" style={{ margin: "8px 0" }} />
        <div className={classes.grid}>
          {Array.from(sectionsMap.keys()).map((sId, sidx) => {
            return (
              <div key={"s" + sidx}>
                <Typography variant="body2" className={classes.sectionTitle}>
                  {sectionsMap.get(sId).name}
                </Typography>
                <div className={classes.sectionGrid}>
                  {sectionsMap.get(sId).questionIds.map((qId, qidx) => {
                    const question = questionsMap.get(qId);                    
                    return (
                      <Legend
                        key={"sq" + sidx + qidx}
                        badge={
                          question.review ? orange : ""
                        }
                        value={qidx + 1}
                        color={
                          question.answered
                            ? green
                            : red
                        }
                        highlight={ selected === qId }
                        // shape={question.review ? "box" : "round"}                        
                        onClick={async (event) => handleClick(qId)}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </>
  );
};

export default ReviewPanel;
