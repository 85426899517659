import * as actionTypes from "../actionTypes";
import axios from "../../shared/utils/axios";
import { loadStripe } from "@stripe/stripe-js";
import appconfig from "../../config";
// import { ObjectId } from "bson";

/********************************/
/** Async action handling      **/
/********************************/

// ACCOUNT_CREDITS_ADDING, ACCOUNT_CREDITS_ADD_SUCCESS, ACCOUNT_CREDITS_ADD_ERROR
export const onAddCredits = (transaction) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ACCOUNT_CREDITS_ADDING
    });
    // {
    //   category: "bonus",
    //   credits: 200
    // }
    return axios
      .put("/api/accounts/add", transaction)
      .then((response) => {
        const account = response.data;
        dispatch({
          type: actionTypes.ACCOUNT_CREDITS_ADD_SUCCESS,
          payload: { account }
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ACCOUNT_CREDITS_ADD_ERROR,
          payload: { error }
        });
      });
  };
};

// ACCOUNT_CREDITS_FETCHING, ACCOUNT_CREDITS_FETCH_SUCCESS, ACCOUNT_CREDITS_FETCH_ERROR
export const onTotalCreditsFetch = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ACCOUNT_CREDITS_FETCHING
    });
    return axios
      .get("/api/accounts/total")
      .then((response) => {
        const account = response.data;
        dispatch({
          type: actionTypes.ACCOUNT_CREDITS_FETCH_SUCCESS,
          payload: { account }
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ACCOUNT_CREDITS_FETCH_ERROR,
          payload: { error }
        });
      });
  };
};

export const onAccountFetch = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ACCOUNT_FETCHING
    });
    return axios
      .get("/api/accounts")
      .then((response) => {
        const account = response.data;
        dispatch({
          type: actionTypes.ACCOUNT_FETCH_SUCCESS,
          payload: { account }
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ACCOUNT_FETCH_ERROR,
          payload: { error }
        });
      });
  };
};

export const onTransactionsFetch = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ACCOUNT_TRSN_FETCHING
    });
    return axios
      .get("/api/accounts/transactions")
      .then((response) => {
        const account = response.data;
        dispatch({
          type: actionTypes.ACCOUNT_TRSN_FETCH_SUCCESS,
          payload: { account }
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ACCOUNT_TRSN_FETCH_ERROR,
          payload: { error }
        });
      });
  };
};

export const onProductsFetch = (priceKeys) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ALL_PRODUCTS_FETCHING
    });
    const url = "/api/accounts/all-products";
    return axios
      .get(url)
      .then((response) => {
        const allProducts = response.data;
        dispatch({
          type: actionTypes.ALL_PRODUCTS_FETCH_SUCCESS,
          payload: { allProducts }
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ALL_PRODUCTS_FETCH_ERROR,
          payload: { error }
        });
      });
  };
};

export const onCreateCheckoutSession = ({
  productId,
  priceId,
  successUrl,
  cancelUrl
}) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CHECKOUT_CREATING
    });
    try {
      const response = await axios.post("/api/accounts/select-plan", {
        productId,
        priceId,
        successUrl,
        cancelUrl
      });
      const stripe = await loadStripe(appconfig.STRIPE_PUBLISHABLE_KEY);

      await stripe
        .redirectToCheckout({
          sessionId: response.data.sessionId
        })
        .then((result) => {
          if (result.error) throw result.error;
          else return result;
        });
      return Promise.resolve();
    } catch (error) {
      dispatch({
        type: actionTypes.CHECKOUT_CREATE_ERROR,
        payload: { error }
      });
    }
  };
};

export const onPaymentSuccess = (sessionId, productName, credits) => {
  return async (dispatch) => {
    try {
      await axios.post("/api/accounts/purchase-success", {
        sessionId, productName, credits
      });
    } catch (error) {
      console.log(error);
    }
  };
};
