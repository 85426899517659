import React from "react";
import {
  Divider,
  Typography,
  List,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";
import { ContactSupport, StarOutlined, Assignment, Subject, ScreenShare } from "@material-ui/icons";
import NavigationItem from "./NavigationItem";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "row",
    minHeight: "32px",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
  },
  link: {
    color: "black",
  },
  active: {
    backgroundColor: theme.palette.background.light,
    color: theme.palette.primary.main,
  },
}));

const AppNavigation = (props) => {
  const { role } = props;
  const classes = useStyles();

  return (
    <List>
      {role === "tutor" ? (
        <>
          <NavigationItem to="/t/tests" exact key={"tests"}>
            <div className={classes.item}>
              <ListItemIcon>
                <Subject />
              </ListItemIcon>
              <Typography>My Tests</Typography>
            </div>
          </NavigationItem>
          <NavigationItem to="/tests/shared" exact key={"shared"}>
            <div className={classes.item}>
              <ListItemIcon>
                <ScreenShare />
              </ListItemIcon>
              <Typography>Shared With Me</Typography>
            </div>
          </NavigationItem>
          <NavigationItem to="/s/tests" exact key={"assigned"}>
            <div className={classes.item}>
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <Typography>Assigned To Me</Typography>
            </div>
          </NavigationItem>
          <NavigationItem key={"pool"}>
            <div className={classes.item}>
              <ListItemIcon>
                <StarOutlined />
              </ListItemIcon>
              <div style={{ flexDirection: "column" }}>
                <Typography>Question Pool</Typography>
                <Typography variant="caption" style={{ color: "grey" }}>
                  Coming Soon
                </Typography>
              </div>
            </div>
          </NavigationItem>
          <NavigationItem key={"learners"}>
            <div className={classes.item}>
              <ListItemIcon>
                <StarOutlined />
              </ListItemIcon>
              <div style={{ flexDirection: "column" }}>
                <Typography>Learners</Typography>
                <Typography variant="caption" style={{ color: "grey" }}>
                  Coming Soon
                </Typography>
              </div>
            </div>
          </NavigationItem>
        </>
      ) : null}
      {role === "student" ? (
        <NavigationItem to="/s/tests" exact key={"assigned"}>
          <div className={classes.item}>
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <Typography>Assigned To Me</Typography>
          </div>
        </NavigationItem>
      ) : null}
      {role === "admin" ? (
        <>
          <NavigationItem to="/t/tests" exact key={"tests"}>
            <div className={classes.item}>
              <ListItemIcon>
                <Subject />
              </ListItemIcon>
              <Typography>My Tests</Typography>
            </div>
          </NavigationItem>
          <NavigationItem to="/tests/shared" exact key={"shared"}>
            <div className={classes.item}>
              <ListItemIcon>
                <ScreenShare />
              </ListItemIcon>
              <Typography>Shared With Me</Typography>
            </div>
          </NavigationItem>
          <NavigationItem to="/s/tests" exact key={"assigned"}>
            <div className={classes.item}>
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <Typography>Assigned To Me</Typography>
            </div>
          </NavigationItem>
          <NavigationItem to="/selectuser" exact key={"selectuser"}>
            <div className={classes.item}>
              <ListItemIcon>
                <ContactSupport />
              </ListItemIcon>
              <Typography>Select User</Typography>
            </div>
          </NavigationItem>
        </>
      ) : null}
      <Divider variant="middle" />
      <NavigationItem key={"notifications"}>
        <div className={classes.item}>
          <ListItemIcon>
            <StarOutlined />
          </ListItemIcon>
          <div style={{ flexDirection: "column" }}>
            <Typography>Notifications</Typography>
            <Typography variant="caption" style={{ color: "grey" }}>
              Coming Soon
            </Typography>
          </div>
        </div>
      </NavigationItem>
      <NavigationItem
        to="https://testcrackr.freshdesk.com/support/home"
        external
        key={"support"}
      >
        <div className={classes.item}>
          <ListItemIcon>
            <ContactSupport />
          </ListItemIcon>
          <Typography>Support</Typography>
        </div>
      </NavigationItem>
      <Divider variant="middle" />
    </List>
  );
};

export default AppNavigation;
