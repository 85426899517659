import React from "react";
import { connect } from "react-redux";

import {
  Toolbar,
  Divider,
  makeStyles,
  IconButton,
  Tooltip,
  TablePagination
} from "@material-ui/core";
import { Refresh } from "@material-ui/icons";

import TestReviewActions from "./TestReviewActions";
import TestReviewSummary from "./TestReviewSummary";
import { onReviewTestFetch2 } from "../../../store/actions";
import TestReviewCSV from "./TestReviewCSV";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10px",
    display: "flex",
    flexDirection: "column"
  },
  summary: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    padding: "12px 0"
  },
  tools: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    flexDirection: "row"
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    alignItems: "center",
    // paddingLeft: "6px",
    boxSizing: "border-box"
  },
  selection: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-start"
  },
  title: {
    flex: "1 1 100%"
  },
  pgnActions: {
    marginLeft: "8px"
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const {
    page,
    rowsPerPage,
    testId,
    test,
    handleChangePage,
    handleChangeRowsPerPage,
    selected,
    count,
    assigns
  } = props;

  const {
    onReviewTestFetch2,
    nav: { loading }
  } = props;

  return (
    <>
      <Toolbar variant="dense" className={classes.root}>
        <div className={classes.summary}>
          <TestReviewSummary test={test} />
        </div>
        <div className={classes.tools}>
          <div className={classes.pagination}>
            <div className={classes.selection}>
              {selected && selected.length > 0 ? (
                <TestReviewActions selected={selected} />
              ) : null}
            </div>
            <TestReviewCSV assigns={assigns} loading={loading} test={test} />
            <Tooltip title="Refresh">
              <span>
                <IconButton
                  disabled={loading}
                  onClick={(e) => onReviewTestFetch2(testId)}
                >
                  <Refresh />
                </IconButton>
              </span>
            </Tooltip>
            <TablePagination
              rowsPerPageOptions={[]}
              labelRowsPerPage=""
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              classes={{ actions: classes.pgnActions }}
              backIconButtonProps={{ size: "small" }}
              nextIconButtonProps={{ size: "small" }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </Toolbar>

      <Divider />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    nav: state.blockNav
  };
};

export default connect(mapStateToProps, { onReviewTestFetch2 })(
  EnhancedTableToolbar
);
