import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import withAppHeader from "../../shared/hoc/withAppHeader";
import SubmitReview from "./SubmitReview";

const SubmitReviewWithHeader = withAppHeader(SubmitReview);

const SubmitReviewPage = (props) => {
  const { submit } = props;
  const { testId: test } = submit || {};

  const { match, location } = props;

  const submitId = match.params.submitId;
  const prevSubmitId = submit ? submit._id : null;

  const [same, setSame] = useState(false);

  useEffect(() => {
    if (prevSubmitId === submitId) setSame(true);
  }, [prevSubmitId, submitId]);

  const testName =
    test && same ? test.name : location.state ? location.state.testName : "";

  return (
    <SubmitReviewWithHeader
      {...props}
      title={testName}
      navAction="back"
      progress={!testName || !same}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    submit: state.reviewsubmit.submit,
  };
};

export default connect(mapStateToProps, {})(SubmitReviewPage);

//   <PageHeader
//     title={testName}
//     back={true}
//     loading={!testName}
//     progress={spinner || !same}
//   />
