import React, { useState } from "react";

import {
  TextField,
  Button,
  InputAdornment,
  // Divider,
  Typography
} from "@material-ui/core";

import EmailTwoTone from "@material-ui/icons/EmailTwoTone";
import LockTwoTone from "@material-ui/icons/LockTwoTone";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import PersonOutlineTwoTone from "@material-ui/icons/PersonOutlineTwoTone";
// import GoogleButton from "../Auth/GoogleButton";

const Signup = React.memo((props) => {
  const { handleOnSubmit } = props;
  const [role, setRole] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    nameError: false,
    nameErrorMsg: "",
    emailError: false,
    emailErrorMsg: "",
    pwdError: false,
    pwdErrorMsg: ""
  });

  const handleNameChange = (event) => {
    setName(event.target.value);
    setError({ ...error, nameError: false, nameErrorMsg: "" });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setError({ ...error, emailError: false, emailErrorMsg: "" });
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError({ ...error, pwdError: false, pwdErrorMsg: "" });
  };

  const handleSignup = async (email, password) => {
    await handleOnSubmit(email, password, role, name).catch((error) => {
      const nameErrorMsg = error.nameErrorMsg;
      const emailErrorMsg = error.emailErrorMsg;
      const pwdErrorMsg = error.pwdErrorMsg;
      setError({
        nameError: !!nameErrorMsg,
        nameErrorMsg,
        emailError: !!emailErrorMsg,
        emailErrorMsg,
        pwdError: !!pwdErrorMsg,
        pwdErrorMsg
      });
    });
  };

  // const handleRoleChange = (event) => {
  //   setRole(event.target.value);
  // };

  const handleSelectRole = (value) => {
    setRole(value);
  };

  return (
    <>
      {!role || role === "tutor" ? (
        <div style={{ margin: "24px 0" }}>
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/testcrackr09.appspot.com/o/public%2Fteachers.png?alt=media&token=45309765-2ab8-42de-9a63-208ab9175782"
            }
            alt={"teachers"}
          />
          {role === "tutor" ? (
            <>
              <Typography color="primary">Signing up as a Teacher</Typography>
              <Typography
                style={{ cursor: "pointer" }}
                variant="body2"
                onClick={(e) => handleSelectRole("student")}
              >
                Change to Learner
              </Typography>
            </>
          ) : (
            <Button
              color="primary"
              variant="contained"
              style={{ textTransform: "none" }}
              onClick={(e) => handleSelectRole("tutor")}
            >
              Signup as a Teacher
            </Button>
          )}
        </div>
      ) : null}
      {!role || role === "student" ? (
        <div style={{ margin: "24px 0" }}>
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/testcrackr09.appspot.com/o/public%2Fstudents.png?alt=media&token=012eefc4-fe6b-4ce2-ab2b-9da6db9fecb1"
            }
            alt={"teachers"}
          />
          {role === "student" ? (
            <>
              <Typography color="primary">Signing up as a Learner</Typography>
              <Typography
                style={{ cursor: "pointer" }}
                variant="body2"
                onClick={(e) => handleSelectRole("tutor")}
              >
                Change to Teacher
              </Typography>
            </>
          ) : (
            <Button
              color="primary"
              variant="contained"
              style={{ textTransform: "none" }}
              onClick={(e) => handleSelectRole("student")}
            >
              Signup as a Learner
            </Button>
          )}
        </div>
      ) : null}
      {role ? (
        <>
          {/* <div
            style={{
              margin: "16px auto",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <GoogleButton
              label="Signup with Google"
              onClick={(event) => {
                event.preventDefault();
                handleGooglAuth(role);
              }}
            />
          </div>
          <div style={{ margin: "24px 0 12px 0" }}>
            <Divider style={{ height: "2px" }} />
            <div>
              <Typography
                style={{
                  margin: "auto",
                  marginTop: "-14px",
                  width: "20px",
                  backgroundColor: "white",
                  padding: "0 5px"
                }}
              >
                or
              </Typography>
            </div>
          </div> */}
          <div>
            {/* <Typography>Create TestCrackr Account</Typography> */}
            <form>
              <TextField
                style={{ width: "100%", margin: "10px 0" }}
                id="name"
                value={name}
                error={error.nameError}
                helperText={error.nameError ? error.nameErrorMsg : null}
                type="text"
                onChange={handleNameChange}
                placeholder="Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineTwoTone color="primary" />
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                style={{ width: "100%", margin: "10px 0" }}
                id="email"
                value={email}
                error={error.emailError}
                helperText={error.emailError ? error.emailErrorMsg : null}
                type="email"
                onChange={handleEmailChange}
                placeholder="Email address"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailTwoTone color="primary" />
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                style={{ width: "100%", margin: "10px 0" }}
                id="password"
                type="password"
                value={password}
                error={error.pwdError}
                helperText={error.pwdError ? error.pwdErrorMsg : null}
                onChange={handlePasswordChange}
                placeholder="Password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockTwoTone color="primary" />
                    </InputAdornment>
                  )
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <VisibilityIcon color="active" />
                  //   </InputAdornment>
                  // )
                }}
              />
              <Button
                style={{
                  width: "100%",
                  marginTop: "30px",
                  textTransform: "none"
                }}
                variant="contained"
                color="primary"
                size="large"
                endIcon={<ArrowForwardIos style={{ fontSize: 15 }} />}
                onClick={(event) => {
                  event.preventDefault();
                  handleSignup(email, password);
                }}
              >
                Signup
              </Button>
            </form>
            <Typography
              style={{ fontSize: "12px", marginTop: "4px" }}
              variant="body2"
            >
              By signing up, you agree to the Terms & Conditions and Privacy
              Policy
            </Typography>
          </div>
        </>
      ) : null}
    </>
  );
});

export default Signup;
