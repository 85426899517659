import React, { useContext, useEffect } from "react";
import {
  Box,
  Drawer,
  Divider,
  ListItem,
  List,
  Toolbar,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";

import { MenuContext } from "../../context/menu-context";
import AppNavigation from "./AppNavigation";
import ProfileAvatar from "../User/ProfileAvatar";
import UserAccordion from "../User/UserAccordion";
import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
  logoBox: {
    minWidth: `${theme.global.drawerWidthSm}px`,
    height: `${theme.global.appHeaderHeightSm}px`,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    boxSizing: "border-box",
    marginTop: "20px",
  },
  toolBar: {
    height: `${theme.global.appHeaderHeight}px`,
  },
  box: {
    width: `${theme.global.drawerWidth}px`,
    [theme.breakpoints.down("xs")]: {
      width: `${theme.global.drawerWidthSm}px`,
    },
    flexShrink: 0,
  },
  drawer: {
    display: "block",
  },
  drawerPaper: {
    width: `${theme.global.drawerWidth}px`,
    [theme.breakpoints.down("xs")]: {
      width: `${theme.global.drawerWidthSm}px`,
    },
    boxSizing: "border-box",
  },
  paperDocked: {
    border: "none",
  },
}));

const SideNav = (props) => {
  const classes = useStyles();
  const { role, navEffect } = props;
  const { mobileNavOpen, onMobileNavClose, sideNav, showSideNav } = useContext(MenuContext);
  const breakpoint = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  useEffect(() => {
   if (navEffect) showSideNav(true); 
  }, [navEffect, showSideNav])

  const drawer = (
    <div
      style={{ overflow: "overlay" }}
      onClick={onMobileNavClose}
      onKeyDown={onMobileNavClose}
    >
      {breakpoint ? (
        <Box component="div" className={classes.logoBox}>
          <Logo />
        </Box>
      ) : (
        <>
          <Toolbar className={classes.toolBar} />
          <Toolbar style={{ minHeight: "20px" }} />
        </>
      )}

      {breakpoint ? (
        <List>
          <ListItem key="userinfo">
            <UserAccordion iconWithInfo />
          </ListItem>
        </List>
      ) : (
        <List>
          <ListItem key="userinfo">
            <ProfileAvatar iconWithInfo expandIcon />
          </ListItem>
        </List>
      )}
      <Divider variant="middle" />
      <div
        role="presentation"
        onClick={onMobileNavClose}
        onKeyDown={onMobileNavClose}
      >
        <AppNavigation role={role} />
      </div>
    </div>
  );

  return (
    <>
      {sideNav ? (
        <Box component="nav" className={classes.box}>
          {breakpoint ? (
            <Drawer
              variant="temporary"
              open={mobileNavOpen}
              anchor="right"
              onClose={onMobileNavClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              className={classes.drawer}
              classes={{ paper: classes.drawerPaper }}
            >
              {drawer}
            </Drawer>
          ) : (
            <Drawer
              variant="permanent"
              className={classes.drawer}
              classes={{
                paper: classes.drawerPaper,
                paperAnchorDockedLeft: classes.paperDocked,
              }}
              open
            >
              {drawer}
            </Drawer>
          )}
        </Box>
      ) : null}
    </>
  );
};

export default SideNav;
