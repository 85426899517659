/**
 * This page is a wrapper for a test submission layout
 * It provides common methods to capture
 * - changes to answers ?
 * - saving a test (periodically) ?
 * - detecting expired timer for a test ?
 * - sumbitting a test
 */
/**
 * Done:
 * Timer display logic moved out to another component
 * Timer synced with server via websocket
 * Detects when new client is connected to server
 * Shows error popup (non-dismissable) when another client is connected
 *    disabling user action on the previously connected clients
 * If the server disconnects temporarily, the client reconnects to it
 *    automatically when the server starts back up. Manual reconnection is
 *    not required.
 */
/**
 * To be done for socket based implementation, part 2:
 * 1. Save marked answers dynamically using the socket instead of
 * axios http requests
 */
/**
 * To be done for socket based implementation, part 3:
 * 1. Sync answers marked between multiple clients (if multiple allowed)
 */

/** React imports */
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";

/** Library imports */
import moment from "moment";

/** mui imports */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";

/** Utility imports */
import {
  // timerSocket,
  submitSocket,
} from "../../shared/utils/socket";
import { ServerTimeContext } from "../../shared/context/server-time-context";
import { withServerTime } from "../../shared/hoc/withServerTime";

/** Component imports */
import SubmitLayoutOnePage from "../components/SubmitLayout2";
import SubmitLayoutByQuestion from "../components/SubmitLayout4";
import TestError from "./TestError";

/** actions imports */
import {
  onTestSubmit2 as onTestSubmit,
  onTestSave2 as onTestSave,
  onMSChange3 as onMSChange,
  onSSChange3 as onSSChange,
  onNumAnsChange3 as onNumAnsChange,
  onShortAnsChange3 as onShortAnsChange,
  onLongAnsChange3 as onLongAnsChange,
  onImagesAnsChange3 as onImagesAnsChange,
  onMarkForReview3 as onMarkForReview,
  onAnswersSetup,
  onSubmitFetch,
  onSubmitReset,
  onSubmitSetTime,
} from "../../store/actions";

// import useTraceUpdate from "../../shared/hooks/trace-hook";

/** styling */
const useStyles = makeStyles(() => ({
  title: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));

/** main class */
const Submit = (props) => {
  // useTraceUpdate(props);

  const [lastSaved, setLastSaved] = useState({ text: "", time: "" });
  const [shouldBlockNav, setShouldBlockNav] = useState(false);
  const [error, setError] = useState({});
  const [firstLoad, setFirstLoad] = useState(true);
  const [started, setStarted] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  const serverDiff = useContext(ServerTimeContext);

  /** actions from props via connect middleware */
  const {
    onTestSubmit,
    onTestSave,
    // onMSChange,
    // onSSChange,
    // onNumAnsChange,
    // onShortAnsChange,
    // onLongAnsChange,
    // onImagesAnsChange,
    // onMarkForReview,
    onAnswersSetup,
    onSubmitFetch,
    onSubmitReset,
    onSubmitSetTime,
  } = props;

  /** global objects in props via connect middleware */
  const { spinner, loading } = props.nav;
  const { submit, test } = props.submit;

  /** attribute from url */
  const sId = props.match.params.submitId;

  const {
    _id: submitId,
    status,
    answers: submitAnswers,
    startedTime,
    endTime,
  } = submit || {};
  const { times, display, name } = test || {};
  const { startTime: start, endTime: end, timeLimit } = times || {};
  const { layout } = display || {};
  let { showError, errorType } = error;
  const [cancelClient, setCancelClient] = useState(false);

  const answerQueue = useRef([]);
  const ansQueuePointer = useRef(0);
  const answerSaveTimer = useRef(null);

  const [answers, setAnswers] = useState(null);

  /**
   * Initialize and manage test submit socket.
   * This socket helps cancel a client when multiple clients
   * are detected for the same user.
   * This socket also helps runs a timer to send answers to the server
   * every few seconds.
   */
  useEffect(() => {
    submitSocket.connect();
    // Register submitSocket event listeners
    submitSocket.on("CANCEL_CLIENT", () => {
      setCancelClient(true);
      submitSocket.io.disconnect();
    });
    submitSocket.on("disconnect", (reason) => {
      console.log("socket closed because:", reason);
    });
    return () => {
      submitSocket.disconnect();
    };
  }, []);

  /**
   * Set 1 second polling to check if the answerQueue has updated
   * If it is, send the remaining answerQueue for processing
   * to the server.
   * The server responds with the number of items it has processed
   * from the queue.
   */
  const emitAnswerQueue = useCallback(() => {
    if (answerQueue.current.length > ansQueuePointer.current) {
      submitSocket.emit(
        "UPDATE_ANSWER",
        sId,
        [...answerQueue.current].splice(ansQueuePointer.current),
        (response) => {
          ansQueuePointer.current += response;
        }
      );
    }
  }, [sId]);

  useEffect(() => {
    if (!answerSaveTimer.current) {
      submitSocket.on("connect", () => {
        submitSocket.emit("CLIENT_INIT", sId);
        answerSaveTimer.current = setInterval(emitAnswerQueue, 1000);
      });
    }
  }, [emitAnswerQueue, sId]);

  /**
   * Run onSubmitReset when the page unmounts
   * --add more context here
   */
  useEffect(() => {
    return () => {
      onSubmitReset();
    };
  }, [onSubmitReset]);

  /**
   * This useEffect sets onbeforeunload to true whenever an answer is
   * marked and to undefined when answers are saved.
   * This results in showing the user an alert if they are about to
   * close the page with unsaved answers.
   */
  useEffect(() => {
    if (shouldBlockNav) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [shouldBlockNav]);

  /**
   * This useEffect checks if the test has 'started' but the startedTime is not set.
   * Here 'started' implies that the test is loaded on-screen without error.
   * The 'startedTime' is mandatory for a submission and indicates the server's
   * record of when the test was started.
   * This value can appear missing if the request to the server to store the
   * startedTime fails for whatever reason.
   * This useEffect detects this and tries to fix it by setting the startedTime
   * as well as the endTime if required
   */
  useEffect(() => {
    if (submitId && started && !startedTime) {
      const time = moment().add(serverDiff);
      const submitEndTime =
        end && timeLimit
          ? moment.min(end, time.clone().add(timeLimit, "minutes"))
          : end && !timeLimit
          ? end
          : !end && timeLimit
          ? time.clone().add(timeLimit, "minutes")
          : null;
      onSubmitSetTime(submitId, {
        startedTime: time,
        endTime: submitEndTime,
        timeLimit,
      });
    }
    if (submitId && started && startedTime && (end || timeLimit) && !endTime) {
      const time = moment().add(serverDiff);
      const submitEndTime =
        end && timeLimit
          ? moment.min(end, time.clone().add(timeLimit, "minutes"))
          : end && !timeLimit
          ? end
          : !end && timeLimit
          ? time.clone().add(timeLimit, "minutes")
          : null;
      onSubmitSetTime(submitId, {
        endTime: submitEndTime,
        timeLimit,
      });
    }
  }, [
    started,
    startedTime,
    end,
    timeLimit,
    submitId,
    onSubmitSetTime,
    endTime,
    serverDiff,
  ]);

  /**
   * This useEffect fetches the submit object if it doesn't exist and
   * if there is no error to show.
   * It also checks that if the submit object is available,
   * there are no other errors to show
   */
  useEffect(() => {
    if ((!submit || submit._id !== sId) && firstLoad && !showError) {
      setFirstLoad(false);
      onSubmitFetch(sId);
    } else if (submit && submit._id === sId && !showError && !started) {
      setFirstLoad(false);
      if (status === "COMPLETED") {
        setError({ showError: true, errorType: "COMPLETED" });
      } else if (endTime && moment(endTime) < moment().add(serverDiff)) {
        setError({ showError: true, errorType: "COMPLETED" });
      } else if (start && moment(start) > moment().add(serverDiff)) {
        setError({ showError: true, errorType: "NOTSTARTED" });
      } else {
        setStarted(true);
        onAnswersSetup();
      }
    }
  }, [
    end,
    endTime,
    start,
    status,
    submit,
    sId,
    firstLoad,
    showError,
    onSubmitFetch,
    onAnswersSetup,
    started,
    serverDiff,
  ]);

  /**
   * Set the local answers state from the redux state
   */
  useEffect(() => {
    setAnswers(submitAnswers);
  }, [submitAnswers]);

  // useEffect(() => {
  //   if (submit && started)
  //     setAnswers(submit.answers);
  // }, [submit, started])

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handleGoHome = () => {
    history.push("/");
  };

  //  Answer change handlers

  const handleAnswerMetrics = (valueIsGood, question) => {
    let incTotalAns = 0;
    let incAnsReview = 0;
    let answered = false;
    if (!valueIsGood) {
      incTotalAns = question.answered ? -1 : 0;
      incAnsReview = question.answered && question.review ? -1 : 0;
    } else {
      incTotalAns = !question.answered ? 1 : 0;
      incAnsReview = !question.answered && question.review ? 1 : 0;
      answered = true;
    }
    return { incTotalAns, incAnsReview, answered };
  };

  const handleReviewMetrics = (value, question) => {
    let incAnsReview = 0;
    let incTotalReview = 0;
    let review = false;
    if (!value) {
      incAnsReview = question.answered && question.review ? -1 : 0;
      incTotalReview = question.review ? -1 : 0;
    } else {
      incAnsReview = question.answered && !question.review ? 1 : 0;
      incTotalReview = !question.review ? 1 : 0;
      review = true;
    }
    return { incAnsReview, incTotalReview, review };
  };

  const handleMSChange = useCallback((qid, value = []) => {
    // onMSChange(qid, value, checked);
    // value is the entire array of checked values
    setAnswers((prevAnswers) => {
      const answers = { ...prevAnswers };
      const { questionsMap } = answers;
      const question = questionsMap.get(qid);
      question.msAns = value;
      const { incTotalAns, incAnsReview, answered } = handleAnswerMetrics(
        value.length > 0,
        question
      );
      question.answered = answered;
      answers.totalAnswered += incTotalAns;
      answers.ansReview += incAnsReview;

      answerQueue.current = [
        ...answerQueue.current,
        {
          qtype: "MCQMS",
          qid,
          value,
          totalAnswered: answers.totalAnswered,
          ansReview: answers.ansReview,
          answered,
        },
      ];
      return answers;
    });
  }, []);

  const handleSSChange = useCallback((qid, value) => {
    // onSSChange(qid, value);
    setAnswers((prevAnswers) => {
      const answers = { ...prevAnswers };
      const { questionsMap } = answers;
      const question = questionsMap.get(qid);
      question.ssAns = value;
      const { incTotalAns, incAnsReview, answered } = handleAnswerMetrics(
        !!value,
        question
      );
      question.answered = answered;
      answers.totalAnswered += incTotalAns;
      answers.ansReview += incAnsReview;

      answerQueue.current = [
        ...answerQueue.current,
        {
          qtype: "MCQSS",
          qid,
          value,
          totalAnswered: answers.totalAnswered,
          ansReview: answers.ansReview,
          answered,
        },
      ];
      return answers;
    });
  }, []);

  const handleNumAnsChange = useCallback((qid, value) => {
    // onNumAnsChange(qid, value);
    setAnswers((prevAnswers) => {
      const answers = { ...prevAnswers };
      const { questionsMap } = answers;
      const question = questionsMap.get(qid);
      question.numAns = value;
      const { incTotalAns, incAnsReview, answered } = handleAnswerMetrics(
        value || value === 0,
        question
      );
      question.answered = answered;
      answers.totalAnswered += incTotalAns;
      answers.ansReview += incAnsReview;

      answerQueue.current = [
        ...answerQueue.current,
        {
          qtype: "NUMBER",
          qid,
          value,
          totalAnswered: answers.totalAnswered,
          ansReview: answers.ansReview,
          answered,
        },
      ];
      return answers;
    });
  }, []);

  const handleShortAnsChange = useCallback((qid, value) => {
    setAnswers((prevAnswers) => {
      const answers = { ...prevAnswers };
      const { questionsMap } = answers;
      const question = questionsMap.get(qid);
      question.shortAns = value;
      const { incTotalAns, incAnsReview, answered } = handleAnswerMetrics(
        value || value === 0,
        question
      );
      question.answered = answered;
      answers.totalAnswered += incTotalAns;
      answers.ansReview += incAnsReview;

      answerQueue.current = [
        ...answerQueue.current,
        {
          qtype: "SHORT",
          qid,
          value,
          totalAnswered: answers.totalAnswered,
          ansReview: answers.ansReview,
          answered,
        },
      ];
      return answers;
    });
  }, []);

  const handleLongAnsChange = useCallback((qid, value) => {
    // onLongAnsChange(qid, value);
    setAnswers((prevAnswers) => {
      const answers = { ...prevAnswers };
      const { questionsMap } = answers;
      const question = questionsMap.get(qid);
      question.longAns = value;
      const { incTotalAns, incAnsReview, answered } = handleAnswerMetrics(
        value || value === 0,
        question
      );
      question.answered = answered;
      answers.totalAnswered += incTotalAns;
      answers.ansReview += incAnsReview;

      answerQueue.current = [
        ...answerQueue.current,
        {
          qtype: "LONG",
          qid,
          value,
          totalAnswered: answers.totalAnswered,
          ansReview: answers.ansReview,
          answered,
        },
      ];
      return answers;
    });
  }, []);

  const handleImagesAnsChange = useCallback((qid, value = []) => {
    // onImagesAnsChange(qid, image, index);
    setAnswers((prevAnswers) => {
      const answers = { ...prevAnswers };
      const { questionsMap } = answers;
      const question = questionsMap.get(qid);
      question.imagesAns = value;
      const { incTotalAns, incAnsReview, answered } = handleAnswerMetrics(
        value.length > 0,
        question
      );
      question.answered = answered;
      answers.totalAnswered += incTotalAns;
      answers.ansReview += incAnsReview;

      answerQueue.current = [
        ...answerQueue.current,
        {
          qtype: "IMAGE",
          qid,
          value,
          totalAnswered: answers.totalAnswered,
          ansReview: answers.ansReview,
          answered,
        },
      ];
      return answers;
    });
  }, []);

  const handleMarkForReview = useCallback((qid, value) => {
    // onMarkForReview(qid);
    setAnswers((prevAnswers) => {
      const answers = { ...prevAnswers };
      const { questionsMap } = answers;
      const question = questionsMap.get(qid);

      const { incAnsReview, incTotalReview, review } = handleReviewMetrics(
        value,
        question
      );
      question.review = review;
      answers.ansReview += incAnsReview;
      answers.totalReview += incTotalReview;

      answerQueue.current = [
        ...answerQueue.current,
        {
          qtype: "REVIEW",
          qid,
          value,
          totalReview: answers.totalReview,
          ansReview: answers.ansReview,
          review,
        },
      ];
      return answers;
    });
  }, []);

  const handleTestSave = useCallback(() => {
    onTestSave()
      .then((result) => {
        if (result && ["NOT_FOUND", "IS_SUBMITTED"].includes(result)) {
          setLastSaved((lastSaved) => ({
            ...lastSaved,
            text: "An error ocurred while saving the test. Last saved: ",
          }));
          setErrorOpen(true);
        } else {
          setLastSaved({
            text: "Last saved: ",
            time: moment().format("h:mma"),
          });
          setShouldBlockNav(false);
        }
      })
      .catch(() => {
        setLastSaved((lastSaved) => ({
          ...lastSaved,
          text: "An error ocurred while saving the test. Last saved: ",
        }));
      });
  }, [onTestSave]);

  const handleGoToReview = useCallback(() => {
    history.replace("/review/" + submitId, { testName: name });
  }, [history, submitId, name]);

  const handleTestSubmit = useCallback(
    (mode) => {
      /**
       * This condition makes sure test is submitted only if the
       * client has not been cancelled already
       */
      if (!cancelClient) {
        onTestSubmit(mode)
          .then((result) => {
            // Need to implement cancel subscription logic
            // to prevent below from running on component unmount
            // https://stackoverflow.com/questions/56450975/to-fix-cancel-all-subscriptions-and-asynchronous-tasks-in-a-useeffect-cleanup-f
            if (result && ["NOT_FOUND", "IS_SUBMITTED"].includes(result)) {
              setLastSaved((lastSaved) => ({
                ...lastSaved,
                text: "An error ocurred while saving the test. Last saved: ",
              }));
              setErrorOpen(true);
            } else {
              setShouldBlockNav(false);
              handleGoToReview();
            }
          })
          .catch(() => {
            setLastSaved((lastSaved) => ({
              ...lastSaved,
              text: "An error ocurred while saving the test. Last saved: ",
            }));
          });
      }
    },
    [onTestSubmit, handleGoToReview, cancelClient]
  );

  return (
    <React.Fragment>
      {showError && !started ? (
        <TestError
          errorType={errorType}
          submitId={submitId}
          startTime={start}
        />
      ) : layout === "BY_QUESTION" ? (
        <SubmitLayoutByQuestion
          lastSaved={lastSaved.text + lastSaved.time}
          test={test}
          answers={answers}
          endTime={endTime}
          spinner={spinner}
          loading={loading}
          shouldBlockNav={shouldBlockNav}
          handleTestSave={handleTestSave}
          handleTestSubmit={handleTestSubmit}
          handleMSChange={handleMSChange}
          handleSSChange={handleSSChange}
          handleNumAnsChange={handleNumAnsChange}
          handleShortAnsChange={handleShortAnsChange}
          handleLongAnsChange={handleLongAnsChange}
          handleImagesAnsChange={handleImagesAnsChange}
          handleMarkForReview={handleMarkForReview}
        />
      ) : (
        <SubmitLayoutOnePage
          lastSaved={lastSaved.text + lastSaved.time}
          test={test}
          answers={answers}
          endTime={endTime}
          spinner={spinner}
          loading={loading}
          shouldBlockNav={shouldBlockNav}
          handleTestSave={handleTestSave}
          handleTestSubmit={handleTestSubmit}
          handleMSChange={handleMSChange}
          handleSSChange={handleSSChange}
          handleNumAnsChange={handleNumAnsChange}
          handleShortAnsChange={handleShortAnsChange}
          handleLongAnsChange={handleLongAnsChange}
          handleImagesAnsChange={handleImagesAnsChange}
          handleMarkForReview={handleMarkForReview}
        />
      )}
      {cancelClient ? (
        <Dialog open={cancelClient} fullWidth={true}>
          <DialogContent>
            <DialogContentText style={{ textAlign: "center" }}>
              The test was opened in another tab, browser or on another device.
              To continue in this window, refresh the page.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : null}
      <Dialog open={errorOpen} fullWidth={true} onClose={handleErrorClose}>
        <DialogTitle id="alert-dialog-title">
          <div className={classes.title}>
            <Typography variant="h6">Hmmm...</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            This didn't work as expected.
            <br />
            The test was either removed or already submitted in another window.
            <br />
            Go to the{" "}
            <Link to="/" onClick={handleGoHome}>
              homepage
            </Link>{" "}
            to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            style={{ textTransform: "none", backgroundColor: "lightgrey" }}
            onClick={handleErrorClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

/** export with middleware */
export default connect(
  (state) => ({
    submit: state.submit,
    nav: state.blockNav,
  }),
  {
    onTestSubmit,
    onTestSave,
    onMSChange,
    onSSChange,
    onNumAnsChange,
    onShortAnsChange,
    onLongAnsChange,
    onImagesAnsChange,
    onMarkForReview,
    onAnswersSetup,
    onSubmitFetch,
    onSubmitReset,
    onSubmitSetTime,
  }
)(withServerTime(Submit));
