import React from "react";

const Home = () => {
  return (
    <>
      <div style={{ marginTop: "100px" }}>
        Landing Page
        <br />
        Nothing to see here.
      </div>
    </>
  );
};

export default Home;
