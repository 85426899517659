import React, { useState } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  RadioGroup,
  Radio,
  Button,
} from "@material-ui/core";
import { sequences } from "../../tutor/components/Question/OptionsSequence";
import Image from "../../shared/components/UIElements/Image1";
import ImageReceiver from "../../shared/components/UIElements/ImageReceiver";
import ImageProvider from "../../shared/components/UIElements/ImageProvider";
import { AddPhotoAlternate } from "@material-ui/icons";

/**
 * Using controlled components below.
 * Using Uncontrolled results in an error since initial value is
 * coming from an external prop which can change
 */

const MCQMSComponent = React.memo((props) => {
  const { question, questionAns, handleChange, disabled, classes } = props;
  const seq = question.sequence;
  const qid = question._id;
  const func = seq ? sequences[seq].func : null;
  return (
    <FormControl component="fieldset">
      <FormGroup>
        {question.options.map((option, oidx) => {
          return (
            <div key={option._id}>
              <FormControlLabel
                classes={{ root: classes.formControlLabelRoot }}
                disabled={disabled}
                control={
                  <Checkbox
                    classes={{ root: classes.checkRoot }}
                    color="primary"
                    checked={questionAns.includes(option.text) || false}
                    onChange={(event) => {
                      const idx = questionAns.indexOf(option.text);
                      const checked = event.target.checked;
                      if (checked && idx === -1) {
                        const arr = [...questionAns];
                        arr.push(option.text);
                        handleChange(qid, arr, event.target.checked);
                      } else if (!checked && idx > -1) {
                        const arr = [...questionAns];
                        arr.splice(idx, 1);
                        handleChange(qid, arr);
                      }
                    }}
                  />
                }
                label={(func ? func(oidx) + ".  " : "") + option.text}
              />
              {option.image ? <Image image={option.image} /> : null}
            </div>
          );
        })}
      </FormGroup>
    </FormControl>
  );
});

const MCQSSComponent = React.memo((props) => {
  const { question, questionAns, handleChange, disabled, classes } = props;
  const seq = question.sequence;
  const qid = question._id;
  const func = seq ? sequences[seq].func : null;
  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="options"
        name="options1"
        value={questionAns}
        onChange={(event) => {
          handleChange(qid, event.target.value);
        }}
      >
        {question.options.map((option, oidx) => {
          return (
            <div key={option._id}>
              <FormControlLabel
                classes={{ root: classes.formControlLabelRoot }}
                value={option.text}
                disabled={disabled}
                control={
                  <Radio
                    classes={{ root: classes.checkRoot }}
                    color="primary"
                  />
                }
                label={(func ? func(oidx) + ".  " : "") + option.text}
              />
              {option.image ? (
                <div style={{ marginLeft: "30px" }}>
                  <Image image={option.image} />
                </div>
              ) : null}
            </div>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
});

const NumComponent = React.memo((props) => {
  const { question, questionAns, handleChange, disabled } = props;
  const qid = question._id;
  return (
    <div>
      <TextField
        type="number"
        placeholder="Answer"
        variant="outlined"
        disabled={disabled}
        value={questionAns}
        onChange={(event) => {
          handleChange(qid, event.target.value);
        }}
        autoComplete="off"
        margin="dense"
        InputProps={{
          autoComplete: "off",
        }}
      />
    </div>
  );
});

const ShortComponent = React.memo((props) => {
  const { question, questionAns, handleChange, disabled } = props;
  const qid = question._id;
  return (
    <div>
      <TextField
        placeholder="Answer"
        variant="outlined"
        disabled={disabled}
        value={questionAns}
        onChange={(event) => {
          handleChange(qid, event.target.value);
        }}
        autoComplete="off"
        margin="dense"
        InputProps={{
          autoComplete: "off",
        }}
      />
    </div>
  );
});

const LongComponent = React.memo((props) => {
  const { question, questionAns, handleChange, disabled } = props;
  const qid = question._id;
  return (
    <div>
      <TextField
        multiline
        rows="9"
        rowsMax="18"
        placeholder="Answer"
        disabled={disabled}
        value={questionAns}
        onChange={(event) => {
          handleChange(qid, event.target.value);
        }}
        variant="outlined"
        autoComplete="off"
        margin="dense"
        InputProps={{ autoComplete: "off" }}
        style={{ width: "100%" }}
      />
    </div>
  );
});

// Custom CONTROLLED component
const ImageComponent = React.memo((props) => {
  const { question, questionAns, handleChange } = props;
  const { numImages } = question;
  console.log(questionAns.length, numImages);
  const [imageFile, setImageFile] = useState();
  const handleAnsImageFile = (file) => {
    setImageFile(file);
  };
  const qid = question._id;
  return (
    <div style={{ margin: "20px 0" }}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {questionAns.map((image, index) => {
          return (
            <div
              key={`question-${qid}-${index}`}
              style={{ marginRight: "10px" }}
            >
              <Image
                image={image}
                removable
                thumbnail
                handleImageChange={(image) => {
                  // presence of index means image has to be removed
                  const arr = [...questionAns];
                  arr.splice(index, 1);
                  handleChange(qid, arr);
                }}
              />
            </div>
          );
        })}

        <ImageReceiver
          name={`question-${qid}`}
          imageObj={imageFile}
          handleImageChange={(image) => {
            // Clear the imageFile to avoid loading this again and again
            setImageFile(null);
            const arr = [...questionAns, image];
            handleChange(qid, arr);
          }}
        />
      </div>
      {questionAns.length >= numImages ? null : (
        <ImageProvider
          name={`question-img-${qid}`}
          handleImageFile={handleAnsImageFile}
        >
          <Button
            aria-label="upload picture"
            component="span"
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            <AddPhotoAlternate
              fontSize="default"
              style={{ marginRight: "10px" }}
            />
            Add Image
          </Button>
        </ImageProvider>
      )}
    </div>
  );
});

const QuestionLayout = (props) => {
  const {
    question,
    questionAns,
    disabled,
    classes,
    handleMSChange,
    handleSSChange,
    handleNumAnsChange,
    handleShortAnsChange,
    handleLongAnsChange,
    handleImagesAnsChange,
  } = props;

  switch (question.type) {
    case "MCQMS":
      return (
        <MCQMSComponent
          question={question}
          questionAns={(questionAns && questionAns.msAns) || []}
          handleChange={handleMSChange}
          disabled={disabled}
          classes={classes}
        />
      );
    case "MCQSS":
      return (
        <MCQSSComponent
          question={question}
          questionAns={(questionAns && questionAns.ssAns) || ""}
          handleChange={handleSSChange}
          disabled={disabled}
          classes={classes}
        />
      );
    case "NUMBER":
      return (
        <NumComponent
          question={question}
          questionAns={(questionAns && questionAns.numAns) || ""}
          handleChange={handleNumAnsChange}
          disabled={disabled}
        />
      );
    case "SHORT":
      return (
        <ShortComponent
          question={question}
          questionAns={(questionAns && questionAns.shortAns) || ""}
          handleChange={handleShortAnsChange}
          disabled={disabled}
        />
      );
    case "LONG":
      return (
        <LongComponent
          question={question}
          questionAns={(questionAns && questionAns.longAns) || ""}
          handleChange={handleLongAnsChange}
          disabled={disabled}
        />
      );
    case "IMAGE":
      return (
        <ImageComponent
          question={question}
          questionAns={(questionAns && questionAns.imagesAns) || []}
          handleChange={handleImagesAnsChange}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};

export default QuestionLayout;
