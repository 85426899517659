import { createMuiTheme } from "@material-ui/core/styles";
import { indigo, green, red, lime, grey, blue } from "@material-ui/core/colors";

/**
 * This is used in conjuntion with MuiThemeProvider (import from @material-ui/core/styles)
 * The MuiThemeProvider wraps the entire App and uses this theme as a prop.
 * The theme is then consumed in components using the useTheme hook (also import from @material-ui/core/styles).
 * The same theme object is also passed into the makeStyles hook generator.
 */

let theme = createMuiTheme();

theme = createMuiTheme({
  spacing: [0, 4, 6, 8, 10, 12, 16, 20, 30, 40, 80, 100, 120],
  typography: {
    fontFamily: [
      "Open Sans",
      "Roboto",
      '"Helvetica Neue"',
      "Helvetica",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h4: {
      fontSize: "1.5rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.3rem",
      },
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.2rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.1rem",
      },
      fontWeight: 600,
    },
    h6: {
      fontSize: "1.1rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
      fontWeight: 600,
    },
    title: {
      fontSize: "1.2rem",
      // [theme.breakpoints.up("sm")]: {
      //   fontSize: "1.3rem"
      // },
      // fontWeight: 500
    },
  },
  palette: {
    bg: {
      positive: green[50],
      active: lime[50],
      disabled: grey[50],
      positiveHover: green[100],
      activeHover: lime[100],
      disabledHover: grey[100],
    },
    icons: {
      main: indigo[900],
      contrastText: "#fff",
      safe: green[400],
      danger: red[400],
    },
    background: {
      light: indigo[50],
    },
    appbar: {
      default: grey[100],
    },
    primary: { main: "#3C7AC0" },
    secondary: { main: blue[600] },
    text: {
      nav: grey[700],
    },
    button: {
      default: grey[200],
      textBtn: grey[700], 
      borderDefault: grey[500]     
    },
    border: {
      default: grey[700],
      light: grey[400]
    },
  },
  // breakpoints: {
  //   keys: ["xs", "mob", "sm", "smd", "md", "lg", "xl"],
  //   values: {
  //     xs: 0,
  //     mob: 450,
  //     sm: 600,
  //     smd: 750,
  //     md: 960,
  //     lg: 1280,
  //     xl: 1920
  //   }
  // },
  global: {
    drawerWidth: 240,
    drawerWidthSm: 280,
    appHeaderHeight: 68,
    appHeaderHeightSm: 56,
  },
  /**
   * The following format of applying styles can be used
   * after upgrading to MUI v5.
   */
  // components: {
  //   // Name of the component
  //   MuiAppBar: {
  //     styleOverrides: {
  //       // Name of the slot
  //       root: {
  //         // Some CSS
  //         height: '68px',
  //         [theme.breakpoints.down("sm")]: {
  //           height: "68px"
  //         },
  //       },
  //     },
  //   },
  // },
});

export default theme;
