import React from "react";
import ReactDOM from "react-dom";

import { CookiesProvider } from "react-cookie";
import AuthProvider from "./shared/context/auth-context";
import App from "./App";
import { Provider } from "react-redux";

// Sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import appconfig from "./config";

import store from "./store/store";

const SENTRY_DSN = appconfig.SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

const rootElement = document.getElementById("root");
ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      {/* <React.StrictMode> */}
      <AuthProvider>
        <App />
      </AuthProvider>
      {/* </React.StrictMode> */}
    </Provider>
  </CookiesProvider>,
  rootElement
);
