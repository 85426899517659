import React from "react";
import { NavLink } from "react-router-dom";

import { ListItem, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.nav,
    textDecoration: "none",
    width: "100%",
    padding: "6px 8px",
    "& p": {
      fontWeight: 600,
    },
    "&:hover": {
      backgroundColor: theme.palette.background.light,
      color: theme.palette.primary.main,
      "& p": {
        fontWeight: 600,
      },
    },
  },
  nolink: {
    color: theme.palette.text.nav,
    textDecoration: "none",
    width: "100%",
    padding: "6px 8px",
  },
  active: {
    // backgroundColor: theme.palette.background.light,
    color: theme.palette.primary.main,
    "& p": {
      fontWeight: 600,
    },
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}));

const NavigationItem = (props) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.item}>
      {props.to ? (
        props.external ? (
          <a
            href={props.to}
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.children}
          </a>
        ) : (
          <NavLink
            to={props.to}
            exact={props.exact}
            activeClassName={classes.active}
            className={classes.link}
          >
            {props.children}
          </NavLink>
        )
      ) : (
        <div className={classes.nolink}>{props.children}</div>
      )}
      {/* <a href={props.link} className={props.active ? "active" : null}>
      {props.children}
    </a> */}
    </ListItem>
  );
};

export default NavigationItem;
