import * as actionTypes from "../actionTypes";
import axios from "../../shared/utils/axios";
import { validate } from "../../shared/utils/validator";
import { omitDeep } from "../../shared/utils/funcs";
// import { ObjectId } from "bson";

/********************************/
/** Async action handling      **/
/********************************/

// TEST_FETCHING
export const onTestFetch = (testId, shareCode) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TEST_FETCHING,
    });
    const headers = { "X-Object-Share": shareCode };
    return axios
      .get("/api/tests/" + testId, { headers })
      .then((response) => {
        const test = response.data;
        const access = response.headers["x-object-access"];
        dispatch(onTestFetchSuccess(test, access));
      })
      .catch((error) => {
        dispatch(onTestFetchError(error));
      });
  };
};

// TEST_FETCH_SUCCESS
export const onTestFetchSuccess = (test, access) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TEST_FETCH_SUCCESS,
      payload: { test, access },
    });
    return Promise.resolve();
  };
};

// TEST_FETCH_ERROR
export const onTestFetchError = (error) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TEST_FETCH_ERROR,
      payload: { error },
    });
  };
};

export const onTestShare = (testId, emails, permission) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    return axios
      .patch("/api/tests/" + testId + "/share", { emails, permission })
      .then((response) => {
        const acl = response.data;
        dispatch({
          type: actionTypes.TEST_SHARED,
          payload: { acl },
        });
        dispatch(onTestEditSuccess({ message: "Changes saved" }));
      })
      .catch((error) => {
        dispatch(onTestEditError(error));
      });
  };
};

// TEST_EDITING
// Try to move this load off to the server
// export const onTestEdit = () => {
//   return (dispatch, getState) => {
//     dispatch({
//       type: actionTypes.TEST_EDITING
//     });
//     const { details, sections, tags } = getState().test;
//     const sectionsUpdated = sections.map((section) => {
//       const numQuestions = section.questions.length;
//       return { ...section, numQuestions };
//     });
//     const numQuestions = sectionsUpdated.reduce((total, s) => {
//       return total + s.numQuestions;
//     }, 0);
//     const numSections = sectionsUpdated.length;
//     const testObj = {
//       ...details,
//       tags,
//       numQuestions,
//       numSections,
//       sections: sections
//     };
//     const url = "/api/tests/" + testObj._id;
//     return axios
//       .put(url, testObj)
//       .then((response) => {
//         dispatch(onTestEditSuccess("All changes saved"));
//       })
//       .catch((error) => {
//         dispatch(onTestEditError(error));
//       });
//   };
// };

// TEST_EDIT_SUCCESS
export const onTestEditSuccess = ({ test, message }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TEST_EDIT_SUCCESS,
      payload: { test, message },
    });
    return Promise.resolve();
  };
};

// TEST_EDIT_ERROR
export const onTestEditError = (error) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TEST_EDIT_ERROR,
      payload: { message: error && error.message ? error.message : "" },
    });
  };
};

// TEST_EDITING, TEST_SECTION_ADDED, TEST_SECTION_EDITED
export const onTestSectionAddEdit = () => {
  return (dispatch, getState) => {
    const { rules, mode, localSection } = getState().sectionEditor;
    const nameErrorMsg = validate(localSection.name, rules.name);
    if (nameErrorMsg) {
      return Promise.reject({ nameErrorMsg });
    }
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    const { _id: sectionId, ...localSection1 } = localSection;

    const {
      sections,
      details: { _id },
    } = getState().test;

    let sectionsCopy = [...sections];

    if (mode === "NEW_SECTION") {
      return axios
        .patch("/api/tests/" + _id + "/section", localSection1)
        .then((response) => {
          const { test, section } = response.data;
          if (section) {
            sectionsCopy.push(section);
            dispatch(onTestEditSuccess({ test, message: "All changes saved" }));
          }
        })
        .then(() => {
          dispatch({
            type: actionTypes.TEST_SECTION_ADDED,
            payload: {
              sections: sectionsCopy,
              selectedSectionIdx: sectionsCopy.length - 1,
              selectedSectionId: sectionsCopy.slice(-1)[0]._id,
            },
          });
          dispatch(onSectionEditorClose());
        })
        .catch((error) => {
          dispatch(onTestEditError(error));
        });
    } else if (mode === "EDIT_SECTION") {
      const { questions, marking } = localSection;
      const questionsUpdated = copySectionMarkingDown(questions, marking);
      localSection.questions = questionsUpdated;
      return axios
        .patch("/api/tests/" + _id + "/" + sectionId, localSection)
        .then((response) => {
          const { test, section } = response.data;
          if (section) {
            const idx = sections.map((s) => s._id).indexOf(section._id);
            sections[idx] = section;
            dispatch(onTestEditSuccess({ test, message: "All changes saved" }));
          }
        })
        .then(() => {
          dispatch({
            type: actionTypes.TEST_SECTION_EDITED,
            payload: {
              sections: sections,
            },
          });
          dispatch(onSectionEditorClose());
        })
        .catch((error) => {
          dispatch(onTestEditError(error));
        });
    }
  };
};

// TEST_EDITING, TEST_SECTION_EDITED
export const onTestSectionNameEdit = (id, name) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    const {
      sections,
      details: { _id },
    } = getState().test;
    const newSections = sections.map((s) => {
      if (s._id === id) s.name = name;
      return s;
    });
    const section = newSections.filter((s) => s._id === id)[0];

    dispatch({
      type: actionTypes.TEST_SECTION_EDITED,
      payload: {
        sections: sections,
      },
    });
    return axios
      .patch("/api/tests/" + _id + "/" + id, section)
      .then((response) => {
        const { section } = response.data;
        if (section) {
          dispatch(onTestEditSuccess({ message: "Section name updated" }));
        }
      })
      .catch((error) => {
        dispatch(onTestEditError(error));
      });
  };
};

// TEST_EDITING, TEST_SECTION_ADDED
export const onTestSectionDuplicate = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    const {
      sections,
      details: { _id },
    } = getState().test;
    const section = sections.filter((s) => s._id === id)[0];
    // const { _id: sectionId, name, questions, ...other } = section;
    // const questions1 = questions.map((q) => {
    //   const { _id: qid, ...rest } = q;
    //   return { ...rest };
    // });
    let section1 = omitDeep(section, "_id");
    section1.name = section.name + " Copy";
    // const section1 = { name: name + " Copy", questions: questions1, ...other };
    let sectionsCopy = [...sections];
    return axios
      .patch("/api/tests/" + _id + "/section", section1)
      .then((response) => {
        const { test, section } = response.data;
        if (section) {
          sectionsCopy.push(section);
          dispatch(
            onTestEditSuccess({ test: test, message: "All changes saved" })
          );
        }
      })
      .then(() => {
        dispatch({
          type: actionTypes.TEST_SECTION_ADDED,
          payload: {
            sections: sectionsCopy,
            selectedSectionIdx: sectionsCopy.length - 1,
            selectedSectionId: sectionsCopy.slice(-1)[0]._id,
          },
        });
        dispatch(onSectionEditorClose());
      })
      .catch((error) => {
        dispatch(onTestEditError(error));
      });
  };
};

// TEST_SECTION_REORDERING, TEST_SECTION_REORDERED
export const onTestSectionReorder = (from, to) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_SECTION_REORDERING,
    });
    const {
      sections,
      selectedSectionId,
      details: { _id },
    } = getState().test;
    const sectionId = sections[from]._id;
    if (from === to) {
      return Promise.resolve();
    } else {
      let reordered = [...sections];
      reordered.splice(to, 0, reordered.splice(from, 1)[0]);
      const newSelectedIdx = reordered
        .map((s) => s._id)
        .indexOf(selectedSectionId);
      dispatch({
        type: actionTypes.TEST_SECTION_REORDERED,
        payload: {
          sections: reordered,
          selectedSectionIdx: newSelectedIdx,
        },
      });
      return axios
        .patch("/api/tests/" + _id + "/" + sectionId + "/reorder", {
          position: to,
        })
        .then((response) => {
          dispatch(onTestEditSuccess({}));
        })
        .catch((error) => {
          dispatch(onTestEditError(error));
        });
    }
  };
};

// TEST_EDITING, TEST_SECTION_REMOVED
export const onTestSectionRemove = (idx) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    const {
      details: { _id },
    } = getState().test;
    let sections = [...getState().test.sections];
    const sectionId = sections[idx]._id;
    return axios
      .patch("/api/tests/" + _id + "/" + sectionId + "/delete")
      .then((response) => {
        const { test } = response.data;
        dispatch(onTestEditSuccess({ test: test }));
      })
      .then(() => {
        let { selectedSectionId, selectedSectionIdx } = getState().test;
        sections = sections.filter((s, i) => idx !== i);
        if (idx === selectedSectionIdx) {
          selectedSectionIdx =
            sections.length === 0 ? null : idx === 0 ? idx : idx - 1;
          selectedSectionId =
            selectedSectionIdx || selectedSectionIdx === 0
              ? sections[selectedSectionIdx]._id
              : null;
        }
        dispatch({
          type: actionTypes.TEST_SECTION_REMOVED,
          payload: {
            sections: sections,
            selectedSectionIdx: selectedSectionIdx,
            selectedSectionId: selectedSectionId,
          },
        });
      })
      .catch((error) => {
        dispatch(onTestEditError(error));
      });
  };
};

// TEST_EDITING, TEST_QUESTION_ADDED, TEST_QUESTION_EDITED
export const onTestQuestionAddEdit = () => {
  return async (dispatch, getState) => {
    const { localQuestion } = getState().questionEditor;
    const { mode } = getState().questionEditor;
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    const {
      sections,
      selectedSectionIdx,
      selectedSectionId,
      details: { _id },
    } = getState().test;
    const { _id: questionId, ...localQuestion1 } = localQuestion;
    let questionsCopy = [...sections[selectedSectionIdx].questions];
    if (mode === "NEW_QUESTION") {
      return axios
        .patch(
          "/api/tests/" + _id + "/" + selectedSectionId + "/question",
          localQuestion1
        )
        .then((response) => {
          const { test, question } = response.data;
          if (question) {
            questionsCopy.push(question);
            dispatch(onTestEditSuccess({ test: test }));
          }
        })
        .then(() => {
          dispatch({
            type: actionTypes.TEST_QUESTION_ADDED,
            payload: {
              questions: questionsCopy,
            },
          });
          dispatch(onQuestionEditorClose());
        })
        .catch((error) => {
          dispatch(onTestEditError(error));
        });
    } else if (mode === "EDIT_QUESTION") {
      return axios
        .patch(
          "/api/tests/" +
            _id +
            "/" +
            selectedSectionId +
            "/" +
            localQuestion._id,
          localQuestion
        )
        .then((response) => {
          const { test, question } = response.data;
          if (question) {
            const idx = questionsCopy
              .map((q) => q._id)
              .indexOf(localQuestion._id);
            questionsCopy[idx] = localQuestion;
            dispatch(
              onTestEditSuccess({ test: test, message: "All changes saved" })
            );
          }
        })
        .then(() => {
          dispatch({
            type: actionTypes.TEST_QUESTION_EDITED,
            payload: {
              questions: questionsCopy,
            },
          });
          dispatch(onQuestionEditorClose());
        })
        .catch((error) => {
          dispatch(onTestEditError(error));
        });
    }
    // }
  };
};

// TEST_EDITING, TEST_QUESTION_ADDED
export const onTestQuestionDuplicate = (idx) => {
  return async (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    const {
      sections,
      selectedSectionIdx,
      selectedSectionId,
      details: { _id },
    } = getState().test;
    let questionsCopy = [...sections[selectedSectionIdx].questions];
    const question = questionsCopy[idx];
    // const { _id: questionId, text, ...other } = question;
    // const question1 = { text, ...other };
    const question1 = omitDeep(question, "_id");
    return axios
      .patch(
        "/api/tests/" +
          _id +
          "/" +
          selectedSectionId +
          "/question?pos=" +
          (idx + 1),
        question1
      )
      .then((response) => {
        const { test, question: newQ } = response.data;
        if (newQ) {
          questionsCopy.splice(idx + 1, 0, newQ);
          dispatch(onTestEditSuccess({ test: test }));
        }
      })
      .then(() => {
        dispatch({
          type: actionTypes.TEST_QUESTION_ADDED,
          payload: {
            questions: questionsCopy,
          },
        });
        dispatch(onQuestionEditorClose());
      })
      .catch((error) => {
        dispatch(onTestEditError(error));
      });
  };
};

// TEST_EDITING, TEST_QUESTION_REORDERED
export const onTestQuestionReorder = (from, to) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    const {
      sections,
      selectedSectionId,
      selectedSectionIdx,
      details: { _id },
    } = getState().test;
    const selectedSection = sections[selectedSectionIdx];
    const { questions } = selectedSection;
    const questionId = questions[from]._id;
    if (from === to) {
      return Promise.resolve();
    } else {
      let reordered = [...questions];
      reordered.splice(to, 0, reordered.splice(from, 1)[0]);
      dispatch({
        type: actionTypes.TEST_QUESTION_REORDERED,
        payload: { questions: reordered },
      });

      return axios
        .patch(
          "/api/tests/" +
            _id +
            "/" +
            selectedSectionId +
            "/" +
            questionId +
            "/reorder",
          {
            position: to,
          }
        )
        .then((response) => {
          dispatch(onTestEditSuccess({}));
        })
        .catch((error) => {
          dispatch(onTestEditError(error));
        });
    }
  };
};

// TEST_EDITING, TEST_QUESTION_REMOVED
export const onTestQuestionRemove = (idx) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    const {
      sections,
      selectedSectionId,
      selectedSectionIdx,
      details: { _id },
    } = getState().test;
    const selectedSection = sections[selectedSectionIdx];
    const { questions } = selectedSection;
    const questionId = questions[idx]._id;
    return axios
      .patch(
        "/api/tests/" +
          _id +
          "/" +
          selectedSectionId +
          "/" +
          questionId +
          "/delete"
      )
      .then((response) => {
        const { test } = response.data;
        dispatch(onTestEditSuccess({ test: test }));
      })
      .then(() => {
        const questionsCopy = questions.filter((q, i) => idx !== i);
        dispatch({
          type: actionTypes.TEST_QUESTION_REMOVED,
          payload: {
            questions: questionsCopy,
          },
        });
      })
      .catch((error) => {
        dispatch(onTestEditError(error));
      });
  };
};

// TEST_EDITING, TEST_SETTINGS_UPDATED
export const onTestSettingsUpdate = (settings) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    const {
      details: { _id },
      sections,
    } = getState().test;

    const { name, instructions, introImage, acknowledge, acknowledgeText } =
      settings;
    const { timeLimit, startTime, endTime } = settings;
    const { marking } = settings;
    const { layout } = settings;
    const { reviewMode, reviewWhen, reviewCustomWhen } = settings;

    const sectionsUpdated = copyTestMarkingDown(sections, marking);

    const body = {
      name,
      marking,
      intro: { instructions, introImage, acknowledge, acknowledgeText },
      times: { timeLimit, startTime, endTime },
      display: { layout },
      review: { reviewMode, reviewWhen, reviewCustomWhen },
      sections: sectionsUpdated,
    };
    const url = "/api/tests/" + _id + "/settings";
    return axios
      .patch(url, body)
      .then((response) => {
        const { test } = response.data;
        dispatch(
          onTestEditSuccess({ test: test, message: "All changes saved" })
        );
      })
      .then(() => {
        dispatch({
          type: actionTypes.TEST_SETTINGS_UPDATED,
          payload: { details: { name }, settings, sections: sectionsUpdated },
        });
      })
      .catch((error) => {
        dispatch(onTestEditError(error));
      });
  };
};

// TEST_EDITING, TEST_TAGS_UPDATED
export const onTestTagsUpdate = (tags) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    const {
      details: { _id },
    } = getState().test;

    const body = { tags };
    const url = "/api/tests/" + _id + "/tags";
    return axios
      .patch(url, body)
      .then((response) => {
        const { test } = response.data;
        dispatch(onTestEditSuccess({ test: test, message: "Changes saved" }));
      })
      .then(() => {
        dispatch({
          type: actionTypes.TEST_TAGS_UPDATED,
          payload: { tags },
        });
      })
      .catch((error) => {
        dispatch(onTestEditError(error));
      });
  };
};

// TEST_NAME_UPDATING, TEST_NAME_UPDATED
export const onTestNameUpdate = (_id, name) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_NAME_UPDATING,
    });
    const body = { name };
    const url = "/api/tests/" + _id + "/settings";
    return axios
      .patch(url, body)
      .then((response) => {
        dispatch(onTestEditSuccess({ message: "All changes saved" }));
      })
      .then(() => {
        dispatch({
          type: actionTypes.TEST_NAME_UPDATED,
          payload: { name, _id },
        });
      })
      .catch((error) => {
        console.log(error)
        dispatch(onTestEditError(error));
      });
  };
};

// TEST_EDITING, TEST_ASSIGNED
export const onTestAssign = (testId, emails) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    const url = "/api/assigns/" + testId;
    return axios
      .post(url, { emails })
      .then((response) => {
        dispatch(onTestEditSuccess({ message: "Test Assigned" }));
      })
      .then(() => {
        dispatch({
          type: actionTypes.TEST_ASSIGNED,
          payload: { testId },
        });
      })
      .catch((error) => {
        dispatch(onTestEditError(error));
      });
  };
};

// TEST_EDITING, TEST_OPEN_UPDATED
export const onTestOpenUpdate = (testId, open) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_EDITING,
    });
    const url = "/api/tests/" + testId + "/settings/";
    return axios
      .patch(url, { open })
      .then((response) => {
        const { test } = response.data;
        dispatch(onTestEditSuccess({ test: test, message: "Test Edited" }));
      })
      .then(() => {
        dispatch({
          type: actionTypes.TEST_OPEN_UPDATED,
          payload: { open },
        });
      })
      .catch((error) => {
        dispatch(onTestEditError(error));
      });
  };
};

/********************************/
/** Local State Change Actions **/
/********************************/
// TEST_NAME_UPDATED
// UI Only. Used when Test is first being created.
// Should drive db update if implemented separately
export const onTestNameChange = (name) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_NAME_UPDATED,
      payload: { name },
    });
  };
};

// SECTION_SELECTED
export const onSectionSelected = (idx) => {
  return (dispatch, getState) => {
    const { sections } = getState().test;
    const id = sections[idx]._id;
    dispatch({
      type: actionTypes.TEST_SECTION_SELECTED,
      payload: {
        selectedSectionId: id,
        selectedSectionIdx: idx,
        sectionSelectorOpen: false,
      },
    });
    return Promise.resolve();
  };
};

// QUESTION_EDITOR_CLOSE
export const onQuestionEditorClose = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUESTION_EDITOR_CLOSE,
    });
    return Promise.resolve();
  };
};

// SECTION_EDITOR_CLOSE
export const onSectionEditorClose = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SECTION_EDITOR_CLOSE,
    });
    return Promise.resolve();
  };
};

// TEST_ERROR_RESET
export const onTestErrorReset = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_ERROR_RESET,
    });
    return Promise.resolve();
  };
};

// Util Functions
const copyTestMarkingDown = (sections, marking) => {
  const sectionsUpdated = sections.map((section) => {
    const { markingFrom } = section;
    if (!markingFrom || markingFrom !== "SELF") {
      const { questions = [] } = section;
      const updatedQuestions = questions.map((question) => {
        const { markingFrom } = question;
        if (!markingFrom || markingFrom !== "SELF") {
          return { ...question, marking, markingFrom: "TEST" };
        } else return { ...question };
      });
      return {
        ...section,
        questions: updatedQuestions,
        marking,
        markingFrom: "TEST",
      };
    } else {
      return { ...section };
    }
  });
  return sectionsUpdated;
};

const copySectionMarkingDown = (questions, marking) => {
  const questionsUpdated = questions.map((question) => {
    const { markingFrom } = question;
    if (!markingFrom || markingFrom !== "SELF") {
      return {
        ...question,
        marking,
        markingFrom: "SECTION",
      };
    } else {
      return { ...question };
    }
  });
  return questionsUpdated;
};
