import React from "react";

import PropTypes from "prop-types";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, Typography } from "@material-ui/core";

import ColumnSelector from "./TestReviewColSelector";

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  header: {
    fontWeight: "600"
  },
  cell: { paddingLeft: "8px", paddingRight: "12px" }
}));

const EnhancedTableHead = (props) => {
  const classes = useStyles();

  const {
    handleSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    handleColumns,
    handlePresetColumns
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.cell}>
          {numSelected > 0 ? (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              disabled={rowCount === 0}
              onChange={handleSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
              className={classes.checkbox}
            />
          ) : (
            <div className={classes.colSelector}>
              <ColumnSelector
                columns={columns}
                handleColumns={handleColumns}
                handlePresetColumns={handlePresetColumns}
              />
            </div>
          )}
        </TableCell>
        {columns.map((column) =>
          column.show ? (
            <TableCell
              key={column.id}
              align={column.align ? "left" : "left"}
              className={classes.cell}
              sortDirection={orderBy === column.sortid ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.sortid}
                direction={orderBy === column.sortid ? order : "asc"}
                onClick={createSortHandler(column.sortid)}
              >
                <Typography variant="body2" className={classes.header}>
                  {column.label}
                </Typography>
                {orderBy === column.sortid ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  handleSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default EnhancedTableHead;
