import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  makeStyles,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import { onTestCreate } from "../../../store/actions";
import { validate } from "../../../shared/utils/validator";
import { Add as AddIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  addBtn: {
    textTransform: "none",
    // border: "solid 1px"
  },
  addBtnIcon: {
    border: "solid 2px",
    borderColor: theme.palette.primary.main,
    borderRadius: "24px",
  },
}));

const TestsHeader = (props) => {
  const classes = useStyles();

  // const breakpoint = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const { onTestCreate } = props;
  const history = useHistory();
  const location = useLocation();

  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("My New Test");

  const breakpoint = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setError("");
    setOpen(false);
    // history.goBack();
  };

  const handleNameChange = async (event) => {
    error && setError("");
    setName(event.target.value);
  };

  const handleTestCreate = async () => {
    const nameErrorMsg = await validate(name, {
      required: [true, "A valid name is required"],
    });
    if (nameErrorMsg) {
      setError(nameErrorMsg);
    } else {
      const newTestId = await onTestCreate(name || "My New Test");
      if (newTestId) history.push("/q/test/" + newTestId);
    }
  };

  // const handleTestCreateDirect = async (event) => {
  //   const newTestId = await onTestCreate("My New Test");
  //   if (newTestId) history.push("/q/test/" + newTestId);
  // };

  useEffect(() => {
    if (location.state && location.state.createOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location]);

  return (
    <React.Fragment>
      {!breakpoint ? (
        <Button
          color="primary"
          aria-label="add"
          onClick={handleDialogOpen}
          size="medium"
          variant="contained"
          className={classes.addBtn}
          disableFocusRipple
          startIcon={<AddIcon />}
        >
          New Test
        </Button>
      ) : (
        <Tooltip title="New Test">
          <IconButton onClick={handleDialogOpen} color="primary">
            <AddIcon className={classes.addBtnIcon} />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullScreen={!breakpoint}
      >
        <DialogTitle id="alert-dialog-title">Create New</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="name"
            label="Name"
            type="text"
            fullWidth
            helperText={error ? error : null}
            value={name}
            error={!!error}
            onChange={handleNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} style={{ textTransform: "none" }}>
            Cancel
          </Button>
          <Button
            onClick={handleTestCreate}
            autoFocus
            color="primary"
            variant="contained"
            style={{ textTransform: "none" }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default connect(null, { onTestCreate })(TestsHeader);
