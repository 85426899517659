import React, { useContext } from "react";
import {
  makeStyles,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio
} from "@material-ui/core";
import moment from "moment";

import { TestSettingsContext } from "../../context/test-settings-context";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "20px"
  },
  layoutSelect: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  formControl: {
    width: "100%"
  },
  option: { padding: "10px 0" },
  body2: theme.typography.body2,
  dateTimeInput: {
    flexGrow: 1
  }
}));

const TestDisplaySettings = (props) => {
  const classes = useStyles();

  const context = useContext(TestSettingsContext);
  const { fields, funcs } = context;
  const { reviewMode, reviewWhen, reviewCustomWhen, endTime } = fields;
  const { handleReviewMode, handleReviewWhen, handleReviewCustomWhen } = funcs;

  const handleReviewWhen1 = (value) => {
    handleReviewWhen(value);
    if (value === "custom" && !reviewCustomWhen)
      handleReviewCustomWhen(moment());
  };

  const CustomWhenPicker = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          classes={{ root: classes.dateTimeInput }}
          disablePast
          showTodayButton
          format="MMM dd, yyyy hh:mm aa"
          margin="none"
          id="datetime-picker"
          value={reviewCustomWhen}
          minDate={endTime}
          onChange={(val) => {
            handleReviewCustomWhen(val);
            handleReviewWhen("custom");
          }}
          // error={!!endTimeErrorMsg}
          // helperText={endTimeErrorMsg ? endTimeErrorMsg : ""}
          placeholder="Select time"
          InputProps={{ className: classes.body2 }}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const subRadioGroup = () => {
    return (
      <div style={{ paddingLeft: "30px" }}>
        <RadioGroup
          name="when"
          value={reviewWhen}
          onChange={(e) => handleReviewWhen1(e.target.value)}
        >
          <FormControlLabel
            value="immediate"
            control={<Radio />}
            label="Immediately after submission"
            classes={{ label: classes.body2 }}
          />
          <FormControlLabel
            value="endtime"
            control={<Radio />}
            label={
              "After 'End Time' " +
              (endTime
                ? "(" + moment(endTime).format("MMM DD, YYYY hh:mm A") + ")"
                : "")
            }
            classes={{ label: classes.body2 }}
            disabled={!endTime}
          />
          <FormControlLabel
            value="custom"
            control={<Radio />}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  className={classes.body2}
                  style={{ marginRight: "5px" }}
                >
                  After:
                </Typography>
                <CustomWhenPicker />
              </div>
            }
          />
        </RadioGroup>
      </div>
    );
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.section}>
          <Typography style={{ fontWeight: 600 }}>Respondents can:</Typography>
          <RadioGroup
            name="mode"
            value={reviewMode}
            onChange={(e) => handleReviewMode(e.target.value)}
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              label="See own responses with scores and answers"
              className={classes.option}
              classes={{ label: classes.body2 }}
            />
            {reviewMode === "all" ? subRadioGroup() : null}

            <FormControlLabel
              value="scores"
              control={<Radio />}
              label="See own scores"
              className={classes.option}
              classes={{ label: classes.body2 }}
            />
            {reviewMode === "scores" ? subRadioGroup() : null}
            <FormControlLabel
              value="none"
              control={<Radio />}
              label="Cannot see scores and answers"
              className={classes.option}
              classes={{ label: classes.body2 }}
            />
          </RadioGroup>
        </div>
      </div>
    </>
  );
};

export default TestDisplaySettings;
