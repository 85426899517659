import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import { ArrowDropDown, Public, People } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";

import {
  makeStyles,
  Avatar,
  List,
  ListItem,
  Container
} from "@material-ui/core";
import { ButtonWithPopover } from "../../../shared/hoc/withPopover";
import { onTestAssign, onTestOpenUpdate } from "../../../store/actions";
import ContentDialog from "../../../shared/components/Modals/ContentDialog1";
import ChipInputWrapper from "../../../shared/components/UIElements/ChipInputWrapper";

const AssignTestDialog = (props) => {
  const classes = makeStyles((theme) => ({
    icon: { height: "30px", width: "30px" },
    iconSelected: { backgroundColor: theme.palette.primary.main },
    sectionTitleText: { marginLeft: "10px" },
    sectionTitle: {
      display: "flex",
      alignItems: "center",
      marginBottom: "6px"
    },
    linkOuter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      maxWidth: "100%",
      margin: "12px 0"
    },
    link: {
      flex: 1,
      marginLeft: "10px",
      backgroundColor: "lightgrey",
      padding: "8px 8px",
      borderRadius: "4px",
      minWidth: 0
    },
    link1: { backgroundColor: "lightblue" },
    linkText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      minWidth: 0
    },
    dropdown: { textTransform: "none", fontWeight: 600 },
    copyBtn: {
      backgroundColor: theme.palette.background.light,
      color: theme.palette.primary.main,
      textTransform: "none",
      "&:hover": { backgroundColor: theme.palette.background.light },
      width: "110px"
    }
  }))();

  const [restrict, setRestrict] = useState(true);
  const [pop, setPop] = useState(false);

  const [emails, setEmails] = useState([]);

  const {
    testAssignOpen,
    handleAssignOpen,
    testId,
    isTestPublic,
    onTestAssign,
    onTestOpenUpdate
  } = props;

  const testLink = window.location.origin + "/test/" + testId;
  const linkRef = React.createRef();

  useEffect(() => {
    setRestrict(!isTestPublic);
  }, [isTestPublic]);

  const handleRestrict = (value) => {
    setRestrict(value);
  };

  const handleCopy = (ref) => {
    navigator.clipboard.writeText(ref.current.innerHTML).catch((err) => {
      // This can happen if the user denies clipboard permissions:
      console.log("Link could not be copied");
    });
  };

  const handleAssignClose = (event) => {
    handleAssignOpen(false);
    setEmails([]);
    setRestrict(!isTestPublic);
  };

  const handleEmailsChange = (emails) => {
    setEmails(emails);
  };

  const handleSave = () => {
    if (restrict && emails.length > 0) {
      onTestAssign(testId, emails);
    }
    if (!restrict === isTestPublic) {
      handleAssignClose();
    } else {
      onTestOpenUpdate(testId, !restrict);
      handleAssignClose();
    }
  };

  return (
    <React.Fragment>
      <ContentDialog
        open={testAssignOpen}
        dialogTitle={"Assign test"}
        actionText="Save"
        handleClose={handleAssignClose}
        handleAction={handleSave}
        // closeDisabled={props.nav.spinner}
        // actionDisabled={props.nav.spinner}
      >
        <Container style={{ padding: 0 }}>
          <div className={classes.sectionTitle}>
            <Avatar
              className={clsx(
                classes.icon,
                !restrict ? classes.iconSelected : null
              )}
            >
              {restrict ? (
                <People fontSize="small" />
              ) : (
                <Public fontSize="small" />
              )}
            </Avatar>
            <Typography className={classes.sectionTitleText} variant="h6">
              Test is
            </Typography>
            <ButtonWithPopover
              // size="small"
              color="primary"
              className={classes.dropdown}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              disableFocusRipple
              key={pop}
              popover={
                <List>
                  <ListItem
                    button
                    onClick={(e) => {
                      if (!restrict) {
                        handleRestrict(true);
                        // onTestOpenUpdate(testId, false);
                      }
                      setPop(!pop);
                    }}
                  >
                    <Typography>Private</Typography>
                  </ListItem>
                  <ListItem
                    button
                    onClick={(e) => {
                      if (restrict) {
                        handleRestrict(false);
                        // onTestOpenUpdate(testId, true);
                      }
                      setPop(!pop);
                    }}
                  >
                    <Typography>Public</Typography>
                  </ListItem>
                </List>
              }
            >
              <Typography variant="h6">
                {restrict ? "Private" : "Public"}
              </Typography>
              <ArrowDropDown />
            </ButtonWithPopover>
          </div>
          {!restrict ? (
            <Typography variant="body2">
              Anyone with the link can attempt the test (login required)
            </Typography>
          ) : (
            <Typography variant="body2">
              Only people you have assigned the test to will be able to attempt.
              Add email addresses below to assign the test.
            </Typography>
          )}
          {restrict ? (
            <>
              <ChipInputWrapper
                showCount
                classes={{ chipInput: classes.chipInput }}
                handleChange={handleEmailsChange}
                values={emails}
                helperDuplicate="Duplicates were removed"
                helperError="Entries are missing or not correctly formatted"
                helperInput="Enter emails separated by a comma or space"
              />
            </>
          ) : null}

          <div className={classes.linkOuter}>
            <Button
              onClick={(e) => handleCopy(linkRef)}
              className={classes.copyBtn}
              disableFocusRipple
            >
              Copy test link
            </Button>
            <div
              className={clsx(classes.link, restrict ? null : classes.link1)}
            >
              <Typography
                ref={linkRef}
                variant="body2"
                className={classes.linkText}
              >
                {testLink}
              </Typography>
            </div>
          </div>
        </Container>
      </ContentDialog>
    </React.Fragment>
  );
};

// export default AssignTestDialog;
export default connect(null, { onTestAssign, onTestOpenUpdate })(
  AssignTestDialog
);
