import React from "react";
// import { useRef, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import ContentDialog1 from "../../../shared/components/Modals/ContentDialog1";

import { onQuestionEditorClose } from "../../../store/actions";

import QuestionBase from "./QuestionBase";
import QuestionLayout from "../QuestionType/QuestionLayout";
// import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  questionEditor: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column"
  }
}));

const QuestionEditor = React.memo((props) => {
  const { onQuestionEditorClose } = props;
  const classes = useStyles();

  const {
    handleQuestionEdited,
    // handleQuestionEditorLoad,
    sectionMarking
  } = props;
  const {
    localQuestion: { type },
    open,
    mode
  } = props.questionEditor;

  // const history = useHistory();
  // const location = useLocation();
  // const editorRef = useRef();

  const handleClose = (event) => {
    return onQuestionEditorClose();
  };

  const handleAction = async (event) => {
    return handleQuestionEdited();
  };

  // useEffect(() => {
  //   if (open) {
  //     if (
  //       !editorRef.current &&
  //       !(location.state && location.state.questionSettings)
  //     ) {
  //       history.push(location.pathname, { questionSettings: true });
  //       editorRef.current = true;
  //     } else if (!(location.state && location.state.questionSettings)) {
  //       handleClose();
  //       editorRef.current = false;
  //     }
  //   } else {
  //     if (
  //       editorRef.current &&
  //       location.state &&
  //       location.state.questionSettings
  //     ) {
  //       history.goBack();
  //       editorRef.current = false;
  //     } else if (location.state && location.state.questionSettings) {
  //       // handleQuestionEditorLoad(id);
  //       editorRef.current = true;
  //     }
  //   }
  // });

  return (
    <ContentDialog1
      open={open}
      dialogTitle={mode === "NEW_QUESTION" ? "New Question" : "Edit Question"}
      actionText="Save"
      handleClose={handleClose}
      handleAction={handleAction}
      closeDisabled={props.nav.spinner}
      actionDisabled={props.nav.spinner}
    >
      <div className={classes.questionEditor}>
        <QuestionBase sectionMarking={sectionMarking}>
          <QuestionLayout type={type} />
        </QuestionBase>
      </div>
    </ContentDialog1>
  );
});

const mapStateToProps = (state) => {
  return {
    questionEditor: state.questionEditor,
    nav: state.blockNav
  };
};

export default connect(mapStateToProps, {
  onQuestionEditorClose
})(QuestionEditor);
