import React from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Typography } from "@material-ui/core";

import { questionTypes } from "./questionTypes";

const questionTypesArr = Object.values(questionTypes);

const QuestionType = (props) => {
  const classes = makeStyles((theme) => ({
    typeSelector: {
      display: "flex",
      alignItems: "flex-end"
    },
    selectFormControl: {
      flexGrow: 1
    },
    selectRoot: {
      display: "flex",
      alignItems: "center",
      paddingTop: "12px",
      paddingBottom: "12px"
    },
    selectText: {
      minWidth: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    selectLabel: {
      paddingLeft: "14px",
      margin: "-8px 5px 0 5px",
      marginTop: "-8px"
    },
    menu: {
      color: theme.palette.text.primary
    }
  }))();

  const { handleTypeChange, type, classes: classesProp } = props;

  return (
    <div className={clsx(classesProp.root)}>
      <div className={clsx(classes.typeSelector)}>
        <FormControl className={classes.selectFormControl}>
          <InputLabel
            className={classes.selectLabel}
            id="simple-select-label"
            shrink
          >
            Question Type
          </InputLabel>
          <Select
            classes={{ root: classes.selectRoot }}
            variant="outlined"
            label="Question Type"
            labelId="simple-select-label"
            id="simple-select"
            value={type || "MCQSS"}
            onChange={handleTypeChange}
          >
            {questionTypesArr.map((qtype) => {
              const IconComponent = qtype.icon;
              return (
                <MenuItem
                  key={"qtype" + qtype.id}
                  value={qtype.type}
                  className={classes.menu}
                >
                  <IconComponent
                    fontSize="small"
                    style={{ marginRight: "5px" }}
                  />
                  <Typography className={classes.selectText}>
                    {qtype.label}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default React.memo(QuestionType);
