import React, { useContext, useEffect } from "react";

import { MenuContext } from "../../context/menu-context";

const NoSideNav = (props) => {
  const { showSideNav } = useContext(MenuContext);

  useEffect(() => {
    showSideNav(false);
  }, [showSideNav]);

  return <></>;
};

export default NoSideNav;
