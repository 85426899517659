import * as actionTypes from "../actionTypes";
import axios from "../../shared/utils/axios";

import { onTestFetchSuccess } from "./testActions";

export const onReviewTestFetch2 = (testId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REVIEW_TEST_FETCHING
    });
    return axios
      .get("/api/assigns/v2/test/" + testId)
      .then((response) => {
        const { assigns, test } = response.data;
        const newAssigns = assigns.map((a) => {
          if (a.lastSubmit && a.lastSubmit.answers) {
            a.lastSubmit.answers.sections = a.lastSubmit.answers.sections.reduce(
              (sobj, s) => {
                s.questions = s.questions.reduce((qobj, q) => {
                  return { ...qobj, [q.questionId]: q };
                }, {});
                return { ...sobj, [s.sectionId]: s };
              },
              {}
            );
          }
          return a;
        });
        const access = response.headers["x-object-access"];
        dispatch(onTestFetchSuccess(test, access));
        dispatch(
          onReviewTestFetchSuccess({ assigns: newAssigns, testId: test._id })
        );
      })
      .catch((error) => {
        dispatch(onReviewTestFetchError(error));
      });
  };
};

export const onReviewTestFetchSuccess = (data) => {
  const { assigns, testId } = data;
  return (dispatch) => {
    dispatch({
      type: actionTypes.REVIEW_TEST_FETCH_SUCCESS,
      payload: { assigns, testId }
    });
  };
};

export const onReviewTestFetchError = (error) => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.REVIEW_TEST_FETCH_ERROR,
      payload: error || "An unknown error ocurred."
    });
};

export const onReviewTestUnassign = (submits) => {
  return (dispatch, getState) => {
    const assignIds = submits.map((o) => o.assignId);
    const { assigns } = getState().reviewtest;
    const newAssigns = assigns.filter((a) => {
      return !assignIds.includes(a._id);
    });
    dispatch({
      type: actionTypes.REVIEW_TEST_UNASSIGNING,
      payload: { assigns: newAssigns }
    });
    return axios
      .patch("/api/assigns/remove/", { assignIds })
      .then((response) => {
        dispatch(onReviewTestUnassignSuccess({ assigns: newAssigns }));
      })
      .catch((error) => {
        dispatch(onReviewTestUnassignError({ assigns }));
      });
  };
};

export const onReviewTestUnassignSuccess = ({ assigns }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REVIEW_TEST_UNASSIGN_SUCCESS
      // payload: { assigns }
    });
  };
};

export const onReviewTestUnassignError = ({ assigns }) => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.REVIEW_TEST_UNASSIGN_ERROR,
      payload: { assigns }
    });
};

export const onReviewTestSubmitsDiscard = (assignSubmitArr) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.REVIEW_TEST_SUBMIT_DISCARDING
    });
    return axios
      .patch("/api/assigns/removeSubmits/", assignSubmitArr)
      .then((response) => {
        dispatch(
          onReviewTestSubmitsDiscardSuccess({ assigns: response.data.assigns })
        );
      })
      .catch((error) => {
        dispatch(onReviewTestSubmitsDiscardError());
      });
  };
};

export const onReviewTestSubmitsDiscardSuccess = ({ assigns }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REVIEW_TEST_SUBMIT_DISCARD_SUCCESS,
      payload: { assigns }
    });
  };
};

export const onReviewTestSubmitsDiscardError = () => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.REVIEW_TEST_SUBMIT_DISCARD_ERROR
    });
};

export const onReviewSubmitFetch = (submitId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REVIEW_SUBMIT_FETCHING
    });
    return axios
      .get("/api/submits/v2/" + submitId)
      .then((response) => {
        const { submit } = response.data;
        const { answers } = submit;
        answers.sections = answers.sections.reduce((sobj, s) => {
          s.questions = s.questions.reduce((qobj, q) => {
            return { ...qobj, [q.questionId]: q };
          }, {});
          return { ...sobj, [s.sectionId]: s };
        }, {});
        dispatch(onReviewSubmitFetchSuccess(submit));
      })
      .catch((error) => {
        dispatch(onReviewSubmitFetchError(error));
      });
  };
};

export const onReviewSubmitFetchSuccess = (submit) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REVIEW_SUBMIT_FETCH_SUCCESS,
      payload: { submit: submit }
    });
  };
};

export const onReviewSubmitFetchError = (error) => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.REVIEW_SUBMIT_FETCH_ERROR,
      payload: error || "An unknown error ocurred."
    });
};

// export const onReviewSubmitDelete = () => {
//   return (dispatch, getState) => {
//     dispatch({
//       type: actionTypes.REVIEW_SUBMIT_DELETING
//     });
//     const { submit } = getState().reviewsubmit;
//     return axios
//       .delete("/api/submits/" + submit._id)
//       .then((response) => {
//         if (response.status === 200) {
//           console.log("Submit deleted succesfully");
//           return Promise.resolve();
//         } else {
//           console.log("Submit could not be deleted");
//           return Promise.reject(response);
//         }
//       })
//       .then(() => {
//         dispatch({
//           type: actionTypes.SUBMIT_DELETE_SUCCESS
//         });
//         return Promise.resolve();
//       })
//       .catch((error) => {
//         console.log("Submit could not be deleted");
//         console.log("Error", error.message);
//         dispatch({
//           type: actionTypes.SUBMIT_DELETE_ERROR
//         });
//         return Promise.reject(error.message);
//       });
//   };
// };
