import React from "react";
import * as Sentry from "@sentry/react";

import Fallback from "./Fallback";

const ErrorBoundary = (props) => {
  return (
    <Sentry.ErrorBoundary fallback={<Fallback />}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
