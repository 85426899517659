import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext
} from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Checkbox, TextField, Radio } from "@material-ui/core";

import ImageProvider from "../../../shared/components/UIElements/ImageProvider";
import ImageReceiver from "../../../shared/components/UIElements/ImageReceiver";

import {
  onOptionTextChange,
  onOptionCorrectChange,
  onOptionImageChange
} from "../../../store/actions";

import { BlockNavContext } from "../../../shared/context/blocknav-context";

const useStyles = makeStyles((theme) => ({
  mcqOptionRoot: {
    marginBottom: "10px",
    width: "100%"
  },
  startAdorn: {
    height: "32px",
    color: theme.palette.grey[500],
    marginRight: "12px"
  },
  endAdorn: {
    height: "56px",
    marginRight: "-14px",
    alignItems: "center"
  },
  optionActions: {
    // width: "15%",
    // minWidth: "90px",
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const QuestionOption = (props) => {
  const {
    onOptionTextChange,
    onOptionCorrectChange,
    onOptionImageChange,
    handleMoveFocus,
    handleOptionDelete
  } = props;

  const classes = useStyles();
  const textRef = useRef();

  const { block, onBlock } = useContext(BlockNavContext);

  const { option, idx, numOptions, isCorrect, type } = props;
  useEffect(() => {
    if (textRef.current) {
      if (!option.text) {
        onOptionTextChange(idx, "Option " + (idx + 1));
      } else textRef.current.value = option.text;
    }
  });

  // useEffect(() => {
  //   if (textRef.current) {
  //     textRef.current.onFocus = textRef.current.select();
  //   }
  // });

  const [optionImageFile, setOptionImageFile] = useState([]);
  const handleOptionImageFile = (file, name) => {
    const index = name.slice(6) * 1;
    let optionImageFileArr;
    if (optionImageFile) {
      optionImageFileArr = [...optionImageFile];
      optionImageFileArr[index] = file;
    }
    setOptionImageFile(optionImageFileArr);
  };

  const handleOptionTextChange = (idx) => (event) => {
    onOptionTextChange(idx, event.target.value);
  };

  const handleOptionTextLocalChange = () => {
    // if (!block) onBlock(true);
  };

  const handleOptionCorrectChange = (idx) => (event) => {
    onOptionCorrectChange(idx).then(() => {
      if (!block) onBlock(true);
    });
  };

  const handleOptionImageChange = useCallback(
    (image, name) => {
      const index = name.slice(6) * 1;
      onOptionImageChange(index, image).then(() => {
        if (!block) onBlock(true);
      });
    },
    [onOptionImageChange, block, onBlock]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        // justifyContent: "flex-start",
        width: "100%"
      }}
    >
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        {type === "MCQSS" ? (
          <Radio
            color="primary"
            checked={isCorrect}
            onChange={handleOptionCorrectChange(idx)}
          />
        ) : (
          <Checkbox
            color="primary"
            checked={isCorrect}
            onChange={handleOptionCorrectChange(idx)}
          />
        )}
        <TextField
          classes={{ root: classes.mcqOptionRoot }}
          required
          id={`option-${idx}`}
          InputProps={{
            // startAdornment: sequence ?
            // (
            //   <InputAdornment position="start" className={classes.startAdorn}>
            //     <OptionsSequence
            //       sequence={sequence}
            //       handleSequenceSelect={handleSequenceChange}
            //       index={idx}
            //     />
            //     <Divider orientation="vertical" flexItem />
            //   </InputAdornment>
            // )
            // : null,

            // endAdornment: (
            //   <InputAdornment position="end" className={classes.endAdorn}>
            //     <IconButton
            //       onClick={handleOptionCorrectChange(idx)}
            //       aria-label="check"
            //       color={isCorrect ? "primary" : "default"}
            //     >
            //       <CheckCircleIcon fontSize="default" />
            //     </IconButton>
            //   </InputAdornment>
            // ),
            inputProps: {
              onFocus: () => {
                textRef.current.select();
              }
            },
            autoComplete: "off"
          }}
          inputRef={textRef}
          defaultValue={option.text}
          onBlur={handleOptionTextChange(idx)}
          onChange={handleOptionTextLocalChange}
          autoComplete="off"
          onKeyDown={(event) => {
            if (event.key === "ArrowUp" || event.key === "ArrowDown")
              event.preventDefault();
            handleMoveFocus(idx, event);
          }}
        />
        <ImageProvider
          name={`option${idx}`}
          handleImageFile={handleOptionImageFile}
        />
        {idx === 0 && numOptions === 1 ? null : (
          <IconButton onClick={handleOptionDelete(idx)} aria-label="delete">
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      <ImageReceiver
        imageObj={optionImageFile[idx]}
        image={option.image}
        name={`option${idx}`}
        controls={false}
        maxWidth={100}
        handleImageChange={handleOptionImageChange}
      />
    </div>
  );
};

export default connect(null, {
  onOptionTextChange,
  onOptionCorrectChange,
  onOptionImageChange
})(React.memo(QuestionOption));
