import * as actionTypes from "../actionTypes";

// DISPLAY_SNACK_CLOSE
export const onDisplaySnackClose = () => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.TEST_SNACK_CLOSE });
  };
};

// TEST_BLOCK_NAV
export const onTestBlockNav = (value) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_BLOCK_NAV,
      payload: { shouldBlockNav: value }
    });
  };
};
// NETWORK_ERROR
export const onNetworkError = (value) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.NETWORK_ERROR,
      payload: { networkError: value }
    });
  };
};
// 404 Unauthorized Error
export const on404Error = (value) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.NOT_FOUND_ERROR,
      payload: { notFoundError: value }
    });
  };
};
