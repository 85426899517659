import React, { useEffect, useContext } from "react";
import { connect } from "react-redux";
import {
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";

import CardLoading from "../../shared/components/UIElements/CardLoading1";
import NoTests from "../components/Tests/NoTests";
import TestItem from "../components/Tests/TestItem";
// import AssignEditor from "../components/Assign/AssignEditor";
// import { Linear } from "../../shared/components/UIElements/Progress";
import { TestSearchContext } from "../../shared/context/test-search-context";

import {
  onTestsFetchMore,
  onTestRemove,
  onTestDuplicate,
} from "../../store/actions";

const useStyles = makeStyles((theme) => ({
  testListDiv: {
    margin: "20px 20px 100px 20px",
    overflow: "auto",
    position: "relative",
  },
  clearSearchBtn: {
    textTransform: "none",
    backgroundColor: theme.palette.button.default,
    border: "none",
    margin: "0px 15px",
  },
  clearSearchDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

const TestsList = (props) => {
  const { tests, loadMore } = props.tests;
  const { loading } = props.nav;
  const classes = useStyles();
  const {
    onTestsFetchMore,
    onTestDuplicate,
    // onTestRemove
  } = props;

  const { showResults, results, handleShowResults, handleResults } =
    useContext(TestSearchContext);

  // const [testAssignOpen, setTestAssignOpen] = useState(false);
  // const [testIdToAssign, setTestIdToAssign] = useState(null);

  // const handleTestAssignOpen = (value) => {
  //   setTestAssignOpen(value);
  //   if (!value) setTestIdToAssign(null);
  // };

  // const handleTestIdToAssign = (testId) => {
  //   setTestIdToAssign(testId);
  // };

  // const handleTestDelete = (id) => {
  //   onTestRemove(id);
  // };

  const handleTestDuplicate = (id) => {
    onTestDuplicate(id);
  };

  useEffect(() => {
    if ((!tests || tests.length === 0) && loadMore) onTestsFetchMore();
  }, [onTestsFetchMore, tests, loadMore]);

  return (
    <React.Fragment>
      {/* {spinner || (loading && tests) ? <Linear /> : null} */}
      <div className={classes.testListDiv}>
        {loading && (!tests || tests.length === 0) ? (
          <CardLoading count={5} />
        ) : (
          <>
            {showResults ? (
              <div className={classes.clearSearchDiv}>
                <Typography variant="body2">Showing search results</Typography>
                <Button
                  variant="outlined"
                  color="default"
                  size="small"
                  disableRipple
                  disableFocusRipple
                  onClick={() => {
                    handleShowResults(false);
                    handleResults([]);
                  }}
                  className={classes.clearSearchBtn}
                >
                  Clear
                </Button>
              </div>
            ) : null}
            {showResults ? (
              <>
                {results.map((test, idx) => (
                  <TestItem
                    test={test}
                    key={test._id}
                    idx={idx}
                    handleTestDuplicate={handleTestDuplicate}
                    // handleTestDelete={handleTestDelete}
                    // handleTestIdToAssign={handleTestIdToAssign}
                    // handleTestAssignOpen={handleTestAssignOpen}
                  />
                ))}
                {results.length === 0 ? (
                  <Paper elevation={2} style={{ marginTop: "20px" }}>
                    <Typography>
                      No resources match the search criteria
                    </Typography>
                  </Paper>
                ) : null}
              </>
            ) : tests && tests.length > 0 ? (
              <InfiniteScroll
                dataLength={tests.length}
                next={() => {
                  onTestsFetchMore({ skip: tests.length });
                }}
                hasMore={loadMore}
                loader={<CircularProgress size={20} />}
              >
                {tests.map((test, idx) => (
                  <TestItem
                    test={test}
                    key={test._id + idx}
                    idx={idx}
                    handleTestDuplicate={handleTestDuplicate}
                    // handleTestDelete={handleTestDelete}
                    // handleTestIdToAssign={handleTestIdToAssign}
                    // handleTestAssignOpen={handleTestAssignOpen}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <NoTests message={<>Start by creating a test.</>} />
            )}
            {/* <AssignEditor
              testId={testIdToAssign}
              testAssignOpen={testAssignOpen}
              handleAssignOpen={handleTestAssignOpen}
            /> */}
            {/* {spinner || (loading && tests) ? <Spinner /> : null} */}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tests: state.tests,
    nav: state.blockNav,
  };
};

export default connect(mapStateToProps, {
  onTestsFetchMore,
  onTestRemove,
  onTestDuplicate,
})(TestsList);
