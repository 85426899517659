import * as actionTypes from "../actionTypes";
import update from "immutability-helper";

const initialState = {
  error: false,
  loading: false,
  selectedSectionId: null,
  selectedSectionIdx: null,
  details: {}, // name, _id, numQuestions, numSections
  settings: {},
  sections: [], // name, text, questions[], numquestions
  assigns: [],
  tags: [],
  readOnly: false,
  acl: []
};

const test = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TEST_FETCHING:
      return { ...state, error: false, loading: true };
    case actionTypes.TEST_FETCH_SUCCESS: {
      const {
        sections = [],
        marking = {},
        intro = {},
        times = {},
        tags = [],
        display = {},
        review = {},
        acl = [],
        ...details
      } = action.payload.test;
      const settings = { marking, ...intro, ...times, ...display, ...review };
      const selectedSectionIdx = sections.length > 0 && 0;
      const selectedSectionId = sections.length > 0 && sections[0]._id;
      const readOnly = !["edit", "full"].includes(action.payload.access);
      return {
        ...state,
        details,
        settings,
        selectedSectionIdx,
        selectedSectionId,
        sections,
        tags,
        acl,
        readOnly,
        loading: false
      };
    }
    case actionTypes.TEST_FETCH_ERROR:
      return { ...state, error: action.payload.error, loading: false };
    case actionTypes.TEST_EDITING:
      return { ...state };
    case actionTypes.TEST_EDIT_SUCCESS:
      return { ...state };
    case actionTypes.TEST_EDIT_ERROR:
      return { ...state };
    case actionTypes.TEST_SHARED:
      return { ...state, acl: action.payload.acl };
    case actionTypes.TEST_SECTION_ADDED:
      return {
        ...state,
        sections: action.payload.sections,
        selectedSectionIdx: action.payload.selectedSectionIdx,
        selectedSectionId: action.payload.selectedSectionId
      };
    case actionTypes.TEST_SECTION_EDITED:
      return {
        ...state,
        sections: action.payload.sections
      };
    case actionTypes.TEST_SECTION_REORDERED:
      return { ...state, ...action.payload };
    case actionTypes.TEST_SECTION_REMOVED:
      return {
        ...state,
        sections: action.payload.sections,
        selectedSectionIdx: action.payload.selectedSectionIdx,
        selectedSectionId: action.payload.selectedSectionId
      };
    case actionTypes.TEST_SETTINGS_UPDATED: {
      const { details, ...other } = action.payload;
      return { ...state, details: { ...state.details, ...details }, ...other }; //settings and sections
    }
    case actionTypes.TEST_TAGS_UPDATED: {
      return { ...state, tags: action.payload.tags };
    }
    case actionTypes.TEST_ASSIGNED:
      return { ...state, assignsExist: true };
    case actionTypes.TEST_QUESTION_REORDERED: {
      const sections = update(state.sections, {
        [state.selectedSectionIdx]: {
          questions: { $set: action.payload.questions }
        }
      });
      return {
        ...state,
        sections: sections
      };
    }
    case actionTypes.TEST_QUESTION_ADDED: {
      const sections = update(state.sections, {
        [state.selectedSectionIdx]: {
          questions: { $set: action.payload.questions }
        }
      });
      return {
        ...state,
        sections: sections
      };
    }
    case actionTypes.TEST_QUESTION_EDITED: {
      const sections = update(state.sections, {
        [state.selectedSectionIdx]: {
          questions: { $set: action.payload.questions }
        }
      });
      return {
        ...state,
        sections: sections
      };
    }
    case actionTypes.TEST_QUESTION_REMOVED: {
      const sections = update(state.sections, {
        [state.selectedSectionIdx]: {
          questions: { $set: action.payload.questions }
        }
      });
      return {
        ...state,
        sections: sections
      };
    }
    case actionTypes.TEST_NAME_UPDATED: {
      return {
        ...state,
        details: { ...state.details, name: action.payload.name }
      };
    }
    case actionTypes.TEST_OPEN_UPDATED: {
      return {
        ...state,
        details: { ...state.details, open: action.payload.open }
      };
    }
    case actionTypes.TEST_SECTION_SELECTED:
      return {
        ...state,
        selectedSectionId: action.payload.selectedSectionId,
        selectedSectionIdx: action.payload.selectedSectionIdx
      };
    case actionTypes.TEST_ERROR_RESET:
      return {
        ...state,
        error: false
      };
    default:
      return state;
  }
};

export default test;
