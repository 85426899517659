import React, { useEffect } from "react";
import { connect } from "react-redux";

import moment from "moment";

import { Redirect } from "react-router-dom";

import { makeStyles, Typography } from "@material-ui/core";

import SubmitReviewLayout from "../components/Review/SubmitReviewLayout";
import { onReviewSubmitFetch } from "../../store/actions";
import SubmitReviewSummary from "../components/Review/SubmitReviewSummary";
import SubmitReviewLimit from "../components/Review/SubmitReviewLimit";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  root2: {
    margin: "24px auto",
    width: "90%",
    maxWidth: "820px",
    textAlign: "left",
  },
}));

const ReviewNone = (props) => {
  return (
    <div>
      <Typography>Your submission was recorded.</Typography>
      <Typography>
        Please reach out to the administrator for results.
      </Typography>
    </div>
  );
};

const ReviewLater = (props) => {
  const { showTime } = props;
  return (
    <div>
      <Typography>Your submission was recorded.</Typography>
      <Typography>Results will be available at {showTime}.</Typography>
    </div>
  );
};

const Review = (props) => {
  const {
    nav: { serverDiff },
    submit,
    auth,
    onReviewSubmitFetch,
  } = props;

  const submitId = props.match.params.submitId;
  const prevSubmitId = submit ? submit._id : null;
  const { role } = auth || null;
  let same = false;
  if (prevSubmitId === submitId) same = true;

  const classes = useStyles();

  const { error, answers, testId: test, assignId } = submit || {};
  const { review, times } = test || {};
  const {
    reviewMode = "all",
    reviewWhen = "immediate",
    reviewCustomWhen,
  } = review || {};
  const { endTime } = times || {};
  const now = moment().add(serverDiff);
  const itsTime =
    reviewWhen === "immediate" ||
    (reviewWhen === "endtime" && now.isAfter(moment(endTime))) ||
    (reviewWhen === "custom" && now.isAfter(moment(reviewCustomWhen)));

  // const noReview =
  //   test &&
  //   [
  //     "60d75e45af74f3c3476a41f0",
  //     "60d75e35af74f3c3476a41d0",
  //     "60d75e27af74f3c3476a41b0",
  //     "60d75e15af74f3c3476a4190",
  //     "60d75e03af74f3c3476a4170",
  //     "60d719d8af74f3c34769aa17",
  //     "60790982fd1e2001dd974fc5" // QA
  //   ].includes(test._id) &&
  //   role === "student";

  const { user } = assignId || {};
  useEffect(() => {
    if (submitId) {
      onReviewSubmitFetch(submitId);
    }
  }, [submitId, onReviewSubmitFetch]);

  return (
    <React.Fragment>
      {error ? (
        <Redirect to="/" />
      ) : (
        <div>
          {test && same ? (
            <div className={classes.root}>
              <div className={classes.root2}>
                <div>
                  <Typography variant="h5">{user && user.name}</Typography>
                </div>
                {["admin", "tutor"].includes(role) ? (
                  <>
                    <SubmitReviewSummary test={test} submit={submit} />
                    <SubmitReviewLayout test={test} answers={answers} />
                  </>
                ) : null}
                {["student"].includes(role) ? (
                  reviewMode !== "none" ? (
                    itsTime ? (
                      reviewMode === "all" ? (
                        <>
                          <SubmitReviewSummary test={test} submit={submit} />
                          <SubmitReviewLayout test={test} answers={answers} />
                        </>
                      ) : reviewMode === "scores" ? (
                        <>
                          <SubmitReviewSummary test={test} submit={submit} />
                          <SubmitReviewLimit test={test} answers={answers} />
                        </>
                      ) : null
                    ) : (
                      <ReviewLater
                        showTime={moment(reviewCustomWhen || endTime).format(
                          "D MMM YYYY, h:mm a"
                        )}
                      />
                    )
                  ) : (
                    <ReviewNone />
                  )
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    nav: state.blockNav,
    submit: state.reviewsubmit.submit,
    auth: state.auth,
  };
};

const SubmitReview = connect(mapStateToProps, {
  onReviewSubmitFetch,
})(Review);

export default SubmitReview;
