import React, { useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { Close, Label, Search } from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { connect } from "react-redux";

import { Button, DialogActions, makeStyles } from "@material-ui/core";

import { onTagsFetch, onTestTagsUpdate } from "../../../store/actions";

// const tagOptions = [
//   { type: "Subject", name: "Physics" },
//   { type: "Topic", name: "Electromagnetism" },
//   { type: "Subject", name: "Chemistry" },
//   { type: "Suptopic", name: "Organic" }
// ];

const tagTypes = [
  "Subject",
  "Topic",
  "Subtopic",
  "Difficulty",
  "Course",
  "Group",
  "Goal"
];

const filter = createFilterOptions();

// export const onTagsFetch = async (query) => {
//   if (query.length > 0) return tagOptions;
//   else return [];
// };

const useStyles = makeStyles((theme) => ({
  dialog: { alignItems: "stretch" },
  title: { padding: 0 },
  closeBtn: { float: "right" },
  dialogContent: { padding: "0 20px 0 20px" },
  sectionTitleText: { marginLeft: "10px" },
  chip: {
    // padding: 0,
    margin: "4px 12px 4px 0",
    width: "80px"
  },
  section: {
    marginBottom: "6px"
  },
  inline: {
    display: "flex",
    alignItems: "center"
  },
  bold: {
    fontWeight: "600"
  },
  newValue: {
    backgroundColor: "lightgrey",
    padding: "0 2px 0 2px",
    borderRadius: "3px"
  },
  selectedItems: { margin: "20px 0" },
  tagItem: {
    display: "flex",
    alignItems: "center",
    padding: "6px 0",
    "&:hover [name='clearIcon']": {
      visibility: "visible"
    }
  },
  clearIcon: {
    marginRight: "4px",
    visibility: "hidden"
  },
  searchIcon: { marginRight: "4px" }
}));

const TestTagsEditor = (props) => {
  const classes = useStyles();

  const breakpoint = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const [options, setOptions] = React.useState([]);
  const [tagTypeFilter, setTagTypeFilter] = React.useState(tagTypes[0]);
  const [loading, setLoading] = React.useState();
  const [selectedTags, setSelectedTags] = useState([]);

  const { open, handleOpen, testTags = [], onTestTagsUpdate } = props;

  useEffect(() => {
    if (open) setSelectedTags(testTags);
  }, [testTags, open]);

  const handleClose = (event) => {
    handleOpen(false);
    // setSelectedTag("");
  };

  const handleSelectedChanged = (value, reason) => {
    if (reason === "remove-option") return;
    setSelectedTags(value);
  };

  const handleTagRemoved = (index) => {
    const st = [...selectedTags];
    st.splice(index, 1);
    setSelectedTags(st);
  };

  const handleValueChange = async (event) => {
    const query = event.target.value;
    if (query.match(/\s\w{1}$|^\w{1}$/)) {
      setLoading(true);
      onTagsFetch(query, tagTypeFilter)
        .then((result) => {
          setOptions(result || []);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          setLoading(false);
        });
    }
  };

  const handleTagsSave = (event) => {
    const tags = selectedTags.map((tag) => {
      return { type: tag.type, name: tag.name };
    });
    onTestTagsUpdate(tags).then(() => handleOpen(false));
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen={!breakpoint}
        classes={{ scrollPaper: classes.dialog }}
      >
        <DialogTitle
          className={classes.title}
          disableTypography
          id="customized-dialog-title"
        >
          <IconButton
            aria-label="close"
            className={classes.closeBtn}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <div className={classes.section}>
            <div className={classes.inline}>
              <Label fontSize="small" color="primary" />
              <Typography className={classes.sectionTitleText} variant="h5">
                Tags
              </Typography>
            </div>
          </div>
          <div className={classes.section}>
            {tagTypes.map((tagType) => {
              return (
                <Chip
                  clickable
                  key={"tagType" + tagType}
                  label={tagType}
                  color={tagTypeFilter === tagType ? "primary" : "default"}
                  className={classes.chip}
                  onClick={() => {
                    setTagTypeFilter(tagType);
                  }}
                />
              );
            })}
          </div>
          <div>
            <Autocomplete
              id="combo-box-demo"
              options={options}
              freeSolo
              multiple
              value={selectedTags}
              disableClearable
              autoHighlight
              clearOnBlur
              openOnFocus
              filterSelectedOptions
              getOptionDisabled={(option) => option.isDisabled}
              ListboxProps={{
                dense: true,
                disablePadding: true
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                filtered.splice(0, 0, {
                  type: tagTypeFilter,
                  name: "Select an option or create one",
                  isDisabled: true
                });
                if (params.inputValue !== "") {
                  filtered.push({
                    type: tagTypeFilter,
                    name: params.inputValue,
                    isNew: true
                  });
                }
                const f1 = filtered.filter((o) => o.type === tagTypeFilter);
                return f1;
              }}
              getOptionLabel={(option) => {
                return option.name;
              }}
              onChange={(e, value, reason) => {
                handleSelectedChanged(value, reason);
              }}
              renderOption={(option) => {
                return (
                  <React.Fragment>
                    {option && option.isNew ? (
                      <div>
                        <Typography variant="body1">
                          {option && option.isNew ? "Add " : null}
                          <span className={classes.newValue}>
                            {option.name}
                          </span>
                        </Typography>
                      </div>
                    ) : (
                      <div>
                        <Typography variant="body1">{option.name}</Typography>
                      </div>
                    )}
                  </React.Fragment>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // variant="filled"
                  onChange={handleValueChange}
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: "new-password",
                    startAdornment: (
                      <React.Fragment>
                        <Search
                          fontSize="small"
                          className={classes.searchIcon}
                        />
                        <Typography className={classes.bold} variant="body1">
                          {tagTypeFilter}
                          {":"}
                        </Typography>
                      </React.Fragment>
                    ),
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </div>
          <div className={classes.selectedItems}>
            {selectedTags.map((tag, index) => {
              return (
                <React.Fragment key={"tag" + tag.type + tag.name}>
                  <div className={classes.tagItem}>
                    <Close
                      color="disabled"
                      fontSize="small"
                      className={classes.clearIcon}
                      name="clearIcon"
                      onClick={(e) => handleTagRemoved(index)}
                    />
                    <Typography>
                      <span className={classes.bold}>
                        {tag.type}
                        {": "}
                      </span>
                      {tag.name}
                    </Typography>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleTagsSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

// export default TestTagsEditor;

export default connect(null, { onTestTagsUpdate })(TestTagsEditor);
