import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Image from "../../shared/components/UIElements/Image";

const useStyles = makeStyles((theme) => ({
  imageClass: {
    opacity: 0.25,
    filter: "grayscale(100%)"
  },
  root: {
    width: "80%",
    marginTop: "15vh",
    margin: "auto"
  },
  text: {
    opacity: 0.7
  }
}));

const NoAssigns = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Image
        classes={{ img: classes.imageClass, container: classes.container }}
        imageClass={classes.imageClass}
        image={{
          url:
            // "https://firebasestorage.googleapis.com/v0/b/testcrackr09.appspot.com/o/public%2Fexam.png?alt=media&token=e17eab86-155e-4f6c-a514-f7b6ee1c0304",
            "https://firebasestorage.googleapis.com/v0/b/testcrackr09.appspot.com/o/public%2Ftest.png?alt=media&token=c17a5379-aa77-4df9-a06f-35fd6ede501d",
          justify: "center"
        }}
      />
      <Typography className={classes.text}>
        Tests that are assigned to you or you have attempted will appear here.
        <br />
      </Typography>
    </div>
  );
};

export default NoAssigns;
