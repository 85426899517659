import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import UserAvatar from "./UserAvatar";
import clsx from "clsx";

import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  avatar1: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
    height: "36px",
    width: "36px",
  },
  avatar2: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    height: "50px",
    width: "50px",
  },
  user: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    width: "100%",
  },
  noAction: {
    cursor: "default",
  },
}));

const UserAvatarWithInfo = (props) => {
  const { name, email, onClick, size, iconWithInfo, expandIcon } = props;
  const classes = useStyles();

  const avatar = React.useMemo(() => {
    return (
      <div
        className={
          onClick ? classes.user : clsx(classes.user, classes.noAction)
        }
        onClick={onClick}
      >
        <UserAvatar
          name={name}
          className={size === "large" ? classes.avatar2 : classes.avatar1}
        />
        {iconWithInfo ? (
          <div style={{ marginLeft: "10px" }}>
            <Typography variant="body1">{name} </Typography>
            <Typography variant="body2">{email}</Typography>
          </div>
        ) : null}
        {expandIcon ? <ExpandMore /> : null}
      </div>
    );
  }, [name, email, onClick, size, iconWithInfo, expandIcon, classes]);
  return <>{avatar}</>;
};

export default UserAvatarWithInfo;
