import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography
} from "@material-ui/core";

import firebase from "../../utils/firebase";

const Forgot = (props) => {
  const classes = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      margin: "30px auto",
      maxWidth: "400px",
      border: "solid #efefef 1px",
      minHeight: "80vh"
    },
    forgot: {
      color: theme.palette.text.primary,
      textDecoration: "none",
      textTransform: "none"
    }
  }))();

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [sent, setSent] = useState(false);

  const handleOpen = (event) => {
    setOpen(true);
  };

  const handleClose = (event) => {
    setOpen(false);
    setError(false);
    setEmail("");
  };

  const handleEmailChange = (event) => {
    error && setError("");
    setEmail(event.target.value);
  };

  const auth = firebase.auth();

  return (
    <div style={{ textAlign: "right" }}>
      <Button
        style={{ fontSize: "12px", textDecoration: "underline" }}
        onClick={handleOpen}
        className={classes.forgot}
      >
        Forgot Password?
      </Button>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!sent ? (
          <>
            <DialogTitle id="alert-dialog-title">Enter Email</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                id="email"
                type="email"
                fullWidth
                helperText={
                  error ||
                  "Provide the email address you used to create your account."
                }
                value={email || ""}
                error={!!error}
                onChange={handleEmailChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={(event) => {
                  auth
                    .sendPasswordResetEmail(email)
                    .then(function () {
                      setSent(true);
                    })
                    .catch(function (error) {
                      setError(error.message);
                    });
                }}
                autoFocus
                color="primary"
                variant="contained"
              >
                Continue
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <Typography>
                An email has been sent to you email address with a link to reset
                your password.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="contained">
                Ok
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default Forgot;
