import mapValues from "lodash.mapvalues";

const isObject = (o) => {
  return typeof o === "object" && o !== null;
};

// export const omitDeep1 = (source, omitKey) =>
//   Array.isArray(source)
//     ? source.map((s) => omitDeep(s, omitKey))
//     : isObject(source)
//     ? (({ [omitKey]: _, ...rst }) =>
//         mapValues(rst, (s) => omitDeep(s, omitKey)))(source)
//     : source;

export const omitDeep = (source, omitKey, level = 0) => {
  if (Array.isArray(source)) {
    return source.map((s) => omitDeep(s, omitKey, level + 1));
  } else if (isObject(source)) {
    const { [omitKey]: key1, ...rst } = source;
    return mapValues(rst, (s) => omitDeep(s, omitKey));
  } else return source;
};

export const reduceDeep = (source, key, level = 0) => {
  let result = [];
  if (Array.isArray(source)) {
    result = result.concat(...source.map((s) => reduceDeep(s, key, level + 1)));
  } else if (isObject(source)) {
    for (const [k, v] of Object.entries(source)) {
      if (k === key && typeof v === "string") {
        result.push(v);
      } else {
        result = result.concat(reduceDeep(v, key, level + 1));
      }
    }
  }
  // console.log("level", level, result);
  return result;
};
