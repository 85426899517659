import React from "react";

const Image = (props) => {
  const { image, classes } = props;
  const { url = "", justify = "flex-start", scale = 100 } = image || {};

  return (
    <>
      {url ? (
        <div
          style={{
            display: "flex",
            justifyContent: `${justify}`
          }}
          className={classes && classes.container}
        >
          <img
            // onLoad={() => setShow(true)}
            style={{
              maxWidth: `${scale}%`
              // display: show ? "flex" : "none"
            }}
            className={classes && classes.img}
            src={url}
            alt="q"
          />
        </div>
      ) : null}
    </>
  );
};

export default Image;
