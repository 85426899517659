import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  List,
  ListItem,
  Tooltip,
  ListItemIcon,
  ListItemText,
  Box,
  Card,
  CardHeader,
  CardActions,
  Button,
  CardContent,
  Typography,
  makeStyles
} from "@material-ui/core";

import { IconButtonWithPopover } from "../../../shared/hoc/withPopover";
// import { ListItemWithConfirm } from "../../../shared/hoc/withConfirm";

import {
  Edit,
  MoreVert,
  FileCopyOutlined,
  Assessment,
  Visibility
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4, 3),
    margin: theme.spacing(4, 0),    
    borderRadius: "8px",
    borderLeftWidth: "4px",
    "&:hover": {      
      borderLeftColor: theme.palette.primary.main
    }
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    flexWrap: "wrap"
    // flexDirection: "column",
    // [theme.breakpoints.up("smd")]: { flexDirection: "row" }
  },
  header: {
    width: "100%",
    [theme.breakpoints.up("smd")]: { width: "60%" }
  },
  headerText: {
    textAlign: "left"
    // width: "100%",
    // // flexGrow: 1,
    // alignItems: "center"
  },
  headerRoot: {
    padding: theme.spacing(1, 3, 1, 3)
  },
  actions: {
    width: "100%",
    [theme.breakpoints.up("smd")]: { width: "30%" },
    alignItems: "flex-start",
    justifyContent: "flex-end",
    padding: theme.spacing(0)
  },
  action: {
    margin: theme.spacing(2),
    textTransform: "none"
  },
  subheader1: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: theme.spacing(0, 3),
    "&:last-child": { paddingBottom: theme.spacing(5) }
  },
  subheader2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  subheaderItem: {
    flexGrow: 1,
    textAlign: "left",
    paddingRight: theme.spacing(3)
  }
}));

const TestItem = (props) => {
  const classes = useStyles();

  const {
    test,
    handleTestDuplicate
    // handleTestDelete,
    // handleTestIdToAssign,
    // handleTestAssignOpen,
  } = props;

  const { _id: testId, name, numQuestions, times } = test;
  const { startTime, endTime, timeLimit = 0 } = times || {};

  const numQLabel = numQuestions ? numQuestions + " Questions" : null;
  const timeLabel = timeLimit ? timeLimit + " minutes" : null;
  const momentStart = moment(startTime);
  const momentEnd = moment(endTime);
  const startLabel =
    startTime &&
    "Start: " +
      momentStart.format(
        momentStart.minutes() === 0 ? "MMM Do ha" : "MMM Do h:mma"
      );
  const endLabel =
    endTime &&
    "End: " +
      momentEnd.format(
        momentEnd.minutes() === 0 ? "MMM Do ha" : "MMM Do h:mma"
      );

  const subheader = () => {
    return (
      <CardContent className={classes.subheader1}>
        <div className={classes.subheader2}>
          <Typography
            className={classes.subheaderItem}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {numQLabel}
          </Typography>
          <Typography
            className={classes.subheaderItem}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {timeLabel}
          </Typography>
        </div>
        <div className={classes.subheader2}>
          {startLabel ? (
            <Typography
              className={classes.subheaderItem}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {startLabel}
            </Typography>
          ) : null}

          {endLabel ? (
            <Typography
              className={classes.subheaderItem}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {endLabel}
            </Typography>
          ) : null}
        </div>
      </CardContent>
    );
  };

  const [pop, setPop] = useState(false);
  const morepopover = () => {
    return (
      <IconButtonWithPopover
        disableRipple
        disableFocusRipple
        size="medium"
        key={pop}
        popover={
          <List aria-label="More Test Actions">
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/test/" + testId}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItem button>
                <ListItemIcon>
                  <Visibility />
                </ListItemIcon>
                <ListItemText primary="Preview" />
              </ListItem>
            </Link>
            {/* <ListItem
              button
              onClick={(event) => {
                handleTestIdToAssign(testId);
                handleTestAssignOpen(true);
                setPop(!pop);
              }}
            >
              <ListItemIcon>
                <Send />
              </ListItemIcon>
              <ListItemText primary="Assign" />
            </ListItem> */}
            <ListItem
              button
              onClick={(event) => {
                handleTestDuplicate(testId);
                setPop(!pop);
              }}
            >
              <ListItemIcon>
                <FileCopyOutlined />
              </ListItemIcon>
              <ListItemText primary="Duplicate" />
            </ListItem>
            {/* <ListItemWithConfirm
              dialog={{
                title: "Delete Test?",
                text: "Are you sure you want to delete this test?",
                onClose: () => setPop(!pop)
              }}
              button
              onClick={() => handleTestDelete(testId)}
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </ListItemWithConfirm> */}
          </List>
        }
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
      >
        <Tooltip title="More">
          <MoreVert fontSize="small" />
        </Tooltip>
      </IconButtonWithPopover>
    );
  };

  return (
    <React.Fragment>
      <Card className={classes.root} variant="outlined" >
        <Box className={classes.top}>
          <div className={classes.header}>
            <CardHeader
              classes={{
                content: classes.headerText,
                root: classes.headerRoot
              }}
              title={name}
            />
            {subheader()}
          </div>
          <CardActions className={classes.actions} disableSpacing>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/q/test/" + testId,
                state: { testName: name }
              }}
            >
              <Button
                className={classes.action}
                variant="contained"
                color="primary"
                startIcon={<Edit />}
              >
                Questions
              </Button>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/q/test/" + testId,
                state: { testName: name },
                search: "?view=1"
              }}
            >
              <Button
                className={classes.action}
                variant="outlined"
                color="primary"
                startIcon={<Assessment />}
              >
                Submissions
              </Button>
            </Link>
            {morepopover()}
          </CardActions>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default TestItem;
