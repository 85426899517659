import { combineReducers } from "redux";
import * as actionTypes from "../actionTypes";

// Tutor
import test from "./test";
import tests from "./tests";
import sectionEditor from "./sectionEditor";
import questionEditor from "./questionEditor";
import reviewsubmit from "./reviewsubmit";
import reviewtest from "./reviewtest";
import account from "./account";
// Student
import assigns from "./assigns";
import submit from "./submit";
import attempt from "./attempt";
// Common
import nav from "./nav";
import auth from "./auth";
// Admin
import admin from "./admin";

const appReducer = combineReducers({
  test: test,
  tests: tests,
  sectionEditor: sectionEditor,
  questionEditor: questionEditor,
  reviewsubmit: reviewsubmit,
  reviewtest: reviewtest,
  submit: submit,
  assigns: assigns,
  attempt: attempt,
  blockNav: nav,
  auth: auth,
  admin: admin,
  account: account
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
