import * as actionTypes from "../actionTypes";

const initialState = {
  error: false,
  assigns: null,
  testId: null
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REVIEW_TEST_FETCHING:
      return { ...state };
    case actionTypes.REVIEW_TEST_FETCH_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case actionTypes.REVIEW_TEST_FETCH_ERROR:
      return { ...state, error: action.payload.error };
    case actionTypes.REVIEW_TEST_UNASSIGNING:
    case actionTypes.REVIEW_TEST_UNASSIGN_ERROR:
      // case actionTypes.REVIEW_TEST_UNASSIGN_SUCCESS:
      return { ...state, assigns: action.payload.assigns };
    // case actionTypes.REVIEW_TEST_SUBMIT_DISCARDING:
    // case actionTypes.REVIEW_TEST_SUBMIT_DISCARD_ERROR:
    case actionTypes.REVIEW_TEST_SUBMIT_DISCARD_SUCCESS: {
      const { assigns } = state;
      const newAssigns = action.payload.assigns;
      const newAssignObjs = newAssigns.reduce((obj, a) => {
        obj[a._id] = a;
        return obj;
      }, {});
      const newAssignIds = Object.keys(newAssignObjs);
      const assigns2 = assigns.reduce((arr, a) => {
        if (newAssignIds.includes(a._id)) arr.push(newAssignObjs[a._id]);
        else arr.push(a);
        return arr;
      }, []);
      return { ...state, assigns: assigns2 };
    }
    default:
      return state;
  }
};

export default reviewReducer;
