import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import appconfig from "../../config";

const GA_TRACKING_ID_UA = appconfig.GA_TRACKING_ID_UA;
const GA_TRACKING_ID_GA4 = appconfig.GA_TRACKING_ID_GA4;

const GoogleAnalytics = (props) => {
  const {
    location
    // history
  } = props;

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const gtag = window.gtag;
    if (typeof gtag === "function") {
      gtag("config", GA_TRACKING_ID_UA);
      gtag("config", GA_TRACKING_ID_GA4);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    const gtag = window.gtag;
    if (typeof gtag === "function" && initialized) {
      gtag("event", "page_view", {
        page_title: document.title,
        page_path: location.pathname,
        page_location: window.location.href
      });
    }
  }, [location, initialized]);

  return null;
};

export default withRouter(GoogleAnalytics);
