import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
// import Slide from "@material-ui/core/Slide";
import {
  useMediaQuery,
  DialogContent,
  DialogActions,
  Fade
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "sticky",
    paddingLeft: "16px"
  },
  toolbar: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  title: { fontWeight: "500" },
  actions: { paddingLeft: "24px", paddingRight: "24px" }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  // return <Slide direction="up" ref={ref} {...props} />;
  return <Fade in ref={ref} {...props} />;
});

export default function ContentDialog(props) {
  const classes = useStyles();

  const breakpoint = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const {
    open,
    handleClose,
    dialogTitle,
    handleAction,
    closeText = "Cancel",
    actionText = "Save",
    closeDisabled = false,
    actionDisabled = false,
    hasCancel = true,
    disableTypography = false,
    ...dialogProps
  } = props;

  return (
    <div>
      <Dialog
        open={open || false}
        fullWidth
        fullScreen={!breakpoint}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{ className: classes.paper }}
        {...dialogProps}
      >
        <AppBar elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            {disableTypography ? (
              dialogTitle
            ) : (
              <Typography variant="h6" className={classes.title}>
                {dialogTitle}
              </Typography>
            )}

            {hasCancel ? (
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Toolbar>
        </AppBar>
        <DialogContent dividers>{props.children}</DialogContent>
        <DialogActions className={classes.actions}>
          {hasCancel ? (
            <Button onClick={handleClose} disabled={closeDisabled}>
              {closeText}
            </Button>
          ) : null}
          <Button
            onClick={(event) => {
              handleAction();
            }}
            color="primary"
            disabled={actionDisabled}
          >
            {actionText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
