// import * as actionTypes from "../actionTypes";
import axios from "../../shared/utils/axios";

// No actionTypes linked with this method
export const onTagsFetch = async (query, type) => {
  if (query.length > 0)
    return axios
      .get("/api/tags/" + query + "/?type=" + type)
      .then((response) => {
        const tags = response.data;
        return tags;
      })
      .catch((error) => {
        console.log("Error", error.message);
        return error;
      });
  else return [];
};
