import React, { createContext, useEffect } from "react";
import { useState, useCallback } from "react";
import { Prompt } from "react-router-dom";

export const BlockNavContext = createContext({
  block: false,
  onBlock: () => {}
});

const BlockNavProvider = props => {
  const [block, setBlock] = useState(false);

  const onBlock = useCallback(value => {
    setBlock(value);
  }, []);

  useEffect(() => {
    return () => {
      setBlock(false);
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (block) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [block]);

  return (
    <BlockNavContext.Provider
      value={{
        block: block,
        onBlock: onBlock
      }}
    >
      <Prompt
        when={block}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {props.children}
    </BlockNavContext.Provider>
  );
};

export default BlockNavProvider;
