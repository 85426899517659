import React, { useCallback, useEffect, useReducer, useState } from "react";
import axios from "../../shared/utils/axios";

import {
  Button,
  makeStyles,
  TextField,
  Typography,
  Link,
  Dialog,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Divider,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";

import {
  Close,
  Delete,
  FileCopy,
  PhotoOutlined,
  Refresh
} from "@material-ui/icons";
import ImageReceiver from "../../shared/components/UIElements/ImageReceiver";
import ImageProvider from "../../shared/components/UIElements/ImageProvider";

const onNotificationCreatorFetch = async () => {
  const response = await axios
    .get("/api/whatsnew/?role=student,tutor")
    .catch((error) => {
      console.log("Error", error.message);
    });
  const { whatsNew } = response.data;
  return whatsNew;
};

const onNotificationCreatorCreate = async (item) => {
  const { _id, ...item1 } = item;
  const response = await axios.post("/api/whatsnew", item1).catch((error) => {
    console.log("Error", error.message);
  });
  return response.data;
};

const onNotificationCreatorUpdate = async (item) => {
  await axios.patch("/api/whatsnew/" + item._id, item).catch((error) => {
    console.log("Error", error.message);
  });
  return item;
};

const onNotificationCreatorDelete = async (itemId) => {
  await axios.delete("/api/whatsnew/" + itemId).catch((error) => {
    console.log("Error", error.message);
  });
  return;
};

const tags = [
  "NEW",
  "ANNOUNCEMENT",
  "TIP OF THE DAY",
  "COMING SOON",
  "ENHANCEMENT",
  "WEBINAR",
  "PRODUCT UPDATES"
];

const userRoles = ["student", "tutor"];

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "40px"
  },
  dialogPaper: {
    alignItems: "center"
  },
  dialog: {
    padding: "10px",
    width: "90%",
    maxWidth: "800px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  dialogHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  dialogFooter: {
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
}));

const NotificationCreator = (props) => {
  const init = () => {
    return {
      _id: "",
      title: "",
      tag: "NEW",
      date: "",
      text: "",
      image: "",
      ctaText: "",
      ctaLink: "",
      roles: ["student", "tutor"],
      pinned: false
    };
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SELECTED":
        return { ...state, ...action.payload };
      case "TITLE":
        return { ...state, title: action.payload };
      case "TAG":
        return { ...state, tag: action.payload };
      case "DATE":
        return { ...state, date: action.payload };
      case "TEXT":
        return { ...state, text: action.payload };
      case "IMAGE":
        return { ...state, image: action.payload };
      case "CTA_TEXT":
        return { ...state, ctaText: action.payload };
      case "CTA_LINK":
        return { ...state, ctaLink: action.payload };
      case "ROLES":
        return { ...state, roles: action.payload };
      case "PINNED":
        return { ...state, pinned: action.payload };
      default:
        throw new Error();
    }
  };

  const [items, setItems] = useState(new Map());
  const [selected, dispatch] = useReducer(reducer, init());
  const [refresh, setRefresh] = useState(true);
  const [editorOpen, setEditorOpen] = useState(false);
  const [imageFile, setImageFile] = useState();

  const classes = useStyles();

  const arrToMap = (arr) => {
    const map = arr.reduce((itemMap, item) => {
      itemMap.set(item._id, item);
      return itemMap;
    }, new Map());
    return map;
  };

  const handleFetch = useCallback(async () => {
    const items = await onNotificationCreatorFetch();
    setItems(arrToMap(items));
  }, []);

  useEffect(() => {
    if (refresh) {
      handleFetch();
      setRefresh(false);
    }
  }, [refresh, handleFetch]);

  const handleSave = async () => {
    let item = {};
    if (selected._id) {
      items.set(selected._id, selected);
      await onNotificationCreatorUpdate(selected);
    } else {
      item = await onNotificationCreatorCreate(selected);
      const itemsArr = Array.from(items.values());
      itemsArr.splice(0, 0, item);
      setItems(arrToMap(itemsArr));
    }
    handleEditorClose();
  };

  const handleDelete = async () => {
    items.delete(selected._id);
    onNotificationCreatorDelete(selected._id);
    handleEditorClose();
  };

  const handleSelected = (id) => {
    if (id && items.has(id))
      dispatch({ type: "SELECTED", payload: items.get(id) });
    setEditorOpen(true);
  };

  const handleDuplicate = (id) => {
    if (id && items.has(id)) {
      const { _id, ...item } = items.get(id);
      dispatch({ type: "SELECTED", payload: { _id: "", ...item } });
    }
    setEditorOpen(true);
  };

  const handleEditorClose = () => {
    setEditorOpen(false);
    dispatch({ type: "SELECTED", payload: init() });
  };

  const handleImageFile = (file, name) => {
    setImageFile(file);
  };

  const handleImageChange = useCallback((image, name) => {
    dispatch({ type: "IMAGE", payload: image ? image.url : "" });
  }, []);

  const handleRolesChange = (e) => {
    const role = e.target.name;
    const checked = e.target.checked;
    if (checked && !selected.roles.includes(role)) {
      selected.roles.splice(0, 0, role);
      dispatch({ type: "ROLES", payload: [...selected.roles] });
    } else if (!checked && selected.roles.includes(role)) {
      selected.roles.splice(selected.roles.indexOf(role), 1);
      dispatch({ type: "ROLES", payload: [...selected.roles] });
    }
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={() => setRefresh(true)}>
        <Refresh />
      </IconButton>
      <Typography variant="h5">What's New</Typography>
      <Button
        disableFocusRipple
        disableRipple
        onClick={(e) => handleSelected()}
      >
        Add New
      </Button>
      <div style={{ textAlign: "left" }}>
        {Array.from(items.values()).map((item) => {
          return (
            <div key={item._id}>
              <Typography variant="h6">{item.title}</Typography>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  onClick={(e) => handleSelected(item._id)}
                >
                  Edit
                </Button>
                <IconButton onClick={(e) => handleDuplicate(item._id)}>
                  <FileCopy />
                </IconButton>
                <Typography variant="body2">{item.date}</Typography>
                <Typography variant="body2">{item.tag}</Typography>
                {/* <Typography>{item.roles.toString()}</Typography> */}
                <Link
                  href={item.ctaLink}
                  className={classes.ctaLink}
                  onClick={(e) => e.preventDefault()}
                >
                  <Typography variant="body2" className={classes.ctaText}>
                    {item.ctaText}
                  </Typography>
                </Link>
              </div>
              <Divider />
            </div>
          );
        })}
      </div>

      <Dialog
        classes={{ paper: classes.dialogPaper }}
        fullScreen
        open={editorOpen}
        onClose={handleEditorClose}
      >
        <div className={classes.dialog}>
          <div className={classes.dialogHeader}>
            <Typography variant="h5">Add/Edit What's New</Typography>
            <IconButton onClick={handleEditorClose}>
              <Close />
            </IconButton>
          </div>
          <Typography>_id: {selected._id}</Typography>
          <TextField
            label="Title"
            value={selected.title}
            onChange={(e) =>
              dispatch({ type: "TITLE", payload: e.target.value })
            }
          />
          <TextField
            label="Date"
            placeholder="E.g. July 21, 2021"
            value={selected.date}
            onChange={(e) =>
              dispatch({ type: "DATE", payload: e.target.value })
            }
          />
          <FormControl>
            <InputLabel id="select-tag">Tag</InputLabel>
            <Select
              labelId="select-tag"
              value={selected.tag || "NEW"}
              onChange={(e) =>
                dispatch({ type: "TAG", payload: e.target.value })
              }
            >
              {tags.map((tag) => {
                return (
                  <MenuItem value={tag} key={tag}>
                    {tag}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            label="Content"
            multiline
            value={selected.text}
            onChange={(e) =>
              dispatch({ type: "TEXT", payload: e.target.value })
            }
          />
          <TextField
            label="CTA Text"
            value={selected.ctaText}
            onChange={(e) =>
              dispatch({ type: "CTA_TEXT", payload: e.target.value })
            }
          />
          <TextField
            label="CTA Link"
            value={selected.ctaLink}
            onChange={(e) =>
              dispatch({ type: "CTA_LINK", payload: e.target.value })
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selected.pinned}
                onChange={(e) =>
                  dispatch({ type: "PINNED", payload: e.target.checked })
                }
              />
            }
            label="Pinned"
          />

          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Roles</FormLabel>
            <FormGroup>
              {userRoles.map((u) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selected.roles.includes(u)}
                      onChange={handleRolesChange}
                      name={u}
                    />
                  }
                  label={u}
                />
              ))}
            </FormGroup>
          </FormControl>
          <ImageReceiver
            imageObj={imageFile}
            image={{ url: selected.image, justify: "flex-start" }}
            name={`r_my_img`}
            controls={false}
            maxWidth={100}
            handleImageChange={handleImageChange}
          />
          <ImageProvider name={`my_img`} handleImageFile={handleImageFile}>
            <Button
              variant="contained"
              disableElevation
              component="span"
              startIcon={<PhotoOutlined fontSize="default" />}
            >
              Add Image
            </Button>
          </ImageProvider>

          <div className={classes.dialogFooter}>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
            <Button onClick={handleEditorClose}>Cancel</Button>
            <IconButton onClick={handleDelete}>
              <Delete />
            </IconButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default NotificationCreator;
