import React from "react";
import { connect } from "react-redux";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles, Tooltip, useMediaQuery } from "@material-ui/core";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import QuestionEditor from "./QuestionEditor";
import DraggableQuestionItem from "./DraggableQuestion";
import CardLoading from "../../../shared/components/UIElements/CardLoading1";

import {
  onTestQuestionReorder,
  onTestQuestionAddEdit,
  onTestQuestionRemove,
  onTestQuestionDuplicate,
  onQuestionEditorLoad
} from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  fab: {
    marginTop: "70px",
    position: "sticky",
    bottom: "20px",
    right: "20px",
    padding: theme.spacing(1, 6)
  },
  fabIcon: {
    marginRight: theme.spacing(2)
  }
}));

const QuestionList = (props) => {
  const classes = useStyles();

  const {
    onTestQuestionReorder,
    onTestQuestionAddEdit,
    onTestQuestionRemove,
    onTestQuestionDuplicate,
    onQuestionEditorLoad,
    change
  } = props;

  const breakpoint = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const { sections, selectedSectionIdx, readOnly } = props.test;
  const { marking: sectionMarking } = sections[selectedSectionIdx] || {};

  const { loading } = props.nav;
  const selectedSection = sections[selectedSectionIdx];

  const questions = selectedSection ? selectedSection.questions : null;

  const onDragEnd = (result) => {
    const {
      destination,
      source
      // draggableId
    } = result;
    if (!destination || destination.index === source.index) return;
    onTestQuestionReorder(source.index, destination.index);
  };

  const handleQuestionCreate = (event) => {
    onQuestionEditorLoad();
  };

  const handleQuestionEditorLoad = (idx) => (event) => {
    onQuestionEditorLoad(idx);
  };

  const handleQuestionEdited = () => {
    return onTestQuestionAddEdit().catch((error) => {
      throw error;
    });
  };

  const handleQuestionDelete = (idx) => {
    onTestQuestionRemove(idx);
  };

  const handleQuestionDuplicate = (idx) => {
    onTestQuestionDuplicate(idx);
  };

  return (
    <>
      <div>
        {loading && change ? (
          <CardLoading count={2} />
        ) : questions ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={"questions"}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {questions.map((question, idx) => (
                      <DraggableQuestionItem
                        id={question._id}
                        key={question._id}
                        question={question}
                        idx={idx}
                        handleQuestionEdit={handleQuestionEditorLoad}
                        handleQuestionDelete={handleQuestionDelete}
                        handleQuestionDuplicate={handleQuestionDuplicate}
                        last={idx === questions.length - 1}
                        sectionMarking={sectionMarking}
                        handleQuestionEdited={handleQuestionEdited}
                        readOnly={readOnly}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {readOnly ? null : (
              <>
                <Tooltip title="Add Question">
                  <Fab
                    onClick={handleQuestionCreate}
                    size={breakpoint ? "medium" : "small"}
                    color="primary"
                    aria-label="add-question"
                    className={classes.fab}
                  >
                    <AddIcon
                    // className={classes.fabIcon}
                    />
                  </Fab>
                </Tooltip>
                <div style={{ height: "100px" }} />
                <QuestionEditor
                  sectionMarking={sectionMarking}
                  handleQuestionEdited={handleQuestionEdited}
                  handleQuestionEditorLoad={handleQuestionEditorLoad}
                />
              </>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    test: state.test,
    nav: state.blockNav
  };
};

export default connect(mapStateToProps, {
  onTestQuestionReorder,
  onTestQuestionAddEdit,
  onTestQuestionRemove,
  onTestQuestionDuplicate,
  onQuestionEditorLoad
})(QuestionList);
