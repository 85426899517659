/**
 * This component is responsible for 
 */

import React, { useState, useCallback } from "react";
import moment from "moment";

// mui
import {
  makeStyles,
  Typography,
  Checkbox,
  Button,
  Paper,
  Divider,
} from "@material-ui/core";

// page components
import Timer from "../components/Timer";
import SimpleTimeDisplay from "../components/SimpleTimeDisplay";
import Image from "../../shared/components/UIElements/Image";
import { Spinner } from "../../shared/components/UIElements/Progress";
// import AskForLogin from "../components/AskForLogin";

const TestIntro = (props) => {
  const classes = makeStyles((theme) => ({
    paper: {
      maxWidth: "820px",
      width: "90%",
      margin: "50px auto",
      border: "1px solid grey",
    },
    page: {
      maxWidth: "820px",
      width: "90%",
      margin: "10px auto",
      textAlign: "left",
    },
    title: {
      width: "90%",
      margin: "15px auto",
      textAlign: "left",
    },
    headerSubText: {
      fontStyle: "italic",
      marginTop: "5px",
    },
    instructions: {
      margin: "30px auto",
    },
    accept: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: "30px",
    },
    acceptText: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.secondary,
      textAlign: "left",
    },
    actions: {
      textAlign: "center",
      margin: "40px auto",
    },
    action: {
      maxWidth: "280px",
      width: "100%",
      textDecoration: "none",
    },
    time: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
    },
    timeText: {
      fontWeight: 600,
    },
  }))();

  const [accepted, setAccepted] = useState(false);

  const { test, serverDiff, spinner, ask, handleTestStart } = props;

  const { name, intro, times, creator } = test || {};
  const { instructions, introImage, acknowledge, acknowledgeText } =
    intro || {};
  const { name: creatorName } = creator || {};
  let { startTime, endTime, timeLimit } = times || {};

  const headerText = name;

  const [time, setTime] = useState(0);
  // const [open, setOpen] = useState(false);

  const startDisabled = time > 1000 || (acknowledge && !accepted);

  const onTimeChange = useCallback((clock) => {
    const { diff } = clock;
    setTime(diff);
  }, []);

  // const handleDialogOpen = () => {
  //   setOpen(true);
  // };

  // const handleDialogClose = () => {
  //   setOpen(false);
  // };

  // const continueUrl = {
  //   pathname: "/test/" + testId,
  //   state: { accepted: accepted || !acknowledge }
  // };

  return (
    <React.Fragment>
      <Paper className={classes.paper} elevation={3}>
        <div className={classes.title}>
          <Typography variant="h5">{headerText}</Typography>
          {creatorName ? (
            <Typography className={classes.headerSubText} variant="body2">
              {"by " + creatorName}
            </Typography>
          ) : null}
        </div>
        <Divider variant="fullWidth" />
        <div className={classes.page}>
          <div className={classes.instructions}>
            <div>
              {instructions &&
                instructions
                  .split("\n")
                  .map((item, i) => <Typography key={i}>{item}</Typography>)}
            </div>
          </div>
          <Image image={introImage} />
          {acknowledge ? (
            <div className={classes.accept}>
              <Checkbox
                size="small"
                color="primary"
                checked={accepted}
                onChange={(event) => {
                  setAccepted(event.target.checked);
                }}
              />
              <Typography
                className={classes.acceptText}
                style={{ textAlign: "left" }}
              >
                {acknowledgeText}
              </Typography>
            </div>
          ) : null}
          {name ? (
            <React.Fragment>
              <div className={classes.actions}>
                {ask ? (
                  <Button
                    className={classes.action}
                    variant="contained"
                    color="primary"
                    aria-label="attempt"
                    disabled={startDisabled}
                    // onClick={handleDialogOpen}
                  >
                    Start
                  </Button>
                ) : (
                  <Button
                    className={classes.action}
                    variant="contained"
                    color="primary"
                    aria-label="attempt"
                    disabled={startDisabled}
                    onClick={handleTestStart}
                  >
                    Start
                  </Button>
                )}

                {startTime && moment(startTime) > moment().add(serverDiff) ? (
                  <div className={classes.time}>
                    <Typography className={classes.timeText}>
                      Starting in:&nbsp;{" "}
                    </Typography>
                    <Timer
                      TimeDisplay={SimpleTimeDisplay}
                      showZero
                      onChange={onTimeChange}
                      endTime={startTime}
                      offset={serverDiff}
                    />
                  </div>
                ) : null}
                {endTime ? (
                  <div className={classes.time}>
                    <Typography className={classes.timeText}>
                      {`End Time: ${moment(endTime).format(
                        "DD/MM/YY hh:mm A"
                      )}`}
                    </Typography>
                  </div>
                ) : null}
                {timeLimit ? (
                  <div className={classes.time}>
                    <Typography className={classes.timeText}>
                      {`Time Limit: ${timeLimit} minutes`}
                    </Typography>
                  </div>
                ) : null}
              </div>
              {/* <Button
                  // variant="contained"
                  style={{ background: "transparent", marginTop: "30px" }}
                  onClick={() => history.replace("/tests")}
                >
                  Exit
                </Button> */}
              {/* {timeLimit}
              {numQuestions} */}
              {/* <AskForLogin
                open={open}
                handleClose={handleDialogClose}
                continueUrl={continueUrl}
                replace
                blank={false}
              /> */}
            </React.Fragment>
          ) : null}
        </div>
      </Paper>
      {spinner ? <Spinner /> : null}
    </React.Fragment>
  );
};

export default TestIntro;
