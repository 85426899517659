import * as actionTypes from "../actionTypes";

const initialState = {
  userSelected: null
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_USER_SELECTED: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default adminReducer;
