import React, { useState } from "react";
import { connect } from "react-redux";

import {
  Typography,
  makeStyles,
  List,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import { ArrowDropDown, Delete, RemoveCircle } from "@material-ui/icons";
import { ButtonWithPopover } from "../../../shared/hoc/withPopover";
import { ListItemWithConfirm } from "../../../shared/hoc/withConfirm";

import {
  onReviewTestUnassign,
  onReviewTestSubmitsDiscard
} from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  select: {
    textTransform: "none",
    color: theme.palette.secondary.main
  }
}));

const TestReviewActions = (props) => {
  const classes = useStyles();
  const {
    selected,
    onReviewTestUnassign,
    onReviewTestSubmitsDiscard,
    nav: { spinner }
  } = props;

  const numSelected = selected.length || 0;

  const handleTestUnassign = (event) => {
    onReviewTestUnassign(selected);
  };

  const handleTestSubmitDiscard = (event) => {
    onReviewTestSubmitsDiscard(selected);
  };

  const [pop, setPop] = useState(false);
  const actionsPop = () => {
    return (
      <ButtonWithPopover
        disableRipple
        disableFocusRipple
        key={pop}
        popover={
          <List aria-label="More Test Actions">
            <ListItemWithConfirm
              dialog={{
                title: "Discard Submission?",
                text:
                  "This submission will be discarded. The test can be attempted again. This action cannot be reversed.",
                onClose: () => setPop(!pop)
              }}
              button
              onClick={handleTestSubmitDiscard}
            >
              <ListItemIcon>
                <RemoveCircle />
              </ListItemIcon>
              <ListItemText primary="Discard Submission" />
            </ListItemWithConfirm>
            <ListItemWithConfirm
              dialog={{
                title: "Unassign Test?",
                text:
                  "This submission will be deleted and the test wll be unassigned. This action cannot be reversed.",
                onClose: () => setPop(!pop)
              }}
              button
              onClick={handleTestUnassign}
            >
              <ListItemIcon>
                <Delete color="error" />
              </ListItemIcon>
              <ListItemText primary="Unassign Test" />
            </ListItemWithConfirm>
          </List>
        }
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        variant="outlined"
        endIcon={<ArrowDropDown />}
        // className={classes.select}
      >
        <Typography variant="body2" className={classes.select}>
          {numSelected} selected
        </Typography>
      </ButtonWithPopover>
    );
  };

  return (
    <>
      {spinner ? (
        <div className={classes.select}>Updating...</div>
      ) : numSelected > 0 ? (
        <>{actionsPop()}</>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    nav: state.blockNav
  };
};

export default connect(mapStateToProps, {
  onReviewTestUnassign,
  onReviewTestSubmitsDiscard
})(TestReviewActions);
