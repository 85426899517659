import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef
} from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Tabs, Tab, makeStyles, Box } from "@material-ui/core";

import ContentDialog from "../../../shared/components/Modals/ContentDialog1";
import TestGeneralSettings from "./TestGeneralSettings";
import TestTimingSettings from "./TestTimingSettings";
import TestDisplaySettings from "./TestDisplaySettings";
import SubmitReviewSettings from "./SubmitReviewSettings";
import { TestSettingsContext } from "../../context/test-settings-context";

import { onTestSettingsUpdate, onTestBlockNav } from "../../../store/actions";
import { useHistory, useLocation } from "react-router-dom";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const TestSettings = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      textTransform: "none"
    }
  }));
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const { open, handleOpen } = props;
  const { onTestBlockNav, onTestSettingsUpdate } = props;
  const context = useContext(TestSettingsContext);

  const { fields, handleReset, errors, handleErrors } = context;
  const history = useHistory();
  const location = useLocation();
  const settingsRef = useRef();

  useEffect(() => {
    if (open) handleReset(true);
  }, [open, handleReset]);

  const handleDialogClose = useCallback(
    (event) => {
      handleOpen(false);
      // handleReset(true);
      onTestBlockNav(false);
      return Promise.resolve();
    },
    [onTestBlockNav, handleOpen]
  );

  useEffect(() => {
    if (open) {
      if (
        !settingsRef.current &&
        !(location.state && location.state.testSettings)
      ) {
        history.push(location.pathname, { testSettings: true });
        settingsRef.current = true;
      } else if (!(location.state && location.state.testSettings)) {
        handleDialogClose();
        settingsRef.current = false;
      }
    } else {
      if (
        settingsRef.current &&
        location.state &&
        location.state.testSettings
      ) {
        history.goBack();
        // history.replace(location.pathname)
        settingsRef.current = false;
      } else if (location.state && location.state.testSettings) {
        handleOpen(true);
        settingsRef.current = true;
      }
    }
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSettingsUpdated = () => {
    return onTestSettingsUpdate(fields);
  };

  const handleAction = async () => {
    const allGood = await handleValidations().catch((newErrors) => {
      handleErrors({ ...errors, ...newErrors });
    });
    if (allGood) {
      handleSettingsUpdated().then(() => handleDialogClose());
      // handleDialogClose().then(() => handleSettingsUpdated());
    }
  };

  const handleValidations = () => {
    const { name, startTime, endTime, timeLimit } = fields;
    let nameErrorMsg, startTimeErrorMsg, endTimeErrorMsg, timeLimitErrorMsg;
    if (!name) {
      nameErrorMsg = "Name is required";
    }

    if (endTime && startTime && endTime < startTime) {
      startTimeErrorMsg = "Start time should be before end time";
      endTimeErrorMsg = "End time should be after start time ";
    }

    if (
      timeLimit &&
      endTime &&
      startTime &&
      moment(endTime).diff(moment(startTime), "minutes") < timeLimit
      // differenceInMinutes(endTime, startTime) < timeLimit
    ) {
      timeLimitErrorMsg =
        "Time limit should be less than difference between start and end time";
    }

    const errors = {
      nameErrorMsg,
      startTimeErrorMsg,
      endTimeErrorMsg,
      timeLimitErrorMsg
    };
    const isError = Object.values(errors).reduce(
      (numErrors, errorMsg) => (errorMsg ? numErrors + 1 : numErrors),
      0
    );
    if (!isError) {
      return Promise.resolve(true);
    } else return Promise.reject(errors);
  };

  return (
    <React.Fragment>
      <ContentDialog
        open={open}
        handleClose={handleDialogClose}
        dialogTitle="Settings"
        handleAction={handleAction}
        closeDisabled={props.nav.spinner}
        actionDisabled={props.nav.spinner}
      >
        <Tabs
          value={tabValue}
          textColor="primary"
          indicatorColor="primary"
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="instructions display scoring tabs"
          centered
          style={{ borderBottom: "0.5px grey solid" }}
        >
          <Tab label="General" classes={{ root: classes.root }} />
          <Tab label="Timing" classes={{ root: classes.root }} />
          <Tab label="Layout" classes={{ root: classes.root }} />
          <Tab label="Responses" classes={{ root: classes.root }} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <TestGeneralSettings />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <TestTimingSettings />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <TestDisplaySettings />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <SubmitReviewSettings />
        </TabPanel>
      </ContentDialog>
    </React.Fragment>
  );
};

export default connect(
  (state) => ({
    nav: state.blockNav
  }),
  { onTestSettingsUpdate, onTestBlockNav }
)(TestSettings);
