import React from "react";
import clsx from "clsx";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { Button, makeStyles, Typography } from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import QuestionLayout from "./QuestionLayout";
import Image from "../../../shared/components/UIElements/Image";
import { withConfirm } from "../../../shared/hoc/withConfirm";

const IconButtonWithConfirm = withConfirm(IconButton);

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20px",
    width: "100%"
  },
  handle: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  handleHidden: {
    visibility: "hidden"
  },
  qTop: {
    display: "flex",
    alignItems: "flex-start"
  },
  qText: {
    marginLeft: "5px"
  },
  qNumDiv: {
    width: "100%",
    textAlign: "left",
    margin: "4px 0",
    paddingLeft: "10px"
  },
  qNum: {
    fontWeight: 700
  },
  actions: {
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  edit: {
    textTransform: "none",
    opacity: 0.8,
    marginRight: "20px"
  }
}));

const QuestionItem = (props) => {
  const {
    dragHandle,
    idx,
    question,
    handleQuestionEdit,
    handleQuestionDelete,
    handleQuestionDuplicate,
    readOnly
  } = props;

  const { marking, text, image } = question || {};

  const renderScoring = (marking) => {
    let arr = [];
    marking.correctMarks && arr.push("Correct (" + marking.correctMarks + ")");
    marking.incorrectMarks &&
      arr.push("Incorrect (" + marking.incorrectMarks + ")");
    marking.blankMarks && arr.push("Blank (" + marking.blankMarks + ")");
    return arr.length > 0 ? (
      <Typography variant="caption">Scoring: {arr.join(", ")}</Typography>
    ) : null;
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.qNumDiv}>
        <Typography className={classes.qNum} variant="body2">
          {`Question ${idx + 1}`}
        </Typography>
      </div>
      <Card className={classes.root}>
        <div
          className={clsx(
            classes.handle,
            readOnly ? classes.handleHidden : null
          )}
        >
          {dragHandle}
        </div>
        <CardContent style={{ marginBottom: "36px" }}>
          <div style={{ marginBottom: "20px" }}>
            <div className={classes.qText}>
              {text &&
                text.split("\n").map((item, i) => (
                  <Typography align="left" key={i}>
                    {item}
                  </Typography>
                ))}
            </div>
            <div style={{ marginTop: "8px" }}>
              <Image image={image} />
            </div>
          </div>
          <QuestionLayout question={question} />
        </CardContent>
        {/* <Divider style={{ margin: "0 16px" }} /> */}
        <CardActions className={classes.actions}>
          <div style={{ padding: "12px 0 12px 12px" }}>
            {renderScoring(marking)}
          </div>
          {readOnly ? null : (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleQuestionEdit(idx)}
                className={classes.edit}
              >
                Edit
              </Button>
              <Tooltip title="Duplicate">
                <span>
                  <IconButton
                    onClick={(e) => handleQuestionDuplicate(idx)}
                    size="medium"
                  >
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Delete">
                <span>
                  <IconButtonWithConfirm
                    onClick={(e) => handleQuestionDelete(idx)}
                    size="medium"
                    dialog={{
                      title: "Delete Question",
                      text: "Are you sure you want to delete this question?"
                    }}
                  >
                    <Delete />
                  </IconButtonWithConfirm>
                </span>
              </Tooltip>
            </div>
          )}
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default QuestionItem;
