import * as actionTypes from "../actionTypes";

const initialState = {
  error: false,
  submit: {}
};

const submit = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REVIEW_SUBMIT_FETCH_SUCCESS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default submit;
