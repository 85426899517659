import * as actionTypes from "../actionTypes";
import axios from "../../shared/utils/axios";
import { reduceDeep } from "../../shared/utils/funcs";

// Attempt
export const onAttemptFetch = (testId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ATTEMPT_FETCHING
    });
    return axios
      .get("/api/assigns/test/" + testId + "/user")
      .then((response) => {
        dispatch(onAttemptFetchSuccess(response.data));
      })
      .catch((error) => {
        dispatch(onAttemptFetchError(error));
        return Promise.reject(error);
      });
  };
};

export const onAttemptFetchSuccess = (data) => {
  return (dispatch, getState) => {
    const { assign, test } = data;
    dispatch({
      type: actionTypes.ATTEMPT_FETCH_SUCCESS,
      payload: { assign, test }
    });
  };
};

export const onAttemptFetchError = (error) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ATTEMPT_FETCH_ERROR,
      payload: { error: error }
    });
  };
};

export const onAttemptStart = (testId, acknowledged) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ATTEMPT_STARTING
    });
    return axios
      .post("/api/submits/new", { testId, acknowledged })
      .then((response) => {
        dispatch(onAttemptStartSuccess(response.data));
      })
      .catch((error) => {
        dispatch(onAttemptStartError(error));
        return Promise.reject(error);
      });
  };
};

export const onAttemptStartSuccess = (data) => {
  return (dispatch) => {
    const { submit } = data;
    dispatch({
      type: actionTypes.ATTEMPT_START_SUCCESS,
      payload: { submit }
    });
  };
};

export const onAttemptStartError = (error) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ATTEMPT_START_ERROR,
      payload: { error: error }
    });
  };
};

export const onAttemptReset = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ATTEMPT_RESET
    });
  };
};

// deprecated
// export const checkAttempt = (location) => {
//   return async (dispatch, getState) => {
//     const attempt = getState().attempt;
//     const { serverDiff } = getState().blockNav;
//     const { test, assign } = attempt;
//     const { submitsAllowed, lockSubmits, lastSubmit, numberOfSubmits } =
//       assign || {};
//     const { status: lastSubmitStatus } = lastSubmit || {};

//     const { times, intro, open } = test || {};
//     const { disableIntro } = intro || {};
//     const { endTime, startTime } = times || {};

//     const subroute = location.pathname.split("/").slice(-1)[0];
//     let showError, errorType;
//     const user = firebase.auth().currentUser;
//     const idTokenResult = user ? await user.getIdTokenResult() : null;
//     const claims = idTokenResult ? idTokenResult.claims : {};

//     if (!assign && !open && claims.mid !== test.creator._id) {
//       showError = true;
//       errorType = "NOACCESS";
//     } else if (lastSubmitStatus === "IN PROGRESS") {
//       showError = true;
//       errorType = "INPROGRESSSUBMIT";
//     } else if (
//       !open &&
//       (lockSubmits ||
//         (submitsAllowed !== 0 && submitsAllowed <= numberOfSubmits))
//     ) {
//       showError = true;
//       errorType = "NOMORESUBMITS";
//     } else if (endTime && moment(endTime) < moment().add(serverDiff)) {
//       showError = true;
//       errorType = "ENDTIME";
//     }

//     if (
//       disableIntro &&
//       startTime &&
//       moment(startTime) > moment().add(serverDiff).add(15000)
//     ) {
//       showError = true;
//       errorType = "NOTSTARTED";
//     }

//     const introAccepted = location.state && location.state.accepted;

//     const readyToSetup =
//       !showError && (disableIntro || (subroute !== "intro" && introAccepted));

//     const redirectToIntro =
//       !showError && !disableIntro && subroute !== "intro" && !introAccepted;

//     const redirectToStart = !showError && disableIntro && subroute === "intro";

//     // console.log(
//     //   acknowledge,
//     //   showError,
//     //   disableIntro,
//     //   subroute,
//     //   introAccepted,
//     //   readyToSetup,
//     //   redirectToIntro
//     // );

//     // console.log(
//     //   showError,
//     //   errorType,
//     //   readyToSetup,
//     //   redirectToIntro,
//     //   redirectToStart
//     // );

//     return {
//       showError,
//       errorType,
//       readyToSetup,
//       redirectToIntro,
//       redirectToStart
//     };
//   };
// };

// Submit
export const onSubmitFetch = (submitId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SUBMIT_FETCHING
    });
    return axios
      .get("/api/submits/" + submitId)
      .then((response) => {
        const { submit } = response.data;
        const test = submit.testId;
        dispatch(onSubmitFetchSuccess({ submit, test }));
      })
      .catch((error) => {
        dispatch(onSubmitFetchError(error));
        return Promise.reject(error);
      });
  };
};

export const onSubmitFetchSuccess = ({ submit, test }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SUBMIT_FETCH_SUCCESS,
      payload: { submit, test }
    });
  };
};

export const onSubmitFetchError = (error) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SUBMIT_FETCH_ERROR,
      payload: { error }
    });
  };
};

export const onAnswersSetup = () => {
  return (dispatch, getState) => {
    const { submit, test } = getState().submit;

    test.imageUrls = reduceDeep(test, "url");

    const testSectionsMap = new Map(
      test.sections.map((section) => {
        const { questions, ...other } = section;
        const questionIds = questions.map((q) => q._id);
        return [section._id, { questionIds, ...other }];
      })
    );
    test.sectionsMap = testSectionsMap;
    const allTestQuestions = test.sections.reduce(
      (all, section) =>
        all.concat(
          section.questions.map((q) => ({ sectionId: section._id, ...q }))
        ),
      []
    );
    test.questionsMap = new Map(allTestQuestions.map((q) => [q._id, q]));

    let { answers } = submit;
    if (!answers) answers = { testId: test._id, sections: [] };
    const existingSectionIds = answers.sections.map((s) => s.sectionId);
    const newSections = test.sections.map((section) => {
      if (existingSectionIds.includes(section._id)) {
        const existingSection = answers.sections.filter(
          (s) => s.sectionId === section._id
        )[0];
        const existingQuestionIds = existingSection.questions.map(
          (q) => q.questionId
        );
        return {
          sectionId: section._id,
          name: section.name,
          questions: section.questions.map((question) => {
            if (existingQuestionIds.includes(question._id)) {
              return existingSection.questions.filter(
                (q) => q.questionId === question._id
              )[0];
            } else {
              return { questionId: question._id };
            }
          })
        };
      } else {
        return {
          sectionId: section._id,
          name: section.name,
          questions: section.questions.map((q) => {
            return { questionId: q._id };
          })
        };
      }
    });
    answers.sections = newSections;
    // Creating the maps
    const answerSectionsMap = new Map(
      newSections.map((section, i) => {
        const { questions, ...other } = section;
        const questionIds = questions.map((q) => q.questionId);
        return [section.sectionId, { questionIds, index: i, ...other }];
      })
    );
    answers.sectionsMap = answerSectionsMap;
    const allQuestions = newSections.reduce((all, section, sidx) => {
      const questions = section.questions.map((q, i) => ({
        sectionId: section.sectionId,
        index: i,
        sidx: sidx,
        ...q
      }));
      return all.concat(questions);
    }, []);
    answers.questionsMap = new Map(allQuestions.map((q) => [q.questionId, q]));
    answers.questionIds = allQuestions.map((q) => q.questionId);

    const counts = answers.sections.reduce(
      (values, section) => {
        // total
        values.total += section.questions.length;
        // totalAnswered
        values.totalAnswered += section.questions.reduce(
          (v, q) => v + (q.answered ? 1 : 0),
          0
        );
        // totalReview
        values.totalReview += section.questions.reduce(
          (v, q) => v + (q.review ? 1 : 0),
          0
        );
        // ansReview
        values.ansReview += section.questions.reduce(
          (v, q) => v + (q.review && q.answered ? 1 : 0),
          0
        );
        return values;
      },
      { total: 0, totalAnswered: 0, totalReview: 0, ansReview: 0 }
    );
    const answersObj = { ...answers, ...counts };
    dispatch({
      type: actionTypes.SUBMIT_UPDATE_ANSWERS,
      payload: { answers: answersObj, test }
    });
    return Promise.resolve();
  };
};

export const onSubmitSetTime = (submitId, times) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SUBMIT_TIME_SETTING,
      payload: times
    });
    return axios
      .put("/api/submits/v2/savetimes/" + submitId, { times })
      .then((response) => {
        dispatch({
          type: actionTypes.SUBMIT_TIME_SUCCESS
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SUBMIT_TIME_ERROR
        });
        return Promise.reject(error);
      });
  };
};
