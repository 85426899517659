import * as actionTypes from "../actionTypes";

const initialState = {
  test: null,
  assign: null,
  submit: null,
  error: null
};

const submit = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT_RESET:
      return { ...initialState };
    case actionTypes.SUBMIT_FETCH_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case actionTypes.SUBMIT_FETCH_ERROR:
      return { ...state, error: action.payload.error };
    case actionTypes.SUBMIT_TIME_SETTING: {
      return { ...state, submit: { ...state.submit, ...action.payload } };
    }
    case actionTypes.SUBMIT_UPDATE_ANSWERS: {
      return {
        ...state,
        submit: {
          ...state.submit,
          answers: action.payload.answers,
          test: action.payload.test || state.submit.test
        }
      };
    }
    case actionTypes.SUBMIT_REVIEW_ANSWER: {
      return {
        ...state,
        submit: {
          ...state.submit,
          answers: action.payload.answers
        }
      };
    }
    default:
      return state;
  }
};

export default submit;
