import React, { useContext, useState } from "react";
import { connect } from "react-redux";

// import TestsHeader from "../components/Tests/TestsHeader";
import SearchBar from "./SearchBar";
import { IconButton, makeStyles, useMediaQuery } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { TestSearchContext } from "../../../shared/context/test-search-context";

import { onTestsSearch } from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    padding: "0 20px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px",
      width: "150px",      
    },
    maxWidth: "700px",
    flexGrow: 1
  },
}));

const TestSearchBar = (props) => {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const breakpoint = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const classes = useStyles();

  const { onTestsSearch } = props;
  const { handleShowResults, handleResults } = useContext(TestSearchContext);
  const onClose = () => {
    if (breakpoint) setShowSearchBar(false);
  }
  
  return (
    <>
      {!breakpoint || (showSearchBar && breakpoint) ? (
        <div className={classes.searchBar}>
          <SearchBar
            onClose={onClose}
            onSearch={onTestsSearch}
            handleShowResults={handleShowResults}
            handleResults={handleResults}
          />
        </div>
      ) : (
        <IconButton
          onClick={() => setShowSearchBar(true)}
          style={{ color: "inherit" }}
        >
          <Search />
        </IconButton>
      )}
    </>
  );
};

export default connect(null, { onTestsSearch })(TestSearchBar);
