import React from "react";

import { DragIndicator } from "@material-ui/icons";

import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import QuestionOption from "./QuestionOption";

const Container = styled.div`
  display: flex;
  align-items: center;
  /* border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: whitesmoke; */
`;

const Handle = styled.div`
  opacity: 0.5;
  display: flex;
  align-items: center;
`;

const DraggableOption = (props) => {
  const { option, idx } = props;
  return (
    <Draggable draggableId={option._id || option.id} index={idx}>
      {(provided) => (
        <Container {...provided.draggableProps} ref={provided.innerRef}>
          <Handle {...provided.dragHandleProps}>
            <DragIndicator fontSize="small" />
          </Handle>
          <QuestionOption {...props} />
        </Container>
      )}
    </Draggable>
  );
};

export default DraggableOption;
