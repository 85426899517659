import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

const RedirectToLogin = (props) => {
  const login = {
    pathname: "/login",
    search: props.userlogout
      ? null
      : "?goto=" +
        encodeURIComponent(
          window.location.href.replace(window.location.origin, "")
        )
  };
  return <Redirect to={login} />;
};

const mapStateToProps = (state) => {
  return {
    userlogout: state.blockNav.userlogout
  };
};

export default connect(mapStateToProps, null)(RedirectToLogin);
