import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  // useMediaQuery,
  Button,
  ListItem
} from "@material-ui/core";

export const withConfirm = Component => ({ dialog, onClick, ...other }) => {
  // const breakpoint = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const [open, setOpen] = useState(false);

  const { title, text, content, cancel, confirm, fullScreen, onClose } = dialog;

  const handleOpen = event => {
    setOpen(true);
  };

  const handleClose = event => {
    onClose && onClose();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{cancel || "Cancel"}</Button>
          <Button
            onClick={event => {
              handleClose(event);
              onClick(event);
            }}
            color="primary"
            variant="contained"
          >
            {confirm || "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      <Component {...other} onClick={handleOpen} />
    </React.Fragment>
  );
};

export const ListItemWithConfirm = withConfirm(ListItem);
export const ButtonWithConfirm = withConfirm(Button);
