import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import qs from "qs";

// import { Spinner } from "../../shared/components/UIElements/Progress";
import TestReview from "./TestReview";
import Test from "./Test";

// import { colorsMap } from "../components/colors";
import { Tabs, Tab, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  themed: {
    display: "flex",
    // backgroundColor: (props) => colorsMap[props.color][50]
    // backgroundColor: "white"
  },
  // header: (props) => ({
  //   backgroundColor: colorsMap[props.color][500],
  //   color: theme.palette.getContrastText(colorsMap[props.color][500]),
  // }),
}));

const TestRouter = (props) => {
  const { history, match, location } = props;
  const { test } = props;
  const testId = match.params.testId;
  // const { spinner } = props.nav;
  const {
    error,
    details,
    // readOnly
    // theme = { color: "indigo" }
  } = test;

  // const [theme, setTheme] = useState({ color: "indigo" });
  // const classes = useStyles({ color: theme.color });
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);
  const view = qs.parse(location.search, { ignoreQueryPrefix: true }).view;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    query.view = newValue || 0;
    const queryString = qs.stringify(query);
    const path = location.pathname + "?" + queryString;
    history.replace(path);
  };

  // const handleTheme = ({ color }) => {
  //   console.log(color);
  //   setTheme({ color });
  // };

  useEffect(() => {
    if (view === "1" && (!tabValue || tabValue === 0)) setTabValue(1);
  }, [view, tabValue]);

  return (
    <>
      {error && (!details._id || details._id !== testId) ? (
        <Redirect to="/tests" />
      ) : (
        <div>
          {/* <TestHeader
            allowEdit={true}
            handleTitleUpdated={handleTitleUpdated}
            title={testName}
            loading={!testName}
            progress={loading && tabValue === 1}
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            className={classes.header}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {testName && !readOnly ? (
                <TestHeaderActions
                  handleTestDelete={handleTestDelete}
                  handleTestDuplicate={handleTestDuplicate}
                  open={open}
                  testId={testId}
                  testTags={tags || []}
                  theme={theme}
                  handleTheme={handleTheme}
                />
              ) : null}
            </div>
          </TestHeader>
          <div style={{ height: "106px" }} /> */}
          <div>
            <Tabs
              value={tabValue}
              centered
              onChange={handleTabChange}
              indicatorColor="primary"
              // TabIndicatorProps={{ className: classes.tabIndicator }}
            >
              <Tab
                label="Questions"
                style={{ textTransform: "none" }}
                disableFocusRipple
                disableRipple
              />
              <Tab
                label="Submissions"
                style={{ textTransform: "none" }}
                disableFocusRipple
                disableRipple
              />
            </Tabs>
          </div>
          <div className={classes.themed}>
            {view !== "1" ? (
              <Test
                {...props}
                test={test}
                tabValue={tabValue}
                handleTabChange={handleTabChange}
              />
            ) : (
              <TestReview {...props} test={test} />
            )}
          </div>
          {/* {spinner ? <Spinner /> : null} */}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    test: state.test,
    nav: state.blockNav,
  };
};

export default connect(mapStateToProps, {})(TestRouter);
