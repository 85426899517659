import React from "react";
import {
  CircularProgress,
  makeStyles,
  LinearProgress
} from "@material-ui/core";

import DotLoader from "./Loader";

export const Spinner = DotLoader;

export const Spinner1 = () => {
  const classes = makeStyles((theme) => ({
    spinner: {
      position: "fixed",
      top: "50%",
      right: "calc(50% - 24px)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2000
    }
  }))();

  return (
    <>
      <div className={classes.spinner}>
        <CircularProgress size="3rem" color="primary" />
      </div>
    </>
  );
};

export const Loader = (props) => {
  const classes = makeStyles((theme) => ({
    loader: {
      height: "100vh",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    progress: {
      width: "40%",
      minWidth: "200px"
    }
  }))();

  return (
    <>
      <div className={classes.loader}>
        Loading...
        <br />
        <br />
        <LinearProgress className={classes.progress} color="primary" />
      </div>
    </>
  );
};

export const Linear = (props) => {
  const classes = makeStyles((theme) => ({
    progress: {
      width: "100%"
    }
  }))();

  return <LinearProgress className={classes.progress} color="primary" />;
};
