import React from "react";

import QuestionTypeMCQ from "./QuestionTypeMCQ";

import QuestionTypeNum from "./QuestionTypeNum";
import QuestionTypeShort from "./QuestionTypeShort";
import QuestionTypeImage from "./QuestionTypeImage";

const QuestionLayout = React.memo((props) => {
  const { type } = props;

  const questionTypeLayout = (qtype) => {
    if (qtype === "MCQSS" || qtype === "MCQMS") {
      return <QuestionTypeMCQ />;
    }
    if (qtype === "NUMBER") {
      return <QuestionTypeNum />;
    }
    if (qtype === "SHORT") {
      return <QuestionTypeShort />;
    }
    if (qtype === "IMAGE") {
      return <QuestionTypeImage />;
    }
    return null;
  };

  return <>{questionTypeLayout(type)}</>;
});

export default QuestionLayout;
