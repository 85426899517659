import React from "react";

import { Divider } from "@material-ui/core";

import UserSelect from "./UserSelect";
import NotificationCreator from "./NotificationCreator";

const AdminDash = (props) => {
  return (
    <div
      style={{
        margin: "auto",
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "80%"
      }}
    >
      <UserSelect {...props} />
      <Divider style={{ margin: "50px 0" }} />
      <NotificationCreator />
    </div>
  );
};

export default AdminDash;
