import React from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { Clear } from "@material-ui/icons";
import { Fab } from "@material-ui/core";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    "&:hover [name='toshow']": {
      visibility: "visible"
    },
    position: "relative"
  },
  imageBox1: {
    position: "relative",
    marginRight: "10px",
    marginBottom: "10px",
    boxSizing: "border-box",
    display: "flex",
    boxShadow: theme.shadows[1]
  },
  imageBoxThumb: {
    maxWidth: "150px"
  },
  imageBoxLarge: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      minWidth: "50%",
      maxWidth: "480px"
    }
  },
  imageControlsDelete: {
    visibility: "hidden",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "absolute",
    right: -14,
    top: -14
  },
  imageDeleteFab: {
    boxShadow: theme.shadows[1],
    height: "28px",
    minHeight: "28px",
    width: "28px"
  },
  imageDeleteFabLabel: {
    color: theme.palette.action.active
  }
}));

const Image = (props) => {
  const classes = useStyles();

  const { name, image, handleImageChange, removable, thumbnail } = props;

  const { url = "" } = image || {};

  return (
    <React.Fragment>
      {url ? (
        <div className={classes.imageContainer} style={{}}>
          <div
            className={clsx(
              classes.imageBox1,
              thumbnail ? classes.imageBoxThumb : classes.imageBoxLarge
            )}
          >
            <img style={{ width: "100%", height: "auto" }} src={url} alt="q" />
            {removable ? (
              <div className={classes.imageControlsDelete} name="toshow">
                <Fab
                  color="default"
                  size="small"
                  aria-label="more"
                  className={classes.imageDeleteFab}
                  classes={{ label: classes.imageDeleteFabLabel }}
                  onClick={() => handleImageChange({ url: "" }, name)}
                >
                  <Clear fontSize="small" />
                </Fab>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Image);
