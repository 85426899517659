import React from "react";
import { useLocation, Redirect } from "react-router-dom";
import qs from "qs";

const Redirector = (props) => {
  const location = useLocation();
  const { search } = location;
  const terms = qs.parse(search, { ignoreQueryPrefix: true }) || {};
  const redirect = terms["goto"];
  return <Redirect to={redirect || "/"} />;
};

export default Redirector;
