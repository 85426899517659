import React, { useEffect } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import {
  Paper,
  Tabs,
  Tab,
  Box,
  makeStyles,
  Typography
} from "@material-ui/core";

import Login from "../components/Auth/Login";
import Signup from "../components/Auth/Signup";

import {
  onLoginEP,
  onSignupEP,
  // onSignupGoogle,
  onLogout
} from "../../store/actions";
import { Spinner } from "../components/UIElements/Progress";

// import { Link } from "react-router-dom";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const LoginSignup = React.memo((props) => {
  const classes = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      margin: "30px auto 60px auto",
      // backgroundColor: theme.palette.background.paper,
      maxWidth: "400px",
      border: "solid #efefef 1px",
      minHeight: "80vh"
    },
    logo: {
      width: "30%"
    },
    error: {
      margin: "5px 0"
    }
  }))();

  const { selected } = props;
  const {
    onLoginEP,
    onSignupEP
    // onSignupGoogle
  } = props;
  const [tabValue, setTabValue] = React.useState(selected);
  const { abortController, error } = props.auth;
  const { spinner } = props.nav;

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOnLogin = async (email, password) => {
    await onLoginEP({ email, password }).catch((error) => {
      throw error;
    });
  };

  const handleOnSignup = async (email, password, role, name) => {
    await onSignupEP({ email, password, role, name }).catch((error) => {
      throw error;
    });
  };

  // const handleGoogleSignup = async (role) => {
  //   await onSignupGoogle({ role }).catch((error) => {
  //     throw error;
  //   });
  // };

  // const handleGoogleLogin = () => {};

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.cancel("Request is being cancelled");
      }
    };
  }, [abortController]);

  const logo_url =
    "https://firebasestorage.googleapis.com/v0/b/testcrackr09.appspot.com/o/public%2FTC_Logo_Small-removebg-preview.png?alt=media&token=20cf3ae7-a364-4ee7-9195-e469e9cc7683";

  return (
    <>
      {/* <Typography onClick={(e) => onLogout()}>Logout</Typography> */}
      <Paper className={classes.root}>
        <Paper>
          <img src={logo_url} alt="q" className={classes.logo} />
          {/* <h4>{props.auth.user && props.auth.user.email}</h4> */}
          {error ? (
            <Typography color="error" variant="body2" className={classes.error}>
              {error}
            </Typography>
          ) : null}
          <Tabs
            value={tabValue}
            textColor="primary"
            indicatorColor="primary"
            onChange={handleChange}
            variant="fullWidth"
            aria-label="login signup tabs"
            centered
          >
            <Tab label="Login" {...a11yProps(0)} />
            <Tab label="Sign up" {...a11yProps(1)} />
          </Tabs>
        </Paper>
        <TabPanel value={tabValue} index={0}>
          <Login
            handleOnSubmit={handleOnLogin}
            // handleGooglAuth={handleGoogleLogin}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Signup
            handleOnSubmit={handleOnSignup}
            // handleGooglAuth={handleGoogleSignup}
          />
        </TabPanel>
        {/* <Link to="/test/5f563c6f2f337314dc4fbb25"> */}
        {/* <Link to="/test/5f5a6d5775aea727e1224fb9">
          <Button>AnonTEST</Button>
        </Link>
        <Link to="/anon">
          <Button>Anon</Button>
        </Link>
        <Button onClick={props.onLogout}>Logout</Button> */}
      </Paper>
      {spinner ? <Spinner /> : null}
    </>
  );
});

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    nav: state.blockNav
  };
};

export default connect(mapStateToProps, {
  onLoginEP,
  onSignupEP,
  // onSignupGoogle,
  onLogout
})(LoginSignup);
