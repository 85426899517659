import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import clsx from "clsx";
import {
  makeStyles,
  Typography,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox
} from "@material-ui/core";

import { duration as formatDuration } from "../../../shared/utils/formats";
import TestReviewHeader from "./TestReviewHeader";
import TestReviewToolbar from "./TestReviewToolbar";

function descendingComparator(a, b, orderBy) {
  let result = 0;
  if (b[orderBy] < a[orderBy]) {
    result = -1;
  }
  if (b[orderBy] > a[orderBy]) {
    result = 1;
  }
  if (result === 0 && b[orderBy] === 0) {
    result = 1;
  }
  return result;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "95%",
    maxWidth: "800px",
    margin: "0 auto"
    // marginBottom: theme.spacing(6),
    // [theme.breakpoints.up("md")]: {
    //   width: "80%"
    // },
    // [theme.breakpoints.up("lg")]: {
    //   width: "75%"
    // }
  },
  table: {
    minWidth: 750
  },
  cell: { paddingLeft: "8px", paddingRight: "12px" },
  row1: {
    backgroundColor: theme.palette.bg.positive,
    cursor: "pointer",
    "&:hover": { backgroundColor: theme.palette.bg.positiveHover }
  },
  row2: {
    backgroundColor: theme.palette.bg.active,
    "&:hover": { backgroundColor: theme.palette.bg.activeHover }
  },
  row3: { backgroundColor: theme.palette.bg.disabled }
}));

export default function EnhancedTable(props) {
  const { assigns, handleSubmitSelected, test } = props;
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("nameValue");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (selected.length < rows.length) {
      const newSelecteds = rows.map((row) => row.key);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelect = (event, key) => {
    const selectedIndex = selected.indexOf(key);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (key) => selected.indexOf(key) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [columns, setColumns] = useState([]);

  const handleColumns = (i) => {
    const newCols = [...columns];
    newCols[i].show = !newCols[i].show;
    setColumns(newCols);
  };

  const handlePresetColumns = (colsSelected) => {
    const newCols = columns.map((col, i) => {
      if (colsSelected.includes(i)) col.show = true;
      else col.show = false;
      return col;
    });
    setColumns(newCols);
  };

  const handleInitColumns = useCallback(() => {
    if (test)
      setColumns(
        [
          {
            id: "name",
            sortid: "nameValue",
            numeric: false,
            disablePadding: false,
            label: "Name",
            align: "left",
            show: true
          },
          {
            id: "status",
            sortid: "statusValue",
            numeric: false,
            disablePadding: false,
            label: "Status",
            align: "left",
            show: true
          },
          {
            id: "score",
            sortid: "score",
            numeric: true,
            disablePadding: false,
            label: "Score",
            align: "left",
            show: true
          },
          {
            id: "counts",
            sortid: "correctValue",
            numeric: false,
            disablePadding: false,
            label: "Corr. / Incorr.",
            align: "left",
            show: true
          },
          {
            id: "duration",
            sortid: "duration",
            numeric: false,
            disablePadding: false,
            label: "Duration",
            align: "left",
            show: true
          },
          {
            id: "time",
            sortid: "time",
            numeric: false,
            disablePadding: false,
            label: "Submit Time",
            align: "left",
            show: false
          }
        ].concat(
          test.sections.map((s, i) => {
            return {
              id: "section" + i,
              numeric: true,
              disablePadding: true,
              label: s.name,
              align: "left",
              show: false
            };
          })
        )
      );
  }, [test]);

  const handleRows = useCallback(() => {
    if (assigns) {
      const rows = assigns.map((assign) => {
        const assignId = assign._id;
        const { role, _id: userId } = assign.user || {};
        let { name, email } = assign.user || {};
        name = !name ? (role === "anon" ? "Unknown" : email) : name;
        const {
          answers,
          status = "NOT STARTED",
          endTime,
          startedTime,
          submitTime,
          timeTaken,
          logs,
          _id: submitId
        } = assign.lastSubmit || {};
        const statusComponent = (
          <Chip
            label={status}
            variant="outlined"
            size="small"
            disabled={status === "NOT STARTED"}
            style={{ fontSize: "0.8em", textTransform: "lowercase" }}
          />
        );
        const nameComponent = <Typography variant="body2">{name}</Typography>;
        const score =
          answers && (answers.score || answers.score === 0)
            ? answers.score
            : null;
        const { counts: c } = answers || {};
        const corr = c ? (c.correct || 0) + (c.partial || 0) : 0;
        const counts = c ? (
          <Typography variant="body2">
            <span style={{ fontWeight: 600 }}>{corr}</span>
            {" / "}
            <span style={{ color: "grey" }}>{c.incorrect || 0}</span>
          </Typography>
        ) : (
          ""
        );
        const time =
          status === "COMPLETED"
            ? endTime
              ? moment
                  .min(moment(endTime), moment(submitTime))
                  .format("DD/MM hh:mma")
              : moment(submitTime).format("DD/MM hh:mma")
            : status === "IN PROGRESS" && endTime
            ? moment(endTime).format("DD/MM hh:mma")
            : "-";

        const maxDuration =
          endTime && startedTime
            ? endTime >= startedTime
              ? moment(endTime).diff(startedTime)
              : moment(logs[0].time).diff(endTime)
            : null;
        const duration =
          maxDuration && timeTaken
            ? formatDuration(Math.abs(Math.min(maxDuration, timeTaken)))
            : timeTaken
            ? formatDuration(timeTaken)
            : "";
        const scores = {};
        if (test) {
          const sectionIds = test.sections.map((s) => s._id);
          if (answers) {
            for (const i in sectionIds) {
              const sectionId = sectionIds[i];
              scores["section" + i] = answers.sections[sectionId]
                ? answers.sections[sectionId].score || 0
                : 0;
            }
          }
        }
        // const key = Math.random().toString(36).substring(2, 15);
        if (answers)
          for (const i in answers.sections) {
            const section = answers.sections[i];
            scores["section" + i] = section.score || 0;
          }
        return userId
          ? {
              key: { assignId, submitId },
              assignId,
              submitId,
              name: nameComponent,
              nameValue: name,
              status: statusComponent,
              statusValue: status,
              score,
              time,
              duration,
              counts,
              correctValue: c ? c.correct : 0,
              ...scores
            }
          : null;
      });
      setRows(rows.filter((row) => !!row));
    }
  }, [assigns, test]);

  useEffect(() => {
    handleInitColumns();
  }, [handleInitColumns]);

  useEffect(() => {
    handleRows();
    setSelected([]);
  }, [handleRows]);

  return (
    <Paper className={classes.paper}>
      <TestReviewToolbar
        count={rows.length}
        page={page}
        testId={test.details._id}
        test={test}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        selected={selected}
        columns={columns}
        handleColumns={handleColumns}
        handlePresetColumns={handlePresetColumns}
        assigns={assigns}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <TestReviewHeader
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            handleSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            columns={columns}
            handleColumns={handleColumns}
            handlePresetColumns={handlePresetColumns}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.key);

                return (
                  <TableRow
                    // hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={"row" + row.assignId}
                    selected={isItemSelected}
                    className={clsx(
                      classes.row,
                      row.statusValue === "COMPLETED"
                        ? classes.row1
                        : row.statusValue === "IN PROGRESS"
                        ? classes.row2
                        : classes.row3
                    )}
                  >
                    <TableCell className={classes.cell}>
                      <Checkbox
                        onChange={(e) => handleSelect(e, row.key)}
                        checked={isItemSelected}
                      />
                    </TableCell>
                    {columns.map((col) => {
                      return col.show ? (
                        <TableCell
                          padding={col.disablePadding ? "none" : "default"}
                          key={"cell" + col.id}
                          align={col.align || "left"}
                          className={classes.cell}
                          onClick={(event) => {
                            if (row.statusValue === "COMPLETED")
                              handleSubmitSelected(
                                row.submitId,
                                row.statusValue
                              );
                          }}
                        >
                          {row[col.id]}
                        </TableCell>
                      ) : null;
                    })}
                  </TableRow>
                );
              })}
            {rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <div
                    style={{
                      maxWidth: "100vw",
                      margin: "10px 20px",
                      fontStyle: "italic"
                    }}
                  >
                    <Typography>
                      Share the test with students to start seeing information
                      here.
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell colSpan={columns.length}>
                <div style={{ height: "50px" }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
