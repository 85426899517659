import React, { useRef } from "react";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";

import { onCorrectAnsChange } from "../../../store/actions";

const QuestionTypeNum = React.memo((props) => {
  const { onCorrectAnsChange } = props;
  const textRef = useRef();

  const {
    localQuestion: { correctAns }
  } = props.questionEditor;

  const handleCorrectAnsChange = (event) => {
    onCorrectAnsChange(event.target.value);
  };

  const handleTextLocalChange = () => {};

  return (
    <div>
      <TextField
        id="num-ans"
        placeholder="Correct answer"
        defaultValue={correctAns}
        inputRef={textRef}
        onBlur={handleCorrectAnsChange}
        onChange={handleTextLocalChange}        
        InputProps={{ autoComplete: "off" }}
        autoComplete="off"
        helperText="Multiple correct values or range can be specified. E.g. 20, 20.5 or 19.59-20.99"
      />
    </div>
  );
});

const mapStateToProps = (state) => {
  return {
    questionEditor: state.questionEditor
  };
};

export default connect(mapStateToProps, {
  onCorrectAnsChange
})(QuestionTypeNum);
