import React, { useEffect } from "react";
import { connect } from "react-redux";

// share components
import CardLoading from "../../shared/components/UIElements/CardLoading1";

// page components
import TestItem from "../components/TestItem";
import NoAssigns from "../components/NoAssigns";

// actions
import { onAssignsFetch } from "../../store/actions";
import withAppHeader from "../../shared/hoc/withAppHeader";

const Assigns = (props) => {
  const { onAssignsFetch } = props;

  useEffect(() => {
    onAssignsFetch();
  }, [onAssignsFetch]);

  const { error, assigns } = props.assigns || {};
  const { loading } = props.nav;

  return (
    <React.Fragment>
      {/* <PageHeader
        title={headerText}
        progress={spinner || (loading && assigns)}
      /> */}
      <div
        style={{
          // maxWidth: "820px",
          width: "100%",
          padding: "20px 20px 100px 20px",
          boxSizing: "border-box",
        }}
      >
        {error ? (
          <div>Network error or connection timed out.</div>
        ) : loading && (!assigns || assigns.length === 0) ? (
          <CardLoading count={5} />
        ) : assigns && assigns.length > 0 ? (
          assigns
            .filter((a) => !!a.test)
            .map((assign, idx) => (
              <TestItem assign={assign} key={assign._id} idx={idx} />
            ))
        ) : (
          <NoAssigns />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    assigns: state.assigns,
    nav: state.blockNav,
  };
};

const AssignsWithHeader = withAppHeader(
  connect(mapStateToProps, {
    onAssignsFetch,
  })(Assigns)
);

const AssignsPage = (props) => {
  return <AssignsWithHeader {...props} title={"Assigned To Me"} />;
};

export default AssignsPage;
