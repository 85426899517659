import * as actionTypes from "../actionTypes";

const initialState = {
  test: null,
  assign: null,
  submit: null,
  error: null
};

const attempt = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ATTEMPT_RESET:
      return { ...initialState };
    case actionTypes.ATTEMPT_FETCH_SUCCESS:
    case actionTypes.ATTEMPT_START_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case actionTypes.ATTEMPT_FETCH_ERROR:
    case actionTypes.ATTEMPT_START_ERROR:
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
};

export default attempt;
