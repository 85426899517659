import React, { useContext } from "react";
import {
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";

import { TestSettingsContext } from "../../context/test-settings-context";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "20px"
  },
  layoutSelect: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  formControl: {
    width: "100%"
  }
}));

const TestDisplaySettings = (props) => {
  const classes = useStyles();

  const context = useContext(TestSettingsContext);
  const { fields, funcs } = context;
  const { layout } = fields;
  const { handleLayout } = funcs;

  return (
    <>
      <div className={classes.container}>
        <React.Fragment>
          <div className={classes.layoutSelect}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="select-layout">Questions</InputLabel>
              <Select
                labelId="select-layout"
                value={layout || "BY_QUESTION"}
                onChange={handleLayout}
              >
                <MenuItem value={"SINGLE_PAGE"}>
                  All questions on one page
                </MenuItem>
                <MenuItem value={"BY_QUESTION"}>One question per page</MenuItem>
              </Select>
            </FormControl>
          </div>
        </React.Fragment>
      </div>
    </>
  );
};

export default TestDisplaySettings;
