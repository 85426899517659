import React from "react";

import {
  Button,
  Divider,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";

const Fallback = (props) => {
  const classes = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
      width: "100vw",
      padding: "5px"
    },
    paper: { padding: "40px", maxWidth: "600px" },
    line: { display: "flex", alignItems: "center", justifyContent: "center" },
    refresh: {
      textTransform: "none",
      textDecoration: "underline"
    }
  }))();

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.line}>
            <Typography variant="h5">Whooops...</Typography>
          </div>
          <Divider variant="fullWidth" style={{ margin: "10px auto" }} />
          <Typography variant="body1" style={{ marginBottom: "20px" }}>
            An unexpected error occurred.
            <br />
            While we work on fixing it, you can go back to the previous page or{" "}
            <Link to="/">go to the homepage</Link> to continue. <br />
            <br />
          </Typography>
          <Button
            className={classes.refresh}
            onClick={(event) => {
              window.location.reload();
            }}
          >
            Reload Page
          </Button>
        </Paper>
      </div>
    </>
  );
};

export default Fallback;
