import * as actionTypes from "../actionTypes";

const initialState = {
  error: false,
  tests: [],
  loadMore: true,
  sharedTests: [],
  loadMoreShared: true,
  searchOptions: []
};

const testsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TESTS_FETCHING:
    case actionTypes.TESTS_MORE_FETCHING:
    case actionTypes.SHARED_TESTS_FETCHING:
    case actionTypes.TESTS_SEARCHING:
      // loading managed by nav
      return { ...state, error: false };
    case actionTypes.TESTS_FETCH_SUCCESS: {
      // loading managed by nav
      return { ...state, tests: action.payload.tests };
    }
    case actionTypes.TESTS_FETCH_ERROR:
      // loading managed by nav
      return { ...state, error: action.payload.error };
    case actionTypes.TESTS_FETCH_MORE_SUCCESS: {
      // loading managed by nav
      return {
        ...state,
        tests: [...state.tests, ...action.payload.moreTests],
        loadMore: action.payload.loadMore
      };
    }
    case actionTypes.SHARED_TESTS_FETCH_SUCCESS: {
      // loading managed by nav
      return {
        ...state,
        sharedTests: [...state.sharedTests, ...action.payload.sharedTests],
        loadMoreShared: action.payload.loadMoreShared
      };
    }
    case actionTypes.TEST_CREATING:
      // spinner managed by nav
      return { ...state };
    case actionTypes.TEST_CREATE_SUCCESS: {
      // spinner managed by nav
      const tests = [...state.tests];
      tests.unshift(action.payload.test);
      return { ...state, tests };
    }
    case actionTypes.TEST_DUPLICATE_SUCCESS: {
      const tests = [...state.tests];
      if (!tests || tests.length === 0) return { ...state };
      else {
        tests.unshift(action.payload.test);
        return { ...state, tests };
      }
    }
    case actionTypes.TEST_CREATE_ERROR:
      // spinner, snackbar managed in blockNav
      return { ...state };
    case actionTypes.TEST_ASSIGNED: {
      const tests = state.tests.map((test) => {
        if (test._id === action.payload.testId) test.assignsExist = true;
        return test;
      });
      return { ...state, tests };
    }
    case actionTypes.TEST_REMOVING:
      // spinner managed by nav
      return { ...state };
    case actionTypes.TEST_REMOVE_SUCCESS: {
      // spinner managed by nav
      let tests = [...state.tests];
      tests = tests.filter((test) => test._id !== action.payload.id);
      return { ...state, tests: tests };
    }
    case actionTypes.TEST_REMOVE_ERROR:
      // spinner managed by nav
      return { ...state, error: action.payload.error };
    case actionTypes.TEST_NAME_UPDATED: {
      const tests = [...state.tests];
      if (action.payload._id) {
        const test = state.tests.filter((t) => t._id === action.payload._id)[0];
        if(test) test.name = action.payload.name;
        return { ...state, tests: tests };
      } else {
        return { ...state };
      }
    }
    case actionTypes.TEST_EDIT_SUCCESS: {
      const { test } = action.payload;
      const tests = [...state.tests];
      if (test && tests) {
        const _id = test._id;
        const place = tests.findIndex((t) => t._id === _id);
        if (place > -1) tests.splice(place, 1, test);
        return { ...state, tests };
      } else return { ...state };
    }
    default:
      return state;
  }
};

export default testsListReducer;
