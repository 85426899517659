import React from "react"; // useContext
import { connect } from "react-redux";

import { onNumImagesChange } from "../../../store/actions";

import {
  Typography,
  Select,
  Input,
  MenuItem,
  Tooltip,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import { Info } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  underline: {
    "&:before": {
      borderBottom: "none",
    },
    "&.MuiInput-underline:hover": {
      "&:before": {
        borderBottom: "none",
      },
    },
  },
  select: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
}));

const QuestionTypeNum = (props) => {
  const classes = useStyles();

  const { localQuestion } = props.questionEditor;
  const { numImages } = localQuestion;
  const { onNumImagesChange } = props;
  const numImageOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleNumImages = (event) => {
    onNumImagesChange(event.target.value);
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <div className={classes.select}>
        <Tooltip
          placement="bottom-start"
          title={
            <>
              <div className={classes.select}>
                <Typography
                  variant="body2"
                  style={{ marginRight: "20px", fontStyle: "italic" }}
                >
                  Allowed file types
                </Typography>
                <Typography variant="body2" style={{ marginRight: "20px" }}>
                  jpg, jpeg, png
                </Typography>
              </div>
              <div className={classes.select}>
                <Typography
                  variant="body2"
                  style={{ marginRight: "20px", fontStyle: "italic" }}
                >
                  Maximum file size
                </Typography>
                <Typography variant="body2" style={{ marginRight: "20px" }}>
                  1MB
                </Typography>
              </div>
            </>
          }
        >
          <IconButton>
            <Info />
          </IconButton>
        </Tooltip>
        <Typography variant="body1" style={{ marginRight: "20px" }}>
          Number of images:
        </Typography>
        <Select
          value={numImages * 1 || 1}
          onChange={handleNumImages}
          input={
            <Input
              classes={{
                underline: classes.underline,
              }}
            />
          }
          displayEmpty
        >
          {numImageOptions.map((val) => {
            return (
              <MenuItem key={"numImages-" + val} value={val}>
                <Typography>{val}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    questionEditor: state.questionEditor,
  };
};

export default connect(mapStateToProps, {
  onNumImagesChange,
})(QuestionTypeNum);
