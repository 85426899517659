/* Handling */
// type
// trim
// required
// match -- override type
// minLength and maxLength (for non-numbers)
// enum
// min and max for numbers
// TODO: min and max for Dates

// handle types ["number", "integer", "float", "email", "password", "phone", "date"];

export const validate = (item = "", rules) => {
  let message = "";
  let trim = true;

  trim = rules.trim === undefined ? trim : !!rules.trim;
  item = trim && item ? item.toString().trim() : item;

  if (rules.required && (item === null || item === undefined || item === "")) {
    if (rules.required && Array.isArray(rules.required)) {
      message = rules.required[0] ? rules.required[1] || message : message;
    }
    message = message || "A valid entry is required.";
    return message;
  }
  if (rules.type) {
    if (rules.type === "string") {
      const m = item.match(/^[a-zA-Z]+[a-zA-Z0-9-|\s]*$/);
      if (!m) {
        message = "Value is incorrectly formatted";
      }
      return message;
    }
    if (rules.type === "email") {
      const m = item.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/);
      if (!m) {
        message = "Email is badly formatted";
      }
      return message;
    }
    if (rules.type === "password") {
      const m = item.match(/^[\S]{6,}$/);
      if (!m) {
        message = "Password should be at least 6 characters, no spaces";
      }
      return message;
    }
  }

  return Promise.resolve(message);
};
