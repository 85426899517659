import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  // Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
} from "@material-ui/core";
// import { Info } from "@material-ui/icons";

import { onLogout, onAccountFetch } from "../../../store/actions";
import UserAvatarWithInfo from "./UserAvatarWithInfo";
import { GroupAddOutlined, AccountCircleOutlined } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  popover: {
    padding: "16px",
  },
  divider: { margin: "8px 0" },
  profile: {
    padding: "6px 0px 6px 6px",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
}));

const ProfileAvatar = (props) => {
  const classes = useStyles();
  // const user = firebase.auth().currentUser;
  const { name, email, role, isVerified } = props.auth;
  const { iconWithInfo, expandIcon } = props;
  const {
    // totalCredits,
    _id,
  } = props.account;
  const { onLogout, onAccountFetch } = props;
  const [anchorEl, setAnchorEl] = useState();

  const history = useHistory();
  const handleAccountFetch = useCallback(() => {
    onAccountFetch();
  }, [onAccountFetch]);

  useEffect(() => {
    if (!_id && isVerified) {
      handleAccountFetch();
    }
  }, [_id, isVerified, handleAccountFetch]);

  const handleGoToAccount = () => {
    history.push("/account");
    handleClose();
  };

  const handleTutorTests = () => {
    history.push("/t/tests");
  };

  const handleStudentTests = () => {
    history.push("/s/tests");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.profile}>
        <UserAvatarWithInfo
          name={name}
          onClick={handleClick}
          iconWithInfo={iconWithInfo}
          expandIcon={expandIcon}
        />
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.popover}>
          <UserAvatarWithInfo
            name={name}
            email={email}
            size="large"
            iconWithInfo={true}
          />
          <Divider className={classes.divider} />
          {/* <Typography variant="body1" style={{ fontWeight: 600 }}>
            {`Credits remaining: ${totalCredits || 0}`}
          </Typography> */}
          {/* <Button
            variant="outlined"
            startIcon={<Info />}
            color="secondary"
            style={{ width: "100%", textTransform: "none", marginTop: "10px" }}
          >
            What are credits?
          </Button>

          <Divider className={classes.divider} /> */}
          <List>
            <ListItem button onClick={handleGoToAccount}>
              <ListItemIcon>
                <AccountCircleOutlined />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { fontWeight: 600 } }}
                primary="Account Settings"
              />
            </ListItem>
            {role === "admin" ? (
              <>
                <ListItem button onClick={handleTutorTests}>
                  <ListItemIcon>
                    <GroupAddOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ style: { fontWeight: 600 } }}
                    primary="Tutor Tests"
                  />
                </ListItem>
                <ListItem button onClick={handleStudentTests}>
                  <ListItemIcon>
                    <GroupAddOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ style: { fontWeight: 600 } }}
                    primary="Student Tests"
                  />
                </ListItem>
              </>
            ) : null}
            <Divider className={classes.divider} />
            <ListItem
              button
              onClick={(e) => {
                e.preventDefault();
                onLogout();
              }}
            >
              <ListItemText
                secondaryTypographyProps={{ style: { fontWeight: 600 } }}
                secondary="Logout"
              />
            </ListItem>
          </List>
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  account: state.account,
});

export default connect(mapStateToProps, { onLogout, onAccountFetch })(
  ProfileAvatar
);
