import React from "react";

import { DragIndicator } from "@material-ui/icons";

import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import QuestionItem from "./QuestionItem";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Handle = styled.div`
  opacity: 0.4;
  display: flex;
  align-items: center;
  transform: rotate(90deg);
`;

const DraggableQuestion = (props) => {
  const { id, idx } = props;
  return (
    <Draggable draggableId={id} index={idx}>
      {(provided) => (
        <Container {...provided.draggableProps} ref={provided.innerRef}>
          <QuestionItem
            {...props}
            dragHandle={
              <Handle {...provided.dragHandleProps}>
                <DragIndicator fontSize="small" />
              </Handle>
            }
          />
        </Container>
      )}
    </Draggable>
  );
};

export default DraggableQuestion;
