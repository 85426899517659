import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
// import { withCookies } from "react-cookie";

// auth
import LoginSignup from "./LoginSignup";
import Home from "./Home";
// import AnonTest from "./AnonTest";
import NetworkErrorDialog from "../components/UIElements/NetworkErrorDialog";

// import VerifyPage from "./VerifyPage";
import NotAuth from "./NotAuth";
import ErrorBoundary from "./ErrorBoundary";
// import Verify from "../components/Auth/Verify";

// navigation
import SideNav from "../components/Navigation/SideNav";
import NoSideNav from "../components/Navigation/NoSideNav";
import { MenuProvider } from "../../shared/context/menu-context";

// content container
import Container from "./Container";

// admin
// import Admin from "../../admin/pages/Admin";
import AdminDash from "../../admin/pages/AdminDash";

// tutor
import Tests from "../../tutor/pages/Tests";
import SharedTests from "../../tutor/pages/SharedTests";
import SubmitReview from "../../tutor/pages/SubmitReviewPage";
// import Account from "../../tutor/pages/Account";
import Account from "./Account";

// student
import Assigns from "../../student/pages/Assigns";
import TestStart from "../../student/pages/TestStart1";

import TestRouter from "../../tutor/pages/TestPage";
import Submit from "../../student/pages/Submit";
// import LoginAnon from "./LoginAnon";
import Redirector from "./Redirector";
import RedirectToLogin from "./RedirectToLogin";

// import HeroDND from "../../lab/components/HeroDND";

// experimental
// import Clipboard from "../../components/UI/ClipboardExample/Clipboard";
// import PrivateRoute from "../Auth/PrivateRoute/PrivateRoute";

const Main = React.memo(
  ({ isLoggedIn, isVerified, role, networkError, notFoundError }) => {
    let routes;
    if (isLoggedIn) {
      // if (!isVerified && role !== "anon") {
      //   routes = (
      //     <MenuProvider>
      //       <NoSideNav />
      //       <VerifyPage />
      //     </MenuProvider>
      //   );
      // } else {
      if (["tutor", "student", "admin"].includes(role)) {
        routes = (
          <MenuProvider>
            <Switch>
              <Route exact path="/submit/:submitId">
                <NoSideNav />
              </Route>
              <Route exact path="/test/:testId">
                <NoSideNav />
              </Route>
              <Route path="/">
                <SideNav role={role} navEffect />
              </Route>
            </Switch>
            <Container>
              {/* <Switch>
                 <Route
                  path="/admin"
                  exact
                  render={(props) => <Admin {...props} />}
                />
                  <Route
                  path="/dnd"
                  exact
                  render={(props) => <HeroDND {...props} />}
                /> 
                </Switch> */}
              <Switch>
                <Route path="/" exact>
                  {role === "tutor" ? <Redirect to="/t/tests" /> : null}
                  {role === "student" ? <Redirect to="/s/tests" /> : null}
                  {role === "admin" ? <Redirect to="/selectuser" /> : null}
                </Route>
                <Route path="/tests" exact>
                  {role === "tutor" ? <Redirect to="/t/tests" /> : null}
                  {role === "student" ? <Redirect to="/s/tests" /> : null}
                </Route>
                <Route
                  path="/tests/shared"
                  exact
                  render={(props) => <SharedTests {...props} />}
                />
                <Route
                  path="/selectuser"
                  exact
                  render={(props) => <AdminDash {...props} />}
                />
                <Route
                  path="/t/tests"
                  exact
                  render={(props) => <Tests {...props} />}
                />
                <Route
                  path="/s/tests"
                  exact
                  render={(props) => <Assigns {...props} />}
                />
                <Route
                  path="/q/test/:testId"
                  render={(props) => <TestRouter {...props} />}
                />
                <Route
                  path="/test/:testId"
                  render={(props) => <TestStart {...props} />}
                />
                <Route
                  path="/review/:submitId"
                  exact
                  render={(props) => <SubmitReview {...props} />}
                />
                <Route
                  exact
                  path="/submit/:submitId"
                  render={(props) => <Submit {...props} />}
                />
                <Route
                  exact
                  path="/account"
                  render={(props) => <Account {...props} />}
                />
                <Route>
                  <Redirector />
                </Route>
              </Switch>
            </Container>
          </MenuProvider>
        );
      } else {
        routes = (
          <React.Fragment>
            <Switch>
              {/* <Route
                exact
                path="/anon"
                render={(props) => <AnonTest {...props} />}
              /> */}
              <Route
                path="/login"
                render={(props) => <LoginSignup {...props} selected={0} />}
              />
              <Route
                path="/signup"
                render={(props) => <LoginSignup {...props} selected={2} />}
              />
              <Route>
                <RedirectToLogin />
              </Route>
            </Switch>
          </React.Fragment>
        );
      }
      // }
    } else {
      routes = (
        <React.Fragment>
          {/* <Route path="/" render={props => <Layout {...props} />} /> */}
          <Switch>
            <Route path="/" exact>
              <Redirect to="/login" />
            </Route>
            <Route path="/home" exact render={(props) => <Home {...props} />} />
            <Route
              path="/login"
              render={(props) => <LoginSignup {...props} selected={0} />}
            />
            <Route
              path="/signup"
              render={(props) => <LoginSignup {...props} selected={1} />}
            />
            <Route>
              <RedirectToLogin />
            </Route>
            {/* <Route>
            <LoginAnon />
          </Route> */}
          </Switch>
        </React.Fragment>
      );
    }

    return (
      <ErrorBoundary>
        <main>{notFoundError ? <NotAuth /> : routes}</main>
        {/* {isLoggedIn && !isVerified ? <Verify /> : null} */}
        {networkError ? <NetworkErrorDialog /> : null}
      </ErrorBoundary>
    );
  },
);

export default Main;
// export default withCookies(Main);
