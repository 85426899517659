import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import qs from "qs";

import { makeStyles, Typography, Card, CardContent } from "@material-ui/core";

import UserAvatar from "../components/User/UserAvatar";
import EditableType from "../components/UIElements/EditableType";
import withAppHeader from "../../shared/hoc/withAppHeader";

import { onUserNameChange, onPaymentSuccess } from "../../store/actions";
import { Edit } from "@material-ui/icons";
// import SubscriptionLayout from "../components/Account/SubscriptionLayout";
// import PaySuccess from "../components/Account/PaySuccess";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  page: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    maxWidth: "820px",
    margin: "auto",
  },
  cardRoot: {
    flexGrow: 1,
    margin: "10px",
    width: "100%",
    // [theme.breakpoints.up("smd")]: {
    //   maxWidth: "46%"
    // }
  },
  profile: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  email: {
    color: theme.palette.action.active,
  },
  changeEmail: {
    display: "flex",
    alignItems: "center",
  },
  changeEmailText: {
    marginTop: "20px",
    width: "100%",
  },
  editIcon: {
    color: theme.palette.action.active,
  },
}));

const Account = (props) => {
  const classes = useStyles();
  const { onUserNameChange, onPaymentSuccess } = props;
  const { auth } = props;
  const { name, email } = auth;

  const [n, setN] = useState(name);
  const [editTrigger, setEditTrigger] = useState(false);

  // const [paySuccess, setPaySuccess] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const location = useLocation();
  const {
    res: sessionId,
    name: prodName,
    credits: prodCredits,
  } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (sessionId && document.referrer && firstLoad) {
      if (document.referrer === "https://checkout.stripe.com/") {
        // setPaySuccess(true);
        setFirstLoad(false);
        onPaymentSuccess(sessionId, prodName, prodCredits);
      }
    }
  }, [sessionId, onPaymentSuccess, prodName, prodCredits, firstLoad]);

  const handleNameChange = (newName, reset) => {
    setEditTrigger(false);
    if (!reset) {
      setN(newName);
      onUserNameChange(newName);
    }
  };

  // const handlePaySuccessClose = () => {
  //   setPaySuccess(false);
  // };

  return (
    <>
      <div className={classes.page}>
        <Card className={classes.cardRoot} variant="outlined">
          <CardContent style={{ position: "relative" }}>
            <div className={classes.profile}>
              <UserAvatar name={n} className={classes.avatar} />
              <div style={{ marginLeft: "10px", textAlign: "left" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <EditableType
                    editable
                    editTrigger={editTrigger}
                    onEdited={handleNameChange}
                    onEditing={() => setEditTrigger(true)}
                    title={n}
                  />
                  {editTrigger ? null : (
                    <Edit
                      onClick={() => setEditTrigger(true)}
                      fontSize="small"
                      className={classes.editIcon}
                    />
                  )}
                </div>

                {/* <Typography>{name}</Typography> */}
                <Typography variant="body2" className={classes.email}>
                  {email}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
        {/* <SubscriptionLayout />
        <PaySuccess open={paySuccess} handleClose={handlePaySuccessClose} /> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const AccountWithHeader = withAppHeader(
  connect(mapStateToProps, {
    onUserNameChange,
    onPaymentSuccess,
  })(Account)
);

const AccountPage = (props) => {
  return <AccountWithHeader {...props} title={"Account"} />;
};

export default AccountPage;
