import * as actionTypes from "../actionTypes";
import cloneDeep from "lodash.clonedeep";

// SECTION_EDITING, SECTION_CREATING_NEW
export const onSectionEditorLoad = (id) => {
  return (dispatch, getState) => {
    const test = getState().test;
    if (id) {
      const sections = [...test.sections];
      const section = sections.filter((s) => s._id === id)[0];
      const localSection = cloneDeep(section);
      dispatch({
        type: actionTypes.SECTION_EDITING,
        payload: {
          localSection,
          mode: "EDIT_SECTION"
        }
      });
    } else {
      const { marking = {} } = test.settings;
      dispatch({
        type: actionTypes.SECTION_CREATING_NEW,
        payload: {
          mode: "NEW_SECTION",
          marking: marking,
          markingFrom: "TEST"
        }
      });
    }
    return Promise.resolve();
  };
};

// SECTION_NAME_CHANGE
export const onSectionNameChange = (name) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.SECTION_NAME_CHANGE, payload: { name } });
    return Promise.resolve();
  };
};

// SECTION_TEXT_CHANGE
export const onSectionTextChange = (text) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.SECTION_TEXT_CHANGE, payload: { text } });
    return Promise.resolve();
  };
};

// SECTION_MARKING_CHANGE
export const onSectionMarkingChange = (marking) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SECTION_MARKING_CHANGE,
      payload: { marking }
    });
    return Promise.resolve();
  };
};

// SECTION_MARKING_FROM_CHANGE
export const onSectionMarkingFromChange = (markingFrom) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SECTION_MARKING_FROM_CHANGE,
      payload: { markingFrom }
    });
    return Promise.resolve();
  };
};

// SECTION_IMAGE_CHANGE
export const onSectionImageChange = (image) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SECTION_IMAGE_CHANGE,
      payload: { image }
    });
    return Promise.resolve();
  };
};

// SECTION_QTYPE_CHANGE
export const onSectionQTypeChange = (qtype) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SECTION_QTYPE_CHANGE,
      payload: { qtype }
    });
    return Promise.resolve();
  };
};
