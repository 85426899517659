import React, { useState, useCallback } from "react";
import { Prompt } from "react-router-dom";
import clsx from "clsx";
// mui
import {
  makeStyles,
  Typography,
  Button,
  Paper,
  CircularProgress,
  AppBar,
  Toolbar,
  useMediaQuery,
  IconButton,
  // Avatar,
  // ListItem,
  Dialog,
  Drawer,
  // Popover,
  Divider,
  useScrollTrigger,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import { grey, indigo, orange } from "@material-ui/core/colors";
import {
  // Person,
  // List as ListIcon,
  Close,
  Bookmark,
  Replay,
  InfoOutlined,
  ChevronRight
} from "@material-ui/icons";

// import firebase from "../../shared/utils/firebase";

// page components
import Timer from "../components/Timer";
import TimeDisplay from "../components/TimeDisplay";
import ImageComp from "../../shared/components/UIElements/ImageWithPlaceholder";
import Image from "../../shared/components/UIElements/Image";
import { Spinner } from "../../shared/components/UIElements/Progress";
import ReviewPanel from "./ReviewPanel";
import QuestionLayout from "./QuestionLayout";

// import useTraceUpdate from "../../shared/hooks/trace-hook";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  content: (props) => ({
    // backgroundColor: props.themeColor[100],
    padding: "12px 0",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },        
    display: "flex",
    flexDirection: "row",    
    justifyContent: "center",
    maxWidth: "1080px",
    boxSizing: "border-box",
  }),
  content1: {
    width: "100%",
    margin: "auto",
  },
  content2: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  testNameLg: theme.typography.h5,
  testName: { textAlign: "left" },
  topBarActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px 0 10px",
    boxSizing: "border-box"
  },
  lastSaved: {
    textAlign: "left",
    padding: "0 10px 10px 10px",
  },
  lastSavedText: {
    padding: "0 12px",
    fontStyle: "italic",
  },
  lastSavedSpinner: { width: "16px", height: "16px", padding: "0 10px" },
  topBarActionsLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  topBarActionsRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  container: {
    maxWidth: "900px",
    width: "100%",
    // margin: "auto",
    marginBottom: "100px",
  },
  testHeader: {
    padding: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  topBorder: (props) => ({
    // backgroundColor: props.themeColor[600],
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(4),
    // border: "2px solid",
    // borderColor: props.themeColor[600],
    borderRadius: "8px 8px 0 0",
  }),
  titleAcc: {
    "&:before": {
      height: 0,
    },
  },
  actionBar: (props) => ({
    zIndex: theme.zIndex.drawer + 1,
    margin: "6px 0",
    // padding: "0 40%",
    borderBottom: props.trigger ? "1px solid" : "none",
    position: "sticky",
    top: 0,
    // backgroundColor: props.themeColor[100],
    backgroundColor: "white",
    // border: "2px solid",
  }),
  sectionName: (props) => ({
    padding: "12px 24px",
    // backgroundColor: props.themeColor[600],
    backgroundColor: theme.palette.primary.main,
    color: "white",
    textAlign: "left",
  }),
  sectionNameText: {
    fontWeight: 600,
  },
  sectionDesc: { textAlign: "left", padding: "24px" },
  questionRoot: {
    margin: "12px 0",
    // padding: "18px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderRadius: "8px",
  },
  questionNumber: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 24px",
    color: theme.palette.primary.light,
  },
  questionContainer: {
    textAlign: "left",
    flexGrow: 1,
  },
  questionContent: {
    padding: "24px",
  },
  questionText: {
    textAlign: "left",
    paddingBottom: "16px",
  },
  questionImg: {
    paddingBottom: "16px",
  },
  submitBtn: (props) => ({
    textTransform: "none",
    fontWeight: "600",
    border: "none",
    // backgroundColor: props.themeColor[600],
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    margin: "0 8px",
  }),
  reviewBtn: {
    border: "none",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    textTransform: "none",
    fontWeight: "600",
    color: "white",
  },
  actionBtn: {
    textTransform: "none",
    fontWeight: "600",
    margin: "0 4px",
    border: "1px solid transparent",
  },
  saveBtn: (props) => ({
    textTransform: "none",
    fontWeight: "600",
    border: "none",
    // backgroundColor: props.themeColor[400],
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    margin: "0 8px 0 0",
    // padding: "8px 24px",
    // [theme.breakpoints.down("xs")]: {
    //   padding: "8px 8px",
    // },
  }),
  clearBtn: {
    color: grey[600],
    backgroundColor: "none",
    "&:hover": {
      backgroundColor: "none",
    },
  },
  bookmarkBtn: {
    color: grey[600],
    backgroundColor: "none",
    "&:hover": {
      backgroundColor: "none",
    },
  },
  bookmarkBtnOn: {
    color: orange[400],
    "&:hover": {
      color: orange[400],
    },
  },
  profileBtn: {},
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    padding: "12px 12px",
  },
  dialogTitleText: {
    paddingLeft: "12px",
    fontWeight: "600",
  },
  dialogContent: {
    padding: "12px 24px 24px 24px",
  },
  dialogContentText: {
    marginBottom: "12px",
  },
  reviewPanel: {
    position: "relative",
    overflow: "auto",
  },
  drawerPaper: {
    height: "100%",
    width: `${drawerWidth}px`,
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  submitConfirm: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  confirmSubmitBtn: {
    textTransform: "none",
    fontWeight: "600",
    border: "none",
    margin: "20px 8px",
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    color: "white",
  },
  cancelSubmitBtn: {
    textTransform: "none",
    fontWeight: "600",
    border: "none",
    margin: "20px 8px",
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    color: "white",
  },
}));

const SubmitLayout = (props) => {
  // useTraceUpdate(props);
  const {
    lastSaved,
    spinner,
    test,
    answers,
    endTime,
    disabled,
    shouldBlockNav,
    loading,
    // handleTestSave,
    handleTestSubmit,
    handleMSChange,
    handleSSChange,
    handleNumAnsChange,
    handleShortAnsChange,
    handleLongAnsChange,
    handleImagesAnsChange,
    handleMarkForReview,
  } = props;

  const themeColor = indigo;
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 150 });

  const classes = useStyles({ themeColor, trigger });

  const {
    questionsMap: questionsAMap = new Map(),
    // sectionsMap: sectionsAMap = new Map(),
    // questionIds
  } = answers || {};
  const {
    sectionsMap: sectionsTMap = new Map(),
    questionsMap: questionsTMap = new Map(),
    name,
    intro,
    // imageUrls
  } = test || {};
  const { instructions, introImage } = intro || {};

  const qRefs = {};
  // const user = firebase.auth().currentUser;
  const breakpoint = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const [submitConfirm, setSubmitConfirm] = useState(null);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [testInsOpen, setTestInsOpen] = useState(false);
  // const [morePopup, setMorePopup] = useState(null);

  // const handleMorePopupOpen = (event) => {
  //   setMorePopup(event.target);
  // };

  // const handleMorePopupClose = (event) => {
  //   setMorePopup(null);
  // };

  const handleTestInsOpen = (event) => {
    setTestInsOpen(true);
    // setMorePopup(null);
  };

  const handleTestInsClose = (event) => {
    setTestInsOpen(false);
  };

  const handleReviewOpen = () => {
    setReviewOpen(true);
  };

  const handleReviewClose = () => {
    setReviewOpen(false);
  };

  const handleSubmitConfirmOpen = (event) => {
    setSubmitConfirm(event.target);
  };

  const handleSubmitConfirmClose = (event) => {
    setSubmitConfirm(null);
  };

  const handleReview = (question, value) => {
    handleMarkForReview(question.questionId, value);
  };

  const handleClearAns = (question, event) => {
    switch (questionsTMap.get(question.questionId).type) {
      case "MCQMS":
        return handleMSChange(question.questionId);
      case "MCQSS":
        return handleSSChange(question.questionId);
      case "NUMBER":
        return handleNumAnsChange(question.questionId);
      case "SHORT":
        return handleShortAnsChange(question.questionId);
      case "LONG":
        return handleLongAnsChange(question.questionId);
      case "IMAGE":
        return handleImagesAnsChange(question.questionId);
      default:
        return;
    }
  };

  const handleTimeUp = useCallback(
    (clock) => {
      const { diff } = clock;
      if (diff <= 0) {
        handleTestSubmit("EXPIRED");
      }
    },
    [handleTestSubmit]
  );

  const renderQuestionNumber = (qa, qidx) => {
    return (
      <div className={classes.questionNumber}>
        <div style={{ minWidth: "40%" }}>
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {`Question ${qidx + 1}. `}
          </Typography>
        </div>
        <div style={{ textAlign: "right" }}>
          {qa.answered ? (
            <Button
              size={breakpoint ? "small" : "medium"}
              className={clsx(classes.actionBtn, classes.clearBtn)}
              onClick={(e) => {
                e.preventDefault();
                handleClearAns(qa, e);
              }}
              startIcon={breakpoint ? null : <Replay fontSize="small" />}
              variant="outlined"
            >
              {breakpoint ? "Clear" : "Clear response"}
            </Button>
          ) : null}
          {!breakpoint ? (
            <Button
              size="medium"
              className={clsx(
                classes.actionBtn,
                qa.review ? classes.bookmarkBtnOn : classes.bookmarkBtn
              )}
              onClick={(e) => {
                e.preventDefault();
                handleReview(qa, !qa.review);
              }}
              variant="outlined"
              startIcon={<Bookmark fontSize="default" />}
              disableRipple
              disableFocusRipple
            >
              {qa.review ? "Marked for review" : "Mark for review"}
            </Button>
          ) : (
            <IconButton
              // size="small"
              className={clsx(
                classes.actionBtn,
                qa.review ? classes.bookmarkBtnOn : classes.bookmarkBtn
              )}
              onClick={(e) => {
                e.preventDefault();
                handleReview(qa, !qa.review);
              }}
            >
              <Bookmark
                className={clsx(
                  qa.review ? classes.bookmarkBtnOn : classes.bookmarkBtn
                )}
                fontSize="default"
              />
            </IconButton>
          )}
        </div>
      </div>
    );
  };

  const renderQuestionText = (question) => {
    return (
      <>
        {question.text ? (
          <Typography className={classes.questionText}>
            {question.text}
          </Typography>
        ) : null}
      </>
    );
  };

  const renderQuestionImage = (question) => {
    return (
      <div className={classes.questionImg}>
        <Image image={question.image} />
      </div>
    );
  };

  const renderQuestionComponent = (question, questionAns) => {
    return (
      <div className={classes.questionLayout}>
        <QuestionLayout
          question={question}
          questionAns={questionAns}
          classes={classes}
          disabled={disabled}
          handleMSChange={handleMSChange}
          handleSSChange={handleSSChange}
          handleNumAnsChange={handleNumAnsChange}
          handleShortAnsChange={handleShortAnsChange}
          handleLongAnsChange={handleLongAnsChange}
          handleImagesAnsChange={handleImagesAnsChange}
        />
      </div>
    );
  };

  const renderQuestion = (questionId) => {
    let qId = questionId;
    if (!qId) return;
    const qa = questionsAMap.get(qId);
    const qt = questionsTMap.get(qId);
    const refId = qa.sectionId + qId;
    const qRef = React.createRef();
    qRefs[refId] = qRef;
    return (
      <Paper
        ref={qRef}
        key={qId}
        className={classes.questionRoot}
        elevation={0}
      >
        <div className={classes.questionContainer}>
          {renderQuestionNumber(qa, qa.index)}
          <Divider />
          <div className={classes.questionContent}>
            {renderQuestionText(qt)}
            {renderQuestionImage(qt)}
            {renderQuestionComponent(qt, qa)}
          </div>
        </div>
        {/* {renderQuestionScoring(question)} */}
      </Paper>
    );
  };

  const renderTestTitle = () => {
    return (
      <Paper style={{ borderRadius: "8px" }}>
        <div className={classes.topBorder}></div>
        <div className={classes.testHeader}>
          <Typography className={clsx(classes.testName, classes.testNameLg)}>
            {name}
          </Typography>
          {instructions || introImage ? (
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleTestInsOpen(e);
              }}
              disableRipple
              disableFocusRipple
            >
              <InfoOutlined />
            </IconButton>
          ) : null}
        </div>
      </Paper>
    );
  };

  const renderActionBar = () => {
    return (
      <div className={classes.actionBar}>
        <div className={classes.topBarActions}>
          <div className={classes.topBarActionsLeft}>
            {/* <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleMorePopupOpen(e);
              }}
              className={classes.profileBtn}
            >
              <Person />
            </IconButton> */}            
            {/* <Button
              className={clsx(classes.saveBtn)}
              disableFocusRipple
              onClick={(e) => {
                e.preventDefault();
                handleTestSave(e);
              }}
              size="large"
              variant="outlined"
            >
              {breakpoint ? "Save" : "Save answers"}
            </Button> */}
            <Button
              className={classes.submitBtn}
              variant="outlined"
              disableFocusRipple
              onClick={(e) => {
                e.preventDefault();
                handleSubmitConfirmOpen(e);
              }}
              size="large"
            >
              Submit
            </Button>
          </div>
          <div className={classes.topBarActionsRight}>
            {endTime ? renderTimer() : null}
            {reviewOpen && !breakpoint ? null : (
            <Button
              className={classes.reviewBtn}
              variant="outlined"
              size="large"
              disableFocusRipple              
              onClick={(e) => {
                e.preventDefault();
                handleReviewOpen(e);
              }}
              endIcon={breakpoint ? null : <ChevronRight />}
            >
              Review
            </Button>
            )}
          </div>
        </div>
        <div className={classes.lastSaved}>
          {spinner ? (
            <CircularProgress
              className={classes.lastSavedSpinner}
              style={{ width: "16px", height: "16px", padding: "0 10px" }}
            />
          ) : lastSaved ? (
            <Typography className={classes.lastSavedText} variant="caption">
              {lastSaved}
            </Typography>
          ) : null}
        </div>
      </div>
    );
  };

  const renderSection = (section) => {
    return (
      <div key={section._id} style={{ margin: "10px 0"}}>
        <Card>
          <CardHeader
            title={section.name}
            className={classes.sectionName}
            classes={{ title: classes.sectionNameText }}
            titleTypographyProps={{ variant: "body1" }}
          />
          {section.text || section.image ? <CardContent className={classes.sectionDesc}>
            {section.text ? (
              <div>
                {section.text &&
                  section.text.split("\n").map((item, i) => (
                    <Typography variant="body1" key={"sectext" + i}>
                      {item}
                    </Typography>
                  ))}
              </div>
            ) : null}
            {section.image ? (
              <div className={classes.sectionDesc}>
                <Image image={section.image} />
              </div>
            ) : null}
          </CardContent> : null}
          
        </Card>
        {questionsAMap &&
          questionsAMap.size > 0 &&
          section.questionIds.map((qId) => {
            if (questionsAMap.has(qId)) {
              return renderQuestion(qId);
            } else return null;
          })}
      </div>
    );
  };

  const renderTimer = () => (
    <Timer
      onChange={handleTimeUp}
      endTime={endTime}
      showZero
      // offset={serverDiff}
      TimeDisplay={TimeDisplay}
    />
  );

  // Main render
  return (
    <React.Fragment>
      <Prompt
        when={shouldBlockNav}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {test ? (
        <>
          <div className={
                reviewOpen && !breakpoint
                  ? clsx(classes.content, classes.content2)
                  : clsx(classes.content, classes.content1)
              }>
            <div className={classes.container}>
              {renderTestTitle()}
              {renderActionBar()}
              {/* {Array.from(sectionsAMap.keys()).map((id, i) => {
                const section = sectionsTMap.get(id);
                if (section) return renderSection(section);
                else return null;
              })} */}
              {Array.from(sectionsTMap.values()).map((section) =>
                renderSection(section)
              )}
              {!breakpoint ? (
                <div style={{ textAlign: "left" }}>
                  <Button
                    className={classes.submitBtn}
                    variant="outlined"
                    disableFocusRipple
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmitConfirmOpen(e);
                    }}
                    size="large"
                  >
                    Submit
                  </Button>
                </div>
              ) : null}
            </div>          

            {/* Test Instructions Dialog */}
            <Dialog
              fullScreen={breakpoint ? true : false}
              open={testInsOpen}
              onClose={handleTestInsClose}
              fullWidth
            >
              <AppBar color="default" position="sticky" elevation={0}>
                <Toolbar variant="dense" className={classes.dialogTitle}>
                  <Typography variant="body1" className={classes.dialogTitleText}>
                    {name}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={(e) => {
                      e.preventDefault();
                      handleTestInsClose(e);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Toolbar>
              </AppBar>

              <div className={classes.dialogContent}>
                <div className={classes.dialogContentText}>
                  {instructions &&
                    instructions
                      .split("\n")
                      .map((item, i) => (
                        <Typography key={"ins" + i}>{item}</Typography>
                      ))}
                </div>
                <ImageComp image={introImage} />
              </div>
            </Dialog>

            {/* Submit Confirm Popover */}
                      <Dialog
              open={Boolean(submitConfirm)}
              // anchorEl={submitConfirm}
              // anchorOrigin={{
              //   vertical: "center",
              //   horizontal: "right",
              // }}
              // transformOrigin={{
              //   vertical: "center",
              //   horizontal: "right",
              // }}
              fullWidth
              maxWidth="xs"
              classes={{
                paper: classes.submitConfirm,
              }}
              onClose={handleSubmitConfirmClose}
              disableRestoreFocus
            >
              <Button
                variant="contained"
                className={classes.confirmSubmitBtn}
                onClick={(e) => {
                  e.preventDefault();
                  handleTestSubmit("USER");
                  handleSubmitConfirmClose(e);
                }}
                size="large"
              >
                Confirm Submit
              </Button>
              <Button
                variant="contained"
                className={classes.cancelSubmitBtn}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmitConfirmClose(e);
                }}
                size="large"
              >
                Cancel
              </Button>
            </Dialog>
            
            {/* More Options Popover */}
            {/* <Popover
              open={Boolean(morePopup)}
              anchorEl={morePopup}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handleMorePopupClose}
              disableRestoreFocus
              disableScrollLock
            >
              <div style={{ padding: "12px" }}>
                <ListItem style={{ display: "flex" }}>
                  <Avatar style={{ marginRight: "10px" }}>
                    <Person />
                  </Avatar>
                  <div>
                    <Typography>{user.displayName}</Typography>
                    <Typography variant="body2">{user.email}</Typography>
                  </div>
                </ListItem>
              </div>
            </Popover> */}
          </div>
          {/* Review Panel Dialog */}
          <Drawer
            open={reviewOpen}
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
            anchor={breakpoint ? "bottom" : "right"}
            onClose={handleReviewClose}
            variant={!breakpoint && reviewOpen ? "permanent" : "temporary"}
          >
            <div className={classes.reviewPanel}>
              <ReviewPanel
                qRefs={qRefs}
                answers={answers}
                open={reviewOpen}
                handleClose={handleReviewClose}
                handleQuestionSelected={null}
              />
            </div>
          </Drawer>
        </>
      ) : null}
      {loading ? <Spinner /> : null}
    </React.Fragment>
  );
};

export default SubmitLayout;
