import * as actionTypes from "../actionTypes";
import axios from "../../shared/utils/axios";
// import { omitDeep } from "../../shared/utils/funcs";

// TESTS_FETCHING
export const onTestsFetch = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TESTS_FETCHING
    });
    return axios
      .get("/api/tests", {
        // params: {
        //   _limit: 10,
        //   _skip: 0
        // }
      })
      .then((response) => {
        const tests = response.data;
        dispatch(onTestsFetchSuccess(tests));
      })
      .catch((error) => dispatch(onTestsFetchError(error)));
  };
};

// TESTS_FETCH_SUCCESS
export const onTestsFetchSuccess = (tests) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TESTS_FETCH_SUCCESS,
      payload: { tests }
    });
  };
};

// TESTS_FETCH_ERROR
export const onTestsFetchError = (error) => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.TESTS_FETCH_ERROR,
      payload: { error: error }
    });
};

// TESTS_MORE_FETCHING
export const onTestsFetchMore = (options) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TESTS_MORE_FETCHING
    });
    const { skip = 0 } = options || {};
    return axios
      .get("/api/tests", {
        params: {
          _limit: 10,
          _skip: skip
        }
      })
      .then((response) => {
        const tests = response.data;
        dispatch(onTestsFetchMoreSuccess(tests, 10));
      })
      .catch((error) => dispatch(onTestsFetchError(error)));
  };
};

// TESTS_FETCH_MORE_SUCCESS
export const onTestsFetchMoreSuccess = (moreTests, limit) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TESTS_FETCH_MORE_SUCCESS,
      payload: { moreTests, loadMore: !(moreTests.length < limit) }
    });
  };
};

// SHARED_TESTS_FETCHING
export const onSharedTestsFetch = (options) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SHARED_TESTS_FETCHING
    });
    const { skip = 0 } = options || {};
    return axios
      .get("/api/tests/shared", {
        params: {
          _limit: 10,
          _skip: skip
        }
      })
      .then((response) => {
        const tests = response.data;
        dispatch(onSharedTestsFetchSuccess(tests, 10));
      })
      .catch((error) => dispatch(onTestsFetchError(error)));
  };
};

// SHARED_TESTS_FETCH_SUCCESS
export const onSharedTestsFetchSuccess = (sharedTests, limit) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHARED_TESTS_FETCH_SUCCESS,
      payload: { sharedTests, loadMoreShared: !(sharedTests.length < limit) }
    });
  };
};

// TESTS_SEARCHING, TESTS_FETCH_ERROR
export const onTestsSearch = (query) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TESTS_SEARCHING
    });
    return axios
      .get("/api/tests/search/" + query, {
        // params: {
        //   _limit: 20
        // }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) =>
        dispatch({
          type: actionTypes.TESTS_FETCH_ERROR,
          payload: { error }
        })
      );
  };
};

// TEST_CREATING
export const onTestCreate = (name) => {
  return (dispatch, getState) => {    
    dispatch({
      type: actionTypes.TEST_CREATING
    });
    const marking = {
      name: "custom",
      correctMarks: 1,
      incorrectMarks: 0,
      blankMarks: 0,
      compute: true
    }
    const test = {
      name: name,
      numQuestions: 0,
      numSections: 0,
      sections: [
        { name: "Section 1", marking, markingFrom: "TEST", qtype: "MCQSS" }
      ],
      marking,
      display: { layout: "BY_QUESTION" }
    };
    return axios
      .post("/api/tests", test)
      .then(
        (response) => {
          const test = response.data;
          dispatch(onTestCreateSuccess(test));
          return test._id;
        },
        (error) => {
          dispatch(onTestCreateError(error));
        }
      )
      .catch((error) => {
        dispatch(onTestCreateError(error));
      });
  };
};

// TEST_CREATING on Duplication
export const onTestDuplicate = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TEST_CREATING
    });

    return axios
      .post("/api/tests/" + id + "/")
      .then((response) => {
        const test = response.data;
        dispatch(onTestDuplicateSuccess(test));
        return test._id;
      })
      .catch((error) => {
        dispatch(onTestCreateError(error));
      });
  };
};

// TEST_CREATE_SUCCESS
export const onTestCreateSuccess = (test) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TEST_CREATE_SUCCESS,
      payload: { test }
    });
  };
};

// TEST_CREATE_SUCCESS
export const onTestDuplicateSuccess = (test) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TEST_DUPLICATE_SUCCESS,
      payload: { test }
    });
  };
};

// TEST_CREATE_ERROR
export const onTestCreateError = (error) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TEST_CREATE_ERROR,
      payload: { message: error && error.message ? error.message : "" }
    });
  };
};

// TEST_REMOVING
export const onTestRemove = (id) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TEST_REMOVING
    });
    return axios
      .delete("/api/tests/" + id)
      .then((response) => {
        dispatch(onTestRemoveSuccess(id));
      })
      .catch((error) => {
        dispatch(onTestRemoveError(error));
      });
  };
};

// TEST_REMOVE_SUCCESS
export const onTestRemoveSuccess = (id) => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.TEST_REMOVE_SUCCESS,
      payload: { id: id }
    });
};

// TEST_REMOVE_ERROR
export const onTestRemoveError = (error) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TEST_REMOVE_ERROR,
      payload: { error: error }
    });
  };
};
