const dev = {
  BASE_API_URL:
    "https://testcrackr-qa-serve-tajindersingh30132969.codeanyapp.com",
  GA_TRACKING_ID_GA4: "G-TNSTMWFPFB", // UA
  GA_TRACKING_ID_UA: "UA-172009381-2", // GA4
  SENTRY_DSN:
    "https://0c0980c4eeec481191bf4be7158cbd4c@o574843.ingest.sentry.io/5726427",
  FB_CONFIG: {
    /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
    apiKey: "AIzaSyCTGq_SjRMfjUF3iG8HKxUau6nXS2kDNR0",
    authDomain: "testcrackr09.firebaseapp.com",
    databaseURL: "https://testcrackr09.firebaseio.com",
    projectId: "testcrackr09",
    storageBucket: "testcrackr09.appspot.com",
    messagingSenderId: "528239125835",
    appId: "1:528239125835:web:972a8f96a8485fe4d0f4ce",
    measurementId: "G-FEQQWDE76R"
  },
  STRIPE_PUBLISHABLE_KEY:
    "pk_test_51Is6iUKHi16hnjiibJNfMqeFEnqlp8huYG4KcXHT8ATRnlnuE3qWtrhH6OHUO0DEt8JBYosHvsyKvZ0aNzgPzBL000HtFgOAcX"
};

const qa = {
  BASE_API_URL:
    "https://testcrackr-qa-serve-tajindersingh30132969.codeanyapp.com",
  GA_TRACKING_ID_GA4: "G-TNSTMWFPFB", // UA
  GA_TRACKING_ID_UA: "UA-172009381-2", // GA4
  SENTRY_DSN:
    "https://0c0980c4eeec481191bf4be7158cbd4c@o574843.ingest.sentry.io/5726427",
  FB_CONFIG: {
    /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
    apiKey: "AIzaSyCTGq_SjRMfjUF3iG8HKxUau6nXS2kDNR0",
    authDomain: "testcrackr09.firebaseapp.com",
    databaseURL: "https://testcrackr09.firebaseio.com",
    projectId: "testcrackr09",
    storageBucket: "testcrackr09.appspot.com",
    messagingSenderId: "528239125835",
    appId: "1:528239125835:web:972a8f96a8485fe4d0f4ce",
    measurementId: "G-FEQQWDE76R"
  },
  STRIPE_PUBLISHABLE_KEY:
    "pk_test_51Is6iUKHi16hnjiibJNfMqeFEnqlp8huYG4KcXHT8ATRnlnuE3qWtrhH6OHUO0DEt8JBYosHvsyKvZ0aNzgPzBL000HtFgOAcX"
};

const prod = {
  BASE_API_URL: "https://api.testcrackr.app",
  GA_TRACKING_ID_GA4: "G-JTLQ1YJPJ4", // UA
  GA_TRACKING_ID_UA: "UA-172009381-3", // GA4
  SENTRY_DSN:
    "https://cad3783da8d145adb49623ba8455596b@o574843.ingest.sentry.io/5726387",
  FB_CONFIG: {
    /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
    apiKey: "AIzaSyCTGq_SjRMfjUF3iG8HKxUau6nXS2kDNR0",
    authDomain: "testcrackr09.firebaseapp.com",
    databaseURL: "https://testcrackr09.firebaseio.com",
    projectId: "testcrackr09",
    storageBucket: "testcrackr09.appspot.com",
    messagingSenderId: "528239125835",
    appId: "1:528239125835:web:972a8f96a8485fe4d0f4ce",
    measurementId: "G-FEQQWDE76R"
  },
  STRIPE_PUBLISHABLE_KEY:
    "pk_test_51Is6iUKHi16hnjiibJNfMqeFEnqlp8huYG4KcXHT8ATRnlnuE3qWtrhH6OHUO0DEt8JBYosHvsyKvZ0aNzgPzBL000HtFgOAcX"
};

const env = [prod, qa, dev];

// Default to dev if not set
// const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;
const config =
  process.env.REACT_APP_STAGE === "prod"
    ? env[0]
    : process.env.REACT_APP_STAGE === "qa"
    ? env[1]
    : env[2];

export default {
  // Add common config values here
  // MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
